import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    height: {
      type: Number,
      defautl: ''
    },
    page: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: true,
      maxLen: 0,
      onlyMaxMin: false,
      list: []
    }
  },
	methods: {
    setData (list) {
      this.list = list;

      if (this.setDatas) {
        this.setDatas();
      }
      
    },
    handleSelectionChange (val) {
      this.$emit("select", val);
    },
    calcMaxMinTimes ({max, min}) {

      let maxTimes = 1;
      let minTimes = 1;

      if (max > 1000) {
        maxTimes += 0.0005;
      } else if (max > 100) {
        maxTimes += 0.005;
      } else {
        maxTimes += 0.05;
      }

      if (min > 1000) {
        minTimes -= 0.0005;
      } else if (min > 100) {
        minTimes -= 0.005;
      } else {
        minTimes -= 0.05;
      }

      return { maxTimes, minTimes };
    },
    calcMaxMin (metaData, xAxis) {

      const object = {};
      const maxes = [], mins = [], middles = []; // 这里是按横坐标来存

      for (let i = 0, len = metaData[0].length; i < len; i++) {
        const arr = [];
        for (let item of metaData) {
          arr.push(item[i]);
        }

        let tmpArr = [].concat(arr);
        tmpArr = tmpArr.sort((a, b) => {
          return a- b;
        });

        let max = Math.max(... arr); // 这里的最大最小值是每一个时间点的最大最小值
        let min = 0, index = 0, len = tmpArr.length;
        for (; index < len; index++) {
          const item = tmpArr[index];
          if (item === null) continue;
          min = item;
          break;
        } 
        
        let middle = null;
        tmpArr = tmpArr.slice(index);
        let tmpMiddleIndex = Math.trunc(tmpArr.length / 2);
        if (tmpMiddleIndex > tmpArr.length / 2) {
          tmpMiddleIndex = tmpArr.length - 1;
        }
        if (tmpMiddleIndex > 0) {
          middle = tmpArr[tmpMiddleIndex]; 
        }
        

        let maxIndex = arr.indexOf(max);
        let minIndex = arr.indexOf(min);
        let middleIndex = arr.indexOf(middle);

        maxes.push(max);
        mins.push(min);
        middles.push(middle);

        const key = xAxis[maxes.length - 1];
        object[key] = {
          max: maxIndex + 1, min: minIndex + 1, middle: middleIndex // 加1 的原因是 C、T的编号是从 1 开始，而数组的编号是从 0 开始
        }
      }

      return {
        max: maxes,
        min: mins,
        middle: middles,
        object
      }
    }
	}
}