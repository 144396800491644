<template>
  <div v-loading="loading">
    <el-table :data="table" border style="width: 100%" :height="height" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
      <el-table-column label="上N月">
        <template slot-scope="scope">
          上{{ scope.row.$index }}月
        </template>
      </el-table-column>
      <el-table-column label="冻结时间"></el-table-column>
      <el-table-column label="正向总有功电能"></el-table-column>
      <el-table-column label="正向有功尖电能"></el-table-column>
      <el-table-column label="正向有功峰电能"></el-table-column>
      <el-table-column label="正向有功平电能"></el-table-column>
      <el-table-column label="正向有功谷电能"></el-table-column>
      <el-table-column label="反向总有功电能"></el-table-column>
      <el-table-column label="反向有功尖电能"></el-table-column>
      <el-table-column label="反向有功峰电能"></el-table-column>
      <el-table-column label="反向有功平电能"></el-table-column>
      <el-table-column label="反向有功谷电能"></el-table-column>
      <el-table-column label="正向总无功电能"></el-table-column>
      <el-table-column label="正向无功尖电能"></el-table-column>
      <el-table-column label="正向无功峰电能"></el-table-column>
      <el-table-column label="正向无功平电能"></el-table-column>
      <el-table-column label="正向无功谷电能"></el-table-column>
      <el-table-column label="反向总无功电能"></el-table-column>
      <el-table-column label="反向无功尖电能"></el-table-column>
      <el-table-column label="反向无功峰电能"></el-table-column>
      <el-table-column label="反向无功平电能"></el-table-column>
      <el-table-column label="反向无功谷电能"></el-table-column>
      <el-table-column label="A相有功电能"></el-table-column>
      <el-table-column label="B相有功电能"></el-table-column>
      <el-table-column label="C相有功电能"></el-table-column>
      <el-table-column label="正向有功最大需量"></el-table-column>
      <el-table-column label="正向有功最大需量发生时间"></el-table-column>
      <el-table-column label="反向有功最大需量"></el-table-column>
      <el-table-column label="反向有功最大需量发生时间"></el-table-column>
      <el-table-column label="正向无功最大需量"></el-table-column>
      <el-table-column label="正向无功最大需量发生时间"></el-table-column>
      <el-table-column label="反向无功最大需量"></el-table-column>
      <el-table-column label="反向无功最大需量发生时间"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.getDatas();
  },
  methods: {
    getDatas() {

    }
  }
}
</script>