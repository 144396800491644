var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      staticStyle: { height: "100%" },
      attrs: { "tab-position": "left", "before-leave": _vm.beforeLeave },
      on: { "tab-click": _vm.onTabClick },
      model: {
        value: _vm.tabName,
        callback: function ($$v) {
          _vm.tabName = $$v
        },
        expression: "tabName",
      },
    },
    [
      _vm._t("default"),
      _vm.user.tenantId === 0
        ? _c(
            "el-tab-pane",
            {
              staticStyle: { height: "100%" },
              attrs: { name: "configs", label: "参数配置" },
            },
            [
              _vm.tabName === "configs"
                ? _c("configs", {
                    ref: "configs",
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-tab-pane",
        {
          staticStyle: { height: "100%" },
          attrs: { name: "commands", label: "操作指令" },
        },
        [
          _vm.tabName === "commands"
            ? _c("commands", {
                ref: "commands",
                attrs: { "device-info": _vm.deviceInfo, height: _vm.height },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }