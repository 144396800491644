<template>
  <div class="m-l-15" v-loading="loading">
    <div style="max-height: 70vh; overflow:auto;">
      <el-descriptions :title="`水浸数据【更新时间：${object.updateTime || ''}】`" :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
        </template>
        <el-descriptions-item label="水浸状态">
          {{ utils.checkNumberIsNotEmpty(object.waterloggingStatus) ? (waterLoggingStatusMap[object.waterloggingStatus] || "无数据" ) : "无数据" }}
          </el-descriptions-item>
        <el-descriptions-item label="2号寄存器状态">
          {{ utils.checkNumberIsNotEmpty(object.alarmDelay) ? (registerNumber2StatusList[object.registerNumber2Status] || "无数据") : "无数据" }}
          </el-descriptions-item>
        <el-descriptions-item label="报警延时">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(object.alarmDelay) ? (object.alarmDelay) : "无数据" }}</div>
          <div class="description-unit-box">秒</div>
        </el-descriptions-item>
        <el-descriptions-item label="当前灵敏度">{{ utils.checkNumberIsNotEmpty(object.alarmDelay) ? object.currentSensitivity : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="通讯地址">{{ utils.checkNumberIsNotEmpty(object.alarmDelay) ? object.contactAddress : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="波特率">{{ utils.checkNumberIsNotEmpty(object.baudRate) ? ( baudRateList[object.baudRate] || "无数据" ) : "无数据" }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import WaterImmersionApi from "@/api/470/WaterImmersion";

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      object: {
        waterloggingStatus: "",
        registerNumber2Status: "", // 103c
        alarmDelay: "",
        currentSensitivity: "",
        contactAddress: "",
        baudRate: 0,
        updateTime: ""
      },
      waterLoggingStatusMap: $statics.WATER_LOGGING_STATUS_MAP,
      registerNumber2StatusList: $statics.REGISTER_NUMBER_2_STATUS_LIST,
      baudRateList: $statics.BAUD_RATE_LIST,
      // [20240604] 根据徐工的说法，103C 从旧的接口请求
      start: 0x6000,
      count: 5
    }
  },
  created () {
    console.log("water-logging");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await WaterImmersionApi.detail(params);

        res.registerNumber2Status = res.theWaterloggingStatus;
        res.waterloggingStatus = calcHexFromUnsignedDec(res.waterloggingStatus, 2).toUpperCase();

        this.object = res;
        this.$message.success("水浸数据读取成功");
      } catch (err) {
        this.$message.warning("水浸数据读取失败");
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>