var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "device-tabs",
        {
          attrs: {
            "device-info": _vm.deviceInfo,
            "tab-name": _vm.tabName,
            height: _vm.height,
          },
          on: { "tab-click": _vm.onTabClick },
        },
        [
          [
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "detail", label: "空调数据" },
              },
              [
                _vm.tabName === "detail"
                  ? _c("detail", {
                      staticStyle: { height: "100%" },
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }