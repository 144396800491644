<template>
  <div style="height: 100%;">
    <device-tabs :device-info="deviceInfo" :tab-name="tabName" :height="height" @tab-click="onTabClick">
      <template>
        <el-tab-pane name="basic" label="基本数据" style="height: 100%;">
          <detail v-if="tabName === 'basic'" :device-info="deviceInfo" :height="height" style="height: 100%;" />
        </el-tab-pane>
        <el-tab-pane name="rack" label="Rack数据" style="height: 100%">
          <rack v-if="tabName === 'rack'" ref="rack" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="viewvt" label="电压温度" style="height: 100%">
          <viewvt v-if="tabName === 'viewvt'" ref="vt" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="device-alarm" label="设备告警" style="height: 100%">
          <device-alarm v-if="tabName === 'device-alarm'" ref="alarm" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="history-data" label="历史数据" style="height: 100%">
          <history-data v-if="tabName === 'history-data'" ref="history" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
      </template>
    </device-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import detail from "./detail";
import viewvt from "./viewvt";
import DeviceAlarm from "./device-alarm";
import HistoryData from "./../comnponents/history-data";
import ElectricityBill from "./electricity-bill";
import DeviceTrack from "../comnponents/device-track";
import rack from "./rack";
import DeviceTabs from "./../comnponents/device-tabs";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    detail,
    viewvt,
    DeviceAlarm,
    HistoryData,
    ElectricityBill,
    DeviceTrack,
    DeviceTabs,
    rack
  },
  props: {
    deviceInfo: undefined,
    height: 0
  },
  data () {
    return {
      tabName: "basic"
    }
  },
  methods: {
    onTabClick (evt) {
      this.tabName = evt;
    }
  }
}
</script>