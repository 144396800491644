<template>
  <div class="vt-sub-content">
    <div v-loading="loading">
      <viewvt ref="viewvt" :is-small-screen="isSmallScreen" :device-info="deviceInfo" :data="form" :height="height - 20"
        :vLen="vLen" :tLen="tLen" :vDataList="vDataList" :tDataList="tDataList" @read="getDatas"
        mode="single" @show="onShowTime" />
    </div>
  </div>
</template>
<script>
import viewvt from "./viewvt";

import TemperatureApi from "@/api/470/MainBoardTemperature";
import VoltageApi from "@/api/470/MainBoardVoltage";

import TemperatureMixin from "@/components/battery-template/mixin/temperature";
import VoltageMixin from "@/components/battery-template/mixin/voltage";

import { mapGetters } from 'vuex';

const { decAppendZero } = $numberMatrixing;

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    viewvt
  },
  props: {
    deviceInfo: undefined,
    isSmallScreen: undefined
  },
  data () {
    return {
      loading: false,
      lecuAddrSize: 120,
      height: 0,
      showTime: "",
      canConfig: false,
      afeNum: 0,
      vParams: VoltageMixin.data(),
      tParams: TemperatureMixin.data(),
      vDataList: [],
      tDataList: [],
      length: {
        voltage: 0,
        temperature: 0
      },
      vLen: 12,
      tLen: 16,
      timer: null
    }
  },
  async created () {
    console.log("viewvt");
    this.height = document.body.scrollHeight - 200;
    this.getDatas();
  },
  methods: {
    async getVoltage (parmas) {
      try {
        const res = await VoltageApi.list(parmas);
        return res.data;
      } catch {
        return [];
      }
    },
    async getTemperature (parmas) {
      try {
        const res = await TemperatureApi.list(parmas);
        return res.data;
      } catch {
        return [];
      }
    },
    async getDatas () {
      const parmas = {
          code: this.deviceInfo.code,
          page: 1,
          size: 9999999
        };

      let [ temperatures, voltages ] = await Promise.all([
          this.getTemperature(parmas),
          this.getVoltage(parmas)
      ]);

      temperatures = temperatures.sort((obj1, obj2) => {
        return obj1.bmuNo - obj2.bmuNo;
      });
      voltages = voltages.sort((obj1, obj2) => {
        return obj1.bmuNo - obj2.bmuNo;
      });
        
      // temperatures = temperatures.map(obj => {
      //   // [20231229] 这里这么做的目的是因为规则是 长度 + 地址 + 值的形式，而这里下发的数据是分开的，所以这里需要全部拼在一起处理
      //   obj.temperature = calcHexFromUnsignedDec(obj.digit) + obj.batteryNo + obj.temperature;
      //   return obj;
      // });
      // voltages = voltages.map(obj => {
      //   obj.batchNo = calcHexFromUnsignedDec(obj.digit) + obj.batteryNo + obj.batchNo;
      //   return obj;
      // });

      // [20240102] 这里的临时修改是因为前端想要通过从控来显示数量，所以这里暂时假装每 24 节电池一个从控
      /////////////////////////////////////
      if (temperatures.length > 0) {
        let keys = [];
        this.options = temperatures;
        const data = {};
        for (let i = 0, len = this.options.length; i < len; i++) {
          let mixObj = Object.assign({}, this.tParams);
          mixObj = Object.assign(this.tParams, this);
          mixObj.user = this.user;
          const tmp = TemperatureMixin.methods.onChange(i, mixObj);
          let index = tmp.index;
          data[index] = tmp;
          keys.push(index);
        }

        keys = keys.sort();

        const tDataList = [];
        for (const key of keys) {
          const item = data[key];
          const tData = [];
          let effective = 0;
          for (let i = 0; i < item.length; i++) {
            const data = item.data[`index${i}`] || item.data[`index${decAppendZero(i, 5)}`];
            if (data) {
              if (data.type === "ban" || data.type === "err") {
                if (this.user.tenantId === 0) {
                  tData.push(data);
                }
              } else {
                effective++;
                tData.push(data);
              }
            }
          }
          let createTime = item.createTime;
          if (Array.isArray(createTime)) {
            createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));
          }
          tDataList.push({afe: item.afe, size: item.size, data: tData, createTime, length: tData.length, effective});
        }

        console.log(tDataList);
        this.tDataList = tDataList;
      } else {
        this.tDataList = [];
      }

      if (voltages.length > 0) {
        let keys = [];
        this.options = voltages;
        const data = {};
        for (let i = 0, len = this.options.length; i < len; i++) {
          let mixObj = Object.assign({}, this.vParams);
          mixObj = Object.assign(this.vParams, this);
          mixObj.user = this.user;
          const tmp = VoltageMixin.methods.onChange(i, mixObj);
          let index = tmp.index;
          data[index] = tmp;
          keys.push(index);
        }

        keys = keys.sort();

        const vDataList = [];
        for (const key of keys) {
          const item = data[key];
          const vData = [];
          let effective = 0;
          for (let i = 0; i < item.length; i++) {
            const data = item.data[`index${i}`] || item.data[`index${decAppendZero(i, 5)}`];
            if (data) {
              if (data.type === "ban" || data.type === "err") {
                if (this.user.tenantId === 0) {
                  vData.push(data);
                }
              } else {
                effective++;
                vData.push(data);
              }
            }
          }

          let createTime = item.createTime;
          if (Array.isArray(createTime)) {
            createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));
          }
          vDataList.push({afe: item.afe, size: item.size, data: vData, createTime, length: vData.length, effective});
        }

        console.log(vDataList);
        this.vDataList = vDataList;
      } else {
        this.vDataList = [];
      }

      this.$forceUpdate();
    },
    onShowTime () {
      const time = $utils.formatTime(new Date());
      this.showTime = time;
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding-left: 0px;
  padding-top: 0px;
}

</style>