<template>
  <div>
    <div v-if="list.length > 0">
      <div class="charts-box" :style="`width:${width}px; height: ${height}px;`" ref="socChart"></div>
    </div>
    <div v-else>
      <el-empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";
import { mapGetters } from 'vuex';

let selectSeries = null;

export default {
  computed: {
    ...mapGetters(['routes', 'user']),
  },
  mixins: [ HistoryMixin ],
  data () {
    return {
      loading: true,
      isMounted: false
    }
  },
  created () {
    this.width = document.body.scrollWidth - 400;
  },
  methods: {
    setDatas () {
      this.$nextTick(() => {
      if (this.list.length > 0) {
          this.draw();
        }
      });
    },
    draw () {
      try {
        const chart = echarts.init(this.$refs.socChart);
        chart.clear();
        const options = this.initOption();
        chart.setOption(options);

        chart.on('mousemove',function(params){
          selectSeries = params;
        }); 
        //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
        chart.on('mouseout', function (params) {
          selectSeries = null;
        });

        chart.setOption(options);
      } finally {
      }
    },
    initOption () {
      const map = {};

      for (const item of this.list) {
        map[item.createTime] = (item.systemSoc || item.systemSoc == 0) ? item.systemSoc : item.soc;
      }

      const xDatas = Object.keys(map).sort();
      const yDatas = [];

      for (const item of xDatas) {
        yDatas.push(map[item]);
      }

      const legendData = ["SOC"];

      const options = this.createOptions(xDatas.sort(), legendData, [yDatas], "%");

      return options;
    },
    createOptions (xAxis, legendData, metaData, yTitleName) {

      xAxis = xAxis.map(obj => {
        obj = obj.replace(" ", "\n");
        return obj;
      });

      const serieData = [];
      let maxVals = [];

      for (let i = 0; i < legendData.length; i++) {
        let serie = {
            name: legendData[i],
            type: 'line',
            symbol: "circle",
            symbolSize: 10,
            data: metaData[i]
        };
        serieData.push(serie);

        // 因为有些时候设备会读不到数据获得 null，这里就是要排除 null(为了保险，一并排除 undefined)
        maxVals = maxVals.concat(metaData[i].filter(obj => {
          return obj !== null && obj !== undefined;
        }));
      }

      const max = Math.max(... maxVals);

      let colors = [ "#f59a23", "#1890ff" ];
      const options = {
        legend: {
          show: true,
          data: legendData,
          y: "5%",
          left: "40px",
          orient: 'vertical',
          type: "scroll",
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 14
          }
        },
        color: colors,
        grid: {
          top: '7%',
          left: '130px',
          right: '50px',
          bottom: '50px',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'horizontal',
            start: (xAxis.length - 8) / xAxis.length * 100,
            end: 100,
          },
          {
            orient: 'vertical',
            start: 2000 / max * 100,
            end: 100
          }
        ],
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            let x = 0, y = 0;
            let pointX = point[0], pointY = point[1];
            let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

            if (boxWidth > pointX) {
              x = "5%";
            } else {
              x = pointX - boxWidth;
            }

            if (boxHeight > pointY) {
              y = 5;
            } else {
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (params) {

            if (selectSeries !== null) {

              const obj = params.filter(item => {
                return item.seriesName === selectSeries.seriesName;
              })[0];

              const str = `<div>${selectSeries.name}</div><div>${obj.marker} ${obj.seriesName}： ${obj.value}%</div>`;
              return str;
            }
          },
          axisPointer: {
            type: 'shadow',
            snap: true
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { show: true, lineStyle: { color: '#000' } },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisTick: { show: false },
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yTitleName,
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisLine: { show: true, lineStyle: { color: '#000' } },
          },
        ],
        series: serieData
      };


      return options;
    }
  }
}
</script>