<template>
  <div class="m-l-15">
    <div>
      <ds-title>功率数据</ds-title>
      <div v-loading="loading.power" :style="`height:${(height - 105) / 2}px; overflow:auto;`">
        <el-table :data="chargingPowerList" :height="(height - 105) / 2" :style="`width: 100%; overflow:auto;`" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
          <el-table-column label="月份" prop="moon" align="center" width="60"></el-table-column>
          <el-table-column label="充电">
            <el-table-column label="功率1 / 时间1" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.chargingPowerOne }}</div>
                <div>{{ scope.row.chargingPowerOneStartTime }}～{{ scope.row.chargingPowerOneEndTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率2 / 时间2" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.chargingPowerTwo }}</div>
                <div>{{ scope.row.chargingPowerTwoStartTime }}～{{ scope.row.chargingPowerTwoEndTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率3 / 时间3" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.chargingPowerThree }}</div>
                <div>{{ scope.row.chargingPowerThreeStartTime }}～{{ scope.row.chargingPowerThreeEndTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率4 / 时间4" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.chargingPowerFour }}</div>
                <div>{{ scope.row.chargingPowerFourStartTime }}～{{ scope.row.chargingPowerFourEndTime }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="放电">
            <el-table-column label="功率1 / 时间1" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.dischargingPowerTwo }}</div>
                <div>{{ scope.row.dischargingPowerOneStartTime }}～{{ scope.row.dischargingPowerOneStartTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率3 / 时间2" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.dischargingPowerTwo }}</div>
                <div>{{ scope.row.dischargingPowerTwoStartTime }}～{{ scope.row.dischargingPowerTwoStartTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率3 / 时间3" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.dischargingPowerThree }}</div>
                <div>{{ scope.row.dischargingPowerThreeStartTime }}～{{ scope.row.dischargingPowerThreeStartTime }}</div>
              </template>
            </el-table-column>
            <el-table-column label="功率4 / 时间4" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.dischargingPowerFour }}</div>
                <div>{{ scope.row.dischargingPowerFourStartTime }}～{{ scope.row.dischargingPowerFourEndTime }}</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="m-t-10">
      <ds-title>电价数据</ds-title>
      <div v-loading="loading.bill" :style="`height:${(height - 105) / 2}px; overflow:auto;`">
        <el-table :data="electritiryBillList" :height="(height - 105) / 2" :style="`width: 100%; overflow:auto;`" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
          <el-table-column label="月份" prop="moon" align="center" width="60"></el-table-column>
          <el-table-column label="尖时电价" align="center">
            <el-table-column label="电价(元)" align="center" prop="topElectricityPrice" width="100"></el-table-column>
            <el-table-column label="时段1" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.pointedStartOne }} ～ {{ scope.row.pointedEndOne }}
              </template>
            </el-table-column>
            <el-table-column label="时段2" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.pointedStartTwo }} ～ {{ scope.row.pointedEndTwo }}
              </template>
            </el-table-column>
            <el-table-column label="时段3" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.pointedStartThree }} ～ {{ scope.row.pointedEndThree }}
              </template>
            </el-table-column>
            <el-table-column label="时段4" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.pointedStartFour }} ～ {{ scope.row.pointedEndFour }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="峰时电价" align="center">
            <el-table-column label="电价(元)" align="center" prop="peakElectricityPrice" width="100"></el-table-column>
            <el-table-column label="时段1" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.peakStartOne }} ～ {{ scope.row.peakEndOne }}
              </template>
            </el-table-column>
            <el-table-column label="时段2" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.peakStartTwo }} ～ {{ scope.row.peakEndTwo }}
              </template>
            </el-table-column>
            <el-table-column label="时段3" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.peakStartThree }} ～ {{ scope.row.peakEndThree }}
              </template>
            </el-table-column>
            <el-table-column label="时段4" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.peakStartFour }} ～ {{ scope.row.peakEndFour }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="平时电价" align="center">
            <el-table-column label="电价(元)" align="center" prop="normalElectricityPrice" width="100"></el-table-column>
            <el-table-column label="时段1" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.flatStartOne }} ～ {{ scope.row.flatEndOne }}
              </template>
            </el-table-column>
            <el-table-column label="时段2" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.flatStartTwo }} ～ {{ scope.row.flatEndTwo }}
              </template>
            </el-table-column>
            <el-table-column label="时段3" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.flatStartThree }} ～ {{ scope.row.flatEndThree }}
              </template>
            </el-table-column>
            <el-table-column label="时段4" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.flatStartFour }} ～ {{ scope.row.flatEndFour }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="谷时电价" align="center">
            <el-table-column label="电价(元)" align="center" prop="valleyElectricityPrice" width="100"></el-table-column>
            <el-table-column label="时段1" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.valleyStartOne }} ～ {{ scope.row.valleyEndOne }}
              </template>
            </el-table-column>
            <el-table-column label="时段2" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.valleyStartTwo }} ～ {{ scope.row.valleyEndTwo }}
              </template>
            </el-table-column>
            <el-table-column label="时段3" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.valleyStartThree }} ～ {{ scope.row.valleyEndThree }}
              </template>
            </el-table-column>
            <el-table-column label="时段4" align="center" width="120">
              <template slot-scope="scope">
                {{ scope.row.valleyStartFour }} ～ {{ scope.row.valleyEndFour }}
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import ChargingPowerPriceApi from "@/api/470/ChargingPowerPrice";
import PriceTimeApi from "@/api/470/PriceTime";

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      loading: {
        power: false,
        bill: false
      },
      height: 0,
      chargingPowerList: [],
      electritiryBillList: []
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
    this.getDatas();
  },
  methods: {
    async getDatas () {
      this.getChargingPowerData();
      this.getElectricityBillData();
    },
    getParams () {
      const params = {
        code: this.deviceInfo.code,
        size: 12,
        page: 1
      };

      return params;
    },
    async getChargingPowerData () {
      try {
        this.loading.power = true;
        const res = await ChargingPowerPriceApi.list(this.getParams());
        this.chargingPowerList = this.sorting(res.data, (obj) => {
          obj.chargingPowerOne = (obj.chargingPowerOne).toFixed(2);
          obj.chargingPowerTwo = (obj.chargingPowerTwo).toFixed(2);
          obj.chargingPowerThree = (obj.chargingPowerThree).toFixed(2);
          obj.chargingPowerOne = (obj.chargingPowerFour).toFixed(2);

          obj.chargingPowerOneStartTime = $utils.minuteToHMTime(obj.chargingPowerOneStartTime);
          obj.chargingPowerOneEndTime = $utils.minuteToHMTime(obj.chargingPowerOneEndTime);
          obj.chargingPowerTwoStartTime = $utils.minuteToHMTime(obj.chargingPowerTwoStartTime);
          obj.chargingPowerTwoEndTime = $utils.minuteToHMTime(obj.chargingPowerTwoEndTime);
          obj.chargingPowerThreeStartTime = $utils.minuteToHMTime(obj.chargingPowerThreeStartTime);
          obj.chargingPowerThreeEndTime = $utils.minuteToHMTime(obj.chargingPowerThreeEndTime);
          obj.chargingPowerFourStartTime = $utils.minuteToHMTime(obj.chargingPowerFourStartTime);
          obj.chargingPowerFourEndTime = $utils.minuteToHMTime(obj.chargingPowerFourEndTime);

          obj.dischargingPowerOne = (obj.dischargingPowerOne).toFixed(2);
          obj.dischargingPowerTwo = (obj.dischargingPowerTwo).toFixed(2);
          obj.dischargingPowerThree = (obj.dischargingPowerThree).toFixed(2);
          obj.dischargingPowerFour = (obj.dischargingPowerFour).toFixed(2);

          obj.dischargingPowerOneStartTime = $utils.minuteToHMTime(obj.dischargingPowerOneStartTime);
          obj.dischargingPowerOneEndTime = $utils.minuteToHMTime(obj.dischargingPowerOneEndTime);
          obj.dischargingPowerTwoStartTime = $utils.minuteToHMTime(obj.dischargingPowerTwoStartTime);
          obj.dischargingPowerTwoEndTime = $utils.minuteToHMTime(obj.dischargingPowerTwoEndTime);
          obj.dischargingPowerThreeStartTime = $utils.minuteToHMTime(obj.dischargingPowerThreeStartTime);
          obj.dischargingPowerThreeEndTime = $utils.minuteToHMTime(obj.dischargingPowerThreeEndTime);
          obj.dischargingPowerFourStartTime = $utils.minuteToHMTime(obj.dischargingPowerFourStartTime);
          obj.dischargingPowerFourEndTime = $utils.minuteToHMTime(obj.dischargingPowerFourEndTime);
        });
      } catch (err) {
        this.chargingPowerList = [];
      } finally {
        this.loading.power = false;
      }
    },
    async getElectricityBillData () {
      try {
        this.loading.bill = true;
        const res = await PriceTimeApi.list(this.getParams());
        this.electritiryBillList = this.sorting(res.data, obj => {

          obj.topElectricityPrice = (obj.topElectricityPrice / 10000).toFixed(2);
          obj.peakElectricityPrice = (obj.peakElectricityPrice / 10000).toFixed(2);
          obj.normalElectricityPrice = (obj.normalElectricityPrice / 10000).toFixed(2);
          obj.valleyElectricityPrice = (obj.valleyElectricityPrice / 10000).toFixed(2);

          obj.pointedStartOne = $utils.minuteToHMTime(obj.pointedStartOne);
          obj.pointedEndOne = $utils.minuteToHMTime(obj.pointedEndOne);
          obj.pointedStartTwo = $utils.minuteToHMTime(obj.pointedStartTwo);
          obj.pointedEndTwo = $utils.minuteToHMTime(obj.pointedEndTwo);
          obj.pointedStartThree = $utils.minuteToHMTime(obj.pointedStartThree);
          obj.pointedEndThree = $utils.minuteToHMTime(obj.pointedEndThree);
          obj.pointedStartFour = $utils.minuteToHMTime(obj.pointedStartFour);
          obj.pointedEndFour = $utils.minuteToHMTime(obj.pointedEndFour);

          obj.peakStartOne = $utils.minuteToHMTime(obj.peakStartOne);
          obj.peakEndOne = $utils.minuteToHMTime(obj.peakEndOne);
          obj.peakStartTwo = $utils.minuteToHMTime(obj.peakStartTwo);
          obj.peakEndTwo = $utils.minuteToHMTime(obj.peakEndTwo);
          obj.peakStartThree = $utils.minuteToHMTime(obj.peakStartThree);
          obj.peakEndThree = $utils.minuteToHMTime(obj.peakEndThree);
          obj.peakStartFour = $utils.minuteToHMTime(obj.peakStartFour);
          obj.peakEndFour = $utils.minuteToHMTime(obj.peakEndFour);

          obj.flatStartOne = $utils.minuteToHMTime(obj.flatStartOne);
          obj.flatEndOne = $utils.minuteToHMTime(obj.flatEndOne);
          obj.flatStartTwo = $utils.minuteToHMTime(obj.flatStartTwo);
          obj.flatEndTwo = $utils.minuteToHMTime(obj.flatEndTwo);
          obj.flatStartThree = $utils.minuteToHMTime(obj.flatStartThree);
          obj.flatEndThree = $utils.minuteToHMTime(obj.flatEndThree);
          obj.flatStartFour = $utils.minuteToHMTime(obj.flatStartFour);
          obj.flatEndFour = $utils.minuteToHMTime(obj.flatEndFour);

          obj.valleyStartOne = $utils.minuteToHMTime(obj.valleyStartOne);
          obj.valleyEndOne = $utils.minuteToHMTime(obj.valleyEndOne);
          obj.valleyStartTwo = $utils.minuteToHMTime(obj.valleyStartTwo);
          obj.valleyEndTwo = $utils.minuteToHMTime(obj.valleyEndTwo);
          obj.valleyStartThree = $utils.minuteToHMTime(obj.valleyStartThree);
          obj.valleyEndThree = $utils.minuteToHMTime(obj.valleyEndThree);
          obj.valleyStartFour = $utils.minuteToHMTime(obj.valleyStartFour);
          obj.valleyEndFour = $utils.minuteToHMTime(obj.valleyEndFour);
        });

        console.log(this.electritiryBillList);
      } catch (err) {
        this.electritiryBillList = [];
      } finally {
        this.loading.bill = false;
      }
    },
    sorting (data, callback) {
      data = data.map((obj) => {
        obj.moon += 1;
        callback(obj);
        return obj;
      });
      data = data.sort((obj1, obj2) => {
          return obj1.moon - obj2.moon;
        });

      return data;
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .set-power-box {
  width: 1200px;
}
</style>