import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-Voltage/list?${rand}`, { params });
}

export const voltageList = (code, params) => {
    const rand = Date.now();
    return request.get(`module-Voltage/Voltage/${code}/?${rand}`, { params });
}

export const voltageDetail = (params) => {
    const rand = Date.now();
    return request.get(`module-Voltage/details?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.get(`module-Voltage/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.get(`module-Voltage/update?${rand}`, data);
}

export default {
    list,
    save,
    update,
    voltageList,
    voltageDetail
}