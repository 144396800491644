var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "90px" },
        },
        [
          _c("el-form-item", { attrs: { label: "设备编号" } }, [
            _vm._v(_vm._s(_vm.data.code)),
          ]),
          _c("el-form-item", { attrs: { label: "当前版本号" } }, [
            _c(
              "div",
              {
                staticClass: "description-value-box",
                staticStyle: { width: "145px !important" },
              },
              [_vm._v(_vm._s(_vm.version || "无数据"))]
            ),
            _c(
              "div",
              { staticClass: "description-unit-box" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.clearVersion },
                  },
                  [_vm._v("清除")]
                ),
              ],
              1
            ),
          ]),
          _vm.deviceInfo.projectType !== 1 && _vm.deviceInfo.projectType !== 6
            ? _c(
                "el-form-item",
                { attrs: { label: "主控ID", prop: "masterID" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: "请选择一个主控ID",
                        disabled: _vm.type === 1,
                        size: "mini",
                      },
                      model: {
                        value: _vm.form.masterID,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "masterID", $$v)
                        },
                        expression: "form.masterID",
                      },
                    },
                    _vm._l(_vm.masters, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "固件版本", prop: "version" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "请选择一个固件版本升级",
                    size: "small",
                  },
                  on: { change: _vm.onSelect },
                  model: {
                    value: _vm.form.version,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "version", $$v)
                    },
                    expression: "form.version",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "el-tooltip",
                    {
                      key: item.id,
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.description || "无说明",
                        placement: "right",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: item.edition, value: item.edition },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.utils.userReadOnly(_vm.user),
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("固件升级")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }