import { render, staticRenderFns } from "./synchronize-time.vue?vue&type=template&id=9ac9d552&scoped=true"
import script from "./synchronize-time.vue?vue&type=script&lang=js"
export * from "./synchronize-time.vue?vue&type=script&lang=js"
import style0 from "./synchronize-time.vue?vue&type=style&index=0&id=9ac9d552&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ac9d552",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\bms-front\\anj\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9ac9d552')) {
      api.createRecord('9ac9d552', component.options)
    } else {
      api.reload('9ac9d552', component.options)
    }
    module.hot.accept("./synchronize-time.vue?vue&type=template&id=9ac9d552&scoped=true", function () {
      api.rerender('9ac9d552', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/device/detail/comnponents/device-tabs/commands/events/synchronize-time.vue"
export default component.exports