var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticStyle: { margin: "10px 0px" } }, [
        _vm._v("您真的要" + _vm._s(_vm.message) + "么？"),
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: `请输入随机码 ${_vm.randCode} 进行危险操作确认`,
                prop: "inputRandCode",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini", palaceholder: "请输入随机码" },
                model: {
                  value: _vm.form.inputRandCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "inputRandCode", $$v)
                  },
                  expression: "form.inputRandCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "danger-event-ask-title" }, [
      _c("div", [_c("i", { staticClass: "el-icon-warning" })]),
      _c("div", [_vm._v("这是一个危险的操作！")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }