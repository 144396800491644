var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-l-15", style: `height:${_vm.height}px; overflow:auto;` },
    [
      _c(
        "div",
        [
          _c("ds-title", [_vm._v("功率设置")]),
          _c("set-charging-power", {
            attrs: { "device-info": _vm.deviceInfo },
          }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { "m-t-10": "" } },
        [
          _c("ds-title", [_vm._v("电价设置")]),
          _c("set-electricity-bill", {
            attrs: { "device-info": _vm.deviceInfo },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }