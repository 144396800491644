<template>
  <div>
    <el-descriptions :column="1" :labelStyle="{'width':'100px;'}" border size="small">
      <el-descriptions-item label="一级密码">
        <el-input v-model="password1" size="small" type="password"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="二级密码">
        <el-input v-model="password2" size="small" type="password"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="三级密码">
        <el-input v-model="password3" size="small" type="password"></el-input>
      </el-descriptions-item>
    </el-descriptions>
    <div class="right-btns" style="margin-top:20px;">
      <el-button type="primary" size="small" @click="onCheckPassword">确定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      password1: "",
      password2: "",
      password3: "",
      level: {
        isL1: false,
        isL2: false,
        isL3: false,
        isL0: true
      },
    }
  },
  methods: {
    onClosed () {
      return true;
    },
    onCheckPassword () {
      this.level.isL1 = this.password1 === "53180K2";
      if (!this.isL1) {
        this.level.isL2 = this.password2 === "65980th#";
        if (!this.isL2) {
          this.level.isL3 = this.password3 === "5*hC#09!6";
        }
      }

      if (this.level.isL1 || this.level.isL2 || this.level.isL3) {
        this.level.isL0 = false;
      }

      this.$emit("close");
    },
  }
}
</script>