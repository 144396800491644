<template>
  <el-tabs tab-position="left" style="height: 100%;" v-model="tabName" @tab-click="onTabClick" :before-leave="beforeLeave">
    <slot />
    <el-tab-pane name="configs" label="参数配置" style="height: 100%" v-if="user.tenantId === 0">
      <configs v-if="tabName === 'configs'" ref="configs" :device-info="deviceInfo" :height="height" />
    </el-tab-pane>
    <el-tab-pane name="commands" label="操作指令" style="height: 100%">
      <commands v-if="tabName === 'commands'" ref="commands" :device-info="deviceInfo" :height="height" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { mapGetters } from 'vuex';

import configs from "./configs";
import commands from "./commands";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    configs,
    commands
  },
  props: {
    deviceInfo: undefined,
    tabName: undefined,
    height: undefined
  },
  methods: {
    beforeLeave (evt) {
      const ret = evt === "screen";

      if (ret) {
        window.open(`/#/ems-screen/${this.deviceInfo.code}`);
      }

      return !ret;
    },
    onTabClick (evt) {
      this.$emit("tab-click", evt.name);
    }
  }
}
</script>