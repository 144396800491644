<template>
  <div class="m-l-15" v-loading="loading">
    <div>
      <el-table class="fixed-table" ref="table" :height="height" :data="table" style="width: 100%" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="高压侧电压(V)" prop="highSideVoltage" align="center"></el-table-column>
        <el-table-column label="高压侧电流(A)" prop="highSideCurrent" align="center"></el-table-column>
        <el-table-column label="高压侧功率(kW)" prop="highSidePower" align="center"></el-table-column>
        <el-table-column label="模块IGBT1温度(℃)" prop="temperatureModuleIGBT1" align="center"></el-table-column>
        <el-table-column label="模块IGBT2温度(℃)" prop="temperatureModuleIGBT2" align="center"></el-table-column>
        <el-table-column label="模块IGBT3温度(℃)" prop="temperatureModuleIGBT3" align="center"></el-table-column>
        <el-table-column label="模块IGBT4温度(℃)" prop="temperatureModuleIGBT4" align="center"></el-table-column>
        <el-table-column label="模块IGBT5温度(℃)" prop="temperatureModuleIGBT5" align="center"></el-table-column>
        <el-table-column label="模块IGBT6温度(℃)" prop="temperatureModuleIGBT6" align="center"></el-table-column>
        <el-table-column label="模块进风口温度(℃)" prop="temperatureModuleAirInlet" align="center"></el-table-column>
        <el-table-column label="模块出风口温度(℃)" prop="temperatureModuleAirOutlet" align="center"></el-table-column>
        <el-table-column label="低压侧接入1电压(V)" prop="lowVoltageSideConnected1Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入1电流(A)" prop="lowVoltageSideConnected1Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入1功率(kW)" prop="lowVoltageSideConnected1Power" align="center"></el-table-column>
        <el-table-column label="预留1" prop="reserve1" align="center"></el-table-column>
        <el-table-column label="低压侧接入2电压(V)" prop="lowVoltageSideConnected2Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入2电流(A)" prop="lowVoltageSideConnected2Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入2功率(kW)" prop="lowVoltageSideConnected2Power" align="center"></el-table-column>
        <el-table-column label="预留2" prop="reserve2" align="center"></el-table-column>
        <el-table-column label="低压侧接入3电压(V)" prop="lowVoltageSideConnected3Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入3电流(A)" prop="lowVoltageSideConnected3Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入3功率(kW)" prop="lowVoltageSideConnected3Power" align="center"></el-table-column>
        <el-table-column label="预留3" prop="reserve3" align="center"></el-table-column>
        <el-table-column label="低压侧接入4电压(V)" prop="lowVoltageSideConnected4Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入4电流(A)" prop="lowVoltageSideConnected4Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入4功率(kW)" prop="lowVoltageSideConnected4Power" align="center"></el-table-column>
        <el-table-column label="预留4" prop="reserve4" align="center"></el-table-column>
        <el-table-column label="低压侧接入5电压(V)" prop="lowVoltageSideConnected5Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入5电流(A)" prop="lowVoltageSideConnected5Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入5功率(kW)" prop="lowVoltageSideConnected5Power" align="center"></el-table-column>
        <el-table-column label="预留5" prop="reserve5" align="center"></el-table-column>
        <el-table-column label="低压侧接入6电压(V)" prop="lowVoltageSideConnected6Voltage" align="center"></el-table-column>
        <el-table-column label="低压侧接入6电流(A)" prop="lowVoltageSideConnected6Current" align="center"></el-table-column>
        <el-table-column label="低压侧接入6功率(kW)" prop="lowVoltageSideConnected6Power" align="center"></el-table-column>
        <el-table-column label="预留6" prop="reserve6" align="center"></el-table-column>
        <el-table-column label="设备状态字1" prop="deviceStatusByte1" align="center"></el-table-column>
        <el-table-column label="设备状态字2" prop="deviceStatusByte2" align="center"></el-table-column>
        <el-table-column label="设备状态字3" prop="deviceStatusByte3" align="center"></el-table-column>
        <el-table-column label="设备状态字4" prop="deviceStatusByte4" align="center"></el-table-column>
        <el-table-column label="故障字1（并机）" prop="faultByte1" align="center"></el-table-column>
        <el-table-column label="故障字2（系统）" prop="faultByte2System" align="center"></el-table-column>
        <el-table-column label="直流接入1故障字" prop="dcAccess1FaultByte" align="center"></el-table-column>
        <el-table-column label="直流接入2故障字" prop="dcAccess2FaultByte" align="center"></el-table-column>
        <el-table-column label="直流接入3故障字" prop="dcAccess3FaultByte" align="center"></el-table-column>
        <el-table-column label="直流接入4故障字" prop="dcAccess4FaultByte" align="center"></el-table-column>
        <el-table-column label="直流接入5故障字" prop="dcAccess5FaultByte" align="center"></el-table-column>
        <el-table-column label="直流接入6故障字" prop="dcAccess6FaultByte" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import PcsAcdCInformationApi from '@/api/470/PcsAcdCInformation';

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined,
    height: undefined
  },
  data () {
    return  {
      loading: false,
      table: [],
      utils: $utils
    }
  },
  created () {
    console.log("aircondition");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await PcsAcdCInformationApi.list(params);
        let table = res.data.map((obj) => {

          return obj;
        });

        this.table = table;

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>