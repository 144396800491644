var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "152px" },
        },
        [
          _c(
            "div",
            [
              _vm.utils.isDevelopMode()
                ? _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.isDebugMode,
                        callback: function ($$v) {
                          _vm.isDebugMode = $$v
                        },
                        expression: "isDebugMode",
                      },
                    },
                    [_vm._v("开启调试模式")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.utils.isDevelopMode() && _vm.isDebugMode
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "主机地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.lecuAddr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lecuAddr", $$v)
                                    },
                                    expression: "form.lecuAddr",
                                  },
                                },
                                _vm._l(_vm.lecuAddrSize + 1, function (n) {
                                  return _c("el-option", {
                                    key: n,
                                    attrs: { label: n - 1, value: n - 1 },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "地址调试窗口" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入地址",
                                },
                                model: {
                                  value: _vm.debugData.input,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.debugData, "input", $$v)
                                  },
                                  expression: "debugData.input",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "设备返回信息" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.debugData.output,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.debugData, "output", $$v)
                                  },
                                  expression: "debugData.output",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isDebugMode
            ? _c(
                "el-collapse",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "config-box",
                      style: `height: ${_vm.height}px`,
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "主机参数", name: "main" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "主机地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.lecuAddr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lecuAddr", $$v)
                                    },
                                    expression: "form.lecuAddr",
                                  },
                                },
                                _vm._l(_vm.lecuAddrSize + 1, function (n) {
                                  return _c("el-option", {
                                    key: n,
                                    attrs: { label: n - 1, value: n - 1 },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "新地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.lecuNewAddr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lecuNewAddr", $$v)
                                    },
                                    expression: "form.lecuNewAddr",
                                  },
                                },
                                _vm._l(_vm.lecuNewAddrSize, function (n) {
                                  return _c("el-option", {
                                    key: n,
                                    attrs: { label: n, value: n },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "三级授权" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.empower,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "empower", $$v)
                                  },
                                  expression: "form.empower",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "电池类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.batType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "batType", $$v)
                                    },
                                    expression: "form.batType",
                                  },
                                },
                                _vm._l(_vm.batTypes, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "电芯类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.batCellType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "batCellType", $$v)
                                    },
                                    expression: "form.batCellType",
                                  },
                                },
                                _vm._l(_vm.batCellTypes, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "电池 AFE 数", prop: "afeNum" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.afeNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "afeNum", $$v)
                                  },
                                  expression: "form.afeNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "NTC 数量" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.ntcCnt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ntcCnt", $$v)
                                    },
                                    expression: "form.ntcCnt",
                                  },
                                },
                                _vm._l(_vm.ntcCnts, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "硬件类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.hwType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "hwType", $$v)
                                    },
                                    expression: "form.hwType",
                                  },
                                },
                                _vm._l(_vm.hwTypes, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上拉电阻" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.pullUresistor,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "pullUresistor", $$v)
                                    },
                                    expression: "form.pullUresistor",
                                  },
                                },
                                _vm._l(_vm.pullUresistors, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "NTC" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.ntc,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ntc", $$v)
                                    },
                                    expression: "form.ntc",
                                  },
                                },
                                _vm._l(_vm.ntcs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "从控" } }, [
                            _c("div", { staticClass: "slave-control-box" }, [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.deviceInfo.projectType !== 4,
                                      size: "mini",
                                    },
                                    model: {
                                      value: _vm.form.slaveCount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "slaveCount", $$v)
                                      },
                                      expression: "form.slaveCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { size: "mini" },
                                      model: {
                                        value: _vm.form.slaveControl,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "slaveControl",
                                            $$v
                                          )
                                        },
                                        expression: "form.slaveControl",
                                      },
                                    },
                                    _vm._l(_vm.slaveControls, function (obj) {
                                      return _c("el-option", {
                                        key: obj.value,
                                        attrs: {
                                          label: obj.label,
                                          value: obj.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.slaveControl !== "0"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { size: "mini" },
                                          model: {
                                            value: _vm.form.slaveAfeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "slaveAfeType",
                                                $$v
                                              )
                                            },
                                            expression: "form.slaveAfeType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.slaveAfeTypes,
                                          function (obj) {
                                            return _c("el-option", {
                                              key: obj.value,
                                              attrs: {
                                                label: obj.label,
                                                value: obj.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "总控" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { disabled: "", size: "mini" },
                                  model: {
                                    value: _vm.form.masterControl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "masterControl", $$v)
                                    },
                                    expression: "form.masterControl",
                                  },
                                },
                                _vm._l(_vm.cans, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "分流器参数", name: "Diverter" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电流值" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("A")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3512864594
                                ),
                                model: {
                                  value: _vm.form.shunt_I,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shunt_I", $$v)
                                  },
                                  expression: "form.shunt_I",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "电压值" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("mV")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1972027784
                                ),
                                model: {
                                  value: _vm.form.shunt_mV,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shunt_mV", $$v)
                                  },
                                  expression: "form.shunt_mV",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "偏置量" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("0.1A")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2156023357
                                ),
                                model: {
                                  value: _vm.form.shunt_I_offset,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shunt_I_offset", $$v)
                                  },
                                  expression: "form.shunt_I_offset",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "充放电流参数", name: "ChgDsg" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "放电电流门限值" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("A")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3512864594
                                ),
                                model: {
                                  value: _vm.form.ipQueryDsgTHD,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ipQueryDsgTHD", $$v)
                                  },
                                  expression: "form.ipQueryDsgTHD",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "充电电流门限值" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("A")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3512864594
                                ),
                                model: {
                                  value: _vm.form.ipQueryChgCutTHD,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ipQueryChgCutTHD", $$v)
                                  },
                                  expression: "form.ipQueryChgCutTHD",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "国标最大充电流" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("0.1C")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2088736639
                                ),
                                model: {
                                  value: _vm.form.gbChgMaxCur,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "gbChgMaxCur", $$v)
                                  },
                                  expression: "form.gbChgMaxCur",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "国标充报文延迟" } },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [_vm._v("10S")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  762260481
                                ),
                                model: {
                                  value: _vm.form.gbMsgDelay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "gbMsgDelay", $$v)
                                  },
                                  expression: "form.gbMsgDelay",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "放电切换充" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.disChgChangeToChg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "disChgChangeToChg", $$v)
                                  },
                                  expression: "form.disChgChangeToChg",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "限值电流充" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.limitCurrentchg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "limitCurrentchg", $$v)
                                  },
                                  expression: "form.limitCurrentchg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "协议配置", name: "Protocol" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "充电协议" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "calc(70% - 10px)",
                                    "margin-right": "10px",
                                  },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.chgProtocol,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "chgProtocol", $$v)
                                    },
                                    expression: "form.chgProtocol",
                                  },
                                },
                                _vm._l(_vm.chgProtocols, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "30%" },
                                  attrs: {
                                    disabled: !_vm.user.canEditCanConfig,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.chargeCanType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "chargeCanType", $$v)
                                    },
                                    expression: "form.chargeCanType",
                                  },
                                },
                                _vm._l(_vm.cans, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "VCU" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "calc(70% - 10px)",
                                    "margin-right": "10px",
                                  },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.vcuProtocol,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vcuProtocol", $$v)
                                    },
                                    expression: "form.vcuProtocol",
                                  },
                                },
                                _vm._l(_vm.vcuProtocols, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "30%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.vcuCanType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vcuCanType", $$v)
                                    },
                                    expression: "form.vcuCanType",
                                  },
                                },
                                _vm._l(_vm.cans, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "温控协议" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "calc(70% - 10px)",
                                    "margin-right": "10px",
                                  },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.tempProtocol,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "tempProtocol", $$v)
                                    },
                                    expression: "form.tempProtocol",
                                  },
                                },
                                _vm._l(_vm.tempProtocols, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "30%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.tempCanType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "tempCanType", $$v)
                                    },
                                    expression: "form.tempCanType",
                                  },
                                },
                                _vm._l(_vm.cans, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "COM1传输协议" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.com1Protocol,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "com1Protocol", $$v)
                                    },
                                    expression: "form.com1Protocol",
                                  },
                                },
                                _vm._l(_vm.comProtocols, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "COM2传输协议" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.com2Protocol,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "com2Protocol", $$v)
                                    },
                                    expression: "form.com2Protocol",
                                  },
                                },
                                _vm._l(_vm.comProtocols, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "接触器参数", name: "Contactor" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "单次吸合延迟时间",
                                prop: "contactSingleTime",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.contactSingleTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "contactSingleTime",
                                        $$v
                                      )
                                    },
                                    expression: "form.contactSingleTime",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("ms"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "放电单次吸合延迟时间",
                                prop: "chargeContactSingleTime",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.chargeContactSingleTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "chargeContactSingleTime",
                                        $$v
                                      )
                                    },
                                    expression: "form.chargeContactSingleTime",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("ms"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预充电压和总压比列",
                                prop: "preChargeContactRatio",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.preChargeContactRatio,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "preChargeContactRatio",
                                        $$v
                                      )
                                    },
                                    expression: "form.preChargeContactRatio",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "config-box",
                      style: `height: ${_vm.height}px`,
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "SOC 参数", name: "soc" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "系统容量",
                                prop: "systemVolumetric",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.systemVolumetric,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "systemVolumetric",
                                        $$v
                                      )
                                    },
                                    expression: "form.systemVolumetric",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("AH"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否初始 SOC" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "初始",
                                  "inactive-text": "不初始",
                                },
                                model: {
                                  value: _vm.isConfiginitSoc,
                                  callback: function ($$v) {
                                    _vm.isConfiginitSoc = $$v
                                  },
                                  expression: "isConfiginitSoc",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "初始 SOC", prop: "initSoc" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.isConfiginitSoc,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.initSoc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "initSoc", $$v)
                                  },
                                  expression: "form.initSoc",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "静置电流误差",
                                prop: "staticEurrentError",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.staticEurrentError,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "staticEurrentError",
                                        $$v
                                      )
                                    },
                                    expression: "form.staticEurrentError",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("mA"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "SOH 参数", name: "soh" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "SOH循环次数", prop: "sohCycle" },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.sohCycle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sohCycle", $$v)
                                  },
                                  expression: "form.sohCycle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "循环终止百分比",
                                prop: "sohPercentage",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.sohPercentage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sohPercentage", $$v)
                                    },
                                    expression: "form.sohPercentage",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: { title: "均衡启动条件(mV)", name: "Balance" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "动态阈值",
                                prop: "dynamicThreshold",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.dynamicThreshold,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dynamicThreshold", $$v)
                                  },
                                  expression: "form.dynamicThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "动态差值",
                                prop: "dynamicDifference",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.dynamicDifference,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dynamicDifference", $$v)
                                  },
                                  expression: "form.dynamicDifference",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "静态阈值",
                                prop: "staticThreshold",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.staticThreshold,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "staticThreshold", $$v)
                                  },
                                  expression: "form.staticThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "静态差值",
                                prop: "staticDifference",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.staticDifference,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "staticDifference", $$v)
                                  },
                                  expression: "form.staticDifference",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "最大供电通道数" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "", size: "mini" },
                                model: {
                                  value: _vm.form.maxChargeChannel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxChargeChannel", $$v)
                                  },
                                  expression: "form.maxChargeChannel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大均衡通道数",
                                prop: "maxBalanceChannel",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                on: { blur: _vm.onChangeMaxBalanceChannel },
                                model: {
                                  value: _vm.form.maxBalanceChannel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxBalanceChannel", $$v)
                                  },
                                  expression: "form.maxBalanceChannel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "均衡方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  on: { change: _vm.onChangeMaxBalanceChannel },
                                  model: {
                                    value: _vm.form.balanceMode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "balanceMode", $$v)
                                    },
                                    expression: "form.balanceMode",
                                  },
                                },
                                _vm._l(_vm.balanceModes, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "放电均衡" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.chargeBanlance,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "chargeBanlance", $$v)
                                  },
                                  expression: "form.chargeBanlance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "UPS", name: "ups" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "工作电压", prop: "workVoltage" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.workVoltage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "workVoltage", $$v)
                                    },
                                    expression: "form.workVoltage",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("V"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: {
                            title: "一阶滤波系数（x100）",
                            name: "coefficient",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电压",
                                prop: "ltc6811FilterRatio",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.ltc6811FilterRatio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "ltc6811FilterRatio",
                                      $$v
                                    )
                                  },
                                  expression: "form.ltc6811FilterRatio",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "温度",
                                prop: "mcuadFilterRatio",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.mcuadFilterRatio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "mcuadFilterRatio", $$v)
                                  },
                                  expression: "form.mcuadFilterRatio",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电流",
                                prop: "ad7706FilterRatio",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: _vm.form.ad7706FilterRatio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ad7706FilterRatio", $$v)
                                  },
                                  expression: "form.ad7706FilterRatio",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "config-box",
                      style: `height: ${_vm.height}px`,
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "高低边开关", name: "HighLow" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预充接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.preChargeContactor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "preChargeContactor",
                                        $$v
                                      )
                                    },
                                    expression: "form.preChargeContactor",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "放电接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.dischargeContactor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "dischargeContactor",
                                        $$v
                                      )
                                    },
                                    expression: "form.dischargeContactor",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "充电接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.chargeContactor,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "chargeContactor", $$v)
                                    },
                                    expression: "form.chargeContactor",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "主负接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.mainSlaveContactor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "mainSlaveContactor",
                                        $$v
                                      )
                                    },
                                    expression: "form.mainSlaveContactor",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "加热接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.heatingContactor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "heatingContactor",
                                        $$v
                                      )
                                    },
                                    expression: "form.heatingContactor",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "制冷接触器" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.buzzer,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "buzzer", $$v)
                                    },
                                    expression: "form.buzzer",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "外接指示灯" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.externalIndicator,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "externalIndicator",
                                        $$v
                                      )
                                    },
                                    expression: "form.externalIndicator",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "外接故障灯" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.externalFaultLight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "externalFaultLight",
                                        $$v
                                      )
                                    },
                                    expression: "form.externalFaultLight",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "UPS 工作开关" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.upsWorkSwitch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "upsWorkSwitch", $$v)
                                    },
                                    expression: "form.upsWorkSwitch",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "UPS 中线充放" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.upsForceCharging,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "upsForceCharging",
                                        $$v
                                      )
                                    },
                                    expression: "form.upsForceCharging",
                                  },
                                },
                                _vm._l(_vm.hsdxCfgs, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "充电二级恢复" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.chargeLv2Back,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "chargeLv2Back", $$v)
                                  },
                                  expression: "form.chargeLv2Back",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "外部并机" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.externalParalleling,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "externalParalleling",
                                      $$v
                                    )
                                  },
                                  expression: "form.externalParalleling",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "放电二级恢复" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.disChgLv2Back,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "disChgLv2Back", $$v)
                                  },
                                  expression: "form.disChgLv2Back",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "边充边放" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-text": "使用",
                                  "inactive-text": "不使用",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.chgSameTimeDischg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "chgSameTimeDischg", $$v)
                                  },
                                  expression: "form.chgSameTimeDischg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "CAN速率", name: "canBaud" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "CAN1" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.canBaud1,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "canBaud1", $$v)
                                    },
                                    expression: "form.canBaud1",
                                  },
                                },
                                _vm._l(_vm.canBauds, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "CAN2" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.canBaud2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "canBaud2", $$v)
                                    },
                                    expression: "form.canBaud2",
                                  },
                                },
                                _vm._l(_vm.canBauds, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "COM速率", name: "comBaud" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "COM1", prop: "com1Baud" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.com1Baud,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "com1Baud", $$v)
                                    },
                                    expression: "form.com1Baud",
                                  },
                                },
                                _vm._l(_vm.comSpeeds, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "COM2", prop: "com2Baud" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: _vm.form.com2Baud,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "com2Baud", $$v)
                                    },
                                    expression: "form.com2Baud",
                                  },
                                },
                                _vm._l(_vm.comSpeeds, function (obj) {
                                  return _c("el-option", {
                                    key: obj.value,
                                    attrs: {
                                      label: obj.label,
                                      value: obj.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: {
                            title: "风扇温度(0.1)",
                            name: "Fan_temperature",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "启动", prop: "vanStartTemp" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.level.isL0,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.vanStartTemp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vanStartTemp", $$v)
                                  },
                                  expression: "form.vanStartTemp",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "停止", prop: "vanStop" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.level.isL0,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.vanStop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vanStop", $$v)
                                  },
                                  expression: "form.vanStop",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "温差", prop: "vanStartDiff" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.level.isL0,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.vanStartDiff,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vanStartDiff", $$v)
                                  },
                                  expression: "form.vanStartDiff",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "风扇控制" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  disabled: _vm.level.isL0,
                                  "active-text": "开启",
                                  "inactive-text": "停止",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.vanControler,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vanControler", $$v)
                                  },
                                  expression: "form.vanControler",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: {
                            title: "加热温度(0.1)",
                            name: "Hot_temperature",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "启动", prop: "hotStartTemp" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.level.isL0,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.hotStartTemp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "hotStartTemp", $$v)
                                  },
                                  expression: "form.hotStartTemp",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "停止", prop: "hotStop" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.level.isL0,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.hotStop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "hotStop", $$v)
                                  },
                                  expression: "form.hotStop",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "温升", prop: "hotStartDiff" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled: _vm.level.isL0,
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.hotStartDiff,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "hotStartDiff", $$v)
                                    },
                                    expression: "form.hotStartDiff",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("c/m"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "加热控制" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  disabled: _vm.level.isL0,
                                  "active-text": "开启",
                                  "inactive-text": "停止",
                                  "active-value": "1",
                                  "inactive-value": "0",
                                },
                                model: {
                                  value: _vm.form.hotControler,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "hotControler", $$v)
                                  },
                                  expression: "form.hotControler",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-20" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: { click: _vm.onCreateDefaultConfig },
            },
            [_vm._v("缺省参数生成")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.onReadConfig } },
            [_vm._v("参数读取")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.onSaveConfig } },
            [_vm._v("保存配置")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                disabled: _vm.notConfiged,
                type: "primary",
              },
              on: { click: _vm.onConfigBattery },
            },
            [_vm._v("电池配置")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.onSubmitConfig },
            },
            [_vm._v("参数下传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }