var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c("el-form-item", { attrs: { label: "网络时间" } }, [
            _c("div", { staticClass: "time-box" }, [
              _vm._v(" " + _vm._s(_vm.utils.formatTime(_vm.dateTime)) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onWrite },
            },
            [_vm._v("同步网络时间到设备")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }