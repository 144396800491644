<template>
  <div class="set-power-box" v-loading="loading">
    <el-form :style="`height: ${height}px; margin-bottom: 30px;`" ref="form" :model="form" :rules="rules" label-width="50px">
      <div class="set-months">
        <el-radio-group v-model="radio" size="medium" @input="onSelectRadio">
          <el-radio-button v-for="(index) in 12" :key="index" :label="`${index}月`"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="power-setting-box top-time-box">
        <div class="power-setting-title-box">尖时电价</div>
        <div class="power-setting-content-box">
          <el-form-item label="电价" prop="topPrice">
            <el-input class="bill-input" clearable v-model="form.topPrice" size="mini">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="时段">
            <el-form-item prop="topTime1">
              <from-to-time-selector name="topTime1" :value="form.topTime1" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="topTime2">
              <from-to-time-selector name="topTime2" :value="form.topTime2" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="topTime3">
              <from-to-time-selector name="topTime3" :value="form.topTime3" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="topTime4">
              <from-to-time-selector name="topTime4" :value="form.topTime4" @blur="onBlurTime" />
            </el-form-item>
          </el-form-item>
        </div>
      </div>
      <div class="power-setting-box high-time-box">
        <div class="power-setting-title-box">峰时电价</div>
        <div class="power-setting-content-box">
          <el-form-item label="电价" prop="highPrice">
            <el-input class="bill-input" clearable v-model="form.highPrice" size="mini">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="时段">
            <el-form-item prop="highTime1">
              <from-to-time-selector name="highTime1" :value="form.highTime1" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="highTime2">
              <from-to-time-selector name="highTime2" :value="form.highTime2" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="highTime3">
              <from-to-time-selector name="highTime3" :value="form.highTime3" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="highTime4">
              <from-to-time-selector name="highTime4" :value="form.highTime4" @blur="onBlurTime" />
            </el-form-item>
          </el-form-item>
        </div>
      </div>
      <div class="power-setting-box normal-time-box">
        <div class="power-setting-title-box">平时电价</div>
        <div class="power-setting-content-box">
          <el-form-item label="电价" prop="normalPrice">
            <el-input class="bill-input" clearable v-model="form.normalPrice" size="mini">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="时段">
            <el-form-item prop="normalTime1">
              <from-to-time-selector name="normalTime1" :value="form.normalTime1" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="normalTime2">
              <from-to-time-selector name="normalTime2" :value="form.normalTime2" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="normalTime3">
              <from-to-time-selector name="normalTime3" :value="form.normalTime3" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="normalTime4">
              <from-to-time-selector name="normalTime4" :value="form.normalTime4" @blur="onBlurTime" />
            </el-form-item>
          </el-form-item>
        </div>
      </div>
      <div class="power-setting-box low-time-box">
        <div class="power-setting-title-box">谷时电价</div>
        <div class="power-setting-content-box">
          <el-form-item label="电价" prop="lowPrice">
            <el-input class="bill-input" clearable v-model="form.lowPrice" size="mini">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="时段">
            <el-form-item prop="lowTime1">
              <from-to-time-selector name="lowTime1" :value="form.lowTime1" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="lowTime2">
              <from-to-time-selector name="lowTime2" :value="form.lowTime2" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="lowTime3">
              <from-to-time-selector name="lowTime3" :value="form.lowTime3" @blur="onBlurTime" />
            </el-form-item>
            <el-form-item prop="lowTime4">
              <from-to-time-selector name="lowTime4" :value="form.lowTime4" @blur="onBlurTime" />
            </el-form-item>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="right-btns">
      <el-button size="small" type="primary" @click="getDatas">数据读取</el-button>
      <el-button size="small" type="primary" @click="onSubmitThisMonth">保存当前月的配置</el-button>
    </div>
  </div>
</template>
<script>
import modbusApi from "@/utils/modbus";
import PriceTimeApi from "@/api/470/PriceTime";

const { calcHexFromUnsignedDec } = $numberMatrixing;

import FromToTimeSelector from '@/components/from-to-time-selector';

function checkPrice(pre, value) {
  if (!$utils.checkNumberIsNotEmpty(value)) {
    return `请输入${pre}时电价`;
  }

  value = Number(value);
  if (isNaN(value)) {
    return "请输入数字";
  }
  if (value < 0) {
    return "请输入大于等于0的数字";
  }
}

function calcPrice (value) {
  const vals = (value).toString().split(".");
  const intPart = vals[0] || "0"; // || 操作是为了保证输入的时候不会会出现 .123 这种输入
  const decPart = vals[1] ? vals[1].slice(0, 4): "0000"

  return `${intPart}.${decPart}`;
}

const checkTime = $utils.getTimeChecker($statics.ELECTRICITY_BILL_PRE_LIST);

export default {
  components: {
    FromToTimeSelector
  },
  props: {
    deviceInfo: null
  },
  data () {
    return {
      loading: false,
      start: 0x1090,
      radio: "1月",
      nowMonth: 0,
      form: {
        topPrice: "",
        highPrice: "",
        normalPrice: "",
        lowPrice: "",
        topTime1: [ 0, 0 ],
        topTime2: [ 0, 0 ],
        topTime3: [ 0, 0 ],
        topTime4: [ 0, 0 ],
        highTime1: [ 0, 0 ],
        highTime2: [ 0, 0 ],
        highTime3: [ 0, 0 ],
        highTime4: [ 0, 0 ],
        normalTime1: [ 0, 0 ],
        normalTime2: [ 0, 0 ],
        normalTime3: [ 0, 0 ],
        normalTime4: [ 0, 0 ],
        lowTime1: [ 0, 0 ],
        lowTime2: [ 0, 0 ],
        lowTime3: [ 0, 0 ],
        lowTime4: [ 0, 0 ],
      },
      data: null,
      rules: {
        topPrice: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkPrice("尖", value));
              callback(ret);
            }
          },
          {
            trigger: "blur",
            validator: (rule, vlue, callback) => {
              this.form.topPrice = calcPrice(this.form.topPrice);
              callback();
            }
          }
        ],
        topTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "top", 1));
              callback(ret);
            }
          }
        ],
        topTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "top", 2));
              callback(ret);
            }
          }
        ],
        topTime3: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "top", 3));
              callback(ret);
            }
          }
        ],
        topTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "top", 4));
              callback(ret);
            }
          }
        ],
        highPrice: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkPrice("峰", value));
              callback(ret);
            }
          },
          {
            trigger: "blur",
            validator: (rule, vlue, callback) => {
              this.form.highPrice = calcPrice(this.form.highPrice);
              callback();
            }
          }
        ],
        highTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "high", 1));
              callback(ret);
            }
          }
        ],
        highTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "high", 2));
              callback(ret);
            }
          }
        ],
        highTime3: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "high", 3));
              callback(ret);
            }
          }
        ],
        highTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "high", 4));
              callback(ret);
            }
          }
        ],
        normalPrice: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkPrice("平", value));
              callback(ret);
            }
          },
          {
            trigger: "blur",
            validator: (rule, vlue, callback) => {
              this.form.normalPrice = calcPrice(this.form.normalPrice);
              callback();
            }
          }
        ],
        normalTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "normal", 1));
              callback(ret);
            }
          }
        ],
        normalTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "normal", 2));
              callback(ret);
            }
          }
        ],
        normalTime3: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "normal", 3));
              callback(ret);
            }
          }
        ],
        normalTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "normal", 4));
              callback(ret);
            }
          }
        ],
        lowPrice: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkPrice("谷", value));
              callback(ret);
            }
          },
          {
            trigger: "blur",
            validator: (rule, vlue, callback) => {
              this.form.lowPrice = calcPrice(this.form.lowPrice);
              callback();
            }
          }
        ],
        lowTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "low", 1));
              callback(ret);
            }
          }
        ],
        lowTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "low", 2));
              callback(ret);
            }
          }
        ],
        lowTime3: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "low", 3));
              callback(ret);
            }
          }
        ],
        lowTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const ret = (checkTime(this, "low", 4));
              callback(ret);
            }
          }
        ]
      },
      preList: $statics.ELECTRICITY_BILL_PRE_LIST,
      projectType: 0
    }
  },
  created () {
    console.log("set-electricity-bill");
    let projectType = this.deviceInfo.projectType;
    if (projectType === 6) {
      projectType = 1;
    }
    this.projectType = projectType;
    this.getDatas();
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    async getDatas () {
      try {
        this.loading = true;
        const params = {
          code: this.deviceInfo.code,
          moon: this.nowMonth
        };

        const res = await PriceTimeApi.list(params);
        this.parse(res.data[0]);
      } catch (err) {
        this.parse({});
      } finally {
        this.loading = false;
      }
    },
    parse (data) {
      this.form.id = data.id;
      this.form.topPrice  = data.topElectricityPrice ? (data.topElectricityPrice / 10000) : 0;
      this.form.topTime1  = [ data.pointedStartOne || 0 || 0, data.pointedEndOne || 0 ];
      this.form.topTime2  = [ data.pointedStartTwo || 0, data.pointedEndTwo || 0 ];
      this.form.topTime3  = [ data.pointedStartThree || 0, data.pointedEndThree || 0 ];
      this.form.topTime4  = [ data.pointedStartFour || 0, data.pointedEndFour || 0 ];

      this.form.highPrice  = data.peakElectricityPrice ? (data.peakElectricityPrice / 10000) : 0;
      this.form.highTime1  = [ data.peakStartOne || 0, data.peakEndOne || 0 ];
      this.form.highTime2  = [ data.peakStartTwo || 0, data.peakEndTwo || 0 ];
      this.form.highTime3  = [ data.peakStartThree, data.peakEndThree || 0 ];
      this.form.highTime4  = [ data.peakStartFour, data.peakEndFour || 0 ];

      this.form.normalPrice  = data.normalElectricityPrice ? (data.normalElectricityPrice / 10000) : 0;
      this.form.normalTime1  = [ data.flatStartOne || 0, data.flatEndOne || 0 ];
      this.form.normalTime2  = [ data.flatStartTwo || 0, data.flatEndTwo || 0 ];
      this.form.normalTime3  = [ data.flatStartThree || 0, data.flatEndThree || 0 ];
      this.form.normalTime4  = [ data.flatStartFour || 0, data.flatEndFour || 0 ];

      this.form.lowPrice  = data.valleyElectricityPrice ? (data.valleyElectricityPrice / 10000) : 0;
      this.form.lowTime1  = [ data.valleyStartOne || 0, data.valleyEndOne || 0 ];
      this.form.lowTime2  = [ data.valleyStartTwo || 0, data.valleyEndTwo || 0 ];
      this.form.lowTime3  = [ data.valleyStartThree || 0, data.valleyEndThree || 0 ];
      this.form.lowTime4  = [ data.valleyStartFour || 0, data.valleyEndFour || 0 ];
    },
    onSubmitThisMonth () {
      this.$refs.form.validate(async valid => {
        if (valid) {

          try {
            this.loading = true;

            await Promise.all([
              this.submitCore(this.nowMonth),
              this.writeV2(this.nowMonth)
            ]);
          } catch (err) {
            console.log(err);
          } finally {
            this.loading = false;
          }
        }
      })
    },
    async submitCore () {
      const form = this.form;
      const submitData = {
        id: form.id,
        moon: this.nowMonth,
        code: this.deviceInfo.code,
        // [20240514]
        // 因为电价数字限定为了四位小数，所以这里多扩大一位后进行一次四舍五入，再除以 10，以保证不会出现浮点数计算带来的隐式错误
        topElectricityPrice:  Math.round(form.topPrice * 100000) / 10,
        peakElectricityPrice:  Math.round(form.highPrice * 100000) / 10,
        normalElectricityPrice: Math.round(form.normalPrice * 100000) / 10,
        valleyElectricityPrice: Math.round(form.lowPrice * 100000) / 10,
        // 尖
        pointedStartOne: form.topTime1[0],
        pointedEndOne: form.topTime1[1],
        pointedStartTwo: form.topTime2[0],
        pointedEndTwo: form.topTime2[1],
        pointedStartThree: form.topTime3[0],
        pointedEndThree: form.topTime3[1],
        pointedStartFour: form.topTime4[0],
        pointedEndFour: form.topTime4[1],
        // 峰
        peakStartOne: form.highTime1[0],
        peakEndOne: form.highTime1[1],
        peakStartTwo: form.highTime2[0],
        peakEndTwo: form.highTime2[1],
        peakStartThree: form.highTime3[0],
        peakEndThree: form.highTime3[1],
        peakStartFour: form.highTime4[0],
        peakEndFour: form.highTime4[1],
        // 平
        flatStartOne: form.normalTime1[0],
        flatEndOne: form.normalTime1[1],
        flatStartTwo: form.normalTime2[0],
        flatEndTwo: form.normalTime2[1],
        flatStartThree: form.normalTime3[0],
        flatEndThree: form.normalTime3[1],
        flatStartFour: form.normalTime4[0],
        flatEndFour: form.normalTime4[1],
        // 谷
        valleyStartOne: form.lowTime1[0],
        valleyEndOne: form.lowTime1[1],
        valleyStartTwo: form.lowTime2[0],
        valleyEndTwo: form.lowTime2[1],
        valleyStartThree: form.lowTime3[0],
        valleyEndThree: form.lowTime3[1],
        valleyStartFour: form.lowTime4[0],
        valleyEndFour: form.lowTime4[1]
      };

      const method = form.id ? "update" : "save";
      await PriceTimeApi[method](submitData);
    },
    async writeV2 () {
      const price = this.createPriceArray();

      const params = {
        code: this.deviceInfo.code,
        origin: calcHexFromUnsignedDec(this.start + this.nowMonth * 100),
        size: price.length,
        type: 1, // 功率(和电价)
        data: price.join("").toUpperCase()
      };

      await modbusApi.write(params);
    },
    createPriceArray () {

      const form = this.form;

      const array = [];
      // [20240514]
      // 因为电价数字限定为了四位小数，所以这里多扩大一位后进行一次四舍五入，再除以 10，以保证不会出现浮点数计算带来的隐式错误
      let topElectricityPrice =  calcHexFromUnsignedDec(parseInt(form.topPrice * 100000) / 10, 8);
      let peakElectricityPrice = calcHexFromUnsignedDec(parseInt(form.highPrice * 100000) / 10, 8);
      let normalElectricityPrice = calcHexFromUnsignedDec(parseInt(form.normalPrice * 100000) / 10, 8);
      let valleyElectricityPrice = calcHexFromUnsignedDec(parseInt(form.lowPrice * 100000) / 10, 8);

      array.push(topElectricityPrice.slice(0, 4));
      array.push(topElectricityPrice.slice(4));
      array.push(peakElectricityPrice.slice(0, 4));
      array.push(peakElectricityPrice.slice(4));
      array.push(normalElectricityPrice.slice(0, 4));
      array.push(normalElectricityPrice.slice(4));
      array.push(valleyElectricityPrice.slice(0, 4));
      array.push(valleyElectricityPrice.slice(4));

      let [ topTime1Start, topTime1End ] = form.topTime1;
      let [ topTime2Start, topTime2End ] = form.topTime2;
      let [ topTime3Start, topTime3End ] = form.topTime3;
      let [ topTime4Start, topTime4End ] = form.topTime4;

      if (topTime1End > 1440) {
        topTime1End -= 1440;
      }
      if (topTime2End > 1440) {
        topTime2End -= 1440;
      }
      if (topTime3End > 1440) {
        topTime3End -= 1440;
      }
      if (topTime4End > 1440) {
        topTime4End -= 1440;
      }

      array.push(calcHexFromUnsignedDec(topTime1Start));
      array.push(calcHexFromUnsignedDec(topTime1End));
      array.push(calcHexFromUnsignedDec(topTime2Start));
      array.push(calcHexFromUnsignedDec(topTime2End));
      array.push(calcHexFromUnsignedDec(topTime3Start));
      array.push(calcHexFromUnsignedDec(topTime3End));
      array.push(calcHexFromUnsignedDec(topTime4Start));
      array.push(calcHexFromUnsignedDec(topTime4End));

      let [ highTime1Start, highTime1End ] = form.highTime1;
      let [ highTime2Start, highTime2End ] = form.highTime2;
      let [ highTime3Start, highTime3End ] = form.highTime3;
      let [ highTime4Start, highTime4End ] = form.highTime4;

      if (highTime1End > 1440) {
        highTime1End -= 1440;
      }
      if (highTime2End > 1440) {
        highTime2End -= 1440;
      }
      if (highTime3End > 1440) {
        highTime3End -= 1440;
      }
      if (highTime4End > 1440) {
        highTime4End -= 1440;
      }

      array.push(calcHexFromUnsignedDec(highTime1Start));
      array.push(calcHexFromUnsignedDec(highTime1End));
      array.push(calcHexFromUnsignedDec(highTime2Start));
      array.push(calcHexFromUnsignedDec(highTime2End));
      array.push(calcHexFromUnsignedDec(highTime3Start));
      array.push(calcHexFromUnsignedDec(highTime3End));
      array.push(calcHexFromUnsignedDec(highTime4Start));
      array.push(calcHexFromUnsignedDec(highTime4End));

      let [ normalTime1Start, normalTime1End ] = form.normalTime1;
      let [ normalTime2Start, normalTime2End ] = form.normalTime2;
      let [ normalTime3Start, normalTime3End ] = form.normalTime3;
      let [ normalTime4Start, normalTime4End ] = form.normalTime4;

      if (normalTime1End > 1440) {
        normalTime1End -= 1440;
      }
      if (normalTime2End > 1440) {
        normalTime2End -= 1440;
      }
      if (normalTime3End > 1440) {
        normalTime3End -= 1440;
      }
      if (normalTime4End > 1440) {
        normalTime4End -= 1440;
      }

      array.push(calcHexFromUnsignedDec(normalTime1Start));
      array.push(calcHexFromUnsignedDec(normalTime1End));
      array.push(calcHexFromUnsignedDec(normalTime2Start));
      array.push(calcHexFromUnsignedDec(normalTime2End));
      array.push(calcHexFromUnsignedDec(normalTime3Start));
      array.push(calcHexFromUnsignedDec(normalTime3End));
      array.push(calcHexFromUnsignedDec(normalTime4Start));
      array.push(calcHexFromUnsignedDec(normalTime4End));

      let [ lowTime1Start, lowTime1End ] = form.lowTime1;
      let [ lowTime2Start, lowTime2End ] = form.lowTime2;
      let [ lowTime3Start, lowTime3End ] = form.lowTime3;
      let [ lowTime4Start, lowTime4End ] = form.lowTime4;

      if (lowTime1End > 1440) {
        lowTime1End -= 1440;
      }
      if (lowTime2End > 1440) {
        lowTime2End -= 1440;
      }
      if (lowTime3End > 1440) {
        lowTime3End -= 1440;
      }
      if (lowTime4End > 1440) {
        lowTime4End -= 1440;
      }

      array.push(calcHexFromUnsignedDec(lowTime1Start));
      array.push(calcHexFromUnsignedDec(lowTime1End));
      array.push(calcHexFromUnsignedDec(lowTime2Start));
      array.push(calcHexFromUnsignedDec(lowTime2End));
      array.push(calcHexFromUnsignedDec(lowTime3Start));
      array.push(calcHexFromUnsignedDec(lowTime3End));
      array.push(calcHexFromUnsignedDec(lowTime4Start));
      array.push(calcHexFromUnsignedDec(lowTime4End));

      return array;

    },
    onBlurTime (name, values) {
      this.form[name] = values;
    },
    onSelectRadio (evt) {
      evt = evt.replace("月", "");
      const index = Number(evt) - 1;
      this.nowMonth = index;
      this.getDatas();
    }
  }
}
</script>
<style lang="scss" scoped>
.bill-input {
  width: 170px;
  margin-top: 5px;
  margin-right: 10px;
}

.power-setting-content-box {
  >div {
    display: inline-block;
    vertical-align: bottom;
  }
}

.power-setting-box:last-child {
  margin-bottom: 0px;
}
.low-time-box {
  border: 1px dashed #008000;
  background-image: url(./../../../../assets/images/low-time.svg);
}

.normal-time-box {
  border: 1px dashed #4f5fbc;
  background-image: url(./../../../../assets/images/normal-time.svg);
}

.high-time-box {
  border: 1px dashed #fa6d1f;
  background-image: url(./../../../../assets/images/high-time.svg);
}

.top-time-box {
  border: 1px dashed #db0000;
  background-image: url(./../../../../assets/images/top-time.svg);
}

::v-deep .el-form-item__content .el-form-item {
  display: inline-block;
}
</style>