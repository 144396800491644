<template>
  <div>
    <div class="list-table" style="margin-left: 10px;">
      <el-table :data="list" :height="height" :style="`width: 100%; overflow:auto;`" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="Rack一级告警" prop="rackWarning" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackWarning === null"> 无数据</div>
            <div v-if="scope.row.rackWarning === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackWarning, index) in scope.row.rackWarning">
                {{ rackWarning === "0" ? "" : rackWarningList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Rack二级告警" prop="rackAlarm" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackAlarm === null">无数据</div>
            <div v-if="scope.row.rackAlarm === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackAlarm, index) in scope.row.rackAlarm">
                {{ rackAlarm === "0" ? "" : rackAlarmList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Rack三级告警" prop="rackCriticalAlarm" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackCriticalAlarm === null">无数据</div>
            <div v-if="scope.row.rackCriticalAlarm === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackCriticalAlarm, index) in scope.row.rackCriticalAlarm">
                {{ rackCriticalAlarm === "0" ? "" : rackCriticalAlarmList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import HistoryMixin from "./mixin";

const { hexToBinary } = $numberMatrixing;

export default {
  computed: {
    ...mapGetters(['routes', 'user']),
  },
  mixins: [ HistoryMixin ],
  data () {
    return {
      rackWarningList: $statics.RACK_WARNING_LIST,
      rackAlarmList: $statics.RACK_ALARM_LIST,
      rackCriticalAlarmList: $statics.RACK_CRITICAL_ALARM_LIST,
      statusTxt: $statics.DEVICE_UPGRADE_RESULT_STATUS_LIST,
      typeMap: $statics.FIRMWARE_TYPE_MAP,
      form: {
        state: "",
        type: ""
      }
    }
  },
  methods: {
    setDatas () {
      this.list = this.list.map(data => {

        if (data.rackWarning) {
          if (data.rackWarning === "0000") {
            data.rackWarning = "正常";
          } else {
            data.rackWarning = hexToBinary(data.rackWarning, 4).split("");
          }
        } else {
          data.rackWarning = null;
        }

        if (data.rackAlarm) {
          if (data.rackAlarm === "0000") {
            data.rackAlarm = "正常";
          } else {
            data.rackAlarm = hexToBinary(data.rackAlarm, 4).split("");
          }
        } else {
          data.rackAlarm = null;
        }

        if (data.rackCriticalAlarm) {
          if (data.rackCriticalAlarm === "0000") {
            data.rackCriticalAlarm = "正常";
          } else {
          data.rackCriticalAlarm = hexToBinary(data.rackCriticalAlarm, 4).split("");
          }
        } else {
          data.rackCriticalAlarm = null;
        }

        return data;
      });
    },
    onSelect () {
      this.$emit("select", this.form);
    },
    getStatusVal (row, key) {
      const { state } = row;
      const val = this.statusTxt[state][key];
      return val;
    },
  }
};
</script>