<template>
  <el-dialog
    :title="options.title"
    :visible.sync="centerDialogVisible"
    destroy-on-close
    :close-on-press-escape="false"
    show-close
    :close-on-click-modal="true" 
    class="dialog-comment-box"
    :width="width"
    @close="onClose"
    center>
    <slot></slot>
    <div class="dialog-footer">
      <el-button type="primary" size="mini" @click="onCancel">{{ options.cancelButtonText }}</el-button>
      <el-button type="primary" size="mini" @click="onCommit">{{ options.confirmButtonText }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    width: {
      tyep: String,
      default: "500px"
    }
  },
  data () {
    return {
      centerDialogVisible: false
    }
  },
  methods: {
    onClose () {
      this.$emit("close");
    },
    setVisible (flag) {
      this.centerDialogVisible = flag;
    },
    onCancel () {
      this.$emit("left");
    },
    onCommit () {
      this.$emit("right");
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-comment-box .el-dialog__body {
  padding:0px !important;
}

.dialog-footer {
  text-align: center;
}
</style>