import request from '@/utils/request';

export const download = (data) => {
    const rand = Date.now();
    return request.post(`module-ElectricityPeaksValleys/download?${rand}`, data, {
        responseType:'blob'  // 一定要加
    });
}
export const month = (params) => {
    const rand = Date.now();
    return request.get(`module-ElectricityPeaksValleys/month?${rand}`, { params });

}
export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-ElectricityPeaksValleys/queryByPage?${rand}`, { params });
}
export const save = (params) => {
    const rand = Date.now();
    return request.post(`module-ElectricityPeaksValleys/save?${rand}`, { params });
}
export const update = (params) => {
    const rand = Date.now();
    return request.post(`module-ElectricityPeaksValleys/update?${rand}`, { params });
}
export default {
    download,
    month,
    list,
    save,
    update
}