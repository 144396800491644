var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "m-l-15",
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticClass: "fixed-table",
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.height,
                data: _vm.table,
                "header-cell-style": {
                  background: "#F7F7F7",
                  color: "#2d2d2d",
                  "text-align": "center",
                },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "高压侧电压(V)",
                  prop: "highSideVoltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "高压侧电流(A)",
                  prop: "highSideCurrent",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "高压侧功率(kW)",
                  prop: "highSidePower",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT1温度(℃)",
                  prop: "temperatureModuleIGBT1",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT2温度(℃)",
                  prop: "temperatureModuleIGBT2",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT3温度(℃)",
                  prop: "temperatureModuleIGBT3",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT4温度(℃)",
                  prop: "temperatureModuleIGBT4",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT5温度(℃)",
                  prop: "temperatureModuleIGBT5",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT6温度(℃)",
                  prop: "temperatureModuleIGBT6",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块进风口温度(℃)",
                  prop: "temperatureModuleAirInlet",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块出风口温度(℃)",
                  prop: "temperatureModuleAirOutlet",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入1电压(V)",
                  prop: "lowVoltageSideConnected1Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入1电流(A)",
                  prop: "lowVoltageSideConnected1Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入1功率(kW)",
                  prop: "lowVoltageSideConnected1Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留1", prop: "reserve1", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入2电压(V)",
                  prop: "lowVoltageSideConnected2Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入2电流(A)",
                  prop: "lowVoltageSideConnected2Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入2功率(kW)",
                  prop: "lowVoltageSideConnected2Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留2", prop: "reserve2", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入3电压(V)",
                  prop: "lowVoltageSideConnected3Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入3电流(A)",
                  prop: "lowVoltageSideConnected3Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入3功率(kW)",
                  prop: "lowVoltageSideConnected3Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留3", prop: "reserve3", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入4电压(V)",
                  prop: "lowVoltageSideConnected4Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入4电流(A)",
                  prop: "lowVoltageSideConnected4Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入4功率(kW)",
                  prop: "lowVoltageSideConnected4Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留4", prop: "reserve4", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入5电压(V)",
                  prop: "lowVoltageSideConnected5Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入5电流(A)",
                  prop: "lowVoltageSideConnected5Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入5功率(kW)",
                  prop: "lowVoltageSideConnected5Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留5", prop: "reserve5", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入6电压(V)",
                  prop: "lowVoltageSideConnected6Voltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入6电流(A)",
                  prop: "lowVoltageSideConnected6Current",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "低压侧接入6功率(kW)",
                  prop: "lowVoltageSideConnected6Power",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "预留6", prop: "reserve6", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备状态字1",
                  prop: "deviceStatusByte1",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备状态字2",
                  prop: "deviceStatusByte2",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备状态字3",
                  prop: "deviceStatusByte3",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备状态字4",
                  prop: "deviceStatusByte4",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "故障字1（并机）",
                  prop: "faultByte1",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "故障字2（系统）",
                  prop: "faultByte2System",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入1故障字",
                  prop: "dcAccess1FaultByte",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入2故障字",
                  prop: "dcAccess2FaultByte",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入3故障字",
                  prop: "dcAccess3FaultByte",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入4故障字",
                  prop: "dcAccess4FaultByte",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入5故障字",
                  prop: "dcAccess5FaultByte",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流接入6故障字",
                  prop: "dcAccess6FaultByte",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }