<template>
  <div>
    <div style="margin-bottom: 20px;">
      <el-form>
        <el-row :span="24">
          <el-col :span="4">
              <el-form-item label="电压显示数">
                <el-input-number v-model="vLen" size="mini" :min="1" :max="120" label="电压显示数"></el-input-number>
              </el-form-item>
          </el-col>
          <el-col :span="10">
              <el-form-item label="温度显示数">
                <el-input-number v-model="tLen" size="mini" :min="1" :max="120" label="电压显示数"></el-input-number>
              </el-form-item>
          </el-col>
          <el-col :span="8" class="right-btns">
            <el-checkbox :disabled="true">不分配</el-checkbox>
            <el-checkbox v-model="checked" :disabled="true">已存在</el-checkbox>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-loading="loading">
      <el-table :data="dataList" border style="width: 100%" :height="height - 50" :show-header="false">
        <el-table-column type="index" width="50" align="center" fixed="left">
          <template slot-scope="scope">
            {{ (scope.$index + 1) }}#
          </template>
        </el-table-column>
        <el-table-column width="40" align="center">
          <template slot-scope="scope">
            <el-checkbox :indeterminate="getCheckAllProperty(checkAlls[scope.$index], 'isIndeterminate')"
              :v-model="getCheckAllProperty(checkAlls[scope.$index], 'checkAllFlg')"
              @change="val => onChangeAFE(scope.$index, val)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-checkbox-group v-model="checkAlls[scope.$index].list" @change="val => onCheckedChange(scope.$index, val)">
              <div>
                <div v-for="n in vLen + tLen" class="val-box" v-if="n % 2 === 1">
                  {{ scope.row[n - 1] }}
                  <el-checkbox v-if="n <= vLen" v-model="scope.row[n - 1]" :label="`C#${n}`" :key="`C#${n}`"></el-checkbox>
                  <el-checkbox v-else v-model="scope.row[n - 1]" :label="`T#${n - vLen}`" :key="`T#${n - vLen}`"></el-checkbox>
                </div>
              </div>
              <div>
                <div v-for="n in vLen + tLen" class="val-box" v-if="n % 2 === 0">
                  {{ typeof scope.row[n - 1] }}
                  <el-checkbox v-if="n <= vLen" v-model="scope.row[n - 1]" :label="`C#${n}`" :key="`C#${n}`"></el-checkbox>
                  <el-checkbox v-else v-model="scope.row[n - 1]" :label="`T#${n - vLen}`" :key="`T#${n - vLen}`"></el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 15px; margin-left: 10px;">
      <div>
        <div v-for="n in vLen" class="val-box-check">
          <el-checkbox @change="val => onChangeVol(n - 1, val)" :label="`C#${n}`"></el-checkbox>
        </div>
        <div v-for="n in tLen" class="val-box-check">
          <el-checkbox @change="val => onChangeTemp(n - 1, val)" :label="`T#${n}`"></el-checkbox>
        </div>
      </div>
    </div>
    <div class="right-btns m-t-20">
      <el-button @click="onSelectAll" size="mini">{{ selectAllTxt }}</el-button>
      <el-button @click="onReadConfig" size="mini">参数读取</el-button>
      <el-button @click="onSubmitConfig" :disabled="notRead || utils.userReadOnly(user)" type="primary" size="mini">参数下传</el-button>
    </div>
  </div>
</template>
<script>
const { binaryToHex, calcHexFromUnsignedDec, hexAppendZero } = $numberMatrixing;
import { mapGetters } from 'vuex';
import modbusApi from "@/utils/modbus";

import HostParameterSettingsApi from "@/api/470/HostParameterSettings";

export default {
  computed: {
    ...mapGetters(['user', 'routes'])
  },
  props: {
    level: { // 密码等级，暂时没有用到
      type: Object,
      default: {}
    },
    deviceInfo: {
      type: Object,
      default: {}
    },
    height: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    },
    mask: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      vLen: 16,
      tLen: 16,
      utils: $utils,
      loading: false,
      checked: true, // 这个不修改
      notRead: true,
      vStart: 0x33A0,
      tStart: 0x33A1,
      selectAllTxt: "全选",
      selectAllFlg: false,
      indexes: [],
      dataList: [],
      checkAlls: [],
      temperatureList: []
    }
  },
  created () {
    let _CellCFGStru_Mask = sessionStorage.getItem(`device-configs-_CellCFGStru_Mask:${this.deviceInfo.code}`);
    if (_CellCFGStru_Mask) {
      _CellCFGStru_Mask = JSON.parse(_CellCFGStru_Mask);
      for (let i = 0; i < this.data.afeNum; i++) {
        const volVal = Number(_CellCFGStru_Mask[`Voltage_Mask[${i}]`]);
        const volBinVal = this.getMask(calcHexFromUnsignedDec(volVal), this.vLen);
        const tempVal = Number(_CellCFGStru_Mask[`Temperature_Mask[${i}]`]);
        const tempBinVal = this.getMask(calcHexFromUnsignedDec(tempVal), this.vLen);

        const checkAllFlg = volVal === 65535 && tempVal === 65535;
        const isEmpty = volVal === 0 &&  tempVal === 0;
        const line1 = [], line2 = [], chkAllList = [];

        for (let i = 0; i < volBinVal.length; i++) {
          const value = volBinVal.charAt(i) === "0";
          if (value) {
            chkAllList.push(`C#${i + 1}`);
          }
          line1.push(value);
          line2.push(value);
        }
        for (let i = 0; i < tempBinVal.length; i++) {
          const value = tempBinVal.charAt(i) === "0";
          if (value) {
            chkAllList.push(`T#${i + 1}`);
          }
          line1.push(value);
          line2.push(value);
        }

        this.checkAlls.push({
          checkAllFlg,
          list: chkAllList,
          isIndeterminate: !checkAllFlg && !isEmpty,
        });
        this.dataList.push(line1);
        this.indexes.push(line2);
      }
    } else {
      this.initCheckAll();
    }
  },
  methods: {
    initCheckAll () {
      try {
        this.loading = true;
        this.checkAlls = [];
        for (let i = 0; i < this.data.afeNum; i++) {
          this.checkAlls.push({
            checkAllFlg: false,
            list: [],
            isIndeterminate: false,
          });
          this.dataList.push(new Array(this.vLen + this.tLen).fill(false));
          this.indexes = new Array(this.vLen + this.tLen).fill(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }

    },
    async onReadConfig () {

      this.initCheckAll();

      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code,
          type: 4,
          size: 2,
          page: 1
        };

        const res = await HostParameterSettingsApi.list(params);

        const { data } = res.data[0];
        const vMasks = [], tMasks = [];
        let flag = true;

        for (let i = 0, len = data.length; i < len; i += 4 ) {
          const value = data.slice(i, i + 4);
          if (flag) {
            vMasks.push(this.getMask(value, this.vLen));
          } else {
            tMasks.push(this.getMask(value, this.tLen));
          }

          flag = !flag;
        }

        const dataList = [];
        for (let i = 0, len = vMasks.length; i < len; i++) {
          const voltages = [];
          for (let j = 0; j < this.vLen; j++) {
            const mask = vMasks[i][j] === "0";
            voltages.push(mask);
            if (mask) {
              try {
                this.checkAlls[i].list.push(`C#${j + 1}`);
              } catch (e) {
                console.log(e);
              }
            }
          }
          dataList.push(voltages);
        }

        for (let i = 0, len = tMasks.length; i < len; i++) {
          const temperatures = [];
          for (let j = 0; j < this.tLen; j++) {
            const mask = tMasks[i][j] === "0";
            temperatures.push(mask);
            if (mask) {
              this.checkAlls[i].list.push(`T#${j + 1}`);
            }
          }
          let data = dataList[i];
          dataList[i] = data = data.concat(temperatures);
          this.checkAlls[i].checkAllFlg = data.indexOf(false) < 0;
          this.checkAlls[i].isIndeterminate = data.indexOf(false) >= 0 && data.indexOf(true) >= 0;
        }

        this.dataList = dataList;
      } catch (err) {
        console.log(err);
      } finally {
        this.notRead = false;
        this.loading = false;
      }
    },
    getMask (value, length) {
      const mask = parseInt(value.slice(0, 4), 16).toString(2).split("");
      while (mask.length < length) {
        mask.unshift("0");
      }
      const output = mask.reverse().join("");
      return output;
    },
    async onSubmitConfig () {

      this.loading = true;
      $utils.setDeviceDoing(this.deviceInfo);

      const submitObj = [], saveVal = [];
      let vStart = this.vStart;
      let tStart = this.tStart;

      try {
        
        for (let i = 0, len = this.checkAlls.length; i < len; i++) {
          const row = this.checkAlls[i];
          const list = row.list;
          const vOutput = new Array(this.vLen).fill("1");
          const tOutput = new Array(this.tLen).fill("1");
          const saveOutput = [ `第${i + 1}行`, list.join() ];

          for (let item of list) {
            const key = item[0];
            let index = Number(item.slice(2)) - 1;
            if (key === "C") {
              vOutput[index] = "0";
            } else {
              tOutput[index] = "0";
            }
          }

          const vVal = binaryToHex(vOutput.reverse().join(""));
          submitObj.push(hexAppendZero(vVal));
          const tVal = binaryToHex(tOutput.reverse().join(""));
          submitObj.push(hexAppendZero(tVal));
        }

        const params = {
          code: this.deviceInfo.code,
          origin: calcHexFromUnsignedDec(this.vStart),
          size: submitObj.length,
          type: 9, // 屏蔽位
          data: submitObj.join("").toUpperCase()
        };

        await modbusApi.write(params);
        this.$message.success("参数下发送成功");
      } catch (err) {
        this.$message.warning("参数下发送失败");
      } finally {
        $utils.setDeviceDone(this.deviceInfo);
        this.loading = false;
      }
    },
    getCheckAllProperty(obj, key, defaultVal) {
      const res = obj ? obj[key] : (defaultVal || "");
      return res;
    },
    onChangeVol (val, flag) {
      val = Number(val);
      const dataList = JSON.parse(JSON.stringify(this.dataList));
      const value = this.indexes[val] = !this.indexes[val]; // ? "1" : "0";
      for (const data of dataList) {
        data[val] = value;
      }
      this.dataList = dataList;

      for (const checkAll of this.checkAlls) {
        const label = (val < this.vLen) ? `C#${val + 1}` : `T#${val - this.vLen + 1}`;
        const index = checkAll.list.indexOf(label);
        if (flag) { // 加入
          if (index < 0) {
            checkAll.list.push(label);
          } 
        } else {
          if (index >= 0) {
            checkAll.list.splice(index, 1);
          }
        }

        const listCnt = checkAll.list.length;
        checkAll.checkAllFlg = this.vLen + this.tLen === listCnt;
        checkAll.isIndeterminate = listCnt > 0 && listCnt < this.vLen + this.tLen;
      }
    },
    onChangeTemp (val, flag) {
      val = Number(val) + this.vLen;
      this.onChangeVol(val, flag);
    },
    onSelectAll () {
      const flag = !this.selectAllFlg;
      const dataList = [];
      this.checkAlls = [];
      for (let i = 0, len = this.dataList.length; i < len; i++) {
        dataList.push(new Array(this.vLen + this.tLen).fill(flag));

        const list = [];
        if (flag) {
          for (let i = 0; i < this.vLen; i++) {
            list.push(`C#${i + 1}`);
          }
          for (let i = 0; i < this.tLen; i++) {
            list.push(`T#${i + 1}`);
          }
        }
        this.checkAlls.push( {
          list,
          checkAllFlg: this.vLen + this.tLen === list.length,
          isIndeterminate: false
        });
      }
      if (flag) {
        this.selectAllTxt = "全不选";
      } else {
        this.selectAllTxt = "全选";
      }

      this.dataList = dataList;
      this.selectAllFlg = flag;
    },
    onChangeAFE (index, val) {
      const checkAll = this.checkAlls[index];
      const list = [];
      if (val) {
        for (let i = 0; i < this.vLen; i++) {
          list.push(`C#${i + 1}`);
        }
        for (let i = 0; i < this.tLen; i++) {
          list.push(`T#${i + 1}`);
        }
      }

      checkAll.list = list;
      checkAll.isIndeterminate = false;
    },
    onCheckedChange (index, list) {
      const checkAll = this.checkAlls[index];
      let checkedCount = list.length;

      checkAll.list = list;
      checkAll.checkAllFlg = this.vLen + this.tLen === checkedCount;
      checkAll.isIndeterminate = checkedCount > 0 && checkedCount < this.vLen + this.tLen;
    },
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    }
  }
}
</script>
<style scoped lang="scss">
.val-box {
  width: 6.2%;
  display: inline-table;
  span {
    margin-left: 5px;
  }
}

.val-box-check {
  min-width: 3.5%;
  max-width: 8%;
  display: inline-table;
  margin-left: 10px;
  span {
    margin-left: 2px;
  }
}
</style>