import request from '@/utils/request';

export const last = (params) => {
    const rand = Date.now();
    return request.get(`module-HostSystemParameter/MasterControlAlarm/last?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.post(`module-HostSystemParameter/save?${rand}`, data);
}

export default {
    last,
    save
}