import request from '@/utils/request';
const { calcHexFromUnsignedDec } = $numberMatrixing;

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-SystemWrite/list?${rand}`, { params });
}

export const save = ({ code, size, origin, data }) => {
    const rand = Date.now();
    return request.post(`module-SystemWrite/save?${rand}`, {
        code,
        slave: "01",
        functionCode: "10",
        origin,
        writeBytes: calcHexFromUnsignedDec(size * 2, 2), // 连续写入长度 * 2
        writeLength: calcHexFromUnsignedDec(size, 4), // 连续写入长度
        data
    });
}

export const write = ({ code, size, origin, data }) => {
    const rand = Date.now();
    return request.post(`module-SystemWrite/Write?${rand}`, {
        code,
        slave: "01",
        functionCode: "10",
        origin,
        writeBytes: calcHexFromUnsignedDec(size * 2, 2), // 连续写入长度 * 2
        writeLength: calcHexFromUnsignedDec(size, 4), // 连续写入长度
        data
    });
}

export default {
    list,
    save,
    write
}