import request from '@/utils/request';

// 列表
export const list = (params) => {
    const rand = Date.now();
    return request.get(`hostElectricityPrice/List?${rand}`, { params });
}
// 增加
export const add = (data) => {
    const rand = Date.now();
    return request.put(`hostElectricityPrice/add?${rand}`, data);
}
// 编辑
export const edit = (data) => {
    const rand = Date.now();
    return request.post(`hostElectricityPrice/update?${rand}`, data);
}

// 查询最新一条接口
export const price = (code) => {
    const rand = Date.now();
    return request.get(`hostElectricityPrice/${code}/Price?${rand}`);
}

// 放电电价
export const dischgPrice = (params) => {
    const rand = Date.now();
    return request.get(`Battery/Discharge/electricity/price?${rand}`, {params});
}

// 充电电价
export const chgPrice = (params) => {
    const rand = Date.now();
    return request.get(`Battery/Charging/electricity/price?${rand}`, {params});
}

export default {
    list,
    add, 
    edit,
    price,
    chgPrice,
    dischgPrice
}