var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "fire-protection-items-box",
          attrs: { "label-width": "250px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "CO一级浓度告警值",
                prop: "firstLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.firstLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firstLevelConcentration", $$v)
                  },
                  expression: "form.firstLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "CO二级浓度告警值",
                prop: "secondaryConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.secondaryConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "secondaryConcentration", $$v)
                  },
                  expression: "form.secondaryConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "CO三级浓度告警值",
                prop: "thirdLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.thirdLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "thirdLevelConcentration", $$v)
                  },
                  expression: "form.thirdLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "H2一级浓度告警值",
                prop: "hfirstLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.hfirstLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hfirstLevelConcentration", $$v)
                  },
                  expression: "form.hfirstLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "H2二级浓度告警值",
                prop: "hsecondaryConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.hsecondaryConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hsecondaryConcentration", $$v)
                  },
                  expression: "form.hsecondaryConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "H2三级浓度告警值",
                prop: "hthirdLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.hthirdLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hthirdLevelConcentration", $$v)
                  },
                  expression: "form.hthirdLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "VOC一级浓度告警值",
                prop: "vfirstLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.vfirstLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vfirstLevelConcentration", $$v)
                  },
                  expression: "form.vfirstLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "VOC二级浓度告警值",
                prop: "vsecondaryConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.vsecondaryConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vsecondaryConcentration", $$v)
                  },
                  expression: "form.vsecondaryConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "VOC三级浓度告警值",
                prop: "vthirdLevelConcentration",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.vthirdLevelConcentration,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vthirdLevelConcentration", $$v)
                  },
                  expression: "form.vthirdLevelConcentration",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "一级过热阈值", prop: "firstStageOverheating" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.firstStageOverheating,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firstStageOverheating", $$v)
                  },
                  expression: "form.firstStageOverheating",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二级过热阈值", prop: "secondaryOverheating" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("0.1 PPM")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.secondaryOverheating,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "secondaryOverheating", $$v)
                  },
                  expression: "form.secondaryOverheating",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关闭状态的时间", prop: "closedState" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("秒")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.closedState,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "closedState", $$v)
                  },
                  expression: "form.closedState",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "调节量确定间隔", prop: "adjustmentAmount" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [_vm._v("秒")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.adjustmentAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "adjustmentAmount", $$v)
                  },
                  expression: "form.adjustmentAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "热管和电芯温度的差值可调控系数k",
                prop: "heatBattery",
              },
            },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.form.heatBattery,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "heatBattery", $$v)
                  },
                  expression: "form.heatBattery",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "一个开度多少pls", prop: "opening" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.form.opening,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "opening", $$v)
                  },
                  expression: "form.opening",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", size: "mini" },
              on: { click: _vm.onReadData },
            },
            [_vm._v("数据读取")]
          ),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", size: "mini" },
              on: { click: _vm.onSubmitData },
            },
            [_vm._v("数据下发")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }