var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-base-data-box", staticStyle: { height: "100%" } },
    [
      _c(
        "div",
        [_c("device-base-data", { attrs: { "device-info": _vm.deviceInfo } })],
        1
      ),
      _c(
        "div",
        [_c("detail", { attrs: { "device-info": _vm.deviceInfo } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }