var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-l-15", style: `height: ${_vm.height}px; overflow:auto;` },
    [
      _c(
        "el-descriptions",
        {
          attrs: {
            column: 1,
            title: "温度",
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "T1温度" } }, [
            _vm._v(_vm._s(_vm.tData.object.t1Temperature)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "T2温度" } }, [
            _vm._v(_vm._s(_vm.tData.object.t2Temperature)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "T3温度" } }, [
            _vm._v(_vm._s(_vm.tData.object.t3Temperature)),
          ]),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          attrs: {
            title: "分次谐波数据",
            column: 3,
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDUa（A相电压总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalVoltageDistortionAphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDUb（B相电压总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.voltage2To31HarmonicAphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDUc（C相电压总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalVoltageDistortionBphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDIa（A相电流总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.voltage2To31HarmonicBphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDIb（B相电流总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalVoltageDistortionCphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THDIc（C相电流总畸变率）" } },
            [_vm._v(_vm._s(_vm.fData.object.voltage2To31HarmonicCphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THUa（A相电压2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalCurrentDistortionAphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THUb（B相电压2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.current2To31HarmonicAphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THUc（C相电压2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalCurrentDistortionBphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THIa（A相电流2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.current2To31HarmonicBphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THIb（B相电流2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.totalCurrentDistortionCphase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "THIc（C相电流2~31次谐波含量）" } },
            [_vm._v(_vm._s(_vm.fData.object.current2To31HarmonicCphase))]
          ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          attrs: {
            title: "预留数据",
            column: 3,
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "A相基波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalVoltageAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相基波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalVoltage_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相基波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalVoltageCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相谐波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicVoltageAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相谐波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicVoltage_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相谐波电压" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicVoltageCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相基波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalCurrentAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相基波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalCurrent_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相基波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalCurrentCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相谐波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicCurrentAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相谐波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicCurrent_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相谐波电流" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicCurrentCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相基波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalActivePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相基波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalActivePower_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相基波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalActivePowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相基波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalReactivePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相基波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalReactivePower_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相基波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.fundamentalReactivePowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相谐波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicActivePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相谐波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicActivePower_BPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相谐波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicActivePowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相谐波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicReactivePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相谐波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicReactivePowerBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相谐波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.harmonicReactivePowerCPhase)),
          ]),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          attrs: {
            column: 1,
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "总基波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.totalFundamentalActivePower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总基波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.totalFundamentalReactivePower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总谐波有功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.totalHarmonicActivePower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总谐波无功功率" } }, [
            _vm._v(_vm._s(_vm.bkData.object.totalHarmonicReactivePower)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }