<template>
  <div class="content">
    <el-form>
      <el-row>
        <el-col :span="8">
          <el-form-item label="查询时间">
            <el-date-picker
              size="mini"
              v-model="timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              @change="onSearch"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- <el-form-item label="查询方式">
            <el-select v-model="searchType" size="mini" @change="onChangeSearchType">
              <el-option v-for="search in searchList" :key="search.value" :label="search.label" :value="search.value"></el-option>
            </el-select>
          </el-form-item> -->
        </el-col>
        <el-col :span="8">
          <div class="right-btns m-r-10">
            <el-button class="search-box-btn" type="primary" size="small" @click="onSearch">查询</el-button>
            <!-- <el-dropdown class="search-box-btn" @command="onExport">
              <el-button type="primary" size="small" style="margin-left: 10px; width:100%" :disabled="utils.userReadOnly(user) || !canExport">导出</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="selected" :disabled="utils.userReadOnly(user)">导出所选</el-dropdown-item>
                <el-dropdown-item command="thispage" :disabled="utils.userReadOnly(user)">导出当前页</el-dropdown-item>
                <el-dropdown-item command="all" :disabled="utils.userReadOnly(user)">导出所有</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div  v-loading="loading" :style="`height: ${height}px; overflow:auto;`">
      <el-table :data="list" :height="height" :style="`width: 100%; overflow:auto;`" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="Rack一级告警" prop="rackWarning" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackWarning === null"> 无数据</div>
            <div v-if="scope.row.rackWarning === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackWarning, index) in scope.row.rackWarning">
                {{ rackWarning === "0" ? "" : rackWarningList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Rack二级告警" prop="rackAlarm" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackAlarm === null">无数据</div>
            <div v-if="scope.row.rackAlarm === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackAlarm, index) in scope.row.rackAlarm">
                {{ rackAlarm === "0" ? "" : rackAlarmList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Rack三级告警" prop="rackCriticalAlarm" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.rackCriticalAlarm === null">无数据</div>
            <div v-if="scope.row.rackCriticalAlarm === '正常'">正常</div>
            <div v-else>
              <span v-for="(rackCriticalAlarm, index) in scope.row.rackCriticalAlarm">
                {{ rackCriticalAlarm === "0" ? "" : rackCriticalAlarmList[index] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import RackAlarmApi from "@/api/470/RackAlarm";

import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

const { hexToBinary } = $numberMatrixing;

export default {
  mixins: [ TimerPickerSettingsMixin ],
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    deviceInfo: {
      type: Object,
      default: null
    },
    isSmallScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      isEmpty: false,
      pageData: {
        current: 1,
        size: 50,
        total: 0
      },
      timeRange: null,
      handleStatusList: [ 
        { label: "已完成", color: "#303133" },
        { label: "处理中", color: "#E6A23C" },
        { label: "未处理", color: "#F56C6C" }
      ],
      searchType: "content",
      searchList: [
        { label: "告警内容", value: "content" },
        { label: "告警次数", value: "count" }
      ],
      rackWarningList: $statics.RACK_WARNING_LIST,
      rackAlarmList: $statics.RACK_ALARM_LIST,
      rackCriticalAlarmList: $statics.RACK_CRITICAL_ALARM_LIST,
      multipleSelection: [],
      list: [],
      // data: {
      //   code: "",
      //   rackWarning: [],
      //   rackAlarm: [],
      //   rackCriticalAlarm: [],
      //   createTime: null,
      //   updateTime: null
      // },
      height: 200,
      utils: $utils
    }
  },
  created () {
    this.height = document.body.scrollHeight - 196;
    this.getDatas();
  },
  methods: {
    onSearch () {
      this.getDatas();
    },
    async getDatas() {
      try {
        this.loading = true;

        const params = this.getParams();

        const res = await RackAlarmApi.list(params);
        let list = res.data.map(data => {
          if (!data.rackWarning) {
            data.rackWarning = null;
          } else if (data.rackWarning === "0000") {
            data.rackWarning = "正常";
          } else {
            data.rackWarning = hexToBinary(data.rackWarning, 4).split("");
          }

          if (!data.rackAlarm) {
            data.rackAlarm = null;
          } else if (data.rackAlarm === "0000") {
            data.rackAlarm = "正常";
          } else {
            data.rackAlarm = hexToBinary(data.rackAlarm, 4).split("");
          }

          if (!data.rackCriticalAlarm) {
            data.rackCriticalAlarm = null;
          } else if (data.rackCriticalAlarm === "0000") {
            data.rackCriticalAlarm = "正常";
          } else {
            data.rackCriticalAlarm = hexToBinary(data.rackCriticalAlarm, 4).split("");
          }

          return data;
        });

        this.list = list;
      } catch (err) {
        this.list = [];
        // {
        //   code: this.deviceInfo.code,
        //   rackWarning: [],
        //   rackAlarm: [],
        //   rackCriticalAlarm: [],
        //   createTime: null,
        //   updateTime: null
        // };
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    // initChart (data) {
    //   const chart = echarts.init(this.$refs.chart);

    //   chart.on('mousemove',function(params){
    //     selectSeries = params;
    //   });    
    //   //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
    //   chart.on('mouseout', function (params) {
    //     selectSeries = null;
    //   });

    //   const xDatas = Object.keys(data).sort();
    //   const yDatas1 = [], yDatas2 = [], yDatas3 = [];

    //   for (const item of xDatas) {
    //     yDatas1.push(data[item].one);
    //     yDatas2.push(data[item].two);
    //     yDatas3.push(data[item].three);
    //   }

    //   const options = this.createOptions(xDatas, ["一级告警", "二级告警", "三级告警"], [yDatas1, yDatas2, yDatas3], "次");
    //   chart.setOption(options);

    // },
    // createOptions (xAxis, legendData, metaData, yTitleName) {

    //   const serieData = [];

    //   for (let i = 0; i < legendData.length; i++) {
    //     let serie = {
    //         name: legendData[i],
    //         type: 'line',
    //         symbol: "circle",
    //         symbolSize: 10,
    //         data: metaData[i]
    //     };
    //     serieData.push(serie);
    //   }

    //   let colors = [ "#800080", "#ff00ff", "#ff0000" ];
    //   const options = {
    //     legend: {
    //       show: true, left: "left", data: legendData, y: "5%",
    //       orient: 'vertical',  type: "scroll",
    //       itemWidth: 18, itemHeight: 12, textStyle: { color: "#000", fontSize: 14 }
    //     },
    //     color: colors,
    //     grid: {
    //       top: '7%',
    //       left: '8%',
    //       right: '2%',
    //       bottom: '0%',
    //       containLabel: true
    //     },
    //     dataZoom: [
    //       {
    //         type: 'slider',
    //         orient: 'horizontal',
    //         start: 0,
    //         end: 100,
    //       },
    //       {
    //         orient: 'vertical',
    //         start: 0,
    //         end: 100
    //       }
    //     ],
    //     tooltip: {
    //       trigger: 'axis',
    //       position: function (point, params, dom, rect, size) {
    //         let x = 0, y = 0;
    //         let pointX = point[0], pointY = point[1];
    //         let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

    //         if (boxWidth > pointX) {
    //           x = "5%";
    //         } else {
    //           x = pointX - boxWidth;
    //         }

    //         if (boxHeight > pointY) {
    //           y = 5;
    //         } else {
    //           y = pointY - boxHeight;
    //         }

    //         return [x, y];
    //       },
    //       formatter: function (params) {
    //         const output = [];

    //         for (let i = 0, len = params.length; i < len; i++) {
    //           const obj = params[i];
    //           const str = `<span style="margin-right:10px; background:#F7f7f7;">${obj.marker} ${obj.seriesName} ${obj.value}次</span>`;
    //           output.push(str);

    //           if (i !== 0 && i % 7 === 0) {
    //             output.push("<br />");
    //           }
    //         }

    //         return `<h4>${params[0].axisValue}</h4>` +  output.join("<br />");
    //       },
    //       axisPointer: {
    //         type: 'shadow',
    //         snap: true
    //       }
    //     },
    //     xAxis: [
    //       {
    //         type: 'category',
    //         axisLine: { show: true, lineStyle: { color: '#000' } },
    //         axisLabel: { interval: 0, textStyle: { color: '#000', fontSize: 14 } },
    //         axisTick: { show: false },
    //         data: xAxis,
    //       },
    //     ],
    //     yAxis: [
    //       {
    //         type: 'value',
    //         name: yTitleName,
    //         nameTextStyle: {
    //           color: "#000",
    //           fontSize: 14,
    //           padding: [0, 0, 0, 30]
    //         },
    //         axisTick: { show: false },
    //         splitLine: { show: false },
    //         axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
    //         axisLine: { show: true, lineStyle: { color: '#000' } },
    //       },
    //     ],
    //     series: serieData
    //   };

    //   return options;
    //   },
    getParams (ids, page, size) {

      const params ={
        code: this.deviceInfo.code,
        page: page || this.pageData.current,
        size: size || this.pageData.size,
      };

      if (ids) {
        params.ids = ids;
      }

      // 如果时间没有数据，则把时间设置为最近一周
      // 这么设置的原因是后台如果不传入时间可能会有奇怪的问题
      if (this.timeRange === null) {
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        this.timeRange = [weekAgo, new Date()];
      }
        params.createTime = $utils.formatTime(this.timeRange[0], "YYYY-MM-DD HH:mm:ss");
        params.updateTime = $utils.formatTime(this.timeRange[1], "YYYY-MM-DD HH:mm:ss");

      return params;
    },
    // format () {
    //   const table =  this.list.map(({ id, handleStatus, deviceName, productId, createTime, warning }) => {
    //     if (warning.indexOf("1") < 0) {
    //       return {
    //         id,
    //         deviceName,
    //         handleStatus,
    //         productId, createTime,
    //         warning: []
    //       };
    //     }

    //     if (warning.length < 84) {
    //       for (let i = warning.length; i < 84; i++) {
    //         warning = "0" + warning;
    //       }
    //     }

    //     const { warningData, count } = parseWarningData(warning);

    //     return {
    //       id,
    //       deviceName,
    //       handleStatus,
    //       productId, createTime,
    //       warning: count > 0 ? warningData: []
    //     };
    //   });

    //   this.table = table;
    // },
    // onSizeChange (e) {
    //   this.pageData.size = e;
    //   this.pageData.current = 1;
    //   this.getDatas();
    // },
    // pageChange(e) {
    //   this.pageData.current = e;
    //   this.getDatas();
    // },
    // handleSelectionChange (val) {
    //   this.multipleSelection = val;
    // },
    onExport (command) {
      switch (command) {
        case "selected":

          const txt = this.multipleSelection.length === 0 ?
              "您没有选择想要导出报表的设备，是否导出当前页的报表？" :
              "您是否要导出您所选择的这些设备的报表？";

          this.$confirm(txt, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(async () => {
                const ids = (this.multipleSelection.length === 0) ? 
                    this.list.map(obj => obj.id) :
                    this.multipleSelection.map(obj => obj.id);

                this.doExport(ids.join());
              })
              .catch((e) => {
              });

          break;
        case "thispage":
          const ids = this.list.map(obj => obj.id);

          this.doExport(ids.join());
          break;
        case "all":
          this.doExport(undefined, 1, this.pageData.total);
          break;
      }
    },
    async doExport (ids, page, size) {
      this.loading = true;
      const res = await historyDataApi.alarm.export(this.getParams(ids, page, size));
      $utils.exportExcel(res);
      this.loading = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding-right: 0px;
}

.rack-alarms-box {
  >div {
    display: inline-block;
  }
  >div:first-child {
    width: 250px;
  }
}
</style>