<template>
  <div v-loading="loading">
  <el-form ref="form" :model="form" :rules="rules" label-width="90px">
    <el-form-item label="设备编号">{{ data.code }}</el-form-item>
    <el-form-item label="当前版本号">
      <div class="description-value-box" style="width: 145px !important;">{{ version || "无数据" }}</div>
      <div class="description-unit-box">
        <el-button type="primary" size="mini" @click="clearVersion">清除</el-button>
      </div>
    </el-form-item>
    <el-form-item label="主控ID" v-if="deviceInfo.projectType !== 1 && deviceInfo.projectType !== 6" prop="masterID">
      <el-select v-model="form.masterID" clearable placeholder="请选择一个主控ID" :disabled="type === 1" size="mini">
        <el-option v-for="item in masters" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="固件版本" prop="version">
      <el-select v-model="form.version" clearable placeholder="请选择一个固件版本升级" size="small" @change="onSelect">
        <el-tooltip v-for="item in list" :key="item.id" class="item" effect="dark" :content="item.description || '无说明'" placement="right">
          <el-option :label="item.edition" :value="item.edition" />
        </el-tooltip>
      </el-select>
    </el-form-item>
  </el-form>
  <div style="text-align:right;">
    <el-button size="mini" type="primary" :disabled="utils.userReadOnly(user)" @click="onSubmit">固件升级</el-button>
  </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { upgradeFirmware, getVersionList, editDevices } from "@/api/device";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    index: {
      type: Number,
      default: 0
    },
    deviceInfo: undefined,
    type: { // 0: 设备 ，1：网关
      type: String,
      default: ""
    },
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      name: "",
      version: "",
      form: {
        version: "",
        masterID: ""
      },
      list: [],
      masters: [],
      description: "",
      rules: {
        version: [
          { required: true, message: '请选择一个版本号', trigger: 'blur' }
        ],
        masterID: [
          { required: true, message: '请选择一个主控ID', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    console.log("upgrade");
    this.name = this.data.deviceName;
    this.version = this.type === 1 ? this.data.gatewayVersion : this.data.version;

    for (let i = 0; i < 31; i++) {
      this.masters.push({
        label: i < 10 ? `0${i}` : i,
        value: i < 10 ? `0${i}` : `${i}`
      });
    }
    this.masters.push({
      value: "31",
      label: "广播"
    });

    if (this.type === 1 || this.deviceInfo.projectType === 1 || this.deviceInfo.projectType === 6) {
      this.form.masterID = "00";
    } else {
      this.form.masterID = this.deviceInfo.masterID || "00";
    }

    this.getVersionList();
  },
  methods: {
    async getVersionList () {
      const params = {
        version: this.version,
        type: this.type
      }
      try {
        const res = await getVersionList(params);
        this.list = res;
      } catch {
        this.list = [];
        this.$message.error("读取失败");
      } finally {
      }
    },
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    onSelect () {
      if (this.form.version) {
        for (const obj of this.list) {
          if (obj.edition === this.form.version) {
            this.description = obj.description;
            return;
          }
        }
      } else {
        this.description = "";
      }
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        this.$confirm("是否要执行固件的远程升级？", "提示", {
        confirmButtonText: '确认升级',
        cancelButtonText: '取消升级',
        type: "warning"
        }).then(async () => {
          this.data.type = 3; // 等待
          $utils.setDeviceDoing(this.data);
          const version = this.list.filter(data => data.edition === this.form.version)[0];

          const data = {
            version: version.edition,
            name: this.data.deviceName,
            fileName: version.deviceName,
            masterID: this.form.masterID,
            type: this.type
          };

          upgradeFirmware(data)
          .then((res) => {
            $utils.setDeviceDone(this.data);
            this.deviceInfo.upgrade(res, Date.now(), this.type === "1");
            // this.deviceInfo.upgrade, (callback) => {
            //   callback(res, Date.now(), this.type === "1");
            // });
          })
          .catch(err => {
            this.$message({
            type: "error",
            message: "远程升级失败！"
            });
          });
          this.$message({
            type: "info",
            message: "远程升级正在进行中，请稍候"
          });
        });
      });
    },
    clearVersion () {

      this.$confirm("是否要清除当前设备的版本号？", "提示", {
        confirmButtonText: '清除',
        cancelButtonText: '取消',
        type: "warning"
        }).then(async () => {
          this.deviceInfo.version = this.version = "0.0.0";

          try {
            this.loading = true;
            await editDevices(this.deviceInfo.id, this.deviceInfo);
            await this.getVersionList();
            this.$message.success("设备版本号清除成功");
          } catch {
            this.$message.error("设备版本号清除失败");
          } finally {
            this.loading = false;
          }
        });
    }
  }
}
</script>