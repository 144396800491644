var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.list.length > 0
      ? _c("div", [
          _c("div", {
            ref: "socChart",
            staticClass: "charts-box",
            style: `width:${_vm.width}px; height: ${_vm.height}px;`,
          }),
        ])
      : _c("div", [_c("el-empty", { attrs: { description: "暂无数据" } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }