// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../../../assets/images/low-time.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./../../../../assets/images/normal-time.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./../../../../assets/images/high-time.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./../../../../assets/images/top-time.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".bill-input[data-v-1db17552] {\n  width: 170px;\n  margin-top: 5px;\n  margin-right: 10px;\n}\n.power-setting-content-box > div[data-v-1db17552] {\n  display: inline-block;\n  vertical-align: bottom;\n}\n.power-setting-box[data-v-1db17552]:last-child {\n  margin-bottom: 0px;\n}\n.low-time-box[data-v-1db17552] {\n  border: 1px dashed #008000;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.normal-time-box[data-v-1db17552] {\n  border: 1px dashed #4f5fbc;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.high-time-box[data-v-1db17552] {\n  border: 1px dashed #fa6d1f;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.top-time-box[data-v-1db17552] {\n  border: 1px dashed #db0000;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n[data-v-1db17552] .el-form-item__content .el-form-item {\n  display: inline-block;\n}", ""]);
// Exports
module.exports = exports;
