<!--
  因为 EL 的时间选择器太烂，经常性莫名其妙要把日期设置成 1月1日 ，导致日期大小判断错误，
  所以这里做一个封装，不考虑日期，专门提供以分钟计算的组件
-->
<template>
  <div>
    <el-time-picker
      is-range
      class="time-selector"
      v-model="rangeTime"
      arrow-control
      size="mini"
      format="HH:mm"
      @blur="onBlurTime"
      range-separator="至"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      placeholder="选择时间范围">
    </el-time-picker>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: [ 0, 0 ] // 单位：分钟
    },
    name: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      rangeTime: [],
      times: [],
      isNextDay: false
    }
  },
  watch: {
    value: function(v1, v2) {
      this.times = v1;
      this.getDatas();
    }
  },
  created () {
    console.log("from-to-time-selector");
    this.times = this.value;
    this.getDatas();
  },
  methods: {
    getDatas () {
      this.times = this.times || [ 0, 0 ];
      const dt0 = $utils.createTimeByMinute(this.times[0] || 0);
      const dt1 = $utils.createTimeByMinute(this.times[1] || 0);

      this.rangeTime = [ dt0, dt1 ];
    },
    onBlurTime () {
      if (this.rangeTime === null) {
        this.time = null;
        this.getDatas();
      };

      this.$emit("blur", this.name, this.getMinuteValues());
    },
    getMinuteValues () {
      const [ dt0, dt1] = this.rangeTime;

      let time0 = dt0.getHours() * 60 + dt0.getMinutes();
      let time1 = dt1.getHours() * 60 + dt1.getMinutes();

      if (time1 < time0) {
        time1 += 1440;
      }

      return [ time0, time1 ];
    }
  }
}
</script>
<style lang="scss" scoped>
.time-selector {
  width: 180px !important;
  margin-right: 15px;
  ::v-deep .el-range-input {
    width: 60px !important;
  }

  ::v-deep .el-range-separator {
    width: 30px !important;
  }
}
</style>