<template>
  <div v-loading="loading" :style="`height:${height}px; overflow:auto;`">
    <div  class="m-l-15 m-r-10">
      <el-descriptions :column="3" title="运行信息变量" border size="mini"
        label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
        </template>
        <el-descriptions-item label="交流A相电压">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acVoltagePhaseA) ? (object.acVoltagePhaseA / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流B相电压">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acVoltagePhaseB) ? (object.acVoltagePhaseB / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流C相电压">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acVoltagePhaseC) ? (object.acVoltagePhaseC / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流A相电流">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acCurrentPhaseA) ? (object.acCurrentPhaseA / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流B相电流">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acCurrentPhaseB) ? (object.acCurrentPhaseB / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流C相电流">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acCurrentPhaseC) ? (object.acCurrentPhaseC / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流视在功率">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acApparentPower) ? (object.acApparentPower / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">kVA</div>
        </el-descriptions-item>
        <el-descriptions-item label="交流频率">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.acFrequency) ? (object.acFrequency / 100).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">HZ</div>
        </el-descriptions-item>
        <el-descriptions-item label="功率因数">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.powerFactor) ? (object.powerFactor / 1000).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">HZ</div>
        </el-descriptions-item>
        <el-descriptions-item label="机内温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.unitTemperature) ? (object.unitTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="电池组SOC(多路电池)">{{ object.batteryPackSOC }}</el-descriptions-item>
        <el-descriptions-item label="系统告警">
          <span v-for="err in object.systemAlarmArr">
            {{ err === "1" ? systemAlarmValList[err] : "" }} 
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压1">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage1) ? (object.dcVoltage1 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流1">{{ object.dcCurrent1 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent1) ? (object.dcCurrent1 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率1">{{ object.dcPower1 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower1) ? (object.dcPower1 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压2">{{ object.dcVoltage2 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage2) ? (object.dcVoltage2 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流2">{{ object.dcCurrent2 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent2) ? (object.dcCurrent2 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率2">{{ object.dcPower2 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower2) ? (object.dcPower2 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压3">{{ object.dcVoltage3 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage3) ? (object.dcVoltage3 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流3">{{ object.dcCurrent3 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent3) ? (object.dcCurrent3 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率3">{{ object.dcPower3 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower3) ? (object.dcPower3 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压4">{{ object.dcVoltage4 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage1) ? (object.dcVoltage1 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流4">{{ object.dcCurrent4 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent4) ? (object.dcCurrent4 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率4">{{ object.dcPower4 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower4) ? (object.dcPower4 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压5">{{ object.dcVoltage5 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage5) ? (object.dcVoltage5 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流5">{{ object.dcCurrent5 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent5) ? (object.dcCurrent5 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率5">{{ object.dcPower5 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower5) ? (object.dcPower5 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压6">{{ object.dcVoltage6 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage6) ? (object.dcVoltage6 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流6">{{ object.dcCurrent6 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent6) ? (object.dcCurrent6 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率6">{{ object.dcPower6 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower6) ? (object.dcPower6 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压7">{{ object.dcVoltage7 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage7) ? (object.dcVoltage7 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流7">{{ object.dcCurrent7 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent7) ? (object.dcCurrent7 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率7">{{ object.dcPower7 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower7) ? (object.dcPower7 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压8">{{ object.dcVoltage8 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage8) ? (object.dcVoltage8 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流8">{{ object.dcCurrent8 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent8) ? (object.dcCurrent8 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率8">{{ object.dcPower8 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower8) ? (object.dcPower8 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压9">{{ object.dcVoltage9 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage9) ? (object.dcVoltage9 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流9">{{ object.dcCurrent9 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent9) ? (object.dcCurrent9 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率9">{{ object.dcPower9 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower9) ? (object.dcPower9 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电压10">{{ object.dcVoltage10 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcVoltage10) ? (object.dcVoltage10 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流电流10">{{ object.dcCurrent10 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcCurrent10) ? (object.dcCurrent10 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="直流功率10">{{ object.dcPower10 }}
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.dcPower10) ? (object.dcPower10 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="软件版本号高位">{{ object.softwareVersionNumberHighBit }}</el-descriptions-item>
        <el-descriptions-item label="软件版本号低位">{{ object.softwareVersionNumberLowBit }}</el-descriptions-item> -->
        <el-descriptions-item label="系统状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.systemStatus : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="开关机状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusSwitchOnOff : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块1状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule1 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块2状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule2 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块3状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule3 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块4状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule4 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块5状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule5 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块6状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule6 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块7状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule7 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块8状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule8 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块9状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule9 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="模块10状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusModule10 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块1状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule1 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块2状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule2 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块3状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule3 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块4状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule4 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块5状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule5 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块6状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule6 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块7状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule7 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块8状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule8 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块9状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule9 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块10状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule10 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块11状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule11 : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS模块12状态">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.statusBMSModule12 : "无数据" }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="m-t-20" :column="3" title="参数设置" border size="mini"
        label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
        </template>
        <el-descriptions-item label="开关机设置">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.settingSwitchOnOff) ? (object.settingSwitchOnOff / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KW</div>
        </el-descriptions-item>
        <el-descriptions-item label="有功功率设置">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? (object.settingActivePower / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">kVar</div>
        </el-descriptions-item>
        <el-descriptions-item label="无功功率设置">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.settingReactivePower : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="功率因数设置">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.settingPowerFactor : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="策略设置">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.settingTactics : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="升级执行">{{ utils.checkObjectIsNotEmpty(object.settingActivePower) ? object.upgradeExecution : "无数据" }}</el-descriptions-item>
      </el-descriptions>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      utils: $utils,
      loading: false,
      height: 0,
      systemAlarmValList: $statics.SYSTEM_ALARM_VAL_LIST,
      object: {
        acVoltagePhaseA: "",
        acVoltagePhaseB: "",
        acVoltagePhaseC: "",
        acCurrentPhaseA: "",
        acCurrentPhaseB: "",
        acCurrentPhaseC: "",
        acApparentPower: "",
        acFrequency: "",
        powerFactor: "",
        unitTemperature: "",
        batteryPackSOC: "",
        systemAlarmBit: "",
        dcVoltage1: "",
        dcCurrent1: "",
        dcPower1: "",
        dcVoltage2: "",
        dcCurrent2: "",
        dcPower2: "",
        dcVoltage3: "",
        dcCurrent3: "",
        dcPower3: "",
        dcVoltage4: "",
        dcCurrent4: "",
        dcPower4: "",
        dcVoltage5: "",
        dcCurrent5: "",
        dcPower5: "",
        dcVoltage6: "",
        dcCurrent6: "",
        dcPower6: "",
        dcVoltage7: "",
        dcCurrent7: "",
        dcPower7: "",
        dcVoltage8: "",
        dcCurrent8: "",
        dcPower8: "",
        dcVoltage9: "",
        dcCurrent9: "",
        dcPower9: "",
        dcVoltage10: "",
        dcCurrent10: "",
        dcPower10: "",
        // softwareVersionNumberHighBit: "",
        // softwareVersionNumberLowBit: "",
        systemStatus: "",
        statusSwitchOnOff: "",
        statusModule1: "",
        statusModule2: "",
        statusModule3: "",
        statusModule4: "",
        statusModule5: "",
        statusModule6: "",
        statusModule7: "",
        statusModule8: "",
        statusModule9: "",
        statusModule10: "",
        statusBMSModule1: "",
        statusBMSModule2: "",
        statusBMSModule3: "",
        statusBMSModule4: "",
        statusBMSModule5: "",
        statusBMSModule6: "",
        statusBMSModule7: "",
        statusBMSModule8: "",
        statusBMSModule9: "",
        statusBMSModule10: "",
        statusBMSModule11: "",
        statusBMSModule12: "",
        settingSwitchOnOff: "",
        settingActivePower: "",
        settingReactivePower: "",
        settingPowerFactor: "",
        settingTactics: "",
        upgradeExecution: ""
      },
    }
  },
  created () {
    this.height = document.body.scrollHeight - 150;
    this.getDatas();
  },
  methods: {
    getDatas () {
    }
  }
}
</script>