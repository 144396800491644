<template>
  <div class="m-l-15" v-loading="loading">
    <div :style="`height: ${height}px; overflow:auto; padding-right:10px;`">
      <el-descriptions :title="`空调数据【更新时间：${object.updateTime || ''}】`"  :column="3" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
        </template>
        <el-descriptions-item label="空调数量">{{ object.theNumber }}</el-descriptions-item>
        <el-descriptions-item label="制冷设定点">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.coolingSetPoint) ? (object.coolingSetPoint / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="制冷偏差">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.coolingDeviation) ? (object.coolingDeviation / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="空调状态" span="3">
        <div v-if="object.airconStatusArr && object.airconStatusArr.length > 0">
          <div v-for="(item, index) in object.airconStatusArr" class="rack-online-status-span" :key="index">{{ item }}</div>
        </div>
        <div v-else>无数据</div>
      </el-descriptions-item>
        <el-descriptions-item label="高温告警值">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.highTemperation) ? (object.highTemperation / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="低温告警值">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.lowTemperation) ? (object.lowTemperation / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="加热设定点">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.heatingSetPoint) ? (object.heatingSetPoint / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="加热偏差">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.heatingDeviation) ? (object.heatingDeviation / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="开关机">{{ utils.checkObjectIsNotEmpty(object.switchOnOff) ? switchOnOfList[object.switchOnOff] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="启动制冷指令">{{ utils.checkObjectIsNotEmpty(object.startCoolingOrder) ? onOffStatusList[object.startCoolingOrder] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="启动送风指令">{{ utils.checkObjectIsNotEmpty(object.startFanOrder) ? onOffStatusList[object.startFanOrder] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="启动待机指令">{{ utils.checkObjectIsNotEmpty(object.startHoldOnOrder) ? onOffStatusList[object.startHoldOnOrder] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="启动加热指令">{{ utils.checkObjectIsNotEmpty(object.startHeatingOrder) ? onOffStatusList[object.startHeatingOrder] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="柜内当前温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.currentTemperature) ? (object.currentTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内当前湿度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.currentHumidity) ? (object.currentHumidity / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="传感器故障">{{ utils.checkObjectIsNotEmpty(object.sensorFault) ? alarmStatusList[object.sensorFault].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="高低电压告警">{{ utils.checkObjectIsNotEmpty(object.highLowVoltageAlarm) ? alarmStatusList[object.highLowVoltageAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="高低温告警">{{ utils.checkObjectIsNotEmpty(object.highLowTemperatureAlarm) ? alarmStatusList[object.highLowTemperatureAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="高低压告警">{{ utils.checkObjectIsNotEmpty(object.highLowPressureAlarm) ? alarmStatusList[object.highLowPressureAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="除湿开启温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.startTemperature) ? (object.startTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="除湿停止温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.stopTemperature) ? (object.stopTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="除湿开启湿度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.startHumidity) ? (object.startHumidity / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="除湿停止湿度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.stopHumidity) ? (object.stopHumidity / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="机组状态">{{ utils.checkObjectIsNotEmpty(object.unitState) ? object.unitState : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="电压值">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.voltageValue) ? (object.voltageValue).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="软件名">{{ utils.checkObjectIsNotEmpty(object.heatingDeviation) ? object.softwareName : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="软件版本">{{ utils.checkObjectIsNotEmpty(object.heatingDeviation) ? object.softwareVersion : "无数据" }}</el-descriptions-item> -->
        <el-descriptions-item label="门禁告警">{{ utils.checkObjectIsNotEmpty(object.accessControlAlarm) ? alarmStatusList[object.accessControlAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="加热器告警">{{ utils.checkObjectIsNotEmpty(object.heaterAlarm) ? alarmStatusList[object.heaterAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="制冷状态">{{ utils.checkObjectIsNotEmpty(object.coolingState) ? onOffStatusList[object.coolingState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="制热状态">{{ utils.checkObjectIsNotEmpty(object.heatingState) ? onOffStatusList[object.heatingState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="内风机状态">{{ utils.checkObjectIsNotEmpty(object.internalFanState) ? onOffStatusList[object.internalFanState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="外风机状态">{{ utils.checkObjectIsNotEmpty(object.externalFanState) ? onOffStatusList[object.externalFanState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="内风机转速">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.internalFanRotate) ? (object.internalFanRotate).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">rpm</div>
        </el-descriptions-item>
        <el-descriptions-item label="外风机转速">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.externalFanRotate) ? (object.externalFanRotate).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">rpm</div>
        </el-descriptions-item>
        <el-descriptions-item label="内风机告警">{{ utils.checkObjectIsNotEmpty(object.internalFanAlarm) ? alarmStatusList[object.internalFanAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="外风机告警">{{ utils.checkObjectIsNotEmpty(object.externalFanAlarm) ? alarmStatusList[object.externalFanAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="设备工作状态">{{ utils.checkObjectIsNotEmpty(object.deviceWorkState) ? deviceRunSatusList[object.deviceWorkState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="压缩机状态">{{ utils.checkObjectIsNotEmpty(object.compressorState) ? deviceRunSatusList[object.compressorState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="电加热运行状态">{{ utils.checkObjectIsNotEmpty(object.theRunningState) ? deviceRunSatusList[object.theRunningState] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="容霜温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.defrostingTemperature) ? (object.defrostingTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="冷凝温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.condensingTemperature) ? (object.condensingTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.cabinetTemperature) ? (object.cabinetTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内湿度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.cabinetHumidity) ? (object.cabinetHumidity).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="出风温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.outletAirTemperature) ? (object.outletAirTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="上位机温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.hostTemperature) ? (object.hostTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="上位机湿度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.hostHumidity) ? (object.hostHumidity).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="制冷设定温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.coolingSetTemperature) ? (object.coolingSetTemperature).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="制冷控制回差">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.coolingControlBack) ? (object.coolingControlBack).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="加热设定温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.heatingSetTemperature) ? (object.heatingSetTemperature).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="加热控制回差">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.heatingControlBack) ? (object.heatingControlBack).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="湿度设定值">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.humiditySetValue) ? (object.humiditySetValue).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="控制湿度回差">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.humidityControlBack) ? (object.humidityControlBack).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内温度过高点">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.cabinetOverTemperature) ? (object.cabinetOverTemperature).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内温度过低点">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.cabinetUnderTemperature) ? (object.cabinetUnderTemperature).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="柜内湿度过高点">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.cabinetOverHumidity) ? (object.cabinetOverHumidity).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="监控开关机">{{ utils.checkObjectIsNotEmpty(object.monitorSwitch) ? monitorSwitchStatusMap[object.monitorSwitch] || "无效" : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="强制模式">{{ utils.checkObjectIsNotEmpty(object.forceMode) ? forceModeList[object.forceMode] : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="柜内温度过高告警">{{ utils.checkObjectIsNotEmpty(object.cabinetOver) ? alarmStatusList[object.cabinetOver].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="柜内温度过低告警">{{ utils.checkObjectIsNotEmpty(object.cabinetUnder) ? alarmStatusList[object.cabinetUnder].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="湿度过高告警">{{ utils.checkObjectIsNotEmpty(object.humidityOverAlarm) ? alarmStatusList[object.humidityOverAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="湿度过低告警">{{ utils.checkObjectIsNotEmpty(object.humidityUnder) ? alarmStatusList[object.humidityUnder].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="盘管防冻告警">{{ utils.checkObjectIsNotEmpty(object.coilAntiFreezeAlarm) ? alarmStatusList[object.coilAntiFreezeAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="融霜探头故障告警">{{ utils.checkObjectIsNotEmpty(object.deforstProbeFaultAlarm) ? alarmStatusList[object.deforstProbeFaultAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="冷凝温度探头故障告警">{{ utils.checkObjectIsNotEmpty(object.condensateTemperatureProbe) ? alarmStatusList[object.condensateTemperatureProbe].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="柜内温度探头故障告警">{{ utils.checkObjectIsNotEmpty(object.cabinetTemperatureProbe) ? alarmStatusList[object.cabinetTemperatureProbe].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="出风温度探头故障告警">{{ utils.checkObjectIsNotEmpty(object.outlet) ? alarmStatusList[object.outlet].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="湿度探头故障告警">{{ utils.checkObjectIsNotEmpty(object.temperatureProbe) ? alarmStatusList[object.temperatureProbe].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="内风机故障告警">{{ utils.checkObjectIsNotEmpty(object.internalFanFault) ? alarmStatusList[object.internalFanFault].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="压缩机故障告警">{{ utils.checkObjectIsNotEmpty(object.compressorFaultAlarm) ? alarmStatusList[object.compressorFaultAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="高压告警">{{ utils.checkObjectIsNotEmpty(object.highVoltageAlarm) ? alarmStatusList[object.highVoltageAlarm].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="低压告警">{{ utils.checkObjectIsNotEmpty(object.lowVoltage) ? alarmStatusList[object.lowVoltage].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="相序报警">{{ utils.checkObjectIsNotEmpty(object.phaseSequence) ? alarmStatusList[object.phaseSequence].label : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="通讯地址">{{ utils.checkObjectIsNotEmpty(object.contactAddress) ? object.contactAddress : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="波特率">{{ utils.checkNumberIsNotEmpty(object.baudRate) ? ( baudRateList[object.baudRate] || "无数据" ) : "无数据" }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import AirConditionerApi from '@/api/470/AirConditioner';

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined,
    height: undefined
  },
  data () {
    return  {
      loading: false,
      object: {
        theNumber: "", // 0x103d
        airconStatusArr: [], // 0x103e
        coolingSetPoint: "",
        coolingDeviation: "",
        highTemperation: "",
        lowTemperation: "",
        heatingSetPoint: "",
        heatingDeviation: "",
        switchOnOff: "",
        startCoolingOrder: "",
        startFanOrder: "",
        startHoldonOrder: "",
        startHeatingOrder: "",
        currentTemperature: "",
        currentHumidity: "",
        sensorFault: "",
        highLowVoltageAlarm: "",
        highLowTemperatureAlarm: "",
        highLowPressureAlarm: "",
        startTemperature: "",
        stopTemperature: "",
        startHumidity: "",
        stopHumidity: "",
        unitState: "",
        voltageValue: "",
        softwareName: "",
        softwareVersion: "",
        accessControlAlarm: "",
        heaterAlarm: "",
        coolingState: "",
        heatingState: "",
        internalFanState: "",
        externalFanState: "",
        internalFanRotate: "",
        externalFanRotate: "",
        internalFanAlarm: "",
        externalFanAlarm: "",
        deviceWorkState: "",
        compressorState: "",
        theRunningState: "",
        defrostingTemperature: "",
        condensingTemperature: "",
        cabinetTemperature: "",
        cabinetHumidity: "",
        outletAirTemperature: "",
        hostTemperature: "",
        hostHumidity: "",
        coolingSetTemperature: "",
        coolingControlBack: "",
        heatingSetTemperature: "",
        heatingControlBack: "",
        humiditySetValue: "",
        humidityControlBack: "",
        cabinetOverTemperature: "",
        cabinetUnderTemperature: "",
        cabinetOverHumidity: "",
        monitorSwitch: "",
        forceMode: "",
        cabinetOver: "",
        cabinetUnder: "",
        humidityOverAlarm: "",
        humidityUnder: "",
        coilAntiFreezeAlarm: "",
        deforstProbeFaultAlarm: "",
        condensateTemperatureProbe: "",
        cabinetTemperatureProbe: "",
        outlet: "",
        temperatureProbe: "",
        internalFanFault: "",
        compressorFaultAlarm: "",
        highVoltageAlarm: "",
        lowVoltage: "",
        phaseSequence: "",
        contactAddress: "",
        baudrate: ""
      },
      baudRateList: $statics.BAUD_RATE_LIST,
      switchOnOfList: $statics.SWITCH_ON_OFF_LIST,
      onOffStatusList: $statics.ON_OFF_STATUS_LIST,
      alarmStatusList: $statics.ALARM_STATUS_LIST,
      deviceRunSatusList: $statics.DEVICE_RUN_STATUS_LIST,
      monitorSwitchStatusMap: $statics.MONITOR_SWITCH_STATUS_MAP,
      forceModeList: $statics.FORCE_MODE_LIST,
      utils: $utils,
      // [20240604] 根据徐工的说法，0x103d、0x103e、0x103f 从旧的接口请求
      start: 0x7000,
      count: 73
    }
  },
  created () {
    console.log("aircondition");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await AirConditionerApi.least(params);

        res.monitorSwitch = calcHexFromUnsignedDec(res.monitorSwitch, 2).toUpperCase();
        
        const airConditioningSum = Number(res.theNumber);
        const airconStatusArr = [];
        let { airconditionStatus1, airconditionStatus2 } = res;
        if ((airconditionStatus1 || airconditionStatus1 === 0) && (airconditionStatus2 || airconditionStatus2 === 0)) {
          airconditionStatus1 = calcHexFromUnsignedDec(airconditionStatus1);
          airconditionStatus1 = String.reverse(airconditionStatus1);
          airconditionStatus2 = calcHexFromUnsignedDec(airconditionStatus2);
          airconditionStatus2 = String.reverse(airconditionStatus2);

          let airconditionStatus = airconditionStatus1 + airconditionStatus2;
          airconditionStatus = airconditionStatus.toUpperCase();
        }

        for (let i = 0; i < airConditioningSum; i++) {
          let val = airconditionStatus.charAt(i);

          switch (val) {
            case "0":
              airconStatusArr.push("停机");
              break;
            case "1":
              airconStatusArr.push("制冷");
              break;
            case "2":
              airconStatusArr.push("制热");
              break;
            case "3":
              airconStatusArr.push("通风");
              break;
            case "F":
              airconStatusArr.push("故障");
              break;
          }
        }

        this.object = res;
        this.$message.success("空调数据读取成功");
      } catch (err) {
        this.$message.warning("空调数据读取失败");
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>