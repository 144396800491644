import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-MainBoardTemperature/list?${rand}`, { params });
}

export const detail = (code) => {
    const rand = Date.now();
    return request.get(`module-MainBoardTemperature/Temperature/${code}?${rand}`);
}

export const save = (data) => {
    const rand = Date.now();
    return request.get(`module-MainBoardTemperature/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.get(`module-MainBoardTemperature/update?${rand}`, data);
}

export default {
    list,
    save,
    update,
    detail
}