import { render, staticRenderFns } from "./upgrade.vue?vue&type=template&id=aae96702"
import script from "./upgrade.vue?vue&type=script&lang=js"
export * from "./upgrade.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\bms-front\\anj\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aae96702')) {
      api.createRecord('aae96702', component.options)
    } else {
      api.reload('aae96702', component.options)
    }
    module.hot.accept("./upgrade.vue?vue&type=template&id=aae96702", function () {
      api.rerender('aae96702', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/device/detail/comnponents/device-tabs/commands/events/upgrade.vue"
export default component.exports