<template>
  <div style="height: 100%; padding-left: 10px;">
    <el-row>
      <el-col :span="24">
        <el-form label-width="120">
        <el-row>
          <el-col :span="18">
            <el-form-item label="时间范围">
              <el-date-picker
              v-model="timeRange"
              type="datetimerange"
              align="left"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :default-time="defaultTime"
              :picker-options="pickerOptions"
              size="mini"
              format="yyyy-MM-dd HH:mm"
              :clearable="false"
            />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="seach-btn-box">
            <el-button
              type="primary"
              size="mini"
              @click="getLineMode"
            >轨迹查询</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="getLastGps"
            >末次GPS定位</el-button>
          </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="9">
            <el-form-item label="行驶轨迹">
              <el-button size="mini" type="primary" :disabled="startDisable" @click="startTimer">开始</el-button>
              <el-button size="mini" type="primary" :disabled="pauseDisable" @click="pauseTimer">{{ timerPauseBtnTxt }}</el-button>
              <el-button size="mini" type="primary" :disabled="stopDisable" @click="stopTimer">结束</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="行驶轨迹时间">
              <el-input size="mini" readonly class="track-input-box" v-model="trackInfo.time" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="GPS信号">
              <el-input size="mini" readonly class="gps-input-box" v-model="trackInfo.signal" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="距离(米)">
              <el-input size="mini" class="distance-input-box" readonly v-model="trackInfo.distance" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      </el-col>
    </el-row>
    <div :style="`height:${boxHeight} ;`">
      <div class="track-map">
        <div v-if="noData">
          <el-empty description="暂无数据" />
        </div>
        <div v-else :style="`height:${height}px;`">
          <amap ref="map"
            cache-key="home-map"
            :zoom="zoom"
            :center="center"
          >
            <amap-marker :position="center" />
            <amap-tool-bar :extraOptions="extraOptions" />
            <amap-scale position="LB" />
          </amap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDevicesSingleLoaction, getDevicesLoaction } from '@/api/amap';
import { convertLngLat } from "@/utils/real-time-map";
import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

export default {
  name: 'DeviceTrack',
  mixins: [ TimerPickerSettingsMixin ],
  props: {
    deviceInfo: {
      type: Object,
      default: null
    },
    boxHeight: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      utils: $utils,
      startDisable: false,
      pauseDisable: true,
      timerPaused: false,
      stopDisable: true,
      runTimeMode: false,
      noData: false,
      lineMode: true,
      timerPauseBtnTxt: "暂停",
      zoom: 16,
      center: LngLat,
      width: 1000,
      height: 1000,
      startPoint: [],
      endPoint: [],
      trackInfo: {
        time: '',
        signal: '',
        distance: ''
      },
      extraOptions: {
        liteStyle: true
      },
      singleMarker: null,
      startMarker: null,
      endMarker: null,
      $marker: null,
      polyline: null,
      passedPolyline: null,
      roductId: '',
      tableData: [],
      trackList: [],
      path: [],
      location: [],
      distance: 0,
      dists: [],
      timer: 0,
      index: 0
    };
  },
  computed: {
    $map () {
      return this.$refs.map.$map;
    }
  },
  created () {
    console.log("track-search");
  },
  async mounted() {
    this.tableData = [this.deviceInfo];
    if (this.mode === "dialog") {
      this.height = (document.body.scrollHeight - 200) * 0.8;
    } else {
      this.height = document.body.scrollHeight - 200;
    }
    

    await this.getLastGps();
    // await this.getLineMode();
    await this.initMoveAnimation();

  },
  methods: {
    onClosed () {
      return true;
    },
    async getLastGps () {

      this.stopTimer();
      this.clearMap();

      this.zoom = 17;
      await this.getSignleLocation();

      if (this.singleLocation.length > 0) {
        this.noData = false;
        const lngLat = convertLngLat(this.singleLocation[0]);

        this.singleMarker = new AMap.Marker({
          position: lngLat,
          label: {
            content: this.singleLocation[0].deviceName,
            direction: 'top',
          },
          icon: new AMap.Icon({
            type: "image",
            image: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            "imageSize": [19, 33]
          })
        });

        this.center = lngLat;

        // this.$map.add(this.singleMarker);
        this.$map.setFitView();
      } else {
        this.noData = true;
      }
    },
    async getSignleLocation () {
      clearInterval(this.timer); // 每次选择最新的点时，停止计时器

      const singleLocation = await getDevicesSingleLoaction(this.deviceInfo.code);
      this.singleLocation = singleLocation;
      if (singleLocation.length > 0) {
        this.noData = false;
        this.productId = singleLocation[0].productId;
      } else {
        this.noData = true;
      }
    },
    clearMap () {
      if (this.singleMarker) {
        this.$map.remove(this.singleMarker);
        this.singleMarker = null;
      }
      if (this.startMarker) {
        this.$map.remove(this.startMarker);
        this.startMarker = null;
      }
      if (this.endMarker) {
        this.$map.remove(this.endMarker);
        this.endMarker = null;
      }
      if (this.$marker) {
        this.$map.remove(this.$marker);
        this.$marker = null;
      }
      if (this.polyline) {
        this.$map.remove(this.polyline);
        this.polyline = null;
      }
      if (this.passedPolyline) {
        this.$map.remove(this.passedPolyline);
        this.passedPolyline = null;
      }
    },
    initLineMap () {

      if (this.location.length === 0) {
        return;
      }

      this.startMarker = new AMap.Marker({
        position: this.startPoint,
        icon: new AMap.Icon({
          type: "image",
          image: "https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
          size: [25, 34],
          imageSize: [135, 40],
          imageOffset: [-9, -3]
        })
      });

      this.endMarker = new AMap.Marker({
        position:  this.endPoint,
        icon: new AMap.Icon({
          type: "image",
          image: "https://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
          size: [25, 34],
          imageSize: [135, 40],
          imageOffset: [-95, -3]
        })
      });

      this.polyline = new AMap.Polyline({
        path: this.trackList,
        strokeColor: "#3366FF",
        outlineColor:"#fff",
        strokeWeight: 7,
        strokeOpacity: 1,
        zIndex: 50,
        showDir: true,
        isOutline: true,
        lineJoin:"round",
        lineCap:"round"
      });

      this.passedPolyline = new AMap.Polyline({
        strokeColor: "#45a600",
        outlineColor:"#fff",
        strokeWeight: 7,
        strokeOpacity: 1,
        zIndex: 50,
        isOutline: true,
        strokeStyle: "dashed",  //线样式
        lineJoin:"round",
        lineCap:"round"
      });

      this.$map.add(this.startMarker);
      this.$map.add(this.endMarker);
      this.$map.add(this.polyline);
      this.$map.add(this.passedPolyline);
      this.$map.setFitView();
    },
    async getLineMode () {

      this.stopTimer();
      await this.getLocation();

      this.clearMap();
      this.initLineMap();
    },
    async getLocation() {
      const startTime = $utils.formatTime(this.timeRange[0], "YYYY-MM-DD 00:00:00");
      const endTime = $utils.formatTime(this.timeRange[1],  "YYYY-MM-DD 23:59:59");

      // if (new Date(endTime).getTime() - new Date(startTime).getTime() > 86400000) {
      //   errorMsg('选取时间范围不得大于24小时，请重新选择！');
      //   this.timeRange = [void 0, void 0];
      //   return;
      // }

      let location = await getDevicesLoaction({
            productId: this.productId,
            createTime: startTime,
            uploadTime: endTime
          });

      if (location.length === 0) {
        this.$message.warning("未查询到轨迹");
        return;
      }

      location = location.filter(obj => {
        return obj.latitude >= -90 && obj.latitude <= 90 && obj.longitude >= -180 && obj.longitude <= 180;
      });

      this.location = location;

      const trackList = [];
      for (const it of location) {
        const ret = convertLngLat(it);
        trackList.push(ret);
      }

      this.trackList = trackList;

      const trackListStr = JSON.stringify(this.trackList);
      this.path = JSON.parse(trackListStr);

      this.startPoint = trackList[0];
      this.endPoint = trackList[trackList.length - 1];

      this.reZoom();
    },
    reZoom1 (distance) {
      if (distance < 50) {
        this.$map.setZoom(20);
      } else if (distance < 100) {
        this.$map.setZoom(19);
      } else if (distance < 200) {
        this.$map.setZoom(18);
      } else if (distance < 500) {
        this.$map.setZoom(17);
      } else if (distance < 1000) {
        this.$map.setZoom(16);
      } else if (distance < 2000) {
        this.$map.setZoom(15);
      } else if (distance < 5000) {
        this.$map.setZoom(14);
      } else if (distance < 10000) {
        this.$map.setZoom(13);
      } else if (distance < 20000) {
        this.$map.setZoom(12);
      } else if (distance < 30000) {
        this.$map.setZoom(11);
      } else if (distance < 50000) {
        this.$map.setZoom(10);
      } else if (distance < 100000) {
        this.$map.setZoom(9);
      } else if (distance < 200000) {
        this.$map.setZoom(8);
      } else if (distance < 500000) {
        this.$map.setZoom(7);
      } else if (distance < 1000000) {
        this.$map.setZoom(6);
      } else {
        this.$map.setZoom(5);
      }
    },
    reZoom () {
      this.center = this.trackList[this.trackList.length - 1];

      if (!this.center) {
        return;
      }

      const dists = [];
      const first =  new AMap.LngLat(this.trackList[0][0], this.trackList[0][1]);

      for (let i = 1, len = this.trackList.length; i < len; i++) {
        const item = this.trackList[i];
        const arr = [first, new AMap.LngLat(item[0], item[1])];

        const dist = (AMap.GeometryUtil.distanceOfLine(arr)).toFixed(2);
        dists.push(dist);
      }

      const distance = this.distance = Math.max(... dists);
      this.dists = dists;

      this.reZoom1(distance);
    },
    async initMoveAnimation () {
      AMap.plugin('AMap.MoveAnimation', () => {});
    },
    async startTimer() {

      if (!this.location || this.location.length === 0) {
        this.$notify({
          type: 'warning',
          title: '警告',
          message: '当前没有可供执行的轨迹，请先查询轨迹！'
        });
        return;
      }

      const that = this;

      that.clearMap();
      that.initLineMap();

      that.$marker = new AMap.Marker({
        position:  that.startPoint,
        icon: new AMap.Icon({
          type: "image",
          image: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
          imageSize: [26, 52]
        }),
        anchor:'bottom'
      });
      that.$map.add(that.$marker);

      const path = [];

      that.index = 0;
      that.lineMode = true;
      that.startDisable = true;
      that.pauseDisable = false;
      that.stopDisable = false;
      that.runTimer();

      that.$marker.on('moving', function (e) {
        Array.prototype.push.apply(path, e.passedPath);
        that.passedPolyline.setPath(path);
        that.$map.setCenter(e.target.getPosition(), true);
      });

    },
    runTimer () {

      if (this.index === this.location.length - 1) {
        this.stopTimer();
        return;
      }

      const nowTime = new Date(this.location[this.index].createTime);
      const nextTime = new Date(this.location[this.index + 1].createTime);

      const arr = [].concat(this.path).slice(0, this.index + 2);
      const totalDistance = (AMap.GeometryUtil.distanceOfLine(arr)).toFixed(2);

      const mkPos = this.$marker.getPosition();
      const tmp = [[mkPos.lng, mkPos.lat], this.path[this.index + 1]];
      const tmpDistance = (AMap.GeometryUtil.distanceOfLine(tmp)).toFixed(2);
      // const duration = tmpDistance / speed * 200;
      const duration = (nextTime - nowTime) / 300;

      this.$marker.moveTo(this.path[++this.index], {duration, delay: 500});

      this.reZoom1(tmpDistance);

      const last = this.location[this.index];
      this.trackInfo.time = last.createTime;
      this.trackInfo.signal = last.signal;
      this.trackInfo.distance = totalDistance;

      this.$forceUpdate();

      this.timer = setTimeout(() => {
        this.runTimer();
      }, duration + 15);

    },
    pauseTimer () {
      this.pauseDisable = false;

      if (this.timerPaused) {
        this.timerPauseBtnTxt = "暂停";
        if (this.$marker) {
          this.$marker.resumeMove();
        }
        this.runTimer();
      } else {
        this.timerPauseBtnTxt = "继续";
        if (this.$marker) {
          this.$marker.pauseMove();
        }
        clearTimeout(this.timer);
        this.index--;
      }

      this.timerPaused = !this.timerPaused;
    },
    stopTimer () {
      this.startDisable = false;
      this.pauseDisable = true;
      this.stopDisable = true;
      this.timerPaused = false;
      if (this.$marker) {
        this.$marker.stopMove();
      }
      clearTimeout(this.timer);
    }
  }
};
</script>
<style lang='scss' scoped>

  .track-input-box {
    width: calc(100% - 110px);
  }

  .gps-input-box {
    width: calc(100% - 80px);
  }
  .distance-input-box {
    width: calc(100% - 70px);
  }

  .seach-btn-box {
    text-align: right;
  }

    .track-map {
      ::v-deep .amap-icon {
        margin-top: -30px;
        margin-left: -13px;
      }
      ::v-deep .amap-marker-label {
        margin-top: -32px;
        margin-left: -13px;
      }
    }


</style>
