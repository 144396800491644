<template>
  <div v-loading="loading">
    <div style="margin: 0px 0px 10px 10px;">
      <el-button size="mini" type="primary" @click="onSetPasswd">设置密码</el-button>
      <el-button type="primary" size="mini" @click="onShowConfigFileDialog()">从配置文件中一键配置</el-button>
    </div>
    <div>
      <div class="config-name-btn-box">
        <div>
          <el-button class="config-name-btn" @click="onShowDialog('system')">系统参数设置</el-button>
          <el-button class="config-name-btn" @click="onShowDialog('alarm')">告警参数设置</el-button>
          <!-- <el-button class="config-name-btn" @click="onShowDialog('viewvt')">电压温度显示</el-button>
          <el-button class="config-name-btn" @click="onShowDialog('viewalarm')">告警标志显示</el-button> -->
          <el-button class="config-name-btn" @click="onShowDialog('battery')">电池分布设置</el-button>
          <el-button v-if="deviceInfo.projectType === 0" class="config-name-btn" @click="onShowDialog('systemparam')">主控系统参数信息设置</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="系统参数配置" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.system"
      @close="onCloseDialog('system', false)" width="84%">
      <system ref="system" :data="systemData" :device-info="deviceInfo" :height="height" :level="level" :gw-is-new-soft="gwIsNewSoft" :gw-is-new-hard="gwIsNewHard"
        @battery="onBatteryConfig" @save="onSaveConfig" />
    </el-dialog>
    <el-dialog title="告警参数配置" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.alarm"
      @close="onCloseDialog('alarm', false)" width="60%">
      <alarm ref="alarm" :id="id" :device-info="deviceInfo" :height="height" :level="level" :data="systemData" :is-new-ver="gwIsNewSoft" />
    </el-dialog>
    <el-dialog title="电压温度显示" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.viewvt"
      @close="onCloseDialog('viewvt')" width="84%">
      <viewvt ref="viewvt" :id="id" :device-info="deviceInfo" :height="height - 50" :level="level" :visible="true" :is-new-ver="gwIsNewSoft"
        :data="systemData" :vLen="voltageParams.length" :tLen="templateParams.length" @config="onConfigBattery" />
    </el-dialog>
    <el-dialog title="告警标志显示" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.viewalarm"
      @close="onCloseDialog('viewalarm')" width="84%">
      <viewalarm ref="viewalarm" :id="id" :device-info="deviceInfo" :height="height - 100" :level="level" :data="systemData" :is-new-ver="gwIsNewSoft" />
    </el-dialog>
    <el-dialog title="电池分布设置" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.battery"
      @close="onCloseDialog('battery')" width="90%">
      <battery ref="battery" :id="id" :mask="mask" :device-info="deviceInfo" :height="height - 50" :level="level" :is-new-ver="gwIsNewSoft"
        :data="systemData" :vLen="voltageParams.length" :tLen="templateParams.length" />
    </el-dialog>
    <el-dialog title="设置密码" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.password"
      @close="onCloseDialog('password')" width="450px">
      <password @close="setPassword" />
    </el-dialog>
    <el-dialog title="从配置文件中一键配置" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.configFile" append-to-body
      @close="onCloseDialog('configFile')" width="500px">
      <config-from-file ref="configFile" :device-info="deviceInfo"  @get-configs="onGetConfigs"></config-from-file>
    </el-dialog>
    <el-dialog title="主控系统参数信息设置" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" :visible="dialogVisible.systemparam" append-to-body
      @close="onCloseDialog('systemparam')" width="84%">
      <SystemParam ref="systemparam" :device-info="deviceInfo"></SystemParam>
    </el-dialog>
  </div>
</template>
<script>
import system from "./system";
import alarm from "./alarm";
import viewalarm from "./viewalarm";
import battery from "./battery";
import viewvt from "./viewvt";
import password from "./password";
import ConfigFromFile from "./config-from-file";
import SystemParam from "./system-param";

import TemperatureMixin from "@/components/battery-template/mixin/temperature";
import VoltageMixin from "@/components/battery-template/mixin/voltage";

export default {
  components: {
    system,
    alarm,
    viewalarm,
    battery,
    viewvt,
    password,
    ConfigFromFile,
    SystemParam
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    deviceInfo: {
      type: Object,
      default: {}
    },
    isSmallScreen: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: false,
      onPassword: true,
      gwIsNewSoft: false,
      gwIsNewHard: false,
      saved: true,
      utils: $utils,
      height: 0,
      afeNum: 0,
      systemData: {
        empower: "0",
        lecuAddr: "0",
        lecuNewAddr: "0",
        batType: "0",
        batCellType: "0",
        afeNum: 0,
        ntcCnt: "0",
        hwType: "0",
        chgProtocol: "0",
        chargeCanType: "0",
        vcuProtocol: "0",
        vcuCanType: "0",
        tempProtocol: "0",
        tempCanType: "0",
        ntc: "0",
        systemVolumetric: "0",
        initSoc: "0",
        workVoltage: "0",
        staticEurrentError: "0",
        dynamicThreshold: "0",
        dynamicDifference: "0",
        staticThreshold: "0",
        staticDifference: "0",
        maxChargeChannel: "0",
        maxBalanceChannel: "0",
        balanceMode: "0",
        vanStop: "0",
        slientStop: "0",
        vanControler: "0",
        hotStop: "0",
        dsgHotStop: "0",
        hotControler: "0",
        com1Protocol: "0",
        com2Protocol: "0",
        shunt_I: "0",
        shunt_I_offset: "0",
        shunt_mV: "0",
        canSpeed1: "0",
        canSpeed2: "0",
        com1Baud: "0",
        com2Baud: "0",
        disChgChangeToChg: "",
        limitCurrentchg: "",
        ipQueryDsgTHD: "0",
        ipQueryChgCutTHD: "0",
        contactSingleTime: "0",
        chargeContactSingleTime: "0",
        preChargeContactRatio: "0",
        ltc6811FilterRatio: "0",
        mcuadFilterRatio: "0",
        ad7706FilterRatio: "0",
        // alarmInterval0: "0",
        // alarmInterval1: "0",
        // alarmInterval2: "0",
        // 这些是只配置地址，页面中没有的
        hsdxCfg0: "",
        hsdxCfg1: "",
        hsdxCfg2: "",
        hsdxCfg3: "",
        hsdxCfg4: "",
        canBaud1: "0",
        canBaud2: "0",
        canBaud: "",
        canConfig: "", // can通道分布
        tempAndNumRes: "", // 电池温度探头数量和上拉阻抗
        vcu_soh: "",
        controlBz: "",
        gbChgMaxCur_ComBaud: "",
        range: "",
        vanStart: "",
        slientStart: "",
        hotStart: "",
        dsgHotStart: "",
        // 以下这些都只是页面存在，实际地址没有，是通过其他地址配置出来的
        channel: "0",
        vanStartTemp: "0",
        vanStartDiff: "0",
        slientStartTemp: "0",
        slientStartDiff: "0",
        hotStartTemp: "0",
        hotStartDiff: "0",
        dsgHotStartTemp: "0",
        dsgHotStartDiff: "0",
        pullUresistor: "0",
        slaveControl: "0",
        slaveCount: "0",
        slaveAfeType: "0",
        masterControl: "0",
        preChargeContactor: "0",
        dischargeContactor: "0",
        chargeContactor: "0",
        mainSlaveContactor: "0",
        heatingContactor: "0",
        buzzer: "0",
        externalIndicator: "0",
        externalFaultLight: "0",
        upsWorkSwitch: "0",
        upsForceCharging: "0",
        chargeLv2Back: "0",
        externalParalleling: "0",
        disChgLv2Back: "0",
        chgSameTimeDischg: "0",
        sohCycle: "0",
        sohPercentage: "0",
        gbChgMaxCur: "0",
        gbMsgDelay: "0",
        limitCurrentchg: "0",
        chargeBanlance: "0",
        balnaceDischarge: "0",
        // 未使用
        borderCheck: "0"
      },
      dialogVisible: {
        system: false,
        alarm: false,
        battery: false,
        viewalarm: false,
        viewvt: false,
        password: true,
        configFile: false,
        systemparam: false
      },
      mask: null,
      level: {
        isL1: false,
        isL2: false,
        isL3: false,
        isL0: true
      },
      voltageParams: VoltageMixin.data(),
      templateParams: TemperatureMixin.data()
    }
  },
  created () {
    this.height = document.body.scrollHeight - 450;

    const gwSoftVerNum = this.deviceInfo.gwSoftVerNum;
    const gwHardVerNum = this.deviceInfo.gwHardVerNum;
    this.gwIsNewSoft = 10007 <= gwSoftVerNum; // 0.1.7 | 0 * 100000000 + 1 * 10000 + 7
    this.gwIsNewHard = 20001 >= gwHardVerNum; // 0.2.1 | 0 * 100000000 + 2 * 10000 + 1
  },
  methods: {
    onClosed () {
      return true;
    },
    onShowDialog (name) {
      if (this.systemData.afeNum === 0) {
        if (name !== "system" && name !== "systemparam") {
          this.$message.warning("请先配置 AFE 个数！");
          return;
        }
      }
      this.dialogVisible[name] = true;
    },
    onShowConfigFileDialog () {
      this.dialogVisible.configFile = true;
    },
    onSaveConfig (val) {
      this.systemData = val;
      this.saved = false;
    },
    onBatteryConfig () {
      this.onCloseDialog("system", true);
      this.dialogVisible.battery = true;
    },
    onConfigBattery () {
      this.onCloseDialog("viewvt", true);
      this.onBatteryConfig();
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
    onSetPasswd () {
      this.dialogVisible.password = true;
    },
    setPassword (level) {
      this.level = level;
      this.onCloseDialog("password");
    },
    onGetConfigs (configs, fileName) {
      try {
        this.onCloseDialog("configFile");
        const object = configs["[_SystemParamdownloadStru]"];
        const systemData = {
          lecuAddr: object.Lecu_addr || "0",
          lecuNewAddr: object.Lecu_Newaddr || "0",
          batType: object.BatType || "0",
          batCellType: object.Bat_cell_type || "0",
          afeNum: Number(object.SYS_AFE_num || "0"),
          hwType: object.Device_type || "0",
          // 下面三个 = vcu_soh
          vcuCanType: "0", // 这个没有，默认设置为0
          vcuProtocol: object.VCU_Protocol || "0",
          sohPercentage: object.SOH_percentage || "0",
          chgProtocol: object.CHG_Protocol || "0",
          balnaceDischarge: object.Balance_discharge || "0",
          systemVolumetric: object.Rated01AH || "0",
          initSoc: object.Init_SOC || "0",
          workVoltage: object.UPS_Work_Vol || "0",
          staticEurrentError: object.Current_THD || "0",
          dynamicThreshold: object.Vbalance_Start_Vol || "0",
          dynamicDifference: object.Vbalance_Delta || "0",
          staticThreshold: object.Vbalance_slient_Start_Vol || "0",
          staticDifference: object.Vbalance_slient_Delta || "0",
          maxBalanceChannel: object.Vbalance_CHG_MAXNum || "0",
          balanceMode: object.Balance_Mode || "0",
          shunt_I: object.shunt_I || "0",
          shunt_I_offset: object.shunt_I_offset || "0",
          shunt_mV: object.shunt_mV || "0",
          ipQueryDsgTHD: object.Ip_Query_DsgTHD || "0",
          ipQueryChgCutTHD: object.Ip_Query_ChgCutTHD || "0",
          contactSingleTime: object.Contact_single_time || "0",
          chargeContactSingleTime:object.Charge_contact_single_time || "0",
          preChargeContactRatio: object.PreCharge_contact_ratio || "0",
          // alarmInterval0: object.Alarm_interval0 || "0",
          // alarmInterval1: object.Alarm_interval1 ||"0",
          // alarmInterval2: object.Alarm_interval2 ||"0",
          // 这些是只配置地址，页面中没有的
          hsdxCfg0: object.HSDx_cfg0 || "0",
          hsdxCfg1: object.HSDx_cfg1 || "0",
          hsdxCfg2: object.HSDx_cfg2 || "0",
          hsdxCfg3: object.HSDx_cfg3 || "0",
          hsdxCfg4: object.HSDx_cfg4 || "0",
          canBaud: object.CAN_BAUD || "0",
          canConfig: object.CAN_CONFIG || "0", // can通道分布
          tempAndNumRes: object.Temp_AD_NUM_RES || "0", // 电池温度探头数量和上拉阻抗
          // controlBz 包含：empower borderCheck hotControler chgSameTimeDischg disChgLv2Back vanControler chargeLv2Back externalParalleling
          controlBz: object.Control_bz || "0",
          // 下面两个 = gbChgMaxCur_ComBaud
          gbChgMaxCur: object.GB_CHG_MAX_Cur || "0",
          comBaud: object.COM_BAUD || "0",
          // range 包含：tempCanType tempProtocol strings
          range: object.range || "0",
          vanStart: object.FAN_Start_Temp || "0",
          vanStop: object.FAN_Restore_Temp || "0",
          slientStart: object.FAN_Slient_Start_Temp || "0",
          slientStop: object.FAN_Slient_Restore_Temp || "0",
          hotStart: object.HOT_Start_Temp || "0",
          hotStop: object.HOT_Restore_Temp || "0",
          dsgHotStart: object.DSG_HOT_Start_Temp || "0",
          dsgHotStop: object.DSG_HOT_Restore_Temp || "0",
          // 下面两个 = pbIoset_tranProtocol
          pbIoset: object.PB_IOSET || "0",
          tranProtocol: object.TRAN_Protocol || "0",
          // 滤波系数
          ltc6811FilterRatio: object.Ltc6811_FilterRatio || "0",
          ltc6811FilterRatio: object.Mcuad_FilterRatio || "0",
          ad7706FilterRatio: object.Ad7706_FilterRatio || "0"
        };

        this.systemData.afeNum = systemData.afeNum;

        sessionStorage.setItem("device-configs-file-name", fileName);

        sessionStorage.setItem(`device-configs-_SystemParamdownloadStru:${this.deviceInfo.code}`, JSON.stringify(systemData));
        if (configs["[_SystemAlarmParamdownloadStru_Level_0]"]) {
          sessionStorage.setItem(`device-configs-_SystemAlarmParamdownloadStru_alarm:${this.deviceInfo.code}`,
                                  JSON.stringify(configs["[_SystemAlarmParamdownloadStru_Level_0]"]));
        }
        if (configs["[_SystemAlarmParamdownloadStru_Level_1]"]) {
          sessionStorage.setItem(`device-configs-_SystemAlarmParamdownloadStru_warning:${this.deviceInfo.code}`,
                                  JSON.stringify(configs["[_SystemAlarmParamdownloadStru_Level_1]"]));
        }
        if (configs["[_SystemAlarmParamdownloadStru_Level_2]"]) {
          sessionStorage.setItem(`device-configs-_SystemAlarmParamdownloadStru_criticalAlarm:${this.deviceInfo.code}`,
                                  JSON.stringify(configs["[_SystemAlarmParamdownloadStru_Level_2]"]));
        }
        if (configs["[_CellCFGStru_Mask]"]) {
          sessionStorage.setItem(`device-configs-_CellCFGStru_Mask:${this.deviceInfo.code}`,
                                  JSON.stringify(configs["[_CellCFGStru_Mask]"]));
        }

        this.onShowDialog('system');
        this.$message.success(`系统参数已从配置文件（文件名：${fileName}）读取成功！`);
      } catch {
        this.$message.success(`系统参数已从配置文件（文件名：${fileName}）读取失败！`);
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.config-name-btn {
  @media (min-width: 1920px) {
    width: 350px;
    height: 80px;
    font-size: 30px;
    margin: 20px;
  }

  @media (max-width: 1919px) {
    width: 350px;
    height: 60px;
    font-size: 30px;
    margin: 20px;
  }
}

.config-name-btn-box {
  width: 500px;
  text-align: center;
  margin: auto;
  margin-top: 75px;
}
</style>