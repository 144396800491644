import request from '@/utils/request';

export const least = (params) => {
    const rand = Date.now();
    return request.get(`module-AirConditioner/code?${rand}`, { params });
}

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-AirConditioner/list?${rand}`, { params });
}

export const save = (params) => {
    const rand = Date.now();
    return request.post(`module-AirConditioner/save?${rand}`, { params });
}

export const update = (params) => {
    const rand = Date.now();
    return request.post(`module-AirConditioner/save?${rand}`, { params });
}

export default {
    least,
    list,
    save,
    update
}