var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      style: `height:${_vm.height}px;`,
    },
    [
      _vm.upgrading
        ? _c("div", [
            _vm.deviceInfo.type === 1 || _vm.deviceInfo.type === 9
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: _vm.upgradeMsg } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-progress", {
                            attrs: {
                              "text-color": "#2d2d2d",
                              "text-inside": true,
                              "stroke-width": 25,
                              percentage: _vm.percentage,
                              color: _vm.customColors,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.deviceInfo.type === 3
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: "设备升级等待中，请稍候……" } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.deviceInfo.type === 0 && !_vm.deviceInfo.online
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: "升级完毕，设备重启中，请稍候……" } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-progress", {
                            attrs: {
                              "text-color": "#2d2d2d",
                              "text-inside": true,
                              "stroke-width": 25,
                              percentage: _vm.percentage,
                              color: _vm.customColors,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _vm.deviceInfo.projectType === 0
                ? _c("master", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 9
                ? _c("power", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 10
                ? _c("fire-protection", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 11
                ? _c("water-logging", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 12
                ? _c("aircondition", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 8
                ? _c("enerty-meter", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 1 ||
                  _vm.deviceInfo.projectType === 6
                ? _c("ems", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm.deviceInfo.projectType === 7
                ? _c("pcs", {
                    attrs: {
                      "device-info": _vm.deviceInfo,
                      height: _vm.height,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }