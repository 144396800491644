var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-l-10" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.rack,
            expression: "loading.rack",
          },
        ],
        staticClass: "m-t-20 chg-dischg-data-box",
      },
      [
        _c(
          "el-descriptions",
          {
            attrs: {
              title: `Rack【更新时间：${_vm.rackObj.updateTime || ""}】`,
              column: 2,
              "label-class-name": "descriptions-label-box",
              "content-class-name": "descriptions-content-box",
              border: "",
              size: "mini",
            },
          },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-refresh-right",
                      type: "primary",
                      size: "mini",
                    },
                    on: { click: _vm.onReadRackData },
                  },
                  [_vm._v("数据读取")]
                ),
              ],
              1
            ),
            _c("el-descriptions-item", { attrs: { label: "Rack运行状态" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.rackObj.rackRunState)
                      ? _vm.rackObj.rackRunState
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack预充电阶段" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.rackObj.rackPrechargePhase
                    )
                      ? _vm.rackObj.rackPrechargePhase
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack接触器状态" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.rackObj.rackConnectionState
                    )
                      ? _vm.rackObj.rackConnectionState
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack故障" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.rackObj.rackFault)
                      ? _vm.rackObj.rackFault
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack切入失败原因" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.reasonRackStep
                      )
                        ? _vm.rackObj.reasonRackStep
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack未启动切入原因" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.theReasonRackDid
                      )
                        ? _vm.rackObj.theReasonRackDid
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c("el-descriptions-item", { attrs: { label: "Rack预充总压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackPrechargeTotalVol
                    )
                      ? (_vm.rackObj.rackPrechargeTotalVol / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("V")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack电池总电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.rackRackVoltage)
                      ? (_vm.rackObj.rackRackVoltage / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("V")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack电池总电流" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.rackCurrent)
                      ? (_vm.rackObj.rackCurrent / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("A")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack充放电指示" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.rackObj.rackChargeDischargeState
                    )
                      ? _vm.rackChargeDischargeStateList[
                          _vm.rackObj.rackChargeDischargeState
                        ] || "无数据"
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "SOC" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.rackSoc)
                      ? (_vm.rackObj.rackSoc / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "SOH" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.rackSoh)
                      ? (_vm.rackObj.rackSoh / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack绝缘值" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackInsulationValue
                    )
                      ? _vm.rackObj.rackInsulationValue.toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KΩ"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack正极绝缘值" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackPositiveLnsulationValue
                    )
                      ? _vm.rackObj.rackPositiveLnsulationValue.toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KΩ"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack负极绝缘值" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackNegativeLnsulationValue
                    )
                      ? _vm.rackObj.rackNegativeLnsulationValue.toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KΩ"),
              ]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体电压最高节序号" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.rackMaxVolCellId
                      )
                        ? _vm.rackObj.rackMaxVolCellId
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体最高电压值" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.rackObj.rackMaxCellVoltage
                      )
                        ? _vm.rackObj.rackMaxCellVoltage.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("mV"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体电压最低节序号" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.rackMinVolCellId
                      )
                        ? _vm.rackObj.rackMinVolCellId
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体最低电压值" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.rackObj.rackMinCellVoltage
                      )
                        ? _vm.rackObj.rackMinCellVoltage.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("mV"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体温度最高节序号" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.rackMaxTemperatureCellId
                      )
                        ? _vm.rackObj.rackMaxTemperatureCellId
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体温度最高值" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.rackObj.rackMaxCellTemperature
                      )
                        ? (_vm.rackObj.rackMaxCellTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体温度最低节序号" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.rackObj.rackMinTemperatureCellId
                      )
                        ? _vm.rackObj.rackMinTemperatureCellId
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体温度最低值" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.rackObj.rackMinCellTemperature
                      )
                        ? (_vm.rackObj.rackMinCellTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c("el-descriptions-item", { attrs: { label: "Rack平均电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackAverageVoltage
                    )
                      ? _vm.rackObj.rackAverageVoltage.toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("mV"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "Rack平均温度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.rackObj.rackAverageTemperature
                    )
                      ? (_vm.rackObj.rackAverageTemperature / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("℃")]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack单体累加和总压" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.rackObj.rackCellVoltageCumulativeSum
                      )
                        ? (
                            _vm.rackObj.rackCellVoltageCumulativeSum / 10
                          ).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度1" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp1)
                        ? (_vm.rackObj.hvbTemp1 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度2" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp2)
                        ? (_vm.rackObj.hvbTemp2 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度3" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp3)
                        ? (_vm.rackObj.hvbTemp3 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度4" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp4)
                        ? (_vm.rackObj.hvbTemp4 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度5" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp5)
                        ? (_vm.rackObj.hvbTemp5 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度6" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp6)
                        ? (_vm.rackObj.hvbTemp6 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度7" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp7)
                        ? (_vm.rackObj.hvbTemp7 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "Rack高压箱温度8" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(_vm.rackObj.hvbTemp8)
                        ? (_vm.rackObj.hvbTemp8 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }