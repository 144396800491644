var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "m-l-15",
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "right-btns m-b-20" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-refresh-right",
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.getDatas },
                },
                [_vm._v("数据读取")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "table",
              staticClass: "fixed-table",
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.height - 60,
                data: _vm.table,
                "header-cell-style": {
                  background: "#F7F7F7",
                  color: "#2d2d2d",
                  "text-align": "center",
                },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "台号",
                  width: "80",
                  prop: "platform",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流端口电压(V)",
                  prop: "acPortVoltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流端口电流(A)",
                  prop: "acPortCurrent",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流端口功率(KW)",
                  prop: "acPortPower",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT1温度(℃)",
                  prop: "moduleLgbt1",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT2温度(℃)",
                  prop: "moduleLgbt2",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT3温度(℃)",
                  prop: "moduleLgbt3",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT4温度(℃)",
                  prop: "moduleLgbt4",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT5温度(℃)",
                  prop: "moduleLgbt5",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块IGBT6温度(℃)",
                  prop: "moduleLgbt6",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块进风口温度(℃)",
                  prop: "moduleAirInlet",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "模块出风口温度(℃)",
                  prop: "moduleAirOutlet",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "交流A相有功功率(KW)",
                  prop: "theAcPowerA",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "交流B相有功功率(KW)",
                  prop: "theAcPowerB",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "交流C相有功功率(KW)",
                  prop: "theAcPowerC",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "直流母线电压(V)",
                  prop: "dcBusVoltage",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备运行状态字",
                  width: "300",
                  prop: "deviceStatus",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "系统综合故障字",
                  prop: "systemSynthesisFault",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "系统环境故障字",
                  prop: "systemEnvironmentFault",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "系统硬件故障字",
                  prop: "systemHardwareFault",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "系统温度故障字",
                  prop: "systemTemperatureFault",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }