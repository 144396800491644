<!-- 充放电功率设置 -->
<template>
  <div class="set-power-box" v-loading="loading">
    <el-form :style="`margin-bottom: 15px;`" ref="form" :model="form" :rules="rules" label-width="60px" :inline="true">
      <div class="set-months">
        <el-radio-group v-model="radio" size="medium" @input="onSelectRadio">
          <el-radio-button v-for="(index) in 12" :key="index" :label="`${index}月`"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="power-setting-box set-chg-box">
        <div class="power-setting-title-box">充电设置</div>
        <div class="power-setting-content-box">
        <el-form-item label="时间1" prop="chgTime1">
          <from-to-time-selector name="chgTime1" :value="form.chgTime1" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率1" prop="chargingPowerOne">
        <el-input class="power-input" clearable v-model="form.chargingPowerOne" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间2" prop="chgTime2">
          <from-to-time-selector name="chgTime2" :value="form.chgTime2" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率2" prop="chargingPowerTwo">
        <el-input class="power-input" clearable v-model="form.chargingPowerTwo" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间3" prop="chgTime3">
          <from-to-time-selector name="chgTime3" :value="form.chgTime3" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率3" prop="chargingPowerThree">
        <el-input class="power-input" clearable v-model="form.chargingPowerThree" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间4" prop="chgTime4">
          <from-to-time-selector name="chgTime4" :value="form.chgTime4" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率4" prop="chargingPowerFour">
        <el-input class="power-input" clearable v-model="form.chargingPowerFour" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        </div>
      </div>
      <div class="power-setting-box set-dischg-box">
        <div class="power-setting-title-box">放电设置</div>
        <div class="power-setting-content-box">
        <el-form-item label="时间1" prop="disChgTime1">
          <from-to-time-selector name="disChgTime1" :value="form.disChgTime1" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率1" prop="dischargingPowerOne">
        <el-input class="power-input" clearable v-model="form.dischargingPowerOne" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间2" prop="disChgTime2">
          <from-to-time-selector name="disChgTime2" :value="form.disChgTime2" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率2" prop="dischargingPowerTwo">
        <el-input class="power-input" clearable v-model="form.dischargingPowerTwo" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间3" prop="disChgTime3">
          <from-to-time-selector name="disChgTime3" :value="form.disChgTime3" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率3" prop="dischargingPowerThree">
        <el-input class="power-input" clearable v-model="form.dischargingPowerThree" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        <el-form-item label="时间4" prop="disChgTime4">
          <from-to-time-selector name="disChgTime4" :value="form.disChgTime4" @blur="onBlurTime" />
        </el-form-item>
        <el-form-item label="功率4" prop="dischargingPowerFour">
        <el-input class="power-input" clearable v-model="form.dischargingPowerFour" size="mini">
          <template slot="append">KW</template>
        </el-input>
        </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="right-btns">
      <el-button size="small" type="primary" @click="getDatas">数据读取</el-button>
      <el-button size="small" type="primary" @click="onSubmitThisMonth">保存当前月的配置</el-button>
    </div>
  </div>
</template>
<script>
import modbusApi from "@/utils/modbus";
import ChargingPowerPriceApi from "@/api/470/ChargingPowerPrice";

const { calcHexFromUnsignedDec } = $numberMatrixing;

import FromToTimeSelector from '@/components/from-to-time-selector';

const checkTime = $utils.getTimeChecker($statics.CHARGING_POWER_PRE_LIST);

const KEY_ARR = ["chargingPowerOne", "chargingPowerTwo", "chargingPowerThree", "chargingPowerFour",
  "dischargingPowerOne", "dischargingPowerTwo", "dischargingPowerThree", "dischargingPowerFour",
  "chgTime1", "chgTime2", "chgTime3", "chgTime4",
  "disChgTime1", "disChgTime2", "disChgTime3", "disChgTime4",
];
const KEY_ARR_LEN = KEY_ARR.length;

function powerRule (value) {

  if (value) {
    if (isNaN(value)) {
      return "请输入数字";
    }
    if (value < 0) {
      return "输入值不能小于0";
    }
  }
}

export default {
  components: {
    FromToTimeSelector
  },
  props: {
    deviceInfo: undefined,
    height: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      radio: "1月",
      start: 0x1070,
      loading: false,
      nowMonth: 0,
      form: {
        chgTime1: [ 0, 0 ],
        chgTime2: [ 0, 0 ],
        chgTime3: [ 0, 0 ],
        chgTime4: [ 0, 0 ],
        disChgTime1: [ 0, 0 ],
        disChgTime2: [ 0, 0 ],
        disChgTime3: [ 0, 0 ],
        disChgTime4: [ 0, 0 ],
        chargingPowerOne: "",
        chargingPowerTwo: "",
        chargingPowerThree: "",
        chargingPowerFour: "",
        dischargingPowerOne: "",
        dischargingPowerTwo: "",
        dischargingPowerThree: "",
        dischargingPowerFour: ""
      },
      data: null,
      rules: {
        chgTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "chg", 1));
            }
          }
        ],
        chargingPowerOne: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.chargingPowerOne = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        chgTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "chg", 2));
            }
          }
        ],
        chargingPowerTwo: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.chargingPowerTwo = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        chgTime3: [
        {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "chg", 3));
            }
          }
        ],
        chargingPowerThree: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.chargingPowerThree = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        chgTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "chg", 4));
            }
          }
        ],
        chargingPowerFour:  [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.chargingPowerFour = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        disChgTime1: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "disChg", 1));
            }
          }
        ],
        dischargingPowerOne: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.dischargingPowerOne = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        disChgTime2: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "disChg", 2));
            }
          }
        ],
        dischargingPowerTwo: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.dischargingPowerTwo = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        disChgTime3: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "disChg", 3));
            }
          }
        ],
        dischargingPowerThree: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.dischargingPowerThree = (value).toString().split(".")[0];
              callback();
            }
          }
        ],
        disChgTime4: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(checkTime(this, "disChg", 4));
            }
          }
        ],
        dischargingPowerFour: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              callback(powerRule(value));
            }
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              this.form.dischargingPowerFour = (value).toString().split(".")[0];
              callback();
            }
          }
        ]
      }
    }
  },
  created () {
    console.log("set-charging-power");
    this.getDatas();
  },
  methods: {
    async getDatas () {

      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code,
          moon: this.nowMonth,
          size: 1,
          page: 1
        };

        const res = await ChargingPowerPriceApi.list(params);

        this.data = null;
        if (!res || res.data.length === 0) {
          this.$message.warning(`当前设备（设备编号：${this.deviceInfo.code}）没有${this.nowMonth + 1}月的功率配置`);
        } else {
          this.data = res.data[0];
        }
      } catch (err) {
        this.data = {};
      } finally {
        this.parse();
        this.loading = false;
      }
    },
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    createFormDataObject (object) {
      const data = {
        id: object.id,
        chargingPowerOne: object.chargingPowerOne || 0,
        chargingPowerTwo: object.chargingPowerTwo || 0,
        chargingPowerThree: object.chargingPowerThree || 0,
        chargingPowerFour: object.chargingPowerFour || 0,
        dischargingPowerOne: object.dischargingPowerOne || 0,
        dischargingPowerTwo: object.dischargingPowerTwo || 0,
        dischargingPowerThree: object.dischargingPowerThree || 0,
        dischargingPowerFour: object.dischargingPowerFour || 0,
      }

      data.chgTime1 = [ object.chargingPowerOneStartTime || 0, object.chargingPowerOneEndTime || 0 ];
      data.chgTime2 = [ object.chargingPowerTwoStartTime || 0, object.chargingPowerTwoEndTime || 0 ];
      data.chgTime3 = [ object.chargingPowerThreeStartTime || 0, object.chargingPowerThreeEndTime || 0 ];
      data.chgTime4 = [ object.chargingPowerFourStartTime || 0, object.chargingPowerFourEndTime || 0 ];

      data.disChgTime1 = [ object.dischargingPowerOneStartTime || 0,  object.dischargingPowerOneEndTime || 0 ];
      data.disChgTime2 = [ object.dischargingPowerTwoStartTime || 0,  object.dischargingPowerTwoEndTime || 0 ];
      data.disChgTime3 = [ object.dischargingPowerThreeStartTime || 0,  object.dischargingPowerThreeEndTime || 0 ];
      data.disChgTime4 = [ object.dischargingPowerFourStartTime || 0,  object.dischargingPowerFourEndTime || 0 ];

      return data;
    },
    parse () {
      const data = this.data;

      if (!data) { // 这里表示数据库里没值，且从设备读取失败
        data = {};
      }
      data.id = data.id || (this.form && this.form.id ? this.form.id : undefined);
      this.form = this.createFormDataObject(data);
    },
    onSubmitThisMonth () {
      this.$refs.form.validate(async valid => {
        if (valid) {

          try {
            this.loading = true;

            await Promise.all([
              this.submitCore(this.nowMonth),
              this.writeV2(this.nowMonth)
            ]);
          } catch (err) {
            console.log(err);
          } finally {
            this.loading = false;
          }
        }
      })
    },
    async submitCore () {
      const form = this.form;
      const data = {
        id: form.id,
        moon: this.nowMonth,
        code: this.deviceInfo.code,
        chargingPowerOne: form.chargingPowerOne,
        chargingPowerTwo: form.chargingPowerTwo,
        chargingPowerThree: form.chargingPowerThree,
        chargingPowerFour: form.chargingPowerFour,
        dischargingPowerOne: form.dischargingPowerOne,
        dischargingPowerTwo: form.dischargingPowerTwo,
        dischargingPowerThree: form.dischargingPowerThree,
        dischargingPowerFour: form.dischargingPowerFour,
        chargingPowerOneStartTime: form.chgTime1[0],
        chargingPowerOneEndTime: form.chgTime1[1],
        chargingPowerTwoStartTime: form.chgTime2[0],
        chargingPowerTwoEndTime: form.chgTime2[1],
        chargingPowerThreeStartTime: form.chgTime3[0],
        chargingPowerThreeEndTime: form.chgTime3[1],
        chargingPowerFourStartTime: form.chgTime4[0],
        chargingPowerFourEndTime: form.chgTime4[1],
        dischargingPowerOneStartTime: form.disChgTime1[0],
        dischargingPowerOneEndTime: form.disChgTime1[1],
        dischargingPowerTwoStartTime: form.disChgTime2[0],
        dischargingPowerTwoEndTime: form.disChgTime2[1],
        dischargingPowerThreeStartTime: form.disChgTime3[0],
        dischargingPowerThreeEndTime: form.disChgTime3[1],
        dischargingPowerFourStartTime: form.disChgTime4[0],
        dischargingPowerFourEndTime: form.disChgTime4[1]
      };

      const method = form.id ? "update" : "save";
      const res = await ChargingPowerPriceApi[method](data);

      if (res !== "SUCCESS") {
        this.form.id = Number(res);
      }
    },
    createSubmitPrice () {
      const price = [];
      for (let i = 0; i < KEY_ARR_LEN; i++) {
        const data = this.form[KEY_ARR[i]];
        if (Array.isArray(data)) {
          price.push(calcHexFromUnsignedDec(data[0]));
          price.push(calcHexFromUnsignedDec(data[1]));
        } else {
          price.push(calcHexFromUnsignedDec(data));
        }
      }
      return price;
    },
    async writeV2 () {

      const price = this.createSubmitPrice();

      const params = {
        code: this.deviceInfo.code,
        origin: calcHexFromUnsignedDec(this.start + this.nowMonth * 100),
        size: price.length,
        type: 6, // 功率(和电价)
        data: price.join("").toUpperCase()
      };

      await modbusApi.write(params);
    },
    onBlurTime (name, values) {
      this.form[name] = values;
    },
    onSelectRadio (evt) {
      evt = evt.replace("月", "");
      const index = Number(evt) - 1;
      this.nowMonth = index;
      this.getDatas();
    }
  }
}
</script>
<style lang="scss" scoped>
.set-chg-box {
  border: 1px dashed #4f5fbc;
  background-image: url(./../../../../assets/images/chg-bg.png);
}

.set-dischg-box {
  margin-top: 10px;
  border: 1px dashed #fa6d1f;
  background-image: url(./../../../../assets/images/dischg-bg.png);
}

.power-setting-content-box {
  margin-top: 15px;

  ::v-deep .el-form-item {
    vertical-align: top;
  }

  .time-input {
      width: 85px;
    ::v-deep .el-input__inner {
      width: 80px;
    }
  }

  .time-input, .time-spector {
    display: inline-block;
  }

  .time-spector {
    margin-right: 5px;
  }

  .power-input {
    padding-top: 6px;
    ::v-deep .el-input__inner {
      width: 70px;
    }

    ::v-deep .el-input__suffix {
      margin-top: 5px;
    }

    ::v-deep .el-input-group__append {
      padding: 0px 12px !important;
    }
  }
}

.time-selector {

  width: 180px !important;

  ::v-deep .el-range-input {
    width: 50px !important;
  }

  ::v-deep .el-range-separator {
    width: 30px !important;
  }
}
</style>