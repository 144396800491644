<!-- 因为电表的参数设置完全不一致，所以先剥离电表的页面 -->
<template>
  <div class="big-data-container">
    <div class="content">
      <div v-if="upgrading">
        <div v-if="deviceInfo.type === 1 || deviceInfo.type === 9"><!-- 升级中、传送中 -->
          <div style="margin:50px 20%;">
            <el-result :title="upgradeMsg">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
            <div style="margin-top: 20px;">
              <el-progress text-color="#2d2d2d" :text-inside="true" :stroke-width="25" :percentage="percentage" :color="customColors"></el-progress>
            </div>
          </div>
        </div>
        <div v-else-if="deviceInfo.type === 3"><!-- 等待中 -->
          <div style="margin:50px 20%;">
            <el-result title="设备升级等待中，请稍候……">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
          </div>
        </div>
        <div v-else-if="deviceInfo.type === 0 && !deviceInfo.online">
          <div style="margin:50px 20%;">
            <el-result title="升级完毕，设备重启中，请稍候……">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
            <div style="margin-top: 20px;">
              <el-progress text-color="#2d2d2d" :text-inside="true" :stroke-width="25" :percentage="percentage" :color="customColors"></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div v-else :style="`height: 100%;`" v-loading="loading">
        <div class="content-btn-box" v-if="isDetail && tabName === 'detail'">
          <el-button size="mini" type="primary" icon="el-icon-edit" :disabled="utils.userReadOnly(user)" @click="onEdit">编辑设备</el-button>
          <el-button type="danger" slot="reference" size="mini" icon="el-icon-delete" :disabled="utils.userReadOnly(user)" @click="onDelSite">删除设备</el-button>
        </div>
        <div class="content-input-box" :style="`height: 100%;`">
          <div class="breadcrumb-box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="getGotoObj()">设备管理</el-breadcrumb-item>
              <el-breadcrumb-item>{{ code }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <!-- 这里仅用户导入 detail -->
          <detail :device-info="deviceInfo" style="height: calc(100% - 34px);" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDevicesDetail, getFirmwareInfo } from '@/api/device';
import detail from "./detail";

export default {
  components: {
    detail
  },
  data () {
    return {
      loading: false,
      upgrading: false,
      code: "",
      deviceInfo: {},
      height: 0,
      processTimer: null,
      requestTimer: null
    }
  },
  async created () {
    this.code = this.$route.params.code;
    console.log("device-detail");
    await this.getDeviceDetail();
  },
  destroyed () {
    this.clearTimer();
  },
  methods: {
    clearTimer () {
      clearInterval(this.processTimer);
      clearInterval(this.requestTimer);
    },
    async getDeviceDetail () {
      try{
        this.loading = true;
        const res = await getDevicesDetail(this.code);

        this.deviceInfo = res;

        this.deviceInfo.upgrade = this.startTimer;

        if (!this.upgrading) {
          if (res.type === 1 || res.type === 3 || res.type === 9) {

            const firmware = await getFirmwareInfo({
              fileName: res.fileName || ""
            });

            this.startTimer(firmware.size || "10K", res.gmtModify, firmware.type == "1");
          }
        }

      } catch (err) {
        this.$message.warning(`找不到设备（设备编号${this.code}）`);
        this.$router.push({
          path: "/device"
        });
      } finally {
        this.loading = false;
      }
    },
    getGotoObj () {
      return {
        path: `/device`
      }
    },
    startTimer (size, startTime, type) {

      if (size === null) return;

      this.upgrading = true;
      const unit = size.slice(size.length - 1).toUpperCase();
      let totalSize = Number(size.slice(0, size.length - 1));

      switch (unit) {
        case "M":
          totalSize *= 1024;
        case "K":
          totalSize *= 1024; // k => b
          break;
      }

      const diff = parseInt((Date.now() - startTime) / 500); // 差值，过了多少次
      const perHalfSecSize = type ? 365 : 215; // 每 500 毫秒的大约传输速率（byte）
      let fileSize = perHalfSecSize * diff;

      // 这个定时器仅是用于显示进度条的
      // TODO 因为现在发包的时候无法获得每包的大小，所以只能通过假定的发包速度来修改进度条
      this.processTimer = setInterval(() => {
        if (this.deviceInfo.type === 1 || this.deviceInfo.type === 9) {
          if (this.percentage < 98) {
            fileSize += perHalfSecSize;
            this.percentage = (fileSize / totalSize * 100).toFixed(1);
          } else {
            this.percentage = 98;
            clearInterval(this.processTimer);
          }
        }
      }, 500);

      // 这个定时器是用于请求后台数据的
      this.requestTimer = setInterval(async () => {

        if (this.deviceInfo.type === 1) {
          this.upgrading = true;
          this.upgradeMsg = "设备升级中，请稍候……";
        }
        if (this.deviceInfo.type === 9) {
          this.upgrading = true;
          this.upgradeMsg = "数据传送中，请稍候……";
        }

        if (this.deviceInfo.type == 2 || (this.deviceInfo.type > 4 && this.deviceInfo.type !== 9)) {
          this.upgrading = false;
          this.clearTimer();
          this.$message.error($statics.FIRMWARE_UPGRADE_STATUS_LIST[this.deviceInfo.type].label);
          return;
        }

        if (this.deviceInfo.type === 0) { // 设备升级已经成功，但设备需要重启
          this.percentage = 100; // 因为设备已经升级成功，所以直接把进度条拉满
          if (this.deviceInfo.online) {
            this.clearTimer();
            setTimeout(() => {
              this.percentage = 0;
              this.$message.success("升级成功！");
              this.upgrading = false;
            }, 1000);
            return;
          }
        }

        await this.getDeviceDetail();
      }, 1000);
    },
  }
}
</script>