<template>
  <div class="device-base-data-box" style="height: 100%;">
    <div>
      <device-base-data :device-info="deviceInfo" />
    </div>
    <div>
      <detail :device-info="deviceInfo" />
    </div>
  </div>
</template>
<script>
import DeviceBaseData from "./../comnponents/device-base-data";
import detail from "./detail";

export default {
  components: {
    DeviceBaseData,
    detail
  },
  props: {
    deviceInfo: undefined
  }
}
</script>
<style lang="scss" scoped>
.device-base-data-box {
  >div {
    display: inline-block;
    vertical-align: top;
    height: 100%;
  }
  >div:first-child {
    width: 300px;
    background: #e7eff7;
    padding: 10px;
  }
  >div:last-child {
    width: calc(100% - 300px);
  }
}
</style>