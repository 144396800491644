<template>
  <div class="m-l-15" v-loading="loading">
    <div style="overflow:auto;">
      <el-descriptions :title="`消防数据【更新时间：${object.updateTime || ''}】`" :column="1"
        border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
        </template>
        <el-descriptions-item label="消防状态">
          {{ utils.checkObjectIsNotEmpty(object.fireProtectionStatus) ? (fireProtectionStatusMap[object.fireProtectionStatus] || "无数据" ) : "无数据" }}
          </el-descriptions-item>
        <el-descriptions-item label="瓶组压力">{{ utils.checkObjectIsNotEmpty(object.cylinderBankPressure) ? object.cylinderBankPressure : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="一级告警">
          {{ utils.checkObjectIsNotEmpty(object.primaryAlarm) ? object.primaryAlarm : "无数据" }}
          </el-descriptions-item>
        <el-descriptions-item label="二级告警  ">
          {{ utils.checkObjectIsNotEmpty(object.levelTwoAlarm) ?  object.levelTwoAlarm : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="CO2浓度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.theConcentrationCo2) ? (object.theConcentrationCo2).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="压力">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.pressure) ? (object.pressure / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KPA</div>
        </el-descriptions-item>
        <el-descriptions-item label="温度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.temperature) ? (object.temperature / 100).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="PM25浓度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.pm) ? (object.pm).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="CO浓度">
          <div class="description-value-box">{{ utils.checkObjectIsNotEmpty(object.coPpm) ? (object.coPpm).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="启动信号">{{ utils.checkObjectIsNotEmpty(object.soundSignal) ? object.soundSignal : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="弱电切电">{{ utils.checkObjectIsNotEmpty(object.weakCurrentCutting) ? object.weakCurrentCutting : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="低压报警">{{ utils.checkObjectIsNotEmpty(object.lowVoltageAlarm) ? object.lowVoltageAlarm : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="高压切电">{{ utils.checkObjectIsNotEmpty(object.highVoltageCutting) ? object.highVoltageCutting : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="声光报警">{{ utils.checkObjectIsNotEmpty(object.soundLightAlarm) ? object.soundLightAlarm : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="防爆风机响应输出">{{ utils.checkObjectIsNotEmpty(object.explosionProof) ? object.explosionProof : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="烟感">{{ utils.checkObjectIsNotEmpty(object.smokeSense) ? object.smokeSense : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="温感">{{ utils.checkObjectIsNotEmpty(object.temperatureSense) ? object.temperatureSense : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="BMS输入">{{ utils.checkObjectIsNotEmpty(object.bmsInput) ? object.bmsInput : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="水浸传感器">{{ utils.checkObjectIsNotEmpty(object.waterSensor) ? object.waterSensor : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="信号反馈装置">{{ utils.checkObjectIsNotEmpty(object.sighalFeedbackDevice) ? object.sighalFeedbackDevice : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="手动启动">{{ utils.checkObjectIsNotEmpty(object.manualStart) ? object.manualStart : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="手动复位">{{ utils.checkObjectIsNotEmpty(object.manualReset) ? object.manualReset : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="故障告警">{{ utils.checkObjectIsNotEmpty(object.faultAlarm) ? object.faultAlarm : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="通讯地址">{{ utils.checkObjectIsNotEmpty(object.contactAddress) ? object.contactAddress : "无数据" }}</el-descriptions-item>
        <el-descriptions-item label="波特率">{{ utils.checkNumberIsNotEmpty(object.baudRate) ? ( baudRateList[object.baudRate] || "无数据" ) : "无数据" }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import FireProtectionApi from "@/api/470/FireProtection";

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      utils: $utils,
      loading: false,
      object: {
        fireProtectionStatus: "", // 103b
        cylinderBankPressure: "",
        primaryAlarm: "",
        levelTwoAlarm: "",
        theConcentrationCo2: "",
        pressure: "",
        temperature: "",
        pm: "", 
        coPpm: "",
        soundSignal: "",
        weakCurrentCutting: "",
        lowVoltageAlarm: "",
        highVoltageCutting: "",
        soundLightAlarm: "",
        explosionProof: "",
        smokeSense: "",
        temperatureSense: "",
        bmsInput: "",
        waterSensor: "",
        sighalFeedbackDevice: "",
        manualStart: "",
        manualReset: "",
        faultAlarm: "",
        contactAddress: "",
        baudRate: "",
        updateTime: ""
      },
      fireProtectionStatusMap: $statics.FIRE_PROTECTION_STATUS_MAP,
      baudRateList: $statics.BAUD_RATE_LIST,
      // [20240604] 根据徐工的说法，103b 从旧的接口请求
      start: 0x5000,
      start: 24
    }
  },
  created () {
    console.log("fire-protection");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code,
          page: 1,
          size: 1
        };

        const res = await FireProtectionApi.list(params);        
        const object = res.data[0];

        object.fireProtectionStatus = calcHexFromUnsignedDec(object.fireProtectionStatus, 2).toUpperCase();
        this.object = object;
        this.$message.success("消防数据读取成功");
      } catch (err) {
        this.$message.warning("消防数据读取失败");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>