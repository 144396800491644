<template>
  <div v-loading="loading">
    <div v-if="list.length > 0">
      <div class="charts-box">
        <div :style="`height: ${height * 0.30 - 5}px; margin-top:10px;`" ref="cTotalconsoleChart"></div>
        <div :style="`height: ${height * 0.30 - 5}px; margin-top:10px;`" ref="vTotalconsoleChart"></div>
        <div :style="`height: ${height * 0.40 - 10}px; margin-top:10px;`" ref="tTotalconsoleChart"></div>
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";

let selectSeries = null;

export default {
  mixins: [ HistoryMixin ],
  methods: {
    setDatas () {
      this.$nextTick(() => {
      if (this.list.length > 0) {
          this.drawV();
          this.drawC();
          this.drawT();
        }
      });
      this.loading = false;
    },
    drawV () {
      try {
        this.loading = true;

        const chart = echarts.init(this.$refs.vTotalconsoleChart);
        chart.clear();
        const options = this.initOptionV();

        chart.on('mousemove',function(params){
          selectSeries = params;
        });    
        //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
        chart.on('mouseout', function (params) {
          selectSeries = null;
        });

        chart.setOption(options);
      } finally {
        this.loading = false;
      }
    },
    initOptionV () {
      
      const map = {};
      for (const item of this.list) {
        map[item.createTime] = item;
      }
      
      const xDatas = Object.keys(map).sort();

      const totalVList = [];

      for (const item of xDatas) {
        totalVList.push(map[item].totalVoltage / 10);
      }

      const yDatas = [totalVList];
      const legendData = [ "总电压" ];

      const options = this.createOptionsV(xDatas, legendData, yDatas);

      return options;
    },
    createOptionsV (xAxis, legendData, metaData,) {

      xAxis = xAxis.map(obj => {
        obj = obj.replace(" ", "\n");
        return obj;
      });

      const serieData = [];

      for (let i = 0; i < legendData.length; i++) {
        let serie = {
            name: legendData[i],
            yAxisIndex: i,
            type: 'line',
            symbol: "circle",
            symbolSize: 10,
            data: metaData[i]
        };
        serieData.push(serie);
      }

      let colors = [ "#f59a23" ];
      const options = {
        legend: {
          show: true,
          data: legendData,
          y: "0%",
          left: "0px",
          orient: 'vertical',
          type: "scroll",
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 14
          }
        },
        color: colors,
        grid: {
          top: '7%',
          left: '100px',
          right: '50px',
          bottom: '50px',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'horizontal',
            start: 0,
            end: 100,
          },
          {
            orient: 'vertical',
            start: 0,
            end: 100
          }
        ],
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            let x = 0, y = 0;
            let pointX = point[0], pointY = point[1];
            let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

            if (boxWidth > pointX) {
              x = "5%";
            } else {
              x = pointX - boxWidth;
            }

            if (boxHeight > pointY) {
              y = 5;
            } else {
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (params) {

          if (selectSeries !== null) {

            const obj = params.filter(item => {
              return item.seriesName === selectSeries.seriesName;
            })[0];

            let unit = null;
            switch (selectSeries.seriesName) {
              case "总电压":
                unit = "V";
                break;
            }

            const str = `<div>${selectSeries.name}</div><div>${obj.marker} ${obj.seriesName}： ${obj.value} ${unit}</div>`;
            return str;
          }
          },
          axisPointer: {
            type: 'shadow',
            snap: true
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { show: true, lineStyle: { color: '#000' } },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisTick: { show: false },
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisLine: { show: true, lineStyle: { color: '#000' } },
          },
        ],
        series: serieData
      };

      return options;
    },
    drawC () {
      try {
        this.loading = true;

        const chart = echarts.init(this.$refs.cTotalconsoleChart);
        chart.clear();
        const options = this.initOptionC();

        chart.on('mousemove',function(params){
          selectSeries = params;
        });    
        //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
        chart.on('mouseout', function (params) {
          selectSeries = null;
        });

        chart.setOption(options);
      } finally {
        this.loading = false;
      }
    },
    initOptionC () {
      
      const map = {};
      for (const item of this.list) {
        map[item.createTime] = item;
      }
      
      const xDatas = Object.keys(map).sort();

      const totalCList = [];

      for (const item of xDatas) {
        totalCList.push(map[item].totalCurrent / 10);
      }

      const yDatas = [totalCList];
      const legendData = [ "总电流" ];

      const options = this.createOptionsC(xDatas, legendData, yDatas);

      return options;
    },
    createOptionsC (xAxis, legendData, metaData,) {

      xAxis = xAxis.map(obj => {
        obj = obj.replace(" ", "\n");
        return obj;
      });

      const serieData = [];

      for (let i = 0; i < legendData.length; i++) {
        let serie = {
            name: legendData[i],
            yAxisIndex: i,
            type: 'line',
            symbol: "circle",
            symbolSize: 10,
            data: metaData[i]
        };
        serieData.push(serie);
      }

      let colors = [ "#1890ff" ];
      const options = {
        legend: {
          show: true,
          data: legendData,
          y: "0%",
          left: "0px",
          orient: 'vertical',
          type: "scroll",
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 14
          }
        },
        color: colors,
        grid: {
          top: '7%',
          left: '100px',
          right: '50px',
          bottom: '50px',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'horizontal',
            start: 0,
            end: 100,
          },
          {
            orient: 'vertical',
            start: 0,
            end: 100
          }
        ],
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            let x = 0, y = 0;
            let pointX = point[0], pointY = point[1];
            let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

            if (boxWidth > pointX) {
              x = "5%";
            } else {
              x = pointX - boxWidth;
            }

            if (boxHeight > pointY) {
              y = 5;
            } else {
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (params) {

          if (selectSeries !== null) {

            const obj = params.filter(item => {
              return item.seriesName === selectSeries.seriesName;
            })[0];

            let unit = null;
            switch (selectSeries.seriesName) {
              case "总电流":
                unit = "A";
                break;
            }

            const str = `<div>${selectSeries.name}</div><div>${obj.marker} ${obj.seriesName}： ${obj.value} ${unit}</div>`;
            return str;
          }
          },
          axisPointer: {
            type: 'shadow',
            snap: true
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { show: true, lineStyle: { color: '#000' } },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisTick: { show: false },
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisLine: { show: true, lineStyle: { color: '#000' } },
          },
        ],
        series: serieData
      };

      return options;
    },
    drawT () {
      try {
        this.loading = true;

        const chart = echarts.init(this.$refs.tTotalconsoleChart);
        chart.clear();
        const options = this.initOptionT();

        chart.on('mousemove',function(params){
          selectSeries = params;
        });    
        //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
        chart.on('mouseout', function (params) {
          selectSeries = null;
        });

        chart.setOption(options);
      } finally {
        this.loading = false;
      }
    },
    initOptionT () {
      
      const map = {};
      for (const item of this.list) {
        map[item.createTime] = item;
      }
      
      const xDatas = Object.keys(map).sort();

      const lowTList = [], highTList = [];

      for (const item of xDatas) {
        let lowTemperature = map[item].lowTemperature;
        if (lowTemperature > 32767) {
          lowTemperature = 65536 - lowTemperature;
        }
        // [20240221] 这里 0- 的意思，可能是排除 0度一下的数据，所以这里先这么加上
        if (lowTemperature < 0) {
          lowTemperature = 0 - lowTemperature;
        }
        lowTList.push(lowTemperature / 10); // TODO [20240221] 忘了这里为什么要 0- 了，先去掉 0- ，之后有问题了再来改
        highTList.push(map[item].highTemperature / 10);
      }

      const yDatas = [highTList, lowTList];
      const legendData = ["最高温度", "最低温度"];

      const options = this.createOptionsT(xDatas, legendData, yDatas);

      return options;
    },
    createOptionsT (xAxis, legendData, metaData, yTitleName) {

      xAxis = xAxis.map(obj => {
        obj = obj.replace(" ", "\n");
        return obj;
      });

      const serieData = [];

      for (let i = 0; i < legendData.length; i++) {
        let serie = {
            name: legendData[i],
            type: 'line',
            symbol: "circle",
            symbolSize: 10,
            data: metaData[i]
        };
        serieData.push(serie);
      }

      let colors = [ "#f59a23", "#1890ff" ];
      const options = {
        legend: {
          show: true,
          data: legendData,
          y: "0%",
          left: "0px",
          orient: 'vertical',
          type: "scroll",
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 14
          }
        },
        color: colors,
        grid: {
          top: '7%',
          left: '120px',
          right: '50px',
          bottom: '50px',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'horizontal',
            start: 0,
            end: 100,
          },
          {
            orient: 'vertical',
            start: 0,
            end: 100
          }
        ],
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            let x = 0, y = 0;
            let pointX = point[0], pointY = point[1];
            let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

            if (boxWidth > pointX) {
              x = "5%";
            } else {
              x = pointX - boxWidth;
            }

            if (boxHeight > pointY) {
              y = 5;
            } else {
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (params) {

          if (selectSeries !== null) {

            const obj = params.filter(item => {
              return item.seriesName === selectSeries.seriesName;
            })[0];

            let unit = null;
            switch (selectSeries.seriesName) {
              case "最低温度":
                unit = "℃";
                break;
              case "最高温度":
                unit = "℃";
                break;
            }

            const str = `<div>${selectSeries.name}</div><div>${obj.marker} ${obj.seriesName}： ${obj.value} ${unit}</div>`;
            return str;
          }
          },
          axisPointer: {
            type: 'shadow',
            snap: true
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { show: true, lineStyle: { color: '#000' } },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisTick: { show: false },
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisLine: { show: true, lineStyle: { color: '#000' } },
          },
        ],
        series: serieData
      };

      return options;
    },
    createXAxis (xDatas, callback) {
      let startTime = this.timeRange[0].getTime();
      let endTime = this.timeRange[1].getTime();

      const xAxisData = [];

      for (; startTime <= endTime; startTime += 3600 * 24 * 1000) {
        const date = $utils.formatTime(new Date(startTime), "YYYY-MM-DD");
        xAxisData.push(date);

        const index = xDatas.indexOf(date);

        callback(index);
      }

      return xAxisData;
    }
  }
}
</script>