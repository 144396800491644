import request from '@/utils/request';

export const detail = (params) => {
    const rand = Date.now();
    return request.get(`module-ChargingPowerPrice/ChargingPowerPrice/details?${rand}`, { params });
}

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-ChargingPowerPrice/list?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.post(`module-ChargingPowerPrice/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.post(`module-ChargingPowerPrice/update?${rand}`, data);
}

export default {
    detail,
    list,
    save,
    update
}