<template>
  <div>
    <el-table :data="table" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }"
      border style="width: 100%" :height="height" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="45" align="center"></el-table-column>
      <el-table-column type="index" label="序号" width="50" align="center">
        <template slot-scope="scope">
        {{ (scope.$index + 1) + (page.current - 1) * page.size }}
        </template>
      </el-table-column>
      <el-table-column label="设备编号" prop="code" width="100" align="center"></el-table-column>
      <el-table-column label="主控 ID" prop="masterId" width="80" align="center"></el-table-column>
      <el-table-column label="修改值" prop="price"></el-table-column>
      <el-table-column label="操作对象" prop="type" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">系统配置</span>
          <span v-if="scope.row.type === 2">告警参数</span>
          <span v-if="scope.row.type === 3">电池选项</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="username" width="150" align="center"></el-table-column>
      <el-table-column label="操作时间" width="160" prop="createTime"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";

export default {
  mixins: [ HistoryMixin ],
  data () {
    return {
      table: []
    }
  },
  methods: {
    setDatas () {
      this.table = this.list;
    }
  }
}
</script>