<template>
  <div>
    <el-table :data="table" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }"
          border style="width: 100%;" :height="height"  @selection-change="handleSelectionChange">
          <el-table-column prop="createTime" width="160" label="告警时间" align="center"></el-table-column>
          <el-table-column prop="warningData" label="告警信息" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.count > 0">
                <span v-if="scope.row.warning[0]" class="warning-color">总压过高一级告警 </span>
                <span v-if="scope.row.warning[1]" class="alarm-color">总压过高二级告警 </span>
                <span v-if="scope.row.warning[2]" class="critical-alarm-color">总压过高三级告警 </span>
                <span v-if="scope.row.warning[3]" class="warning-color">总压过低一级告警 </span>
                <span v-if="scope.row.warning[4]" class="alarm-color">总压过低二级告警 </span>
                <span v-if="scope.row.warning[5]" class="critical-alarm-color">总压过低三级告警 </span>
                <span v-if="scope.row.warning[6]" class="warning-color">单体过高一级告警 </span>
                <span v-if="scope.row.warning[7]" class="alarm-color">单体过高二级告警 </span>
                <span v-if="scope.row.warning[8]" class="critical-alarm-color">单体过高三级告警 </span>
                <span v-if="scope.row.warning[9]" class="warning-color">单体过低一级告警 </span>
                <span v-if="scope.row.warning[10]" class="alarm-color">单体过低二级告警 </span>
                <span v-if="scope.row.warning[11]" class="critical-alarm-color">单体过低三级告警 </span>
                <span v-if="scope.row.warning[12]" class="warning-color">高段压差过高一级告警 </span>
                <span v-if="scope.row.warning[13]" class="alarm-color">高段压差过高二级告警 </span>
                <span v-if="scope.row.warning[14]" class="critical-alarm-color">高段压差过高三级告警 </span>
                <span v-if="scope.row.warning[15]" class="warning-color">中段压差过高一级告警 </span>
                <span v-if="scope.row.warning[16]" class="alarm-color">中段压差过高二级告警 </span>
                <span v-if="scope.row.warning[17]" class="critical-alarm-color">中段压差过高三级告警 </span>
                <span v-if="scope.row.warning[18]" class="warning-color">低段压差过高一级告警 </span>
                <span v-if="scope.row.warning[19]" class="alarm-color">低段压差过高二级告警 </span>
                <span v-if="scope.row.warning[20]" class="critical-alarm-color">低段压差过高三级告警 </span>
                <span v-if="scope.row.warning[21]" class="warning-color">辅助压差过高一级告警 </span>
                <span v-if="scope.row.warning[22]" class="alarm-color">辅助压差过高二级告警 </span>
                <span v-if="scope.row.warning[23]" class="critical-alarm-color">辅助压差过高三级告警 </span>
                <span v-if="scope.row.warning[24]" class="warning-color">充电电流过高一级告警 </span>
                <span v-if="scope.row.warning[25]" class="alarm-color">充电电流过高二级告警 </span>
                <span v-if="scope.row.warning[26]" class="critical-alarm-color">充电电流过高三级告警 </span>
                <span v-if="scope.row.warning[27]" class="warning-color">放电电流过高一级告警 </span>
                <span v-if="scope.row.warning[28]" class="alarm-color">放电电流过高二级告警 </span>
                <span v-if="scope.row.warning[29]" class="critical-alarm-color">放电电流过高三级告警 </span>
                <span v-if="scope.row.warning[30]" class="warning-color">反充电流过高一级告警 </span>
                <span v-if="scope.row.warning[31]" class="alarm-color">反充电流过高二级告警 </span>
                <span v-if="scope.row.warning[32]" class="critical-alarm-color">反充电流过高三级告警 </span>
                <span v-if="scope.row.warning[33]" class="warning-color">SOC值过低一级告警 </span>
                <span v-if="scope.row.warning[34]" class="alarm-color">SOC值过低二级告警 </span>
                <span v-if="scope.row.warning[35]" class="critical-alarm-color">SOC值过低三级告警 </span>
                <span v-if="scope.row.warning[36]" class="warning-color">SOH值过低一级告警 </span>
                <span v-if="scope.row.warning[37]" class="alarm-color">SOH值过低二级告警 </span>
                <span v-if="scope.row.warning[38]" class="critical-alarm-color">SOH值过低三级告警 </span>
                <span v-if="scope.row.warning[39]" class="warning-color">继电器温度过高一级告警 </span>
                <span v-if="scope.row.warning[40]" class="alarm-color">继电器温度过高二级告警 </span>
                <span v-if="scope.row.warning[41]" class="critical-alarm-color">继电器温度过高三级告警 </span>
                <span v-if="scope.row.warning[42]" class="warning-color">充电温度过高一级告警 </span>
                <span v-if="scope.row.warning[43]" class="alarm-color">充电温度过高二级告警 </span>
                <span v-if="scope.row.warning[44]" class="critical-alarm-color">充电温度过高三级告警 </span>
                <span v-if="scope.row.warning[45]" class="warning-color">放电温度过高一级告警 </span>
                <span v-if="scope.row.warning[46]" class="alarm-color">放电温度过高二级告警 </span>
                <span v-if="scope.row.warning[47]" class="critical-alarm-color">放电温度过高三级告警 </span>
                <span v-if="scope.row.warning[48]" class="warning-color">充电温度过低一级告警 </span>
                <span v-if="scope.row.warning[49]" class="alarm-color">充电温度过低二级告警 </span>
                <span v-if="scope.row.warning[50]" class="critical-alarm-color">充电温度过低三级告警 </span>
                <span v-if="scope.row.warning[51]" class="warning-color">放电温度过低一级告警 </span>
                <span v-if="scope.row.warning[52]" class="alarm-color">放电温度过低二级告警 </span>
                <span v-if="scope.row.warning[53]" class="critical-alarm-color">放电温度过低三级告警 </span>
                <span v-if="scope.row.warning[54]" class="warning-color">温差过大一级告警 </span>
                <span v-if="scope.row.warning[55]" class="alarm-color">温差过大二级告警 </span>
                <span v-if="scope.row.warning[56]" class="critical-alarm-color">温差过大三级告警 </span>
                <span v-if="scope.row.warning[57]" class="warning-color">均衡温度过高一级告警 </span>
                <span v-if="scope.row.warning[58]" class="alarm-color">均衡温度过高二级告警 </span>
                <span v-if="scope.row.warning[59]" class="critical-alarm-color">均衡温度过高三级告警 </span>
                <span v-if="scope.row.warning[60]" class="warning-color">绝缘电阻过低一级告警 </span>
                <span v-if="scope.row.warning[61]" class="alarm-color">绝缘电阻过低二级告警 </span>
                <span v-if="scope.row.warning[62]" class="critical-alarm-color">绝缘电阻过低三级告警 </span>
                <span v-if="scope.row.warning[63]" class="warning-color">总压采样异常一级告警 </span>
                <span v-if="scope.row.warning[64]" class="alarm-color">总压采样异常二级告警 </span>
                <span v-if="scope.row.warning[65]" class="critical-alarm-color">总压采样异常三级告警 </span>
                <span v-if="scope.row.warning[66]" class="warning-color">静置温度过高一级告警 </span>
                <span v-if="scope.row.warning[67]" class="alarm-color">静置温度过高二级告警 </span>
                <span v-if="scope.row.warning[68]" class="critical-alarm-color">静置温度过高三级告警 </span>
                <span v-if="scope.row.warning[69]" class="warning-color">静置温度过低一级告警 </span>
                <span v-if="scope.row.warning[70]" class="alarm-color">静置温度过低二级告警 </span>
                <span v-if="scope.row.warning[71]" class="critical-alarm-color">静置温度过低三级告警 </span>
                <!-- <span v-if="scope.row.warning[72]">主正接触器无法吸合 </span>
                <span v-if="scope.row.warning[73]">主正接触器粘连故障 </span>
                <span v-if="scope.row.warning[74]">主负接触器无法吸合 </span>
                <span v-if="scope.row.warning[75]">主负接触器粘连故障 </span>
                <span v-if="scope.row.warning[76]">充电接触器无法吸合 </span>
                <span v-if="scope.row.warning[77]">充电接触器粘连故障 </span>
                <span v-if="scope.row.warning[78]">预热接触器无法吸合 </span>
                <span v-if="scope.row.warning[79]">预热接触器粘连故障 </span>
                <span v-if="scope.row.warning[80]">风扇接触器无法吸合 </span>
                <span v-if="scope.row.warning[81]">风扇接触器粘连故障 </span>
                <span v-if="scope.row.warning[82]">预充接触器无法吸合 </span>
                <span v-if="scope.row.warning[83]">预充接触器粘连故障 </span> -->
              </div>
              <divy v-else>设备无告警</divy>
            </template>
          </el-table-column>
          <el-table-column prop="handleStatus" width="80" label="处理状态" align="center">
            <template slot-scope="scope">
              <span :color="handleStatusList[scope.row.handleStatus].color">{{ handleStatusList[scope.row.handleStatus].label }}</span>
            </template>
          </el-table-column>
        </el-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import HistoryMixin from "./mixin";

export default {
  computed: {
    ...mapGetters(['routes', 'user']),
  },
  mixins: [ HistoryMixin ],
  data () {
    return {
      table: [],
      handleStatusList: [ 
        { label: "已完成", color: "#303133" },
        { label: "处理中", color: "#E6A23C" },
        { label: "未处理", color: "#F56C6C" }
      ]
    }
  },
  methods: {
    setDatas () {
      // 这里是有问题的
      const table = this.list.map(row => {
        console.log(row);
        const output = $utils.format3LWarning(row);
        return output;
      });

      this.table = table;
    }
  }
}
</script>