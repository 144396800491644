<template>
  <div style="height: 100%;">
    <device-tabs  :device-info="deviceInfo" :tab-name="tabName" :height="height" @tab-click="onTabClick">
      <template>
        <!-- <el-tab-pane name="basic" label="数据一览" style="height: 100%;">
          <basic v-if="tabName === 'basic'" :device-info="deviceInfo" :height="height" style="height: 100%;" />
        </el-tab-pane> -->
        <el-tab-pane name="settings" label="数据设置" style="height: 100%;">
          <settings v-if="tabName === 'settings'" :device-info="deviceInfo" :height="height" style="height: 100%;" />
        </el-tab-pane>
      </template>
    </device-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import basic from "./basic";
import settings from "./settings";
import DeviceTabs from "./../comnponents/device-tabs";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    basic,
    settings,
    DeviceTabs,
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      tabName: "settings",
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  },
  methods: {
    onTabClick (evt) {
      this.tabName = evt;
    }
  }
}
</script>