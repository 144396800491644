var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "0px 0px 10px 10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.onSetPasswd },
            },
            [_vm._v("设置密码")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.onShowConfigFileDialog()
                },
              },
            },
            [_vm._v("从配置文件中一键配置")]
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "config-name-btn-box" }, [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "config-name-btn",
                  on: {
                    click: function ($event) {
                      return _vm.onShowDialog("system")
                    },
                  },
                },
                [_vm._v("系统参数设置")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "config-name-btn",
                  on: {
                    click: function ($event) {
                      return _vm.onShowDialog("alarm")
                    },
                  },
                },
                [_vm._v("告警参数设置")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "config-name-btn",
                  on: {
                    click: function ($event) {
                      return _vm.onShowDialog("battery")
                    },
                  },
                },
                [_vm._v("电池分布设置")]
              ),
              _vm.deviceInfo.projectType === 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "config-name-btn",
                      on: {
                        click: function ($event) {
                          return _vm.onShowDialog("systemparam")
                        },
                      },
                    },
                    [_vm._v("主控系统参数信息设置")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "系统参数配置",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.system,
            width: "84%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("system", false)
            },
          },
        },
        [
          _c("system", {
            ref: "system",
            attrs: {
              data: _vm.systemData,
              "device-info": _vm.deviceInfo,
              height: _vm.height,
              level: _vm.level,
              "gw-is-new-soft": _vm.gwIsNewSoft,
              "gw-is-new-hard": _vm.gwIsNewHard,
            },
            on: { battery: _vm.onBatteryConfig, save: _vm.onSaveConfig },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "告警参数配置",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.alarm,
            width: "60%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("alarm", false)
            },
          },
        },
        [
          _c("alarm", {
            ref: "alarm",
            attrs: {
              id: _vm.id,
              "device-info": _vm.deviceInfo,
              height: _vm.height,
              level: _vm.level,
              data: _vm.systemData,
              "is-new-ver": _vm.gwIsNewSoft,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "电压温度显示",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.viewvt,
            width: "84%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("viewvt")
            },
          },
        },
        [
          _c("viewvt", {
            ref: "viewvt",
            attrs: {
              id: _vm.id,
              "device-info": _vm.deviceInfo,
              height: _vm.height - 50,
              level: _vm.level,
              visible: true,
              "is-new-ver": _vm.gwIsNewSoft,
              data: _vm.systemData,
              vLen: _vm.voltageParams.length,
              tLen: _vm.templateParams.length,
            },
            on: { config: _vm.onConfigBattery },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "告警标志显示",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.viewalarm,
            width: "84%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("viewalarm")
            },
          },
        },
        [
          _c("viewalarm", {
            ref: "viewalarm",
            attrs: {
              id: _vm.id,
              "device-info": _vm.deviceInfo,
              height: _vm.height - 100,
              level: _vm.level,
              data: _vm.systemData,
              "is-new-ver": _vm.gwIsNewSoft,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "电池分布设置",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.battery,
            width: "90%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("battery")
            },
          },
        },
        [
          _c("battery", {
            ref: "battery",
            attrs: {
              id: _vm.id,
              mask: _vm.mask,
              "device-info": _vm.deviceInfo,
              height: _vm.height - 50,
              level: _vm.level,
              "is-new-ver": _vm.gwIsNewSoft,
              data: _vm.systemData,
              vLen: _vm.voltageParams.length,
              tLen: _vm.templateParams.length,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置密码",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.password,
            width: "450px",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("password")
            },
          },
        },
        [_c("password", { on: { close: _vm.setPassword } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "从配置文件中一键配置",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.configFile,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("configFile")
            },
          },
        },
        [
          _c("config-from-file", {
            ref: "configFile",
            attrs: { "device-info": _vm.deviceInfo },
            on: { "get-configs": _vm.onGetConfigs },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "主控系统参数信息设置",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.systemparam,
            "append-to-body": "",
            width: "84%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("systemparam")
            },
          },
        },
        [
          _c("SystemParam", {
            ref: "systemparam",
            attrs: { "device-info": _vm.deviceInfo },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }