import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-MainBoardVoltage/list?${rand}`, { params });
}

export const detail = (code) => {
    const rand = Date.now();
    return request.get(`module-MainBoardVoltage/Voltage/${code}/?${rand}`);
}

export const save = (data) => {
    const rand = Date.now();
    return request.get(`module-MainBoardVoltage/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.get(`module-MainBoardVoltage/update?${rand}`, data);
}

export default {
    list,
    save,
    update,
    detail,
}