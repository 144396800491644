var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-descriptions",
        {
          attrs: {
            title: "设备基本信息",
            column: 1,
            "label-class-name": "descriptions-label-box",
            border: "",
            "content-class-name": "descriptions-content-box",
            size: "small",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "设备名称" } }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.deviceInfo.information || _vm.deviceInfo.code) +
                  " "
              ),
            ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "设备类型" } }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    (
                      _vm.proTypeList[_vm.deviceInfo.projectType] || {
                        label: "无数据",
                      }
                    ).label
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "设备软件版本" } }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(" " + _vm._s(_vm.deviceInfo.version || "无数据") + " "),
            ]),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "设备编号" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini" },
                  on: { change: _vm.onOpenGroup },
                  model: {
                    value: _vm.selectedDeviceGroup,
                    callback: function ($$v) {
                      _vm.selectedDeviceGroup = $$v
                    },
                    expression: "selectedDeviceGroup",
                  },
                },
                _vm._l(_vm.deviceInfo.deviceGroups, function (code) {
                  return _c("el-option", {
                    key: code,
                    attrs: { label: code, value: code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.deviceInfo.projectType === 0
            ? _c(
                "el-descriptions-item",
                { attrs: { label: "主控地址" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini" },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.selectMasterID,
                        callback: function ($$v) {
                          _vm.selectMasterID = $$v
                        },
                        expression: "selectMasterID",
                      },
                    },
                    _vm._l(_vm.masterIds, function (masterId) {
                      return _c("el-option", {
                        key: masterId,
                        attrs: { label: masterId, value: masterId },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-descriptions-item",
            { attrs: { label: "所属公司" } },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    icon: "el-icon-office-building",
                    type: "primary",
                    href: `/#/company/detail/${_vm.deviceInfo.businessId}`,
                  },
                },
                [_vm._v(_vm._s(_vm.deviceInfo.businessName))]
              ),
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "所属站点" } }, [
            _vm.deviceInfo.site
              ? _c(
                  "div",
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          icon: "el-icon-map-location",
                          type: "primary",
                          href: `/#/site/detail/${_vm.deviceInfo.site}`,
                        },
                      },
                      [_vm._v(_vm._s(_vm.deviceInfo.siteName))]
                    ),
                  ],
                  1
                )
              : _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("暂未关联站点"),
                ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "在线状态" } }, [
            _vm.utils.isProductionMode()
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm.deviceInfo.online
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: "success",
                                size: "mini",
                                effect: "dark",
                              },
                            },
                            [_vm._v("在线")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "small",
                                type: "info",
                                effect: "dark",
                              },
                            },
                            [_vm._v("离线")]
                          ),
                        ],
                        1
                      ),
                ])
              : _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm.deviceInfo.code === "000000"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                effect: "dark",
                              },
                            },
                            [_vm._v("在线")]
                          ),
                        ],
                        1
                      )
                    : _vm.deviceInfo.online
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                effect: "dark",
                              },
                            },
                            [_vm._v("在线")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                size: "small",
                                type: "info",
                                effect: "dark",
                              },
                            },
                            [_vm._v("离线")]
                          ),
                        ],
                        1
                      ),
                ]),
          ]),
          _c("el-descriptions-item", { attrs: { label: "升级状态" } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-tag",
                  {
                    class: (
                      _vm.statusTxt[_vm.deviceInfo.type] || { tag: "无数据" }
                    ).tag,
                    attrs: {
                      type: (
                        _vm.statusTxt[_vm.deviceInfo.type] || { tag: "无数据" }
                      ).tag,
                      size: "small",
                      effect: "dark",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        (
                          _vm.statusTxt[_vm.deviceInfo.type] || {
                            label: "无数据",
                          }
                        ).label
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "上次升级时间" } }, [
            _vm.deviceInfo.gmtModify
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.utils.formatTime(_vm.deviceInfo.gmtModify)) +
                      " "
                  ),
                ])
              : _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("无数据"),
                ]),
          ]),
          _vm.deviceInfo.projectType === 0
            ? _c("el-descriptions-item", { attrs: { label: "告警状态" } }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _vm.deviceInfo.alarm
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "alarm-tag-box",
                            attrs: {
                              type: "danger",
                              size: "small",
                              effect: "dark",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onShowAlarm()
                              },
                            },
                          },
                          [_vm._v("告警")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              size: "small",
                              type: "success",
                              effect: "dark",
                            },
                          },
                          [_vm._v("正常")]
                        ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "LTE信号" } }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _vm._v(" " + _vm._s(_vm.basicData.lte || "无数据") + " "),
            ]),
          ]),
          _vm.user.tenantId === 0
            ? _c("el-descriptions-item", { attrs: { label: "网关硬件版本" } }, [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.basicData.gwHardVer || "无数据") + " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.user.tenantId === 0
            ? _c("el-descriptions-item", { attrs: { label: "网关软件版本" } }, [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.basicData.gwSoftVer || "无数据") + " "
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogVisible.alarm
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "设备告警信息",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.alarm,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("alarm")
                },
              },
            },
            [
              _c("alarm", {
                ref: "alarm",
                attrs: { "device-info": _vm.deviceInfo },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }