import SystemWriteApi from "@/api/470/SystemWrite";
import { checkResult } from "@/utils/device";
import { getObjByAddress } from "./address-configs";
import { checkObjectIsNotEmpty } from "./utils";
const { calcHexFromUnsignedDec } = $numberMatrixing;

// 下发指令
export async function write(params) {
  const res = await SystemWriteApi.write(params);
  checkResult(this, res, { error: true, success: true, type: "write" }, async (valid) => {
    if (valid) {
      await SystemWriteApi.save(params);
    }
  });
}

export const objectToAddrVal = (start, size, object) => {

  const output = [];

  for (let i = 0; i < size; i++) {
    const addr = calcHexFromUnsignedDec(start + i);
    let value = "0000";
    const obj = getObjByAddress(addr);
    if (obj && obj.name) {
      value = object[obj.name];
      if (checkObjectIsNotEmpty(value)) {
        const method = obj.type ? "calcHexFromUnsignedDec": "calcHexFromSignedDec";
        value = $numberMatrixing[method](value, 4);
      }
    } else {
      const key = `addr_${addr}`;
      value = object[key];
    }

    value = value.toUpperCase();
    output.push(value);
  }

  return output;
}

function parse (start, size, str, toDecFlg) {

  const output = {};

  for (let i = 0; i < size; i++) {
    let value = str.slice(i * 4, (i + 1) * 4);
    const addr = calcHexFromUnsignedDec(start++);
    const obj = getObjByAddress(addr);

    if (obj && obj.name) {
      if (toDecFlg) {
        const method = obj.type ? "calcUnsignedDecFromHex": "calcSignedDecFromHex";
        const val = $utils.checkObjectIsNotEmpty(value) ? $numberMatrixing[method](value, length) : "";
        output[obj.name] = val;
      } else {
        output[obj.name] = value;
      }
    } else {
      output[`addr_${addr}`] = value;
    }
  }

  return output;
}

export default {
  write,
  objectToAddrVal,
  parse
}