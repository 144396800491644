var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "device-tabs",
        {
          attrs: {
            "device-info": _vm.deviceInfo,
            "tab-name": _vm.tabName,
            height: _vm.height,
          },
          on: { "tab-click": _vm.onTabClick },
        },
        [
          [
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "basic", label: "实时数据" },
              },
              [
                _vm.tabName === "basic"
                  ? _c("detail", {
                      staticStyle: { height: "100%" },
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "viewvt", label: "电压温度" },
              },
              [
                _vm.tabName === "viewvt"
                  ? _c("viewvt", {
                      ref: "vt",
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "device-alarm", label: "设备告警" },
              },
              [
                _vm.tabName === "device-alarm"
                  ? _c("device-alarm", {
                      ref: "alarm",
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "peak-valley", label: "尖峰平谷" },
              },
              [
                _vm.tabName === "peak-valley"
                  ? _c("peak-valley", {
                      ref: "peakvalley",
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              {
                staticStyle: { height: "100%" },
                attrs: { name: "history-data", label: "历史数据" },
              },
              [
                _vm.tabName === "history-data"
                  ? _c("history-data", {
                      ref: "history",
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }