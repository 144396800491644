<template>
	<div v-loading="loading">
    <el-descriptions title="设备基本信息" :column="1" label-class-name="descriptions-label-box" border content-class-name="descriptions-content-box"  size="small">
    <el-descriptions-item label="设备名称">
      <div style="text-align: center;">
        {{ deviceInfo.information || deviceInfo.code }}
      </div>
      </el-descriptions-item>
    <el-descriptions-item label="设备类型">
      <div style="text-align: center;">
        {{ (proTypeList[deviceInfo.projectType] || {label: "无数据"}).label }}
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="设备软件版本">
      <div style="text-align: center;">
        {{ deviceInfo.version || "无数据" }}
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="设备编号">
      <el-select v-model="selectedDeviceGroup" size="mini" @change="onOpenGroup">
        <el-option v-for="code in deviceInfo.deviceGroups" :key="code" :label="code" :value="code"></el-option>
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label="主控地址" v-if="deviceInfo.projectType === 0">
      <el-select v-model="selectMasterID" size="mini" @change="onChange">
        <el-option v-for="masterId in masterIds" :key="masterId" :label="masterId" :value="masterId"></el-option>
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label="所属公司">
      <el-link icon="el-icon-office-building" type="primary" :href="`/#/company/detail/${deviceInfo.businessId}`">{{ deviceInfo.businessName }}</el-link>
    </el-descriptions-item>
    <el-descriptions-item label="所属站点">
      <div v-if="deviceInfo.site">
        <el-link icon="el-icon-map-location" type="primary" :href="`/#/site/detail/${deviceInfo.site}`">{{ deviceInfo.siteName }}</el-link>
      </div>
      <div v-else style="text-align: center;">暂未关联站点</div>
    </el-descriptions-item>
    <el-descriptions-item label="在线状态">
      <div v-if="utils.isProductionMode()" style="text-align: center;">
        <div v-if="deviceInfo.online">
          <el-tag type="success" size="mini" effect="dark">在线</el-tag>
        </div>
        <div v-else>
          <el-tag size="small" type="info" effect="dark">离线</el-tag>
        </div>
      </div>
      <div v-else style="text-align: center;">
        <div v-if="deviceInfo.code === '000000'">
          <el-tag type="success" size="small" effect="dark">在线</el-tag>
        </div>
        <div v-else-if="deviceInfo.online">
          <el-tag type="success" size="small" effect="dark">在线</el-tag>
        </div>
        <div v-else>
          <el-tag size="small" type="info" effect="dark">离线</el-tag>
        </div>
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="升级状态">
      <div style="text-align: center;">
        <el-tag :type="(statusTxt[deviceInfo.type] || { tag : '无数据'}).tag"
                :class="(statusTxt[deviceInfo.type] || { tag : '无数据'}).tag"
                size="small" effect="dark">{{ (statusTxt[deviceInfo.type] || {label: "无数据"}).label }}
        </el-tag>
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="上次升级时间">
      <div v-if="deviceInfo.gmtModify" style="text-align: center;">
      {{ utils.formatTime(deviceInfo.gmtModify) }}
      </div>
      <div v-else style="text-align: center;">无数据</div>
    </el-descriptions-item>
    <el-descriptions-item label="告警状态" v-if="deviceInfo.projectType === 0">
      <div style="text-align: center;">
        <el-tag v-if="deviceInfo.alarm" class="alarm-tag-box" type="danger" size="small" @click="onShowAlarm()" effect="dark">告警</el-tag>
        <el-tag v-else size="small" type="success" effect="dark">正常</el-tag>
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="LTE信号">
      <div style="text-align: center;">
        {{ basicData.lte || "无数据" }}
      </div>
      </el-descriptions-item>
    <el-descriptions-item v-if="user.tenantId === 0" label="网关硬件版本">
      <div style="text-align: center;">
        {{ basicData.gwHardVer || "无数据" }}
      </div>
    </el-descriptions-item>
    <el-descriptions-item v-if="user.tenantId === 0" label="网关软件版本">
      <div style="text-align: center;">
        {{ basicData.gwSoftVer || "无数据" }}
      </div>
    </el-descriptions-item>
    </el-descriptions>
    <el-dialog title="设备告警信息" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.alarm" :visible="dialogVisible.alarm"
      @close="onCloseDialog('alarm')" width="600px">
      <alarm ref="alarm" :device-info="deviceInfo"></alarm>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { systemConfig } from "@/api/device";
import { addrValToObject, checkResult } from "@/utils/device";
import alarm from "@/components/device-table/alarm";

const { calcHexFromUnsignedDec, hexAppendZero } = $numberMatrixing;

export default {
  components: {
    alarm
  },
	props: {
		deviceInfo: undefined,
		data: {
			type: Object,
			default: {}
		}
	},
  computed: {
    ...mapGetters(['user'])
  },
	data () {
		return {
      basicData: {},
      loading: false,
      utils: $utils,
      dialogVisible: {
        alarm: false
      },
      selectMasterID: "",
      masterIds: [],
      selectedDeviceGroup: "",
      proTypeList: [].concat($statics.DEVICE_PROJECT_TYPE_LIST),
      statusTxt: $statics.FIRMWARE_UPGRADE_STATUS_LIST
		}
	},
  watch: {
    "masterID": function (v1, v2) {
      this.selectMasterID = this.deviceInfo.masterID = v1;
    },
    "selectMasterID": function(v1, v2) {
      if (v1 === undefined || v1 === null || v1 === "undefined" || v1 === "null") {
        v1 = "00";
      }
      this.selectMasterID = this.deviceInfo.masterID = v1;
      sessionStorage.setItem(`using-master-id:${this.deviceInfo.code}`, this.selectMasterID.toUpperCase());
    },
    "deviceInfo.systeminfo": function(v1, v2) {
      const data = addrValToObject(v1);
      this.initBasicData(data);
    }
  },
  created () {
    console.log("device-detail-basic");
    this.onChangeGroup();
  },
	methods: {
    loadData () {
      let masterID = sessionStorage.getItem(`using-master-id:${this.deviceInfo.code}`);
      const data = addrValToObject(this.deviceInfo.systeminfo);
      if (masterID === null) {
        if (data.masterCnts > 0) {
          this.selectMasterID = data.masterID0;
        }
        sessionStorage.setItem(`using-master-id:${this.deviceInfo.code}`, data.masterID0);
      } else {
        this.selectMasterID = masterID;
      }
      this.initBasicData(data);
    },
    // 设备基本信息（LTE、网关软硬件版本号）
    initBasicData (object) {
      const gwSoftVer1 = Number(calcHexFromUnsignedDec(object.gwSoftVer1 || "0"));
      const gwSoftVer2 = hexAppendZero(calcHexFromUnsignedDec(object.gwSoftVer2 || "0"));
      const gwSoftVerMinor = Number(gwSoftVer2.slice(0, 2));
      const gwSoftVerBuild = Number(gwSoftVer2.slice(2));
      const gwHardVer1 = Number(calcHexFromUnsignedDec(object.gwHardVer1 || "0"));
      const gwHardVer2 = hexAppendZero(calcHexFromUnsignedDec(object.gwHardVer2 || "0"));
      const gwHardVerMinor = Number(gwHardVer2.slice(0, 2));
      const gwHardVerBuild = Number(gwHardVer2.slice(2));

      object.gwSoftVer = `${gwSoftVer1}.${gwSoftVerMinor}.${gwSoftVerBuild}`;
      object.gwHardVer = `${gwHardVer1}.${gwHardVerMinor}.${gwHardVerBuild}`;

      sessionStorage.setItem("gwSoftVerNum", gwSoftVer1 * 10000 + gwSoftVerMinor * 10000 + gwSoftVerBuild);

      if (object.masterCnts > 31) {
        object.masterCnts = 31;
      }

      const masterIds = [];
      for (let i = 0;i < object.masterCnts; i++) {
        masterIds.push((object[`masterID${i}`]));
      }

      this.deviceInfo.masterIds = this.masterIds = masterIds;
      this.deviceInfo.masterCnts = object.masterCnts;
      if (masterIds.length > 0) {
        this.selectMasterID = masterIds[0];
      }

      delete object.gwSoftVer1;
      delete object.gwSoftVer2;
      delete object.gwHardVer1;
      delete object.gwHardVer2;
      delete object.activeReporingTime1;
      delete object.activeReporingTime2;

      this.basicData = object;
      this.basicData.uploadTime = $utils.formatTime();
    },
    // 从设备中读取（手动刷新）
    async onReadBasicData () {
      this.loading = true;
      // this.clearInterval();
      await this.onReloadBasicData(true);
      // this.setInterval();
      this.loading = false;
    },
    onShowAlarm () {
      this.dialogVisible.alarm = true;
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
    async onReloadBasicData (flag) {
      try {
        $utils.setDeviceDoing(this.deviceInfo);
        const type = "read";
        const res = await systemConfig[type]({
          masterID: this.deviceInfo.masterID,
          code: this.deviceInfo.code,
          price: [
            "280F" /* lte */, "2810" /* gwSoftVer1 */, "2811" /* gwSoftVer2 */, "2812" /* gwHardVer1 */, "2813" /* gwHardVer2 */
          ].join("")
        });

        checkResult(this, res, { error: flag, type }, valid => {
          if (valid) {
            const object = addrValToObject(res);
            this.initBasicData(object);
          }
        });
      } finally {
        $utils.setDeviceDone(this.deviceInfo);
      }
    },
    onChange (arg) {
      const output = calcHexFromUnsignedDec(arg, 2);
      this.deviceInfo.masterID = output.toUpperCase();
      sessionStorage.setItem(`using-master-id:${this.deviceInfo.code}`, output);
      this.$emit("use");
    },
    onChangeGroup () {
      this.selectedDeviceGroup = this.deviceInfo.code;
    },
    onOpenGroup () {
      this.$router.push({ path: `/device/deviceDetail/${this.selectedDeviceGroup}` });
      this.onChangeGroup();
    }
	}
}
</script>
<style lang="scss" scoped>
.alarm-tag-box {
  cursor: pointer;
}

.device-base-data-box {
  padding: 12px;
  background: #e7eff7;
}
</style>