<template>
  <div class="content">
    <div>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 1 || deviceInfo.projectType === 6"
        :type="btnType.voltage" size="mini" @click='onChangeList("voltage")'>单体电压</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 1 || deviceInfo.projectType === 6"
        :type="btnType.temperature" size="mini" @click='onChangeList("temperature")'>单体温度</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 1 || deviceInfo.projectType === 6"
        :type="btnType.peakValley" size="mini" @click='onChangeList("peakValley")'>尖峰平谷</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 0"
        :type="btnType.hostVoltage" size="mini" @click='onChangeList("hostVoltage")'>单体电压</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 0"
        :type="btnType.hostTemperature" size="mini" @click='onChangeList("hostTemperature")'>单体温度</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 0"
        :type="btnType.rackAlarm" size="mini" @click='onChangeList("rackAlarm")'>Rack 告警</el-button>
      <el-button :disabled="loading" v-if="deviceInfo.projectType === 0"
        :type="btnType.masterAlarm" size="mini" @click='onChangeList("masterAlarm")'>设备告警</el-button>
      <!-- <el-button v-if="deviceInfo.projectType === 1" :type="btnType.logassemble" size="mini" @click='onChangeList("logassemble")'>总控数据</el-button> -->
      <!-- <el-button :disabled="loading" :type="btnType.soc" size="mini" @click='onChangeList("soc")'>SOC</el-button>
      <el-button :disabled="loading" :type="btnType.ota" size="mini" @click='onChangeList("ota")'>升级记录</el-button> -->
      <el-dropdown v-if="isAdmin" @command="onConfig">
        <el-button size="mini" style="margin-left: 10px; width:100%" :type="btnType.configs" :disabled="loading || utils.userReadOnly(user)">{{ configBtnName }}</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1" :disabled="utils.userReadOnly(user)">系统参数</el-dropdown-item>
          <el-dropdown-item command="2" :disabled="utils.userReadOnly(user)">告警参数</el-dropdown-item>
          <el-dropdown-item command="3" :disabled="utils.userReadOnly(user)">电池选项</el-dropdown-item>
          <el-dropdown-item command="0" :disabled="utils.userReadOnly(user)">所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-form label-width="80">
      <el-row :span="24">
        <el-col :span="12">
          <el-form-item label="查询时间">
            <el-date-picker
              size="mini"
              v-model="timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              @change="onSearch"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="right-btns">
            <el-button icon="el-icon-search" class="search-box-btn" type="primary" size="mini" @click="onSearch">查询</el-button>
            <!--<el-dropdown class="search-box-btn" @command="onExport">
              <el-button icon="el-icon-printer" type="primary" size="mini" style="width:100%" :disabled="utils.userReadOnly(user) || !canExport">导出</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="selected" v-if="nowType === 'ota'" :disabled="utils.userReadOnly(user)">导出所选</el-dropdown-item>
                <el-dropdown-item command="thispage" :disabled="utils.userReadOnly(user)">导出当前页</el-dropdown-item>
                <el-dropdown-item command="all" :disabled="utils.userReadOnly(user)">导出所有</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>-->
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div class="data-table" :style="'height:' + tableHeight + 'px;'" v-loading="loading">
          <voltage ref="voltage" v-if="visible.voltage || visible.hostVoltage" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <temperature ref="temperature" v-if="visible.temperature" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <soc ref="soc" v-if="visible.soc" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <ota ref="ota" v-if="visible.ota" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <rack-alarm ref="rackAlarm" v-if="visible.rackAlarm" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <master-alarm ref="masterAlarm" v-if="visible.masterAlarm" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <rack ref="rack" v-if="visible.rack" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <configs ref="configs" v-if="visible.configs" :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <totalconsole ref="totalconsole" v-if="visible.totalconsole && (deviceInfo.projectType === 1 || this.deviceInfo.projectType === 6)"
            :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
          <peak-valley ref="peakValley" v-if="visible.peakValley && (deviceInfo.projectType === 1 || this.deviceInfo.projectType === 6)"
            :height="tableHeight" :page="pageData" :list="list" @select="onSelect" />
      </div>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper" :page-size="pageData.size"
        :page-sizes="[ 50, 100, 200, 500 ]" @size-change="onSizeChange"
        :total="pageData.total" :pager-count="20" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import logApi from "@/api/470/log/index";
import { mapGetters } from 'vuex';

import voltage from "./voltage";
import temperature from "./temperature";
import totalconsole from "./totalconsole";
import soc from "./soc";
import configs from "./configs";
import ota from "./ota";
import RackAlarm from "./rack-alarm";
import masterAlarm from "./master-alarm";
import rack from "./rack";
import PeakValley from "./peak-valley";

import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

export default {
  name: 'historyCharts',
  mixins: [ TimerPickerSettingsMixin ],
  components: {
    voltage,
    temperature,
    totalconsole,
    RackAlarm,
    rack,
    soc,
    configs,
    masterAlarm,
    ota,
    PeakValley
    // logassemble
  },
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    deviceInfo: {
      type: Object,
      default: null
    },
    isSmallScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      utils: $utils,
      searchTime: null,
      nowType: "voltage",
      loading: false,
      canExport: false,
      isEmpty: true,
      tableHeight: 100,
      btnType: {
        voltage: "primary",
        temperature: "",
        // assemblevoltage: "",
        // assembletemperature: "",
        totalconsole: "",
        logassemble: "",
        rackAlarm: "",
        soc: "",
        gps: "",
        configs: "",
        peakValley: "",
        rackAlarm: "",
        masterAlarm: ""
      },
      visible: {
        voltage: true,
        temperature: false,
        rackAlarm: false,
        // assemblevoltage: false,
        // assembletemperature: false,
        totalconsole: false,
        logassemble: false,
        soc: false,
        gps: false,
        configs:  false,
        ota: false,
        peakValley: false
      },
      isFHD: false,
      configBtnName: "参数配置",
      code: "",
      command: "",
      list: [],
      form: {},
      pageData: {
        current: 1,
        size: 50,
        total: 0
      },
      multipleSelection: [],
      isAdmin: false
    };
  },
  created () {
    console.log("history-data");
    this.isFHD = window.outerWidth <= 1920;
    this.tableHeight = document.body.scrollHeight - 270;
    this.isAdmin = this.$route.name === "admin-device-detail";

    if (this.deviceInfo.projectType === 0) {
      // this.onChangeList("hostVoltage");
      this.btnType.hostVoltage = "primary";
      this.btnType.voltage = "";
      this.nowType = "hostVoltage";
    }

    this.getDatas();
  },
  methods: {
    onClosed () {
      return true;
    },
    getRefName () {
      switch (this.nowType) {
        case "voltage":
        case "hostVoltage":
          return "voltage";
        case "temperature":
        case "hostTemperature":
          return "temperature";
        default:
        return this.nowType;
      }
    },
    async getDatas () {
      const api = logApi[this.nowType];
      const typeName = this.getTypeName();
      try {
        this.loading = true;

        const res = await api.list(this.getParams());
        this.isEmpty = false;
        this.$forceUpdate();
        this.$message.success(`历史数据（${typeName}）读取成功`);
        this.$nextTick(() => {
          const refName = this.getRefName();
          this.$refs[refName].setData(res.data);
          if (this.nowType === "peakValley") {
              this.pageData.current = res.currentPage;
              this.pageData.size = res.pageSize;
              this.pageData.total = res.totalCount;
          } else {
            this.pageData = res.paging;
          }
          this.loading = false;
        });
      } catch (e) {
        this.$message.warning(`历史数据（${typeName}）读取失败`);
        this.isEmpty = true;
        this.pageData = {
          current: 1,
          size: 50,
          total: 0
        }
      } finally {
        this.loading = false;
        this.canExport = !!api.export;
        for (const key in this.visible) {
          this.visible[key] = false;
        }
        const refName = this.getRefName();
        this.visible[refName] = true;
        this.$forceUpdate();
      }
    },
    getParams (ids, page, size) {

      const params = this.nowType === "peakValley" ?
        {
          code: this.deviceInfo.code,
          // createTime: $utils.formatTime(this.timeVal, "YYYY-MM")
          // page: this.pageData.size * (this.pageData.current - 1),
          page: this.pageData.current,
          size: this.pageData.size
        }
        : Object.assign({
          // ids,
          type: this.command === "0" ? "" : this.command,
          code: this.deviceInfo.code,
          page: page || this.pageData.current,
          size: size || this.pageData.size,
        }, this.form);

      if (ids) {
        params.ids = ids;
      }

      // 如果时间没有数据，则把时间设置为最近一周
      // 这么设置的原因是后台如果不传入时间可能会有奇怪的问题
      if (this.nowType !== "ota" && this.timeRange === null) { 
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - 7);
        this.timeRange = [weekAgo, new Date()];
      }

      if (this.timeRange !== null) { 
        params.createTime = $utils.formatTime(this.timeRange[0], "YYYY-MM-DD HH:mm:ss");
        params.updateTime = $utils.formatTime(this.timeRange[1], "YYYY-MM-DD HH:mm:ss");
      }

      return params;
    },
    getTypeName () {
      switch (this.nowType) {
        case "voltage":
        case "hostVoltage":
          return "单体电压";
        case "temperature":
        case "hostTemperature":
          return "单体温度";
        case "peakValley":
          return "尖峰平谷";
        case "rackAlarm":
          return "Rack 告警";
        case "masterAlarm":
          return "设备告警";
      }
    },
    onChangeList (name) {

      if (name === this.nowType && name !== "configs") {
        return;
      }

      for (let k in this.btnType) {
        this.btnType[k] = "";
        this.visible[k] = false;
      }
      this.btnType[name] = "primary";
      this.visible[name] = true;
      this.nowType = name;

      if (name === "ota") {
        this.timeRange = null;
      }

      this.pageChange(1);
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.pageData.size = this.pageData.size;
      this.getDatas();
    },
    onSearch () {
      this.pageChange(1);
    },
    onReset () {
      this.bmuNo = "";
      this.timeRange = [];
    },
    async doExport (ids, page, size) {
      this.loading = true;
      const res = await logApi[this.nowType].export(this.getParams(ids, page, size));
      $utils.exportExcel(res);
      this.loading = false;
    },
    getApiType () {
      let apiType = this.nowType;
      if (apiType === "soc") {
        if (this.deviceInfo.projectType === 0) {
          apiType = "masterSoc";
        } else if (this.deviceInfo.projectType === 1 || this.deviceInfo.projectType === 6) {
          apiType = "assembleSoc";
        }
      }

      return apiType;
    },
    async onConfig (command) {
      this.command = command;
      switch (command) {
        case "0":
          this.configBtnName = "参数配置";
          break;
        case "1":
          this.configBtnName = "系统参数";
          break;
        case "2":
          this.configBtnName = "告警参数";
          break;
        case "3":
          this.configBtnName = "电池选项";
          break;
      }
      this.onChangeList("configs");
    },
    onExport (command) {
      switch (command) {
        case "selected":

          const txt = this.multipleSelection.length === 0 ?
              "您没有选择想要导出报表的设备，是否导出当前页的报表？" :
              "您是否要导出您所选择的这些设备的报表？";

          this.$confirm(txt, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(async () => {
                const ids = (this.multipleSelection.length === 0) ? 
                    this.list.map(obj => obj.id) :
                    this.multipleSelection.map(obj => obj.id);

                this.doExport(ids.join());
              })
              .catch((e) => {
              });

          break;
        case "thispage":
          const ids = this.list.map(obj => obj.id);

          this.doExport(ids.join());
          break;
        case "all":
          this.doExport(undefined, 1, this.pageData.total);
          break;
      }
    },
    onSelect (val) {
      this.form = val;
      this.onSearch();
    }
  }
};
</script>
<style scoped lang="scss">
.data-table {
  overflow: hidden;
}
</style>