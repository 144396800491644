<template>
  <div>
    <div class="evt-box">
      <div class="evt-btns-box">
        <el-button class="evt-btns" icon="el-icon-upload2" :disabled="utils.userReadOnly(user)"
          type="primary" size="mini" @click="onUpgrade">设备升级</el-button>
      </div>
      <div class="evt-btns-box">
        <el-button class="evt-btns" icon="el-icon-d-arrow-right" :disabled="utils.userReadOnly(user)"
          type="primary" size="mini" @click="onShowDialog('synchronizetime')">时间同步</el-button>
      </div>
    </div>
    <el-dialog title="时间同步" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.synchronizetime" :visible="dialogVisible.synchronizetime"
      @close="onCloseDialog('synchronizetime')" width="600px">
      <synchronize-time ref="synchronizetime" :device-info="deviceInfo"></synchronize-time>
    </el-dialog>
    <el-dialog :title="upgradeTitle" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.upgrade" :visible="dialogVisible.upgrade" width="600px"
      @close="onCloseDialog('upgrade')"
    >
      <upgrade ref="upgrade" :data="rowData" :device-info="deviceInfo" :type="type" :visible="dialogVisible.upgrade"
        @close-edit-modal="cb => onCloseDialog('upgrade', true, cb)" />
    </el-dialog>
    <two-select-dialog ref="twoSelect" @left="upgrade(false)" @right="upgrade(true)" :options="options" @close="onClose">
      <danger-event-ask ref="dangerEventAsk" :message="`为当前设备（设备编号：${this.deviceInfo.code}）进行升级`" />
    </two-select-dialog>
  </div>
</template>
<script>
import TwoSelectDialog from "@/components/two-select-dialog";
import DangerEventAsk from "@/components/danger-event-ask";
import SynchronizeTime from "./events/synchronize-time";
import upgrade from "./events/upgrade";

export default {
  components: {
    TwoSelectDialog,
    DangerEventAsk,
    SynchronizeTime,
    upgrade
  },
  props: {
    deviceInfo: undefined,
  },
  data () {
    return {
      utils:  $utils,
      options: {
        confirmButtonText: "",
        cancelButtonText: "",
        title: "请问您要升级网关还是设备的固件？"
      },
      dialogVisible: {
        synchronizetime: false,
        upgrade: false
      }
    }
  },
  methods: {
    onShowDialog (name) {
      this.dialogVisible[name] = true;
    },
    async onCloseDialog (refName, reloadFlg, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
    upgrade (flg) { // true: 网关，false：设备
      this.$refs.dangerEventAsk.validate(() => {
        let name = this.deviceInfo.projectType === 2 ? "保护板" : "";
        name =  flg ? `${name}网关固件` : `${name}设备固件`;

        this.rowData = this.deviceInfo;
        this.dialogVisible.upgrade = true;
        this.$refs.twoSelect.setVisible(false);
        this.upgradeTitle = `升级${name}`;

        // 当设备类型为网关时，固件类型固定为1（不管那种设备）
        // 其他情况下，再选择对应表里的值
        const list = [].concat($statics.PROJECT_TYPE_LIST);
        list.splice(5, 0, {});
        this.type = flg ? 1 : list[this.deviceInfo.projectType].firmware;
      });
    },
    onUpgrade () {
      this.$refs.twoSelect.setVisible(true);
      // 因为现在系统中对固件类型为保护板时，需要写出“保护板”三个字，其他可以直接写“设备”
      if (this.deviceInfo.projectType === 2) {
        this.options.confirmButtonText = '保护板网关固件';
        this.options.cancelButtonText = '保护板设备固件';
      } else {
        this.options.confirmButtonText = '网关固件';
        this.options.cancelButtonText = '设备固件';
      }

      if (this.$refs.dangerEventAsk) {
        this.$refs.dangerEventAsk.clear();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.evt-box {
  >div {
    display: inline-block;
    vertical-align: top;
  }

  .evt-btns-box {
    width: calc(12.25% - 20px);
    padding: 15px;
    padding-right: 0px;
    .evt-btns {
      width: 100%;
    }
  }
}
</style>