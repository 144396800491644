var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-comment-box",
      attrs: {
        title: _vm.options.title,
        visible: _vm.centerDialogVisible,
        "destroy-on-close": "",
        "close-on-press-escape": false,
        "show-close": "",
        "close-on-click-modal": true,
        width: _vm.width,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.centerDialogVisible = $event
        },
        close: _vm.onClose,
      },
    },
    [
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.onCancel },
            },
            [_vm._v(_vm._s(_vm.options.cancelButtonText))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.onCommit },
            },
            [_vm._v(_vm._s(_vm.options.confirmButtonText))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }