<template>
  <div v-loading="loading">
    <el-form label-position="left" label-width="100">
      <el-form-item label="选择文件">
        <el-select size="mini" v-model="form.fileName" @change="onChange">
          <el-tooltip v-for="file in fileList" :key="file.id" class="item" effect="dark" :content="file.description || '无说明'" placement="right">
            <el-option :label="file.fileName" :value="file.fileName" />
          </el-tooltip>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="right-btns m-t-10">
      <el-button size="mini" type="primary" @click="onSubmit">选择</el-button>
    </div>
  </div>
</template>
<script>
import { fileList, downloadTestLog } from "@/api/device";

export default {
  data () {
    return {
      loading: false,
      fileList: [],
      pageData: {
        current: 1,
        size: 999999,
        total: 0
      },
      form: {
        fileName: ""
      }
    }
  },
  created () {
    this.getDatas();
  },
  methods: {
    onClosed () {
      return true;
    },
    async getDatas () {
      const res = await fileList(this.pageData);
      this.fileList = res.data;
    },
    async onSubmit () {
      const that = this;
      try {
        that.loading = true;
        const res = await downloadTestLog(that.form.fileName);

        const values = {};
        let inner = {};

        const reader = new FileReader();
        reader.readAsText(res, 'gb2312');
        reader.onload = function (e) {
          if (!!reader.result) {
            const result = reader.result.split(/(\r\n|\r|\n)/);
            for (let line of result) {

              if (line.indexOf("[") === 0) {
                inner = {};
                values[line] = inner;
                continue;
              }

              line = line.trim();

              if (/^(|\s|\r\n|\r|\n)$/.test(line)) continue; // 空白行
              if (line.indexOf(";") === 0) continue; // 注释
              if (line.indexOf("；") === 0) continue; // 注释

              const parts = line.split("=");
              inner[parts[0]] = parts[1];
            }
          }

          that.loading = false;
          that.$emit("get-configs", values, that.form.fileName);
        }
      } catch (err) {
        that.$message.warning(err.response.data.message);
        that.loading = false;
      }
    }
  }
}
</script>