<template>
  <div>
    <el-table :data="table" :height="height">
      <el-table-column label="日期" prop="createTime" width="160" align="center"></el-table-column>
      <el-table-column label="日充电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="topCharging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="peakCharging"></el-table-column>
        <el-table-column label="平段" align="center" prop="normalCharging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="valleyCharging"></el-table-column>
      </el-table-column>
      <el-table-column label="日放电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="topDischarging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="peakDischarging"></el-table-column>
        <el-table-column label="平段" align="center" prop="normalDischarging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="valleyDischarging"></el-table-column>
      </el-table-column>
      <el-table-column label="总充电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="totalTopCharging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="totalPeakCharging"></el-table-column>
        <el-table-column label="平段" align="center" prop="totalNormalCharging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="totalValleyCharging"></el-table-column>
      </el-table-column>
      <el-table-column label="总放电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="totalTopDischarging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="totalPeakDischarging"></el-table-column>
        <el-table-column label="平段" align="center" prop="totalNormalDischarging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="totalValleyDischarging"></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";

export default {
  mixins: [ HistoryMixin ],
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      table: []
    }
  },
  methods: {
    async setDatas () {

      let list = this.list;

      list = list.map(obj => {
        const keys = Object.keys(obj);
        for (const key of keys) {
          if (key !== "id" && key !== "code" && key !== "createTime" && key !== "updateTime") {
            obj[key] = Number(obj[key]).toFixed(2);
          }
        }
        return obj;
      });

      this.table = list;
    }
  }
}
</script>