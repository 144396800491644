<template>
  <div class="m-l-15" :style="`height: ${height}px; overflow:auto;`">
    <el-descriptions :column="1" title="温度" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
      <el-descriptions-item label="T1温度">{{ tData.object.t1Temperature }}</el-descriptions-item>
      <el-descriptions-item label="T2温度">{{ tData.object.t2Temperature }}</el-descriptions-item>
      <el-descriptions-item label="T3温度">{{ tData.object.t3Temperature }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="分次谐波数据" :column="3" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
      <el-descriptions-item label="THDUa（A相电压总畸变率）">{{ fData.object.totalVoltageDistortionAphase }}</el-descriptions-item>
      <el-descriptions-item label="THDUb（B相电压总畸变率）">{{ fData.object.voltage2To31HarmonicAphase }}</el-descriptions-item>
      <el-descriptions-item label="THDUc（C相电压总畸变率）">{{ fData.object.totalVoltageDistortionBphase }}</el-descriptions-item>
      <el-descriptions-item label="THDIa（A相电流总畸变率）">{{ fData.object.voltage2To31HarmonicBphase }}</el-descriptions-item>
      <el-descriptions-item label="THDIb（B相电流总畸变率）">{{ fData.object.totalVoltageDistortionCphase }}</el-descriptions-item>
      <el-descriptions-item label="THDIc（C相电流总畸变率）">{{ fData.object.voltage2To31HarmonicCphase }}</el-descriptions-item>
      <el-descriptions-item label="THUa（A相电压2~31次谐波含量）">{{ fData.object.totalCurrentDistortionAphase }}</el-descriptions-item>
      <el-descriptions-item label="THUb（B相电压2~31次谐波含量）">{{ fData.object.current2To31HarmonicAphase }}</el-descriptions-item>
      <el-descriptions-item label="THUc（C相电压2~31次谐波含量）">{{ fData.object.totalCurrentDistortionBphase }}</el-descriptions-item>
      <el-descriptions-item label="THIa（A相电流2~31次谐波含量）">{{ fData.object.current2To31HarmonicBphase }}</el-descriptions-item>
      <el-descriptions-item label="THIb（B相电流2~31次谐波含量）">{{ fData.object.totalCurrentDistortionCphase }}</el-descriptions-item>
      <el-descriptions-item label="THIc（C相电流2~31次谐波含量）">{{ fData.object.current2To31HarmonicCphase }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="预留数据" :column="3" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
      <el-descriptions-item label="A相基波电压">{{ bkData.object.fundamentalVoltageAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相基波电压">{{ bkData.object.fundamentalVoltage_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相基波电压">{{ bkData.object.fundamentalVoltageCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相谐波电压">{{ bkData.object.harmonicVoltageAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相谐波电压">{{ bkData.object.harmonicVoltage_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相谐波电压">{{ bkData.object.harmonicVoltageCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相基波电流">{{ bkData.object.fundamentalCurrentAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相基波电流">{{ bkData.object.fundamentalCurrent_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相基波电流">{{ bkData.object.fundamentalCurrentCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相谐波电流">{{ bkData.object.harmonicCurrentAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相谐波电流">{{ bkData.object.harmonicCurrent_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相谐波电流">{{ bkData.object.harmonicCurrentCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相基波有功功率">{{ bkData.object.fundamentalActivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相基波有功功率">{{ bkData.object.fundamentalActivePower_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相基波有功功率">{{ bkData.object.fundamentalActivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相基波无功功率">{{ bkData.object.fundamentalReactivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相基波无功功率">{{ bkData.object.fundamentalReactivePower_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相基波无功功率">{{ bkData.object.fundamentalReactivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相谐波有功功率">{{ bkData.object.harmonicActivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相谐波有功功率">{{ bkData.object.harmonicActivePower_BPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相谐波有功功率">{{ bkData.object.harmonicActivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相谐波无功功率">{{ bkData.object.harmonicReactivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相谐波无功功率">{{ bkData.object.harmonicReactivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相谐波无功功率">{{ bkData.object.harmonicReactivePowerCPhase }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
      <el-descriptions-item label="总基波有功功率">{{ bkData.object.totalFundamentalActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总基波无功功率">{{ bkData.object.totalFundamentalReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="总谐波有功功率">{{ bkData.object.totalHarmonicActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总谐波无功功率">{{ bkData.object.totalHarmonicReactivePower }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
export default {
  props: {
    height: undefined
  },
  data () {
    return {
      tData: {
        object: {
          t1Temperature: "",
          t2Temperature: "",
          t3Temperature: ""
        },
        start: 0x8200,
        count: 3
      },
      fData: {
        object: {
          totalVoltageDistortionAphase: "",
          voltage2To31HarmonicAphase: "",
          totalVoltageDistortionBphase: "",
          voltage2To31HarmonicBphase: "",
          totalVoltageDistortionCphase: "",
          voltage2To31HarmonicCphase: "",
          totalCurrentDistortionAphase: "",
          current2To31HarmonicAphase: "",
          totalCurrentDistortionBphase: "",
          current2To31HarmonicBphase: "",
          totalCurrentDistortionCphase: "",
          current2To31HarmonicCphase: ""
        },
        start: 0x8206,
        count: 12
      },
      bkData: {
        object: {
          fundamentalVoltageAPhase: "",
          fundamentalVoltage_BPhase: "",
          fundamentalVoltageCPhase: "",
          harmonicVoltageAPhase: "",
          harmonicVoltage_BPhase: "",
          harmonicVoltageCPhase: "",
          fundamentalCurrentAPhase: "",
          fundamentalCurrent_BPhase: "",
          fundamentalCurrentCPhase: "",
          harmonicCurrentAPhase: "",
          harmonicCurrent_BPhase: "",
          harmonicCurrentCPhase: "",
          fundamentalActivePowerAPhase: "",
          fundamentalActivePower_BPhase: "",
          fundamentalActivePowerCPhase: "",
          totalFundamentalActivePower: "",
          fundamentalReactivePowerAPhase: "",
          fundamentalReactivePower_BPhase: "",
          fundamentalReactivePowerCPhase: "",
          totalFundamentalReactivePower: "",
          harmonicActivePowerAPhase: "",
          harmonicActivePower_BPhase: "",
          harmonicActivePowerCPhase: "",
          totalHarmonicActivePower: "",
          harmonicReactivePowerAPhase: "",
          harmonicReactivePowerBPhase: "",
          harmonicReactivePowerCPhase: "",
          totalHarmonicReactivePower: ""
        },
        start: 0x82E5,
        count: 28
      },

    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions__body {
  margin-bottom: 20px;
}
</style>