var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "m-l-15",
    },
    [
      _c(
        "div",
        {
          style: `height: ${_vm.height}px; overflow:auto; padding-right:10px;`,
        },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                title: `空调数据【更新时间：${_vm.object.updateTime || ""}】`,
                column: 3,
                border: "",
                size: "mini",
                "label-class-name": "descriptions-label-box",
                "content-class-name": "descriptions-content-box",
              },
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-right",
                        type: "primary",
                        size: "mini",
                      },
                      on: { click: _vm.getDatas },
                    },
                    [_vm._v("数据读取")]
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "空调数量" } }, [
                _vm._v(_vm._s(_vm.object.theNumber)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制冷设定点" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.coolingSetPoint
                      )
                        ? (_vm.object.coolingSetPoint / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制冷偏差" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.coolingDeviation
                      )
                        ? (_vm.object.coolingDeviation / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "空调状态", span: "3" } },
                [
                  _vm.object.airconStatusArr &&
                  _vm.object.airconStatusArr.length > 0
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.object.airconStatusArr,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "rack-online-status-span",
                              },
                              [_vm._v(_vm._s(item))]
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", [_vm._v("无数据")]),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "高温告警值" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.highTemperation
                      )
                        ? (_vm.object.highTemperation / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "低温告警值" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.lowTemperation)
                        ? (_vm.object.lowTemperation / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "加热设定点" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.heatingSetPoint
                      )
                        ? (_vm.object.heatingSetPoint / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "加热偏差" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.heatingDeviation
                      )
                        ? (_vm.object.heatingDeviation / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "开关机" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.switchOnOff)
                      ? _vm.switchOnOfList[_vm.object.switchOnOff]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "启动制冷指令" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.startCoolingOrder
                    )
                      ? _vm.onOffStatusList[_vm.object.startCoolingOrder]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "启动送风指令" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.startFanOrder)
                      ? _vm.onOffStatusList[_vm.object.startFanOrder]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "启动待机指令" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.startHoldOnOrder)
                      ? _vm.onOffStatusList[_vm.object.startHoldOnOrder]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "启动加热指令" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.startHeatingOrder
                    )
                      ? _vm.onOffStatusList[_vm.object.startHeatingOrder]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "柜内当前温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.currentTemperature
                      )
                        ? (_vm.object.currentTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "柜内当前湿度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.currentHumidity
                      )
                        ? (_vm.object.currentHumidity / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "传感器故障" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.sensorFault)
                      ? _vm.alarmStatusList[_vm.object.sensorFault].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "高低电压告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.highLowVoltageAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.highLowVoltageAlarm]
                          .label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "高低温告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.highLowTemperatureAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.highLowTemperatureAlarm]
                          .label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "高低压告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.highLowPressureAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.highLowPressureAlarm]
                          .label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "除湿开启温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.startTemperature
                      )
                        ? (_vm.object.startTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "除湿停止温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.stopTemperature
                      )
                        ? (_vm.object.stopTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "除湿开启湿度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.startHumidity)
                        ? (_vm.object.startHumidity / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "除湿停止湿度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.stopHumidity)
                        ? (_vm.object.stopHumidity / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "机组状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.unitState)
                      ? _vm.object.unitState
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "电压值" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.voltageValue)
                        ? _vm.object.voltageValue.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "门禁告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.accessControlAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.accessControlAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "加热器告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.heaterAlarm)
                      ? _vm.alarmStatusList[_vm.object.heaterAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制冷状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.coolingState)
                      ? _vm.onOffStatusList[_vm.object.coolingState]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制热状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.heatingState)
                      ? _vm.onOffStatusList[_vm.object.heatingState]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "内风机状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.internalFanState)
                      ? _vm.onOffStatusList[_vm.object.internalFanState]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "外风机状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.externalFanState)
                      ? _vm.onOffStatusList[_vm.object.externalFanState]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "内风机转速" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.internalFanRotate
                      )
                        ? _vm.object.internalFanRotate.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("rpm"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "外风机转速" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.externalFanRotate
                      )
                        ? _vm.object.externalFanRotate.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("rpm"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "内风机告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.internalFanAlarm)
                      ? _vm.alarmStatusList[_vm.object.internalFanAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "外风机告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.externalFanAlarm)
                      ? _vm.alarmStatusList[_vm.object.externalFanAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "设备工作状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.deviceWorkState)
                      ? _vm.deviceRunSatusList[_vm.object.deviceWorkState]
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "压缩机状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.compressorState)
                      ? _vm.deviceRunSatusList[_vm.object.compressorState]
                      : "无数据"
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "电加热运行状态" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.theRunningState
                      )
                        ? _vm.deviceRunSatusList[_vm.object.theRunningState]
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "容霜温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.defrostingTemperature
                      )
                        ? (_vm.object.defrostingTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "冷凝温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.condensingTemperature
                      )
                        ? (_vm.object.condensingTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "柜内温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.cabinetTemperature
                      )
                        ? (_vm.object.cabinetTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "柜内湿度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.cabinetHumidity
                      )
                        ? _vm.object.cabinetHumidity.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "出风温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.outletAirTemperature
                      )
                        ? (_vm.object.outletAirTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "上位机温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.hostTemperature
                      )
                        ? (_vm.object.hostTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "上位机湿度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.hostHumidity)
                        ? _vm.object.hostHumidity.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制冷设定温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.coolingSetTemperature
                      )
                        ? _vm.object.coolingSetTemperature.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "制冷控制回差" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.coolingControlBack
                      )
                        ? _vm.object.coolingControlBack.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "加热设定温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.heatingSetTemperature
                      )
                        ? _vm.object.heatingSetTemperature.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "加热控制回差" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.heatingControlBack
                      )
                        ? _vm.object.heatingControlBack.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "湿度设定值" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.humiditySetValue
                      )
                        ? _vm.object.humiditySetValue.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "控制湿度回差" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.humidityControlBack
                      )
                        ? _vm.object.humidityControlBack.toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("%"),
                ]),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内温度过高点" } },
                [
                  _c("div", { staticClass: "description-value-box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.utils.checkObjectIsNotEmpty(
                          _vm.object.cabinetOverTemperature
                        )
                          ? _vm.object.cabinetOverTemperature.toFixed(2)
                          : "无数据"
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "description-unit-box" }, [
                    _vm._v("℃"),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内温度过低点" } },
                [
                  _c("div", { staticClass: "description-value-box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.utils.checkObjectIsNotEmpty(
                          _vm.object.cabinetUnderTemperature
                        )
                          ? _vm.object.cabinetUnderTemperature.toFixed(2)
                          : "无数据"
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "description-unit-box" }, [
                    _vm._v("℃"),
                  ]),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内湿度过高点" } },
                [
                  _c("div", { staticClass: "description-value-box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.utils.checkObjectIsNotEmpty(
                          _vm.object.cabinetOverHumidity
                        )
                          ? _vm.object.cabinetOverHumidity.toFixed(2)
                          : "无数据"
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "description-unit-box" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "监控开关机" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.monitorSwitch)
                      ? _vm.monitorSwitchStatusMap[_vm.object.monitorSwitch] ||
                          "无效"
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "强制模式" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.forceMode)
                      ? _vm.forceModeList[_vm.object.forceMode]
                      : "无数据"
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内温度过高告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.cabinetOver)
                        ? _vm.alarmStatusList[_vm.object.cabinetOver].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内温度过低告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.cabinetUnder)
                        ? _vm.alarmStatusList[_vm.object.cabinetUnder].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "湿度过高告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.humidityOverAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.humidityOverAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "湿度过低告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.humidityUnder)
                      ? _vm.alarmStatusList[_vm.object.humidityUnder].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "盘管防冻告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.coilAntiFreezeAlarm
                    )
                      ? _vm.alarmStatusList[_vm.object.coilAntiFreezeAlarm]
                          .label
                      : "无数据"
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "融霜探头故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.deforstProbeFaultAlarm
                      )
                        ? _vm.alarmStatusList[_vm.object.deforstProbeFaultAlarm]
                            .label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "冷凝温度探头故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.condensateTemperatureProbe
                      )
                        ? _vm.alarmStatusList[
                            _vm.object.condensateTemperatureProbe
                          ].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "柜内温度探头故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.cabinetTemperatureProbe
                      )
                        ? _vm.alarmStatusList[
                            _vm.object.cabinetTemperatureProbe
                          ].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "出风温度探头故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.outlet)
                        ? _vm.alarmStatusList[_vm.object.outlet].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "湿度探头故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.temperatureProbe
                      )
                        ? _vm.alarmStatusList[_vm.object.temperatureProbe].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "内风机故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.internalFanFault
                      )
                        ? _vm.alarmStatusList[_vm.object.internalFanFault].label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "压缩机故障告警" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.compressorFaultAlarm
                      )
                        ? _vm.alarmStatusList[_vm.object.compressorFaultAlarm]
                            .label
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "高压告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.highVoltageAlarm)
                      ? _vm.alarmStatusList[_vm.object.highVoltageAlarm].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "低压告警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.lowVoltage)
                      ? _vm.alarmStatusList[_vm.object.lowVoltage].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "相序报警" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.phaseSequence)
                      ? _vm.alarmStatusList[_vm.object.phaseSequence].label
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "通讯地址" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(_vm.object.contactAddress)
                      ? _vm.object.contactAddress
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "波特率" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.object.baudRate)
                      ? _vm.baudRateList[_vm.object.baudRate] || "无数据"
                      : "无数据"
                  )
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }