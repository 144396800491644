<template>
  <div class="m-l-15">
    <!-- <div class="time-table-box">
      <el-descriptions title="第一套时段表" :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <el-descriptions-item label="第1时段费率号">{{ firstTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第1时段起始">{{ firstTime.startTimeFirstTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第2时段费率号">{{ firstTime.rateNumber_secondTime }}</el-descriptions-item>
        <el-descriptions-item label="第2时段起始">{{ firstTime.startTime_secondTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第3时段费率号">{{ firstTime.rateNumberThirdTime }}</el-descriptions-item>
        <el-descriptions-item label="第3时段起始">{{ firstTime.startTimeThirdTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第4时段费率号">{{ firstTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第4时段起始">{{ firstTime.startTimeFourthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第5时段费率号">{{ firstTime.rateNumberFifthTime }}</el-descriptions-item>
        <el-descriptions-item label="第5时段起始">{{ firstTime.startTimeFifthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第6时段费率号">{{ firstTime.rateNumber_sixthTime }}</el-descriptions-item>
        <el-descriptions-item label="第6时段起始">{{ firstTime.startTime_sixthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第7时段费率号">{{ firstTime.rateNumber_seventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第7时段起始">{{ firstTime.startTime_seventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第8时段费率号">{{ firstTime.rateNumber_eighthTime }}</el-descriptions-item>
        <el-descriptions-item label="第8时段起始">{{ firstTime.startTime_eighthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第9时段费率号">{{ firstTime.rateNumberNinthTime }}</el-descriptions-item>
        <el-descriptions-item label="第9时段起始">{{ firstTime.startTimeNinthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第10时段费率号">{{ firstTime.rateNumberTenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第10时段起始">{{ firstTime.startTimeTenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第11时段费率号">{{ firstTime.rateNumber_eleventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第11时段起始">{{ firstTime.startTime_eleventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第12时段费率号">{{ firstTime.rateNumberTwelfthTime }}</el-descriptions-item>
        <el-descriptions-item label="第12时段起始">{{ firstTime.startTimeTwelfthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第13时段费率号">{{ firstTime.rateNumberThirteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第13时段起始">{{ firstTime.startTimeThirteenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第14时段费率号">{{ firstTime.rateNumberFourteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第14时段起始">{{ firstTime.startTimeFourteenthTimeHourMinute }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="time-table-box">
      <el-descriptions title="第二套时段表" :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <el-descriptions-item label="第1时段费率号">{{ secondTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第1时段起始">{{ secondTime.startTimeFirstTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第2时段费率号">{{ secondTime.rateNumber_secondTime }}</el-descriptions-item>
        <el-descriptions-item label="第2时段起始">{{ secondTime.startTime_secondTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第3时段费率号">{{ secondTime.rateNumberThirdTime }}</el-descriptions-item>
        <el-descriptions-item label="第3时段起始">{{ secondTime.startTimeThirdTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第4时段费率号">{{ secondTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第4时段起始">{{ secondTime.startTimeFourthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第5时段费率号">{{ secondTime.rateNumberFifthTime }}</el-descriptions-item>
        <el-descriptions-item label="第5时段起始">{{ secondTime.startTimeFifthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第6时段费率号">{{ secondTime.rateNumber_sixthTime }}</el-descriptions-item>
        <el-descriptions-item label="第6时段起始">{{ secondTime.startTime_sixthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第7时段费率号">{{ secondTime.rateNumber_seventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第7时段起始">{{ secondTime.startTime_seventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第8时段费率号">{{ secondTime.rateNumber_eighthTime }}</el-descriptions-item>
        <el-descriptions-item label="第8时段起始">{{ secondTime.startTime_eighthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第9时段费率号">{{ secondTime.rateNumberNinthTime }}</el-descriptions-item>
        <el-descriptions-item label="第9时段起始">{{ secondTime.startTimeNinthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第10时段费率号">{{ secondTime.rateNumberTenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第10时段起始">{{ secondTime.startTimeTenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第11时段费率号">{{ secondTime.rateNumber_eleventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第11时段起始">{{ secondTime.startTime_eleventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第12时段费率号">{{ secondTime.rateNumberTwelfthTime }}</el-descriptions-item>
        <el-descriptions-item label="第12时段起始">{{ secondTime.startTimeTwelfthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第13时段费率号">{{ secondTime.rateNumberThirteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第13时段起始">{{ secondTime.startTimeThirteenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第14时段费率号">{{ secondTime.rateNumberFourteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第14时段起始">{{ secondTime.startTimeFourteenthTimeHourMinute }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="time-table-box">
      <el-descriptions title="第三套时段表" :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <el-descriptions-item label="第1时段费率号">{{ thirdTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第1时段起始">{{ thirdTime.startTimeFirstTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第2时段费率号">{{ thirdTime.rateNumber_secondTime }}</el-descriptions-item>
        <el-descriptions-item label="第2时段起始">{{ thirdTime.startTime_secondTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第3时段费率号">{{ thirdTime.rateNumberThirdTime }}</el-descriptions-item>
        <el-descriptions-item label="第3时段起始">{{ thirdTime.startTimeThirdTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第4时段费率号">{{ thirdTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第4时段起始">{{ thirdTime.startTimeFourthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第5时段费率号">{{ thirdTime.rateNumberFifthTime }}</el-descriptions-item>
        <el-descriptions-item label="第5时段起始">{{ thirdTime.startTimeFifthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第6时段费率号">{{ thirdTime.rateNumber_sixthTime }}</el-descriptions-item>
        <el-descriptions-item label="第6时段起始">{{ thirdTime.startTime_sixthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第7时段费率号">{{ thirdTime.rateNumber_seventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第7时段起始">{{ thirdTime.startTime_seventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第8时段费率号">{{ thirdTime.rateNumber_eighthTime }}</el-descriptions-item>
        <el-descriptions-item label="第8时段起始">{{ thirdTime.startTime_eighthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第9时段费率号">{{ thirdTime.rateNumberNinthTime }}</el-descriptions-item>
        <el-descriptions-item label="第9时段起始">{{ thirdTime.startTimeNinthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第10时段费率号">{{ thirdTime.rateNumberTenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第10时段起始">{{ thirdTime.startTimeTenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第11时段费率号">{{ thirdTime.rateNumber_eleventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第11时段起始">{{ thirdTime.startTime_eleventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第12时段费率号">{{ thirdTime.rateNumberTwelfthTime }}</el-descriptions-item>
        <el-descriptions-item label="第12时段起始">{{ thirdTime.startTimeTwelfthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第13时段费率号">{{ thirdTime.rateNumberThirteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第13时段起始">{{ thirdTime.startTimeThirteenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第14时段费率号">{{ thirdTime.rateNumberFourteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第14时段起始">{{ thirdTime.startTimeFourteenthTimeHourMinute }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="time-table-box">
      <el-descriptions title="第四套时段表" :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
        <el-descriptions-item label="第1时段费率号">{{ fourthTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第1时段起始">{{ fourthTime.startTimeFirstTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第2时段费率号">{{ fourthTime.rateNumber_secondTime }}</el-descriptions-item>
        <el-descriptions-item label="第2时段起始">{{ fourthTime.startTime_secondTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第3时段费率号">{{ fourthTime.rateNumberThirdTime }}</el-descriptions-item>
        <el-descriptions-item label="第3时段起始">{{ fourthTime.startTimeThirdTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第4时段费率号">{{ fourthTime.rateNumberFirstTime }}</el-descriptions-item>
        <el-descriptions-item label="第4时段起始">{{ fourthTime.startTimeFourthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第5时段费率号">{{ fourthTime.rateNumberFifthTime }}</el-descriptions-item>
        <el-descriptions-item label="第5时段起始">{{ fourthTime.startTimeFifthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第6时段费率号">{{ fourthTime.rateNumber_sixthTime }}</el-descriptions-item>
        <el-descriptions-item label="第6时段起始">{{ fourthTime.startTime_sixthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第7时段费率号">{{ fourthTime.rateNumber_seventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第7时段起始">{{ fourthTime.startTime_seventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第8时段费率号">{{ fourthTime.rateNumber_eighthTime }}</el-descriptions-item>
        <el-descriptions-item label="第8时段起始">{{ fourthTime.startTime_eighthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第9时段费率号">{{ fourthTime.rateNumberNinthTime }}</el-descriptions-item>
        <el-descriptions-item label="第9时段起始">{{ fourthTime.startTimeNinthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第10时段费率号">{{ fourthTime.rateNumberTenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第10时段起始">{{ fourthTime.startTimeTenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第11时段费率号">{{ fourthTime.rateNumber_eleventhTime }}</el-descriptions-item>
        <el-descriptions-item label="第11时段起始">{{ fourthTime.startTime_eleventhTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第12时段费率号">{{ fourthTime.rateNumberTwelfthTime }}</el-descriptions-item>
        <el-descriptions-item label="第12时段起始">{{ fourthTime.startTimeTwelfthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第13时段费率号">{{ fourthTime.rateNumberThirteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第13时段起始">{{ fourthTime.startTimeThirteenthTimeHourMinute }}</el-descriptions-item>
        <el-descriptions-item label="第14时段费率号">{{ fourthTime.rateNumberFourteenthTime }}</el-descriptions-item>
        <el-descriptions-item label="第14时段起始">{{ fourthTime.startTimeFourteenthTimeHourMinute }}</el-descriptions-item>
      </el-descriptions>
    </div> -->
    <el-table :data="table" :height="height" header-cell-class-name="header-cell-class-name"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
      <el-table-column prop="name" label="项目名称" width="200"></el-table-column>
      <el-table-column prop="firstTime" label="第一套时间表"></el-table-column>
      <el-table-column prop="secondTime" label="第二套时间表"></el-table-column>
      <el-table-column prop="thirdTime" label="第三套时间表"></el-table-column>
      <el-table-column prop="fourthTime" label="第四套时间表"></el-table-column>
    </el-table>
  </div>
</template>
<script>

const names = [
  "第1时段费率号",
  "第1时段起始",
  "第2时段费率号",
  "第2时段起始",
  "第3时段费率号",
  "第3时段起始",
  "第4时段费率号",
  "第4时段起始",
  "第5时段费率号",
  "第5时段起始",
  "第6时段费率号",
  "第6时段起始",
  "第7时段费率号",
  "第7时段起始",
  "第8时段费率号",
  "第8时段起始",
  "第9时段费率号",
  "第9时段起始",
  "第10时段费率号",
  "第10时段起始",
  "第11时段费率号",
  "第11时段起始",
  "第12时段费率号",
  "第12时段起始",
  "第13时段费率号",
  "第13时段起始",
  "第14时段费率号",
  "第14时段起始"
];

export default {
  props: {
    height: undefined
  },
  data () {
    return {
      // firstTime: {
      //   rateNumberFirstTime: "",
      //   startTimeFirstTimeHourMinute: "",
      //   rateNumber_secondTime: "",
      //   startTime_secondTimeHourMinute: "",
      //   rateNumberThirdTime: "",
      //   startTimeThirdTimeHourMinute: "",
      //   rateNumberFourthTime: "",
      //   startTimeFourthTimeHourMinute: "",
      //   rateNumberFifthTime: "",
      //   startTimeFifthTimeHourMinute: "",
      //   rateNumber_sixthTime: "",
      //   startTime_sixthTimeHourMinute: "",
      //   rateNumber_seventhTime: "",
      //   startTime_seventhTimeHourMinute: "",
      //   rateNumber_eighthTime: "",
      //   startTime_eighthTimeHourMinute: "",
      //   rateNumberNinthTime: "",
      //   startTimeNinthTimeHourMinute: "",
      //   rateNumberTenthTime: "",
      //   startTimeTenthTimeHourMinute: "",
      //   rateNumber_eleventhTime: "",
      //   startTime_eleventhTimeHourMinute: "",
      //   rateNumberTwelfthTime: "",
      //   startTimeTwelfthTimeHourMinute: "",
      //   rateNumberThirteenthTime: "",
      //   startTimeThirteenthTimeHourMinute: "",
      //   rateNumberFourteenthTime: "",
      //   startTimeFourteenthTimeHourMinute: ""
      // },
      // secondTime: {
      //   rateNumberFirstTime: "",
      //   startTimeFirstTimeHourMinute: "",
      //   rateNumber_secondTime: "",
      //   startTime_secondTimeHourMinute: "",
      //   rateNumberThirdTime: "",
      //   startTimeThirdTimeHourMinute: "",
      //   rateNumberFourthTime: "",
      //   startTimeFourthTimeHourMinute: "",
      //   rateNumberFifthTime: "",
      //   startTimeFifthTimeHourMinute: "",
      //   rateNumber_sixthTime: "",
      //   startTime_sixthTimeHourMinute: "",
      //   rateNumber_seventhTime: "",
      //   startTime_seventhTimeHourMinute: "",
      //   rateNumber_eighthTime: "",
      //   startTime_eighthTimeHourMinute: "",
      //   rateNumberNinthTime: "",
      //   startTimeNinthTimeHourMinute: "",
      //   rateNumberTenthTime: "",
      //   startTimeTenthTimeHourMinute: "",
      //   rateNumber_eleventhTime: "",
      //   startTime_eleventhTimeHourMinute: "",
      //   rateNumberTwelfthTime: "",
      //   startTimeTwelfthTimeHourMinute: "",
      //   rateNumberThirteenthTime: "",
      //   startTimeThirteenthTimeHourMinute: "",
      //   rateNumberFourteenthTime: "",
      //   startTimeFourteenthTimeHourMinute: ""
      // },
      // thirdTime: {
      //   rateNumberFirstTime: "",
      //   startTimeFirstTimeHourMinute: "",
      //   rateNumber_secondTime: "",
      //   startTime_secondTimeHourMinute: "",
      //   rateNumberThirdTime: "",
      //   startTimeThirdTimeHourMinute: "",
      //   rateNumberFourthTime: "",
      //   startTimeFourthTimeHourMinute: "",
      //   rateNumberFifthTime: "",
      //   startTimeFifthTimeHourMinute: "",
      //   rateNumber_sixthTime: "",
      //   startTime_sixthTimeHourMinute: "",
      //   rateNumber_seventhTime: "",
      //   startTime_seventhTimeHourMinute: "",
      //   rateNumber_eighthTime: "",
      //   startTime_eighthTimeHourMinute: "",
      //   rateNumberNinthTime: "",
      //   startTimeNinthTimeHourMinute: "",
      //   rateNumberTenthTime: "",
      //   startTimeTenthTimeHourMinute: "",
      //   rateNumber_eleventhTime: "",
      //   startTime_eleventhTimeHourMinute: "",
      //   rateNumberTwelfthTime: "",
      //   startTimeTwelfthTimeHourMinute: "",
      //   rateNumberThirteenthTime: "",
      //   startTimeThirteenthTimeHourMinute: "",
      //   rateNumberFourteenthTime: "",
      //   startTimeFourteenthTimeHourMinute: ""
      // },
      // fourthTime: {
      //   rateNumberFirstTime: "",
      //   startTimeFirstTimeHourMinute: "",
      //   rateNumber_secondTime: "",
      //   startTime_secondTimeHourMinute: "",
      //   rateNumberThirdTime: "",
      //   startTimeThirdTimeHourMinute: "",
      //   rateNumberFourthTime: "",
      //   startTimeFourthTimeHourMinute: "",
      //   rateNumberFifthTime: "",
      //   startTimeFifthTimeHourMinute: "",
      //   rateNumber_sixthTime: "",
      //   startTime_sixthTimeHourMinute: "",
      //   rateNumber_seventhTime: "",
      //   startTime_seventhTimeHourMinute: "",
      //   rateNumber_eighthTime: "",
      //   startTime_eighthTimeHourMinute: "",
      //   rateNumberNinthTime: "",
      //   startTimeNinthTimeHourMinute: "",
      //   rateNumberTenthTime: "",
      //   startTimeTenthTimeHourMinute: "",
      //   rateNumber_eleventhTime: "",
      //   startTime_eleventhTimeHourMinute: "",
      //   rateNumberTwelfthTime: "",
      //   startTimeTwelfthTimeHourMinute: "",
      //   rateNumberThirteenthTime: "",
      //   startTimeThirteenthTimeHourMinute: "",
      //   rateNumberFourteenthTime: "",
      //   startTimeFourteenthTimeHourMinute: ""
      // },
      start: [ 0x8070, 0x8090, 0x81C0, 0x81E0 ],
      count: 28,
      table: []
    }
  },
  created () {
    this.getDatas();
  },
  methods: {
    getDatas () {
      const table = [];
      for (const name of names) {
        const row = {
          name
        };

        table.push(row);
      }

      this.table = table;
    }
  }
}
</script>
<style lang="scss" scoped>
.time-table-box {
  display: inline-block;
  width: calc(25% - 15px);
  margin-left: 15px;
}

::v-deep .header-cell-class-name {
  text-align: center;
}
</style>