var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      style: `height:${_vm.height}px; overflow:auto;`,
    },
    [
      _c(
        "div",
        { staticClass: "m-l-15 m-r-10" },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                column: 3,
                title: "运行信息变量",
                border: "",
                size: "mini",
                "label-class-name": "descriptions-label-box",
                "content-class-name": "descriptions-content-box",
              },
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-right",
                        type: "primary",
                        size: "mini",
                      },
                      on: { click: _vm.getDatas },
                    },
                    [_vm._v("数据读取")]
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "交流A相电压" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acVoltagePhaseA
                      )
                        ? (_vm.object.acVoltagePhaseA / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流B相电压" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acVoltagePhaseB
                      )
                        ? (_vm.object.acVoltagePhaseB / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流C相电压" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acVoltagePhaseC
                      )
                        ? (_vm.object.acVoltagePhaseC / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流A相电流" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acCurrentPhaseA
                      )
                        ? (_vm.object.acCurrentPhaseA / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流B相电流" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acCurrentPhaseB
                      )
                        ? (_vm.object.acCurrentPhaseB / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流C相电流" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acCurrentPhaseC
                      )
                        ? (_vm.object.acCurrentPhaseC / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流视在功率" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.acApparentPower
                      )
                        ? (_vm.object.acApparentPower / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("kVA"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "交流频率" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.acFrequency)
                        ? (_vm.object.acFrequency / 100).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("HZ"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "功率因数" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.powerFactor)
                        ? (_vm.object.powerFactor / 1000).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("HZ"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "机内温度" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.unitTemperature
                      )
                        ? (_vm.object.unitTemperature / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "电池组SOC(多路电池)" } },
                [_vm._v(_vm._s(_vm.object.batteryPackSOC))]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "系统告警" } },
                _vm._l(_vm.object.systemAlarmArr, function (err) {
                  return _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(err === "1" ? _vm.systemAlarmValList[err] : "") +
                        " "
                    ),
                  ])
                }),
                0
              ),
              _c("el-descriptions-item", { attrs: { label: "直流电压1" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage1)
                        ? (_vm.object.dcVoltage1 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流1" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent1) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent1)
                        ? (_vm.object.dcCurrent1 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率1" } }, [
                _vm._v(_vm._s(_vm.object.dcPower1) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower1)
                        ? (_vm.object.dcPower1 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压2" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage2) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage2)
                        ? (_vm.object.dcVoltage2 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流2" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent2) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent2)
                        ? (_vm.object.dcCurrent2 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率2" } }, [
                _vm._v(_vm._s(_vm.object.dcPower2) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower2)
                        ? (_vm.object.dcPower2 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压3" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage3) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage3)
                        ? (_vm.object.dcVoltage3 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流3" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent3) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent3)
                        ? (_vm.object.dcCurrent3 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率3" } }, [
                _vm._v(_vm._s(_vm.object.dcPower3) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower3)
                        ? (_vm.object.dcPower3 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压4" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage4) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage1)
                        ? (_vm.object.dcVoltage1 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流4" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent4) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent4)
                        ? (_vm.object.dcCurrent4 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率4" } }, [
                _vm._v(_vm._s(_vm.object.dcPower4) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower4)
                        ? (_vm.object.dcPower4 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压5" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage5) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage5)
                        ? (_vm.object.dcVoltage5 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流5" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent5) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent5)
                        ? (_vm.object.dcCurrent5 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率5" } }, [
                _vm._v(_vm._s(_vm.object.dcPower5) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower5)
                        ? (_vm.object.dcPower5 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压6" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage6) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage6)
                        ? (_vm.object.dcVoltage6 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流6" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent6) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent6)
                        ? (_vm.object.dcCurrent6 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率6" } }, [
                _vm._v(_vm._s(_vm.object.dcPower6) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower6)
                        ? (_vm.object.dcPower6 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压7" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage7) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage7)
                        ? (_vm.object.dcVoltage7 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流7" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent7) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent7)
                        ? (_vm.object.dcCurrent7 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率7" } }, [
                _vm._v(_vm._s(_vm.object.dcPower7) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower7)
                        ? (_vm.object.dcPower7 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压8" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage8) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage8)
                        ? (_vm.object.dcVoltage8 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流8" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent8) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent8)
                        ? (_vm.object.dcCurrent8 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率8" } }, [
                _vm._v(_vm._s(_vm.object.dcPower8) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower8)
                        ? (_vm.object.dcPower8 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压9" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage9) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage9)
                        ? (_vm.object.dcVoltage9 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流9" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent9) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent9)
                        ? (_vm.object.dcCurrent9 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率9" } }, [
                _vm._v(_vm._s(_vm.object.dcPower9) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower9)
                        ? (_vm.object.dcPower9 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电压10" } }, [
                _vm._v(_vm._s(_vm.object.dcVoltage10) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcVoltage10)
                        ? (_vm.object.dcVoltage10 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("V"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流电流10" } }, [
                _vm._v(_vm._s(_vm.object.dcCurrent10) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcCurrent10)
                        ? (_vm.object.dcCurrent10 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("A"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "直流功率10" } }, [
                _vm._v(_vm._s(_vm.object.dcPower10) + " "),
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(_vm.object.dcPower10)
                        ? (_vm.object.dcPower10 / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "系统状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.systemStatus
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "开关机状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusSwitchOnOff
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块1状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule1
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块2状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule2
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块3状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule3
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块4状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule4
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块5状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule5
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块6状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule6
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块7状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule7
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块8状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule8
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块9状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule9
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "模块10状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusModule10
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块1状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule1
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块2状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule2
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块3状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule3
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块4状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule4
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块5状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule5
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块6状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule6
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块7状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule7
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块8状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule8
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "BMS模块9状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.statusBMSModule9
                      : "无数据"
                  )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "BMS模块10状态" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.settingActivePower
                      )
                        ? _vm.object.statusBMSModule10
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "BMS模块11状态" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.settingActivePower
                      )
                        ? _vm.object.statusBMSModule11
                        : "无数据"
                    )
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "BMS模块12状态" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.settingActivePower
                      )
                        ? _vm.object.statusBMSModule12
                        : "无数据"
                    )
                  ),
                ]
              ),
            ],
            2
          ),
          _c(
            "el-descriptions",
            {
              staticClass: "m-t-20",
              attrs: {
                column: 3,
                title: "参数设置",
                border: "",
                size: "mini",
                "label-class-name": "descriptions-label-box",
                "content-class-name": "descriptions-content-box",
              },
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-refresh-right",
                        type: "primary",
                        size: "mini",
                      },
                      on: { click: _vm.getDatas },
                    },
                    [_vm._v("数据读取")]
                  ),
                ],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "开关机设置" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.settingSwitchOnOff
                      )
                        ? (_vm.object.settingSwitchOnOff / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("KW"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "有功功率设置" } }, [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkObjectIsNotEmpty(
                        _vm.object.settingActivePower
                      )
                        ? (_vm.object.settingActivePower / 10).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("kVar"),
                ]),
              ]),
              _c("el-descriptions-item", { attrs: { label: "无功功率设置" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.settingReactivePower
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "功率因数设置" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.settingPowerFactor
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "策略设置" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.settingTactics
                      : "无数据"
                  )
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "升级执行" } }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkObjectIsNotEmpty(
                      _vm.object.settingActivePower
                    )
                      ? _vm.object.upgradeExecution
                      : "无数据"
                  )
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }