<template>
  <div class="m-l-15 p-r-10" :style="`height: ${height}px; overflow:auto;`">
    <el-descriptions :column="4" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
      <el-descriptions-item label="当前组合有功总电能">{{ object1.totalElectricityCurrentCoipmbinationActive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合有功尖电能">{{ object1.spikeElectricityCurrentCoipmbinationActive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合有功峰电能">{{ object1.peakElectricityCurrentCoipmbinationActive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合有功平电能">{{ object1.meanElectricityCurrentCoipmbinationActive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合有功谷电能">{{ object1.valleyElectricityCurrentCoipmbinationActive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功总电能">{{ object1.totalElectricityCurrentPositiveActive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功尖电能">{{ object1.spikeElectricityCurrentPositiveActive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功峰电能">{{ object1.peakElectricityCurrentPositiveActive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功平电能">{{ object1.meanElectricityCurrentPositiveActive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功谷电能">{{ object1.valleyElectricityCurrentPositiveActive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功总电能">{{ object1.totalElectricityCurrentReverseActive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功尖电能">{{ object1.spikeElectricityCurrentReverseActive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功峰电能">{{ object1.peakElectricityCurrentReverseActive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功平电能">{{ object1.meanElectricityCurrentReverseActive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功谷电能">{{ object1.valleyElectricityCurrentReverseActive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合无功总电能">{{ object1.totalElectricityCurrentCoipmbinationReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合无功尖电能">{{ object1.spikeElectricityCurrentCoipmbinationReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合无功峰电能">{{ object1.peakElectricityCurrentCoipmbinationReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合无功平电能">{{ object1.meanElectricityCurrentCoipmbinationReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前组合无功谷电能">{{ object1.valleyElectricityCurrentCoipmbinationReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功总电能">{{ object1.totalElectricityCurrentPositiveReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功尖电能">{{ object1.spikeElectricityCurrentPositiveReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功峰电能">{{ object1.peakElectricityCurrentPositiveReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功平电能">{{ object1.meanElectricityCurrentPositiveReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功谷电能">{{ object1.valleyElectricityCurrentPositiveReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功总电能">{{ object1.totalElectricityCurrentReverseReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功尖电能">{{ object1.spikeElectricityCurrentReverseReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功峰电能">{{ object1.peakElectricityCurrentReverseReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功平电能">{{ object1.meanElectricityCurrentReverseReactive }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功谷电能">{{ object1.valleyElectricityCurrentReverseReactive }}</el-descriptions-item>
      <el-descriptions-item label="时间">{{ object1.time }}</el-descriptions-item>
      <el-descriptions-item label="脉冲常数">{{ object1.pulseConst }}</el-descriptions-item>
      <el-descriptions-item label="第一路通讯：通信地址">{{ object1.firstLineCommunicationCommunicationAddress }}</el-descriptions-item>
      <el-descriptions-item label="第一路通讯：波特率">{{ object1.firstLineCommunicationBaudRate }}</el-descriptions-item>
      <el-descriptions-item label="第1时区时段表号">{{ object1.timeTableNumberFirstZone }}</el-descriptions-item>
      <el-descriptions-item label="第1时区起始日期">{{ object1.startDateFirstZone }}</el-descriptions-item>
      <el-descriptions-item label="第2时区时段表号">{{ object1.timeTableNumberSecondZone }}</el-descriptions-item>
      <el-descriptions-item label="第2时区起始日期">{{ object1.startDateSecondZone }}</el-descriptions-item>
      <el-descriptions-item label="第3时区时段表号">{{ object1.timeTableNumberThirdZone }}</el-descriptions-item>
      <el-descriptions-item label="第3时区起始日期">{{ object1.startDateThirdZone }}</el-descriptions-item>
      <el-descriptions-item label="第4时区时段表号">{{ object1.timeTableNumberFourthZone }}</el-descriptions-item>
      <el-descriptions-item label="第4时区起始日期">{{ object1.startDateFourthZone }}</el-descriptions-item>
      <el-descriptions-item label="第一路通讯：校验位">{{ object1.firstLineCommunicationCheckBit }}</el-descriptions-item>
      <el-descriptions-item label="第一路通讯：停止位">{{ object1.firstLineCommunicationstopBit }}</el-descriptions-item>
      <el-descriptions-item label="第二路通讯：通信地址">{{ object1.secondLineCommunicationCommunicationAddress }}</el-descriptions-item>
      <el-descriptions-item label="第二路通讯：波特率">{{ object1.secondLineCommunicationBaudRate }}</el-descriptions-item>
      <el-descriptions-item label="第二路通讯：校验位">{{ object1.secondLineCommunicationCheckBit }}</el-descriptions-item>
      <el-descriptions-item label="第二路通讯：停止位">{{ object1.secondLineCommunicationstopBit }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="m-t-10" :column="4" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box">
      <el-descriptions-item label="A相电压">{{ object2.voltageAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电压">{{ object2.voltageBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电压">{{ object2.voltageCPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相电流">{{ object2.currentAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电流">{{ object2.currentBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电流">{{ object2.currentCPhase }}</el-descriptions-item>
      <el-descriptions-item label="频率">{{ object2.frequency }}</el-descriptions-item>
      <el-descriptions-item label="A_B线电压">{{ object2.voltageABLine }}</el-descriptions-item>
      <el-descriptions-item label="B_C线电压">{{ object2.voltageBCLine }}</el-descriptions-item>
      <el-descriptions-item label="A_C线电压">{{ object2.voltageACLine }}</el-descriptions-item>
      <el-descriptions-item label="正向有功最大需量">{{ object2.positiveActiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="正向有功最大需量发生时间">{{ object2.occurTimePositiveActiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="反向有功最大需量">{{ object2.reverseActiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="反向有功最大需量发生时间">{{ object2.occurTimeReverseActiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="正向无功最大需量">{{ object2.positiveReactiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="正向无功最大需量发生时间">{{ object2.occurTimePositiveReactiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="反向无功最大需量">{{ object2.reverseReactiveMaximumDemand }}</el-descriptions-item>
      <el-descriptions-item label="反向无功最大需量发生时间">{{ object2.occurTimeReverseReactiveMaximumDemandHourMinute }}</el-descriptions-item>
      <el-descriptions-item label="A相正向有功电能">{{ object2.positiveActiveElectricityAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相正向有功电能">{{ object2.positiveActiveElectricityBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相正向有功电能">{{ object2.positiveActiveElectricityCPhase }}</el-descriptions-item>
      <el-descriptions-item label="电压变比PT">{{ object2.voltageRatioPT }}</el-descriptions-item>
      <el-descriptions-item label="电流变比CT">{{ object2.currentRatioCT }}</el-descriptions-item>
      <el-descriptions-item label="DIDO状态,失压状态">{{ object2.didoStateNoVoltageState }}</el-descriptions-item>
      <el-descriptions-item label="运行状态">{{ object2.runningState }}</el-descriptions-item>
      <el-descriptions-item label="零序电流">{{ object2.zeroSequenceCurrent }}</el-descriptions-item>
      <el-descriptions-item label="电压不平衡度">{{ object2.voltageUnbalanceDegree }}</el-descriptions-item>
      <el-descriptions-item label="电流不平衡度">{{ object2.currentUnbalanceDegree }}</el-descriptions-item>
      <el-descriptions-item label="A相有功功率">{{ object2.activePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相有功功率">{{ object2.activePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相有功功率">{{ object2.activePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="总有功功率">{{ object2.totalActivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相无功功率">{{ object2.reactivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相无功功率">{{ object2.reactivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相无功功率">{{ object2.reactivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="总无功功率">{{ object2.totalReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相视在功率">{{ object2.apparentPowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相视在功率">{{ object2.apparentPowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相视在功率">{{ object2.apparentPowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="总视在功率">{{ object2.totalApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="A相功率因数">{{ object2.powerFactorAPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相功率因数">{{ object2.powerFactorBPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相功率因数">{{ object2.powerFactorCPhase }}</el-descriptions-item>
      <el-descriptions-item label="总功率因数">{{ object2.totalPowerFactor }}</el-descriptions-item>
      <el-descriptions-item label="当日正向有功最大需量">{{ object2.maximumPositiveActivePowerDemandToday }}</el-descriptions-item>
      <el-descriptions-item label="当日正向有功最大需量发生时间">{{ object2.occurrenceTimeTodayMaximumPositiveActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="当日反向有功最大需量">{{ object2.maximumReverseActivePowerDemandToday }}</el-descriptions-item>
      <el-descriptions-item label="当日反向有功最大需量发生时间">{{ object2.occurrenceTimeTodayMaximumReverseActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="当日正向无功最大需量">{{ object2.maximumPositiveReactivePowerDemandToday }}</el-descriptions-item>
      <el-descriptions-item label="当日正向无功最大需量发生时间">{{ object2.occurrenceTimeTodayMaximumPositiveReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="当日反向无功最大需量">{{ object2.maximumReverseReactivePowerDemandToday }}</el-descriptions-item>
      <el-descriptions-item label="当日反向无功最大需量发生时间">{{ object2.occurrenceTimeTodayMaximumReverseReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上1日正向有功最大需量">{{ object2.maximumPositiveActivePowerDemandLastOneDay }}</el-descriptions-item>
      <el-descriptions-item label="上1日正向有功最大需量发生时间">{{ object2.occurrenceTimeLastOneDayMaximumPositiveActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上1日反向有功最大需量">{{ object2.maximumReverseActivePowerDemandLastOneDay }}</el-descriptions-item>
      <el-descriptions-item label="上1日反向有功最大需量发生时间">{{ object2.occurrenceTimeLastOneDayMaximumReverseActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上1日正向无功最大需量">{{ object2.maximumPositiveReactivePowerDemandLastOneDay }}</el-descriptions-item>
      <el-descriptions-item label="上1日正向无功最大需量发生时间">{{ object2.occurrenceTimeLastOneDayMaximumPositiveReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上1日反向无功最大需量">{{ object2.maximumReverseReactivePowerDemandLastOneDay }}</el-descriptions-item>
      <el-descriptions-item label="上1日反向无功最大需量发生时间">{{ object2.occurrenceTimeLastOneDayMaximumReverseReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上2日正向有功最大需量">{{ object2.maximumPositiveActivePowerDemandLastTwoDay }}</el-descriptions-item>
      <el-descriptions-item label="上2日正向有功最大需量发生时间">{{ object2.occurrenceTimeLastTwoDayMaximumPositiveActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上2日反向有功最大需量">{{ object2.maximumReverseActivePowerDemandLastTwoDay }}</el-descriptions-item>
      <el-descriptions-item label="上2日反向有功最大需量发生时间">{{ object2.occurrenceTimeLastTwoDayMaximumReverseActivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上2日正向无功最大需量">{{ object2.maximumPositiveReactivePowerDemandLastTwoDay }}</el-descriptions-item>
      <el-descriptions-item label="上2日正向无功最大需量发生时间">{{ object2.occurrenceTimeLastTwoDayMaximumPositiveReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="上2日反向无功最大需量">{{ object2.maximumReverseReactivePowerDemandLastTwoDay }}</el-descriptions-item>
      <el-descriptions-item label="上2日反向无功最大需量发生时间">{{ object2.occurrenceTimeLastTwoDayMaximumReverseReactivePowerDemand }}</el-descriptions-item>
      <el-descriptions-item label="当前正向有功需量">{{ object2.currentPositiveActiveDemand }}</el-descriptions-item>
      <el-descriptions-item label="当前反向有功需量">{{ object2.currentReverseActiveDemand }}</el-descriptions-item>
      <el-descriptions-item label="当前正向无功需量">{{ object2.currentPositiveReactiveDemand }}</el-descriptions-item>
      <el-descriptions-item label="当前反向无功需量">{{ object2.currentReverseReactiveDemand }}</el-descriptions-item>
      <el-descriptions-item label="A相电压极大值">{{ object2.maximumVoltageAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相电压极大值发生时间">{{ object2.occurrenceTimeAPhaseMaximumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="B相电压极大值">{{ object2.maximumVoltageBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电压极大值发生时间">{{ object2.occurrenceTimeBPhaseMaximumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="C相电压极大值">{{ object2.maximumVoltageCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电压极大值发生时间">{{ object2.occurrenceTimeCPhaseMaximumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="A相电流极大值">{{ object2.maximumCurrentAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相电流极大值发生时间">{{ object2.occurrenceTimeAPhaseMaximumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="B相电流极大值">{{ object2.maximumCurrentBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电流极大值发生时间">{{ object2.occurrenceTimeBPhaseMaximumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="C相电流极大值">{{ object2.maximumCurrentCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电流极大值发生时间">{{ object2.occurrenceTimeCPhaseMaximumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="A相有功功率极大值">{{ object2.maximumActivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相有功功率极大值发生时间">{{ object2.occurrenceTimeAPhaseMaximumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="B相有功功率极大值">{{ object2.maximumActivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相有功功率极大值发生时间">{{ object2.occurrenceTimeBPhaseMaximumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="C相有功功率极大值">{{ object2.maximumActivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相有功功率极大值发生时间">{{ object2.occurrenceTimeCPhaseMaximumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总有功功率极大值">{{ object2.maximumTotalActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总有功功率极大值发生时间">{{ object2.occurrenceTimeTotalMaximumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相无功功率极大值">{{ object2.maximumReactivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相无功功率极大值发生时间">{{ object2.occurrenceTimeAPhaseMaximumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="B相无功功率极大值">{{ object2.maximumReactivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相无功功率极大值发生时间">{{ object2.occurrenceTimeBPhaseMaximumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="C相无功功率极大值">{{ object2.maximumReactivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相无功功率极大值发生时间">{{ object2.occurrenceTimeCPhaseMaximumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="总无功功率极大值">{{ object2.maximumTotalReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="总无功功率极大值发生时间">{{ object2.occurrenceTimeTotalMaximumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相视在功率极大值">{{ object2.maximumApparentPowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相视在功率极大值发生时间">{{ object2.occurrenceTimeAPhaseMaximumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="B相视在功率极大值">{{ object2.maximumApparentPowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相视在功率极大值发生时间">{{ object2.occurrenceTimeBPhaseMaximumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="C相视在功率极大值">{{ object2.maximumApparentPowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相视在功率极大值发生时间">{{ object2.occurrenceTimeCPhaseMaximumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="总视在功率极大值">{{ object2.maximumTotalApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="总视在功率极大值发生时间">{{ object2.occurrenceTimeTotalMaximumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="A相电压极小值">{{ object2.minimumVoltageAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相电压极小值发生时间">{{ object2.occurrenceTimeAPhaseMinimumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="B相电压极小值">{{ object2.minimumVoltageBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电压极小值发生时间">{{ object2.occurrenceTimeBPhaseMinimumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="C相电压极小值">{{ object2.minimumVoltageCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电压极小值发生时间">{{ object2.occurrenceTimeCPhaseMinimumVoltage }}</el-descriptions-item>
      <el-descriptions-item label="A相电流极小值">{{ object2.minimumCurrentAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相电流极小值发生时间">{{ object2.occurrenceTimeAPhaseMinimumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="B相电流极小值">{{ object2.minimumCurrentBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相电流极小值发生时间">{{ object2.occurrenceTimeBPhaseMinimumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="C相电流极小值">{{ object2.minimumCurrentCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相电流极小值发生时间">{{ object2.occurrenceTimeCPhaseMinimumCurrent }}</el-descriptions-item>
      <el-descriptions-item label="A相有功功率极小值">{{ object2.minimumActivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相有功功率极小值发生时间">{{ object2.occurrenceTimeAPhaseMinimumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="B相有功功率极小值">{{ object2.minimumActivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相有功功率极小值发生时间">{{ object2.occurrenceTimeBPhaseMinimumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="C相有功功率极小值">{{ object2.minimumActivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相有功功率极小值发生时间">{{ object2.occurrenceTimeCPhaseMinimumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总有功功率极小值">{{ object2.minimumTotalActivePower }}</el-descriptions-item>
      <el-descriptions-item label="总有功功率极小值发生时间">{{ object2.occurrenceTimeTotalMinimumActivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相无功功率极小值">{{ object2.minimumReactivePowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相无功功率极小值发生时间">{{ object2.occurrenceTimeAPhaseMinimumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="B相无功功率极小值">{{ object2.minimumReactivePowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相无功功率极小值发生时间">{{ object2.occurrenceTimeBPhaseMinimumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="C相无功功率极小值">{{ object2.minimumReactivePowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相无功功率极小值发生时间">{{ object2.occurrenceTimeCPhaseMinimumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="总无功功率极小值">{{ object2.minimumTotalReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="总无功功率极小值发生时间">{{ object2.occurrenceTimeTotalMinimumReactivePower }}</el-descriptions-item>
      <el-descriptions-item label="A相视在功率极小值">{{ object2.minimumApparentPowerAPhase }}</el-descriptions-item>
      <el-descriptions-item label="A相视在功率极小值发生时间">{{ object2.occurrenceTimeAPhaseMinimumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="B相视在功率极小值">{{ object2.minimumApparentPowerBPhase }}</el-descriptions-item>
      <el-descriptions-item label="B相视在功率极小值发生时间">{{ object2.occurrenceTimeBPhaseMinimumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="C相视在功率极小值">{{ object2.minimumApparentPowerCPhase }}</el-descriptions-item>
      <el-descriptions-item label="C相视在功率极小值发生时间">{{ object2.occurrenceTimeCPhaseMinimumApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="总视在功率极小值">{{ object2.minimumTotalApparentPower }}</el-descriptions-item>
      <el-descriptions-item label="总视在功率极小值发生时间">{{ object2.occurrenceTimeTotalMinimumApparentPower }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
export default {
  props: {
    height: undefined
  },
  data () {
    return {
      object1: {
        totalElectricityCurrentCoipmbinationActive: "",
        spikeElectricityCurrentCoipmbinationActive: "",
        peakElectricityCurrentCoipmbinationActive: "",
        meanElectricityCurrentCoipmbinationActive: "",
        valleyElectricityCurrentCoipmbinationActive: "",
        totalElectricityCurrentPositiveActive: "",
        spikeElectricityCurrentPositiveActive: "",
        peakElectricityCurrentPositiveActive: "",
        meanElectricityCurrentPositiveActive: "",
        valleyElectricityCurrentPositiveActive: "",
        totalElectricityCurrentReverseActive: "",
        spikeElectricityCurrentReverseActive: "",
        peakElectricityCurrentReverseActive: "",
        meanElectricityCurrentReverseActive: "",
        valleyElectricityCurrentReverseActive: "",
        totalElectricityCurrentCoipmbinationReactive: "",
        spikeElectricityCurrentCoipmbinationReactive: "",
        peakElectricityCurrentCoipmbinationReactive: "",
        meanElectricityCurrentCoipmbinationReactive: "",
        valleyElectricityCurrentCoipmbinationReactive: "",
        totalElectricityCurrentPositiveReactive: "",
        spikeElectricityCurrentPositiveReactive: "",
        peakElectricityCurrentPositiveReactive: "",
        meanElectricityCurrentPositiveReactive: "",
        valleyElectricityCurrentPositiveReactive: "",
        totalElectricityCurrentReverseReactive: "",
        spikeElectricityCurrentReverseReactive: "",
        peakElectricityCurrentReverseReactive: "",
        meanElectricityCurrentReverseReactive: "",
        valleyElectricityCurrentReverseReactive: "",
        time: "",
        firstLineCommunicationCommunicationAddress: "",
        firstLineCommunicationBaudRate: "",
        firstLineCommunicationCheckBit: "",
        firstLineCommunicationstopBit: "",
        secondLineCommunicationCommunicationAddress: "",
        secondLineCommunicationBaudRate: "",
        secondLineCommunicationCheckBit: "",
        secondLineCommunicationstopBit: "",
        pulseConst: "",
        timeTableNumberFirstZone: "",
        startDateFirstZone: "",
        timeTableNumberSecondZone: "",
        startDateSecondZone: "",
        timeTableNumberThirdZone: "",
        startDateThirdZone: "",
        timeTableNumberFourthZone: "",
        startDateFourthZone: ""
      },
      object2: {
        voltageAPhase: "",
        voltageBPhase: "",
        voltageCPhase: "",
        currentAPhase: "",
        currentBPhase: "",
        currentCPhase: "",
        frequency: "",
        voltageABLine: "",
        voltageBCLine: "",
        voltageACLine: "",
        positiveActiveMaximumDemand: "",
        occurTimePositiveActiveMaximumDemandHourMinute: "",
        occurTimePositiveActiveMaximumDemand: "",
        reverseActiveMaximumDemand: "",
        occurTimeReverseActiveMaximumDemandHourMinute: "",
        occurTimeReverseActiveMaximumDemand: "",
        positiveReactiveMaximumDemand: "",
        occurTimePositiveReactiveMaximumDemandHourMinute: "",
        occurTimePositiveReactiveMaximumDemand: "",
        reverseReactiveMaximumDemand: "",
        occurTimeReverseReactiveMaximumDemandHourMinute: "",
        occurTimeReverseReactiveMaximumDemand: "",
        positiveActiveElectricityAPhase: "",
        positiveActiveElectricityBPhase: "",
        positiveActiveElectricityCPhase: "",
        voltageRatioPT: "",
        currentRatioCT: "",
        didoStateNoVoltageState: "",
        runningState: "",
        zeroSequenceCurrent: "",
        voltageUnbalanceDegree: "",
        currentUnbalanceDegree: "",
        activePowerAPhase: "",
        activePowerBPhase: "",
        activePowerCPhase: "",
        totalActivePower: "",
        reactivePowerAPhase: "",
        reactivePowerBPhase: "",
        reactivePowerCPhase: "",
        totalReactivePower: "",
        apparentPowerAPhase: "",
        apparentPowerBPhase: "",
        apparentPowerCPhase: "",
        totalApparentPower: "",
        powerFactorAPhase: "",
        powerFactorBPhase: "",
        powerFactorCPhase: "",
        totalPowerFactor: "",
        maximumPositiveActivePowerDemandToday: "",
        occurrenceTimeTodayMaximumPositiveActivePowerDemandHourMinute: "",
        maximumReverseActivePowerDemandToday: "",
        occurrenceTimeTodayMaximumReverseActivePowerDemandHourMinute: "",
        maximumPositiveReactivePowerDemandToday: "",
        occurrenceTimeTodayMaximumPositiveReactivePowerDemandHourMinute: "",
        maximumReverseReactivePowerDemandToday: "",
        occurrenceTimeTodayMaximumReverseReactivePowerDemandHourMinute: "",
        maximumPositiveActivePowerDemandLastOneDay: "",
        occurrenceTimeLastOneDayMaximumPositiveActivePowerDemandHourMinute: "",
        maximumReverseActivePowerDemandLastOneDay: "",
        occurrenceTimeLastOneDayMaximumReverseActivePowerDemandHourMinute: "",
        maximumPositiveReactivePowerDemandLastOneDay: "",
        occurrenceTimeLastOneDayMaximumPositiveReactivePowerDemandHourMinute: "",
        maximumReverseReactivePowerDemandLastOneDay: "",
        occurrenceTimeLastOneDayMaximumReverseReactivePowerDemandHourMinute: "",
        maximumPositiveActivePowerDemandLastTwoDay: "",
        occurrenceTimeLastTwoDayMaximumPositiveActivePowerDemandHourMinute: "",
        maximumReverseActivePowerDemandLastTwoDay: "",
        occurrenceTimeLastTwoDayMaximumReverseActivePowerDemandHourMinute: "",
        maximumPositiveReactivePowerDemandLastTwoDay: "",
        occurrenceTimeLastTwoDayMaximumPositiveReactivePowerDemandHourMinute: "",
        maximumReverseReactivePowerDemandLastTwoDay: "",
        occurrenceTimeLastTwoDayMaximumReverseReactivePowerDemandHourMinute: "",
        currentPositiveActiveDemand: "",
        currentReverseActiveDemand: "",
        currentPositiveReactiveDemand: "",
        currentReverseReactiveDemand: "",
        maximumVoltageAPhase: "",
        occurrenceTimeAPhaseMaximumVoltage: "",
        occurrenceTimeAPhaseMaximumVoltageHourMinute: "",
        maximumVoltageBPhase: "",
        occurrenceTimeBPhaseMaximumVoltage: "",
        occurrenceTimeBPhaseMaximumVoltageHourMinute: "",
        maximumVoltageCPhase: "",
        occurrenceTimeCPhaseMaximumVoltage: "",
        occurrenceTimeCPhaseMaximumVoltageHourMinute: "",
        maximumCurrentAPhase: "",
        occurrenceTimeAPhaseMaximumCurrent: "",
        occurrenceTimeAPhaseMaximumCurrentHourMinute: "",
        maximumCurrentBPhase: "",
        occurrenceTimeBPhaseMaximumCurrent: "",
        occurrenceTimeBPhaseMaximumCurrentHourMinute: "",
        maximumCurrentCPhase: "",
        occurrenceTimeCPhaseMaximumCurrent: "",
        occurrenceTimeCPhaseMaximumCurrentHourMinute: "",
        maximumActivePowerAPhase: "",
        occurrenceTimeAPhaseMaximumActivePower: "",
        occurrenceTimeAPhaseMaximumActivePowerHourMinute: "",
        maximumActivePowerBPhase: "",
        occurrenceTimeBPhaseMaximumActivePower: "",
        occurrenceTimeBPhaseMaximumActivePowerHourMinute: "",
        maximumActivePowerCPhase: "",
        occurrenceTimeCPhaseMaximumActivePower: "",
        occurrenceTimeCPhaseMaximumActivePowerHourMinute: "",
        maximumTotalActivePower: "",
        occurrenceTimeTotalMaximumActivePower: "",
        occurrenceTimeTotalMaximumActivePowerHourMinute: "",
        maximumReactivePowerAPhase: "",
        occurrenceTimeAPhaseMaximumReactivePower: "",
        occurrenceTimeAPhaseMaximumReactivePowerHourMinute: "",
        maximumReactivePowerBPhase: "",
        occurrenceTimeBPhaseMaximumReactivePower: "",
        occurrenceTimeBPhaseMaximumReactivePowerHourMinute: "",
        maximumReactivePowerCPhase: "",
        occurrenceTimeCPhaseMaximumReactivePower: "",
        occurrenceTimeCPhaseMaximumReactivePowerHourMinute: "",
        maximumTotalReactivePower: "",
        occurrenceTimeTotalMaximumReactivePower: "",
        occurrenceTimeTotalMaximumReactivePowerHourMinute: "",
        maximumApparentPowerAPhase: "",
        occurrenceTimeAPhaseMaximumApparentPower: "",
        occurrenceTimeAPhaseMaximumApparentPowerHourMinute: "",
        maximumApparentPowerBPhase: "",
        occurrenceTimeBPhaseMaximumApparentPower: "",
        occurrenceTimeBPhaseMaximumApparentPowerHourMinute: "",
        maximumApparentPowerCPhase: "",
        occurrenceTimeCPhaseMaximumApparentPower: "",
        occurrenceTimeCPhaseMaximumApparentPowerHourMinute: "",
        maximumTotalApparentPower: "",
        occurrenceTimeTotalMaximumApparentPower: "",
        occurrenceTimeTotalMaximumApparentPowerHourMinute: "",
        minimumVoltageAPhase: "",
        occurrenceTimeAPhaseMinimumVoltage: "",
        occurrenceTimeAPhaseMinimumVoltageHourMinute: "",
        minimumVoltageBPhase: "",
        occurrenceTimeBPhaseMinimumVoltage: "",
        occurrenceTimeBPhaseMinimumVoltageHourMinute: "",
        minimumVoltageCPhase: "",
        occurrenceTimeCPhaseMinimumVoltage: "",
        occurrenceTimeCPhaseMinimumVoltageHourMinute: "",
        minimumCurrentAPhase: "",
        occurrenceTimeAPhaseMinimumCurrent: "",
        occurrenceTimeAPhaseMinimumCurrentHourMinute: "",
        minimumCurrentBPhase: "",
        occurrenceTimeBPhaseMinimumCurrent: "",
        occurrenceTimeBPhaseMinimumCurrentHourMinute: "",
        minimumCurrentCPhase: "",
        occurrenceTimeCPhaseMinimumCurrent: "",
        occurrenceTimeCPhaseMinimumCurrentHourMinute: "",
        minimumActivePowerAPhase: "",
        occurrenceTimeAPhaseMinimumActivePower: "",
        occurrenceTimeAPhaseMinimumActivePowerHourMinute: "",
        minimumActivePowerBPhase: "",
        occurrenceTimeBPhaseMinimumActivePower: "",
        occurrenceTimeBPhaseMinimumActivePowerHourMinute: "",
        minimumActivePowerCPhase: "",
        occurrenceTimeCPhaseMinimumActivePower: "",
        occurrenceTimeCPhaseMinimumActivePowerHourMinute: "",
        minimumTotalActivePower: "",
        occurrenceTimeTotalMinimumActivePower: "",
        occurrenceTimeTotalMinimumActivePowerHourMinute: "",
        minimumReactivePowerAPhase: "",
        occurrenceTimeAPhaseMinimumReactivePower: "",
        occurrenceTimeAPhaseMinimumReactivePowerHourMinute: "",
        minimumReactivePowerBPhase: "",
        occurrenceTimeBPhaseMinimumReactivePower: "",
        occurrenceTimeBPhaseMinimumReactivePowerHourMinute: "",
        minimumReactivePowerCPhase: "",
        occurrenceTimeCPhaseMinimumReactivePower: "",
        occurrenceTimeCPhaseMinimumReactivePowerHourMinute: "",
        minimumTotalReactivePower: "",
        occurrenceTimeTotalMinimumReactivePower: "",
        occurrenceTimeTotalMinimumReactivePowerHourMinute: "",
        minimumApparentPowerAPhase: "",
        occurrenceTimeAPhaseMinimumApparentPower: "",
        occurrenceTimeAPhaseMinimumApparentPowerHourMinute: "",
        minimumApparentPowerBPhase: "",
        occurrenceTimeBPhaseMinimumApparentPower: "",
        occurrenceTimeBPhaseMinimumApparentPowerHourMinute: "",
        minimumApparentPowerCPhase: "",
        occurrenceTimeCPhaseMinimumApparentPower: "",
        occurrenceTimeCPhaseMinimumApparentPowerHourMinute: "",
        minimumTotalApparentPower: "",
        occurrenceTimeTotalMinimumApparentPower: "",
        occurrenceTimeTotalMinimumApparentPowerHourMinute: ""
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-descriptions__body {
  height: 100%;
  overflow: auto;
}
</style>