<template>
  <div class="m-l-15" v-loading="loading">

  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      table: [],
      baudRateList: $statics.BAUD_RATE_LIST,
      switchOnOfList: $statics.SWITCH_ON_OFF_LIST,
      onOffStatusList: $statics.ON_OFF_STATUS_LIST,
      alarmStatusList: $statics.ALARM_STATUS_LIST,
      deviceRunSatusList: $statics.DEVICE_RUN_STATUS_LIST,
      monitorSwitchStatusMap: $statics.MONITOR_SWITCH_STATUS_MAP,
      forceModeList: $statics.FORCE_MODE_LIST,
    }
  }
}
</script>