<template>
  <div v-loading="loading" class="view-alarm-box">
    <div :style="`overflow: auto; height: ${height}px;`">
      <el-descriptions title="三级告警信息" :column="5" contentClassName="des-content-box" border size="small">
        <el-descriptions-item label="总压过高(0.1V)">
          <div :class="warningData[0] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[1] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[2] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="总压过低(0.1V)">
          <div :class="warningData[3] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[4] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[5] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="单体过高(mV)">
          <div :class="warningData[6] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[7] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[8] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="单体过低(mV)">
          <div :class="warningData[9] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[10] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[11] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="高段压差过高">
          <div :class="warningData[12] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[13] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[14] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="中段压差过高">
          <div :class="warningData[15] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[16] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[17] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="低段压差过高">
          <div :class="warningData[18] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[19] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[20] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="辅助电压过低">
          <div :class="warningData[21] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[22] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[23] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="充电电流过高">
          <div :class="warningData[24] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[25] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[26] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="放电电流过高">
          <div :class="warningData[27] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[28] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[29] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="反充电流过高">
          <div :class="warningData[30] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[31] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[31] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="SOC 值过低(‰)">
          <div :class="warningData[33] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[34] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[35] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="SOH 值过低(‰)">
          <div :class="warningData[36] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[37] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[38] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="继电器温度过高">
          <div :class="warningData[39] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[40] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[41] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="充电温度过高">
          <div :class="warningData[42] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[43] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[44] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="放电温度过高">
          <div :class="warningData[45] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[46] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[47] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="充电温度过低">
          <div :class="warningData[48] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[49] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[50] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="放电温度过低">
          <div :class="warningData[51] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[52] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[53] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="温差过大(0.1℃)">
          <div :class="warningData[54] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[55] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[56] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="均衡温度过高">
          <div :class="warningData[57] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[58] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[59] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="绝缘电阻过低">
          <div :class="warningData[60] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[61] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[62] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="总压采样异常">
          <div :class="warningData[63] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[64] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[65] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="静置温度过高">
          <div :class="warningData[66] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[67] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[68] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="静置温度过低">
          <div :class="warningData[69] ? 'warning-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>一级告警</span></div>
          <div :class="warningData[70] ? 'alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>二级告警</span></div>
          <div :class="warningData[71] ? 'critical-alarm-color': 'normal-icon-box'"><i class="el-icon-warning"></i><span>三级告警</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="主正接触器">
          <div :class="warningData[72] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[73] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="主负接触器">
          <div :class="warningData[74] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[75] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="充电接触器">
          <div :class="warningData[76] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[77] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="预热接触器">
          <div :class="warningData[78] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[79] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="风扇接触器">
          <div :class="warningData[80] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[81] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
        <el-descriptions-item label="预充接触器">
          <div :class="warningData[82] ? 'warning-icon-box_1': 'normal-icon-box'"><i class="el-icon-warning"></i><span>无法吸合</span></div>
          <div :class="warningData[83] ? 'warning-icon-box_2': 'normal-icon-box'"><i class="el-icon-warning"></i><span>粘连故障</span></div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <h3>自检告警</h3>
      <div v-for="faultsName in faultsNames" :class="'faults-box ' + (faults[faultsName.key] ? 'warning-icon-box_1': 'normal-icon-box')"><i class="el-icon-warning"></i><span>{{ faultsName.label }}</span></div>
    </div>
    <div class="right-btns m-t-20">
      <el-button size="small" @click="onReadConfig">参数读取</el-button>
    </div>
  </div>
</template>
<script>
import { systemConfig } from "@/api/device";
import { checkResult } from "@/utils/device";
const { binaryAppendZero, hexToBinary } = $numberMatrixing;

export default {
  props: {
    level: { // 密码等级，暂时没有用到
      type: Object,
      default: {}
    },
    deviceInfo: {
      type: Object,
      default: {}
    },
    data: {
      type: Object,
      default: {}
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      value: "1",
      faultsNames: [
        { label: "485通讯故障", key: "rs485Fault" },
        { label: "CAN1通讯故障", key: "can1Fault" },
        { label: "CAN2通讯故障", key: "can2Fault" },
        { label: "预充电故障", key: "preCHGFault" },
        { label: "充电通讯故障", key: "chgComFault" },
        { label: "充电过充故障", key: "chgVFault" },
        { label: "均衡故障", key: "balance" },
        { label: "断线故障", key: "lineBreak" },
        { label: "6811自检故障", key: "ltc6811Fault" },
        { label: "6811通讯故障", key: "ltc6811Com" },
        { label: "开关矩阵故障", key: "switchArray" },
        { label: "高边自检故障", key: "hlsd" },
        { label: "高压互锁故障", key: "hvil" }
      ],
      faults: {},
      warningData: new Array(84).fill("")
    }
  },
  created () {
    for (const fault of this.faultsNames) {
      this.faults[fault.key] = "";
    }
  },
  methods: {
    async onReadConfig () {
      try {
        this.loading = true;
        $utils.setDeviceDoing(this.deviceInfo);

        await this.getWarningData1();
        await this.getWarningData2();
        await this.getScomFault();

      } finally {
        this.loading = false;
        $utils.setDeviceDone(this.deviceInfo);
      }
    },
    async getWarningData1 () {

      const params = {
        code: this.deviceInfo.code,
        masterID: $utils.calcMasterIDToHex(this.data.lecuAddr),
        price: "32F932FA32FB32FC32FD32FE" // 三级告警的所有地址
      };

      try {
        const type = "read";
        const res = await systemConfig[type](params);

        checkResult(this, res, { error: true, type }, (valid) => {
          if (valid) {

            const values = [];

            for (let i = 0, len = res.length / 8; i < len; i += 2) {
              const value1 = res.slice(i * 8 + 4, (i + 1) * 8);
              const value2 = res.slice((i + 1) * 8 + 4, (i + 2) * 8);
              let value = `${value2}${value1}`;
              value = Number(`0x${value}`).toString(2);
              value = binaryAppendZero(value, 32);
              values.push(value);
            }

            const { warningData } = $utils.parseWarningData(values.join(""));
            this.warningData = warningData;
          }
        });
      } finally {
      }
    },
    async getWarningData2 () {

      const params = {
        code: this.deviceInfo.code,
        masterID: $utils.calcMasterIDToHex(this.data.lecuAddr),
        price: "336F" // 三级告警的所有地址
      };

      try {
        const type = "read";
        const res = await systemConfig[type](params);
        
        checkResult(this, res, { error: true, type }, (valid) => {
          if (valid) {

            let contracFault = res.slice(4);
            contracFault = Number(`0x${contracFault}`).toString(2);
            contracFault = binaryAppendZero(contracFault, 16);
            contracFault = String.reverse(contracFault);

            // 预充接触器 == 预充接触器
            this.warningData[82] = contracFault[0] === "1";
            this.warningData[83] = contracFault[1] === "1";
            // 放电接触器 == 主正接触器
            this.warningData[72] = contracFault[2] === "1";
            this.warningData[73] = contracFault[3] === "1";
            // 充电接触器 == 充电接触器
            this.warningData[76] = contracFault[4] === "1";
            this.warningData[77] = contracFault[5] === "1";
            // 主负接触器 == 主负接触器
            this.warningData[74] = contracFault[6] === "1";
            this.warningData[75] = contracFault[7] === "1";
            // 风扇驱动接触器 == 风扇接触器
            this.warningData[80] = contracFault[8] === "1";
            this.warningData[81] = contracFault[9] === "1";
            // 加热棒驱动接触器 == 预热接触器
            this.warningData[78] = contracFault[10] === "1";
            this.warningData[79] = contracFault[11] === "1";
          }
        });
      } finally {
      }
    },
    async getScomFault () {
      const params = {
        code: this.deviceInfo.code,
        masterID: $utils.calcMasterIDToHex(this.data.lecuAddr),
        price: "336D336E" // 自检告警的地址
      };

      try {
        const type = "read";
        const res = await systemConfig[type](params);
        
        checkResult(this, res, { error: true, success: false }, (valid) => {
          if (valid) {

            let scomFault = res.slice(4, 8);
            scomFault = hexToBinary(scomFault, 2);
            let stestFault = res.slice(12);
            stestFault = hexToBinary(stestFault, 2);

            this.faults.rs485Fault = scomFault[7] === "1";
            this.faults.can1Fault = scomFault[6] === "1";
            this.faults.can2Fault = scomFault[5] === "1";
            this.faults.preCHGFault = scomFault[4] === "1";
            this.faults.chgComFault = scomFault[3] === "1";
            this.faults.chgVFault = scomFault[2] === "1";
            this.faults.balance = scomFault[1] === "1";
            this.faults.lineBreak = scomFault[0] === "1";

            this.faults.ltc6811Fault = stestFault[7] === "1";
            this.faults.ltc6811Com = stestFault[6] === "1";
            this.faults.switchArray = stestFault[5] === "1";
            this.faults.hlsd = stestFault[4] === "1";
            this.faults.hvil = stestFault[3] === "1";
          }
        });
      } finally {

      }
    },
    onClosed () {
      return true;
    }
  }
}
</script>
<style lang="scss" scoped>
.view-alarm-box {

  i, span {
    display: inline-block;
    font-size: 16px;
  }
  i {
    width: 25px;
    text-align: left;
  }
}

.warning-icon-box_1 {
  color: #b00e0e;
}
.warning-icon-box_2 {
  color: #a23900;
}

.faults-box {
  display: inline-block;
  width: calc(100% / 7);
}

::v-deep .des-content-box {
  min-width: 120px;
}
</style>