<template>
  <div v-loading="loading" class="m-l-15">
    <el-descriptions :column="3" border size="mini"
      :title="`主控报警信息【更新时间：${object.updateTime || object.createTime || '' }】【处理状态：${object.handleStatus}】`"
      label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
      <template slot="extra">
        <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
      </template>
      <el-descriptions-item label="总压过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[0])">
          <i v-if="object.warning[0]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[1])">
          <i v-if="object.warning[1]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[2])">
          <i v-if="object.warning[2]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[3])">
          <i v-if="object.warning[3]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[4])">
          <i v-if="object.warning[4]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[5])">
          <i v-if="object.warning[5]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span> 
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[6])">
          <i v-if="object.warning[6]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[7])">
          <i v-if="object.warning[7]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[8])">
          <i v-if="object.warning[8]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[9])">
          <i v-if="object.warning[9]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[10])">
          <i v-if="object.warning[10]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="单体过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[11])">
          <i v-if="object.warning[11]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="高段压差过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[12])">
          <i v-if="object.warning[12]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="高段压差过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[13])">
          <i v-if="object.warning[13]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="高段压差过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[14])">
          <i v-if="object.warning[14]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="中段压差过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[15])">
          <i v-if="object.warning[15]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="中段压差过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[16])">
          <i v-if="object.warning[16]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="中段压差过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[17])">
          <i v-if="object.warning[17]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="低段压差过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[18])">
          <i v-if="object.warning[18]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="低段压差过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[19])">
          <i v-if="object.warning[19]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="低段压差过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[20])">
          <i v-if="object.warning[20]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="辅助压差过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[21])">
          <i v-if="object.warning[21]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="辅助压差过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[22])">
          <i v-if="object.warning[22]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="辅助压差过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[23])">
          <i v-if="object.warning[23]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电电流过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[24])">
          <i v-if="object.warning[24]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电电流过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[25])">
          <i v-if="object.warning[25]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电电流过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[26])">
          <i v-if="object.warning[26]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电电流过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[27])">
          <i v-if="object.warning[27]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电电流过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[28])">
          <i v-if="object.warning[28]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电电流过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[29])">
          <i v-if="object.warning[29]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span> 
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="反充电流过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[30])">
          <i v-if="object.warning[30]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="反充电流过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[31])">
          <i v-if="object.warning[31]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="反充电流过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[32])">
          <i v-if="object.warning[32]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOC值过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[33])">
          <i v-if="object.warning[33]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOC值过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[34])">
          <i v-if="object.warning[34]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOC值过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[35])">
          <i v-if="object.warning[35]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOH值过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[36])">
          <i v-if="object.warning[36]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOH值过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[37])">
          <i v-if="object.warning[37]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="SOH值过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[38])">
          <i v-if="object.warning[38]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="继电器温度过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[39])">
          <i v-if="object.warning[39]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="继电器温度过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[40])">
          <i v-if="object.warning[40]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="继电器温度过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[41])">
          <i v-if="object.warning[41]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[42])">
          <i v-if="object.warning[42]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[43])">
          <i v-if="object.warning[43]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[44])">
          <i v-if="object.warning[44]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[45])">
          <i v-if="object.warning[45]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span> 
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[46])">
          <i v-if="object.warning[46]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span> 
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[47])">
          <i v-if="object.warning[47]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[48])">
          <i v-if="object.warning[48]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[49])">
          <i v-if="object.warning[49]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电温度过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[50])">
          <i v-if="object.warning[50]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[51])">
          <i v-if="object.warning[51]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[52])">
          <i v-if="object.warning[52]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="放电温度过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[53])">
          <i v-if="object.warning[53]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="温差过大一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[54])">
          <i v-if="object.warning[54]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="温差过大二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[55])">
          <i v-if="object.warning[55]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="温差过大三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[56])">
          <i v-if="object.warning[56]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="均衡温度过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[57])">
          <i v-if="object.warning[57]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="均衡温度过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[58])">
          <i v-if="object.warning[58]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="均衡温度过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[59])">
          <i v-if="object.warning[59]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="绝缘电阻过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[60])">
          <i v-if="object.warning[60]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="绝缘电阻过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[61])">
          <i v-if="object.warning[61]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="绝缘电阻过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[62])">
          <i v-if="object.warning[62]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压采样异常一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[63])">
          <i v-if="object.warning[63]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压采样异常二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[64])">
          <i v-if="object.warning[64]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="总压采样异常三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[65])">
          <i v-if="object.warning[65]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过高一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[66])">
          <i v-if="object.warning[66]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过高二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[67])">
          <i v-if="object.warning[67]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过高三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[68])">
          <i v-if="object.warning[68]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过低一级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[69])">
          <i v-if="object.warning[69]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过低二级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[70])">
          <i v-if="object.warning[70]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="静置温度过低三级告警">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[71])">
          <i v-if="object.warning[71]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span> 
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <!-- <el-descriptions-item label="主正接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[72])">
          <i v-if="object.warning[72]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="主正接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[73])">
          <i v-if="object.warning[73]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="主负接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[74])">
          <i v-if="object.warning[74]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>    
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="主负接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[75])">
          <i v-if="object.warning[75]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[76])">
          <i v-if="object.warning[76]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>  
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="充电接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[77])">
          <i v-if="object.warning[77]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>     
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="预热接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[78])">
          <i v-if="object.warning[78]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="预热接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[79])">
          <i v-if="object.warning[79]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="风扇接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[80])">
          <i v-if="object.warning[80]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="风扇接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[81])">
          <i v-if="object.warning[81]" class="warning-color icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="预充接触器无法吸合">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[82])">
          <i v-if="object.warning[82]" class="alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item>
      <el-descriptions-item label="预充接触器粘连故障">
        <span v-if="utils.checkObjectIsNotEmpty(object.warning[83])">
          <i v-if="object.warning[83]" class="critical-alarm-color  icon-warning-data el-icon-warning"></i>
          <span v-else>无告警</span>
        </span>
        <span v-else>无数据</span>
      </el-descriptions-item> -->
    </el-descriptions>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MasterControlAlarmApi from "@/api/470/MasterControlAlarm";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      handleStatusList: [ 
        { label: "已完成", color: "#303133" },
        { label: "处理中", color: "#E6A23C" },
        { label: "未处理", color: "#F56C6C" }
      ],
      object: {
        createTime: "",
        updateTime: "",
        warning: new Array(84).fill(false),
        handleStatus: 0
      }
    }
  },
  created () {
    console.log("device-alarm");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;
        const params = {
          code: this.deviceInfo.code
        };
        const res = await MasterControlAlarmApi.last(params);
        if (!res) {
          throw new Error();
        }

        const object = $utils.format3LWarning(res);

        if ($utils.checkObjectIsNotEmpty(object.handleStatus)) {
          object.handleStatus = this.handleStatusList[object.handleStatus].label;
        } else {
          object.handleStatus = "无数据";
        }

        this.object = object;
        this.$message.success("设备告警读取成功");
      } catch (err) {
        this.object = {
          warning: []
        };
        this.$message.warning("设备告警读取失败");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-warning-data{
  font-size: 16px !important;
}
</style>