var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.list.length > 0
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "list-table",
              staticStyle: { "margin-left": "10px" },
            },
            [
              _c(
                "el-table",
                {
                  style: `width: 100%; overflow:auto;`,
                  attrs: {
                    data: _vm.list,
                    height: _vm.height,
                    "header-cell-style": {
                      background: "#F7F7F7",
                      color: "#2d2d2d",
                      "text-align": "center",
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "45", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "升级类型",
                      prop: "time",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.typeMap[scope.row.type].label) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4076574639
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "升级版本号",
                      prop: "version",
                      width: "150",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "主控ID",
                      prop: "masterId",
                      width: "80",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "升级花费时间",
                      prop: "time",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "升级状态",
                      prop: "state",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  class: _vm.getStatusVal(scope.row, "tag"),
                                  attrs: {
                                    type: _vm.getStatusVal(scope.row, "tag"),
                                    size: "small",
                                    effect: "dark",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getStatusVal(scope.row, "label")
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3349221174
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请人",
                      prop: "applicant",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "升级时间",
                      prop: "createTime",
                      width: "180",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", [_c("el-empty", { attrs: { description: "暂无数据" } })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }