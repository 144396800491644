const { calcUnsignedDecFromHex } = $numberMatrixing;

export default {
  props: {
    id: undefined,
      deviceInfo: undefined
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;
        await this.getRealTimeData();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getMask (value) {
      const mask = parseInt(value.slice(0, 4), 16).toString(2).split("");
      while (mask.length < this.length) {
        mask.unshift("0");
      }
      const output = mask.reverse().join("");
      return output;
    },
    async getRealTimeData() {

      const res = await this.getDevicesObject(this.id);

      const output = {};
      res.map((obj) => {
        const key = calcUnsignedDecFromHex(obj.batteryNo);
      
        let createTime = obj.createTime;
        if (Array.isArray(createTime)) {
          createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));
        }

        output[key] = {
          num: ((key - this.address) / this.length + 1),
          [this.keyName]: obj[this.keyValue],
          createTime: createTime
        };
      });

      const options = [];
      for (const key in output) {
        options.push(output[key]);
      }

      this.$refs.template.getRealTimeData(options, this.name, this.method, this.length, this.callback);
    }
  }
}