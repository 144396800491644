var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container" }, [
    _c("div", { staticClass: "content" }, [
      _vm.upgrading
        ? _c("div", [
            _vm.deviceInfo.type === 1 || _vm.deviceInfo.type === 9
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: _vm.upgradeMsg } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-progress", {
                            attrs: {
                              "text-color": "#2d2d2d",
                              "text-inside": true,
                              "stroke-width": 25,
                              percentage: _vm.percentage,
                              color: _vm.customColors,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.deviceInfo.type === 3
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: "设备升级等待中，请稍候……" } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.deviceInfo.type === 0 && !_vm.deviceInfo.online
              ? _c("div", [
                  _c(
                    "div",
                    { staticStyle: { margin: "50px 20%" } },
                    [
                      _c(
                        "el-result",
                        { attrs: { title: "升级完毕，设备重启中，请稍候……" } },
                        [
                          _c(
                            "template",
                            { slot: "icon" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "40%", height: "40%" },
                                attrs: {
                                  src: require("@/assets/images/upgrading.gif"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-progress", {
                            attrs: {
                              "text-color": "#2d2d2d",
                              "text-inside": true,
                              "stroke-width": 25,
                              percentage: _vm.percentage,
                              color: _vm.customColors,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              style: `height: 100%;`,
            },
            [
              _vm.isDetail && _vm.tabName === "detail"
                ? _c(
                    "div",
                    { staticClass: "content-btn-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                            disabled: _vm.utils.userReadOnly(_vm.user),
                          },
                          on: { click: _vm.onEdit },
                        },
                        [_vm._v("编辑设备")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "danger",
                            size: "mini",
                            icon: "el-icon-delete",
                            disabled: _vm.utils.userReadOnly(_vm.user),
                          },
                          on: { click: _vm.onDelSite },
                          slot: "reference",
                        },
                        [_vm._v("删除设备")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content-input-box", style: `height: 100%;` },
                [
                  _c(
                    "div",
                    { staticClass: "breadcrumb-box" },
                    [
                      _c(
                        "el-breadcrumb",
                        { attrs: { "separator-class": "el-icon-arrow-right" } },
                        [
                          _c(
                            "el-breadcrumb-item",
                            { attrs: { to: _vm.getGotoObj() } },
                            [_vm._v("设备管理")]
                          ),
                          _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.code))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("detail", {
                    staticStyle: { height: "calc(100% - 34px)" },
                    attrs: { "device-info": _vm.deviceInfo },
                  }),
                ],
                1
              ),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }