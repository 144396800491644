<template>
  <div v-loading="loading">
    <el-form label-position="top">
      <el-form-item label="网络时间">
        <div class="time-box">
          {{ utils.formatTime(dateTime) }}
        </div>
      </el-form-item>
    </el-form>
    <div class="right-btns">
      <el-button type="primary" size="small" @click="onWrite">同步网络时间到设备</el-button>
    </div>
  </div>
</template>
<script>
import modbusApi from "@/utils/modbus";
// import { systemConfig } from "@/api/device";
// import { checkResult } from "@/utils/device";

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      loading: false,
      utils: $utils,
      editLabel: "修改时间",
      dateTime: new Date(),
      timer: 0
    }
  },
  created () {
    console.log("synchronize-time");
    this.run();
  },
  methods: {
    run () {
      const that = this;
      this.timer = setInterval(() => {
        that.dateTime = new Date();
      }, 500);
    },
    onClosed () {
      return true;
    },
    async onWrite () {
      try {
        clearInterval(this.timer);
        this.loading = true;
        const year = calcHexFromUnsignedDec(this.dateTime.getFullYear(), 4);
        const month = calcHexFromUnsignedDec(this.dateTime.getMonth() + 1, 4);
        const date = calcHexFromUnsignedDec(this.dateTime.getDate(), 4);
        const hour = calcHexFromUnsignedDec(this.dateTime.getHours(), 4);
        const minute = calcHexFromUnsignedDec(this.dateTime.getMinutes(), 4);
        const second = calcHexFromUnsignedDec(this.dateTime.getSeconds(), 4);

        // const params = {
        //   code: this.deviceInfo.code,
        //   masterID: "00",
        //   price: `0003${year}0004${month}0005${date}0006${hour}0007${minute}0008${second}28450009`
        // };

        // const res = await systemConfig.writeOne(params);
        // checkResult(this, res, { error: true, success: true});

        const price = [ year, month, date, hour, minute, second ];

        const params = {
          code: this.deviceInfo.code,
          origin: "0003",
          size: price.length,
          type: 10, // 时间同步
          data: price.join("")
        };

        await modbusApi.write(params);
        this.$message.success("参数下发成功");
      } catch (err) {
        console.log(err);
        this.$message.warning("参数下发失败");
      } finally {
        this.loading = false;
        this.run();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.time-box {
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0px 50px 0px;
  text-align: center;
}
</style>