<template>
  <div v-loading="loading">
    <el-form ref="form" :model="table" :rules="rules">   
      <el-table :data="table" border style="width: 100%" :height="height" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="告警项目" prop="name" width="180"></el-table-column>
        <el-table-column label="告警参数">
          <el-table-column label="门槛值">
            <template slot-scope="scope">
              <el-form-item :prop="`${pre}_${columns[scope.$index].value}_1`">
                <el-input v-model="scope.row[`${pre}_${columns[scope.$index].value}_1`]" size="small" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="时间(ms)">
            <template slot-scope="scope">
              <el-form-item :prop="`${pre}_${columns[scope.$index].value}_2`">
                <el-input v-model="scope.row[`${pre}_${columns[scope.$index].value}_2`]" size="small" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="恢复值">
            <template slot-scope="scope">
              <el-form-item :prop="`${pre}_${columns[scope.$index].value}_3`">
                <el-input v-model="scope.row[`${pre}_${columns[scope.$index].value}_3`]" size="small" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="时间(ms)">
            <template slot-scope="scope">
              <el-form-item :prop="`${pre}_${columns[scope.$index].value}_4`">
                <el-input v-model="scope.row[`${pre}_${columns[scope.$index].value}_4`]" size="small" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="right-btns m-t-20">
      <el-button size="small" round @click="onReadConfig">参数读取</el-button>
      <el-button size="small" round @click="onSubmitConfig" type="primary">参数下传</el-button>
      <!-- <el-button size="small" @click="onSubmitConfig" :disabled="notRead || utils.userReadOnly(user)" type="primary">参数下传</el-button> -->
    </div>
  </div>
</template>
<script>
import { validateNumber } from "@/utils/validate";
import modbusApi from "@/utils/modbus";
import HostParameterSettingsApi from "@/api/470/HostParameterSettings";

const { calcHexFromUnsignedDec } = $numberMatrixing;

const columns = [
  { name: "总压过高(0.1V)", value: "Vtotal_OverPretTHD" },
  { name: "总压过低(0.1V)", value: "Vtotal_UnderPretTHD" },
  { name: "单体过高(mV)", value: "Vcell_OverPretTHD" },
  { name: "单体过低(mV)", value: "Vcell_UnderPretTHD" },
  { name: "高段压差过高(mV)", value: "Vdiff_HOverPretTHD" },
  { name: "中段压差过高(mV)", value: "Vdiff_MOverPretTHD" },
  { name: "低段压差过高(mV)", value: "Vdiff_LOverPretTHD" },
  { name: "辅助压差过高(mV)", value: "Vauxpower_UnderPretTHD" },
  { name: "充电电流过高(0.1A)", value: "ICharge_OverPretTHD" },
  { name: "放电电流过高(0.1A)", value: "IDischarge_OverPretTHD" },
  { name: "反充电流过高(0.1A)", value: "FeedCharge_OverPretTHD" },
  { name: "SOC 值过低(‰)", value: "SOC_UnderPretTHD" },
  { name: "SOH 值过低(‰)", value: "SOH_UnderPretTHD" },
  { name: "继电器温度过高(0.1℃)", value: "T_relay_OV_THD" },
  { name: "充电温度过高(0.1℃)", value: "TChg_OV_THD" },
  { name: "放电温度过高(0.1℃)", value: "TDsg_OV_THD" },
  { name: "充电温度过低(0.1℃)", value: "TChg_UV_THD" },
  { name: "放电温度过低(0.1℃)", value: "TDsg_UV_THD" },
  { name: "温差过大(0.1℃)", value: "Tdiff_cell_OV_THD" },
  { name: "均衡温度过高(0.1℃)", value: "Tbalance_OverPretTHD" },
  { name: "绝缘电阻过低(KΩ)", value: "high_resistance_UnderPretTHD" },
  { name: "总压采样异常(%)", value: "Vtotal_DiffPretTHD" },
  { name: "静置温度过高(0.1℃)", value: "TSLIENT_OV_THD" },
  { name: "静置温度过低(0.1℃)", value: "TSLIENT_UV_THD" }
];

export default {
  props: {
    deviceInfo: undefined,
    position: {
      type: Number,
      default: 0
    },
    pre: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: null
    },
    level: {
      type: Number,
      default: 0
    },
    isNewVer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      start: 0x3067,
      size: 96,
      height: 500,
      columns: columns,
      table: new Array(columns.length).fill({}),
      protoTable: [],
      rules: {}
    }
  },
  created () {
    this.start = this.start + this.position * this.size;
    this.getDatas();
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    getDatas () {
      try {
        this.loading = true;
        let _SystemAlarmParamdownloadStru = sessionStorage.getItem(`device-configs-_SystemAlarmParamdownloadStru_${this.pre}:${this.deviceInfo.code}`);

        if (_SystemAlarmParamdownloadStru !== null) {
          _SystemAlarmParamdownloadStru = JSON.parse(_SystemAlarmParamdownloadStru);
          this.notRead = false;
        } else {
          _SystemAlarmParamdownloadStru = {};
        }

        const table = [], protoTable = [], rules= {};

        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];

          table.push({
            name: column.name,
            [`${this.pre}_${column.value}_1`]: _SystemAlarmParamdownloadStru[`${column.value}[0]`] || "",
            [`${this.pre}_${column.value}_2`]: _SystemAlarmParamdownloadStru[`${column.value}[1]`] || "",
            [`${this.pre}_${column.value}_3`]: _SystemAlarmParamdownloadStru[`${column.value}[2]`] || "",
            [`${this.pre}_${column.value}_4`]: _SystemAlarmParamdownloadStru[`${column.value}[3]`] || "",
          });

          protoTable.push({
            [`${this.pre}_${column.value}_1`]: "",
            [`${this.pre}_${column.value}_2`]: "",
            [`${this.pre}_${column.value}_3`]: "",
            [`${this.pre}_${column.value}_4`]: "",
          });

          rules[`${this.pre}_${column.value}_1`] = this.createRule(`${this.pre}_${column.value}_1`, i);
          rules[`${this.pre}_${column.value}_2`] = this.createRule(`${this.pre}_${column.value}_2`, i);
          rules[`${this.pre}_${column.value}_3`] = this.createRule(`${this.pre}_${column.value}_3`, i);
          rules[`${this.pre}_${column.value}_4`] = this.createRule(`${this.pre}_${column.value}_4`, i);
        }

        this.table = table;
        this.protoTable = protoTable;
        this.rules = rules;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    createRule (columnName, index) {
      return [
        {
          validator: (value, rule, callback) => {

            value = this.table[index][columnName];

            if (!$utils.checkObjectIsNotEmpty(value)) {
              callback(`请输入项目值`);
              return;
            }

            validateNumber(null, value, [], callback);
          },
          trigger: 'blur'
        }
      ];
    },
    async onReadConfig () {

      if (this.deviceInfo.type === 1) {
        this.$message.warning("设备升级中，无法读取参数");
        return;
      }

      try {
        this.loading = true;

        const [ res0, res1 ] = await Promise.all([
          HostParameterSettingsApi.list({
            code: this.deviceInfo.code,
            type: 2,
            size: 1,
            page: 1
          }),
          HostParameterSettingsApi.list({
            code: this.deviceInfo.code,
            type: 3,
            size: 1,
            page: 1
          })
        ]);

        const strings = `${res0.data[0].data}${res1.data[0].data}`;
        const value = strings.slice(this.position * this.size * 4, (this.position + 1) * this.size * 4);

        const data = modbusApi.parse(this.start, this.size, value, true);
        const table = [];

        for (const column of columns) {
          const obj = {
            name: column.name,
            [`${this.pre}_${column.value}_1`]: data[`${this.pre}_${column.value}_1`], 
            [`${this.pre}_${column.value}_2`]: data[`${this.pre}_${column.value}_2`], 
            [`${this.pre}_${column.value}_3`]: data[`${this.pre}_${column.value}_3`], 
            [`${this.pre}_${column.value}_4`]: data[`${this.pre}_${column.value}_4`]
          }

          table.push(obj);
        }

        this.protoTable = JSON.parse(JSON.stringify(table));
        this.table = table;
        this.notRead = false;

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    onSubmitConfig () {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          this.loading = false;
          return;
        }

        try {
          this.loading = true;

          let submitObj = {};

          for (let i = 0, len = this.table.length; i < len; i++) {
            const row = this.table[i];
            delete row.name;
            submitObj = Object.assign(submitObj, row);
          }

          const price = modbusApi.objectToAddrVal(this.start, this.size, submitObj);

          const params = {
            code: this.deviceInfo.code,
            origin: calcHexFromUnsignedDec(this.start),
            size: price.length,
            type: 8, // 告警
            data: price.join("").toUpperCase()
          };

          await modbusApi.write(params);
          this.$message.success("参数下发送成功");
        } catch (err) {
          this.$message.warning("参数下发送失败");
        } finally {
          this.loading = false;
        }
      });
    }
  }
}
</script>