<template>
  <div class="m-l-15" v-loading="loading">
    <div>
      <div class="right-btns m-b-20">
        <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getDatas">数据读取</el-button>
      </div>
      <el-table class="fixed-table" ref="table" :height="height - 60" :data="table" style="width: 100%" @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="台号" width="80" prop="platform" align="center"></el-table-column>
        <el-table-column label="直流端口电压(V)" prop="acPortVoltage" align="center"></el-table-column>
        <el-table-column label="直流端口电流(A)" prop="acPortCurrent" align="center"></el-table-column>
        <el-table-column label="直流端口功率(KW)" prop="acPortPower" align="center"></el-table-column>
        <el-table-column label="模块IGBT1温度(℃)" prop="moduleLgbt1" align="center"></el-table-column>
        <el-table-column label="模块IGBT2温度(℃)" prop="moduleLgbt2" align="center"></el-table-column>
        <el-table-column label="模块IGBT3温度(℃)" prop="moduleLgbt3" align="center"></el-table-column>
        <el-table-column label="模块IGBT4温度(℃)" prop="moduleLgbt4" align="center"></el-table-column>
        <el-table-column label="模块IGBT5温度(℃)" prop="moduleLgbt5" align="center"></el-table-column>
        <el-table-column label="模块IGBT6温度(℃)" prop="moduleLgbt6" align="center"></el-table-column>
        <el-table-column label="模块进风口温度(℃)" prop="moduleAirInlet" align="center"></el-table-column>
        <el-table-column label="模块出风口温度(℃)" prop="moduleAirOutlet" align="center"></el-table-column>
        <el-table-column label="交流A相有功功率(KW)" prop="theAcPowerA" align="center"></el-table-column>
        <el-table-column label="交流B相有功功率(KW)" prop="theAcPowerB" align="center"></el-table-column>
        <el-table-column label="交流C相有功功率(KW)" prop="theAcPowerC" align="center"></el-table-column>
        <el-table-column label="直流母线电压(V)" prop="dcBusVoltage" align="center"></el-table-column>
        <el-table-column label="设备运行状态字" width="300" prop="deviceStatus" align="center"></el-table-column>
        <el-table-column label="系统综合故障字" prop="systemSynthesisFault" align="center"></el-table-column>
        <el-table-column label="系统环境故障字" prop="systemEnvironmentFault" align="center"></el-table-column>
        <el-table-column label="系统硬件故障字" prop="systemHardwareFault" align="center"></el-table-column>
        <el-table-column label="系统温度故障字" prop="systemTemperatureFault" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import PcsAcdCInformationApi from '@/api/470/PcsAcdCInformation';

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined,
    height: undefined
  },
  data () {
    return  {
      loading: false,
      table: [],
      utils: $utils
    }
  },
  created () {
    console.log("acdc");
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await PcsAcdCInformationApi.list(params);
        let table = res.data.map((obj) => {

          obj.acPortVoltage = (Number(obj.acPortVoltage) / 10).toFixed(2);
          obj.acPortCurrent = (Number(obj.acPortCurrent) / 10).toFixed(2);
          obj.acPortPower = (Number(obj.acPortPower) / 10).toFixed(2);
          obj.moduleLgbt1 = (Number(obj.moduleLgbt1) / 10).toFixed(2);
          obj.moduleLgbt2 = (Number(obj.moduleLgbt2) / 10).toFixed(2);
          obj.moduleLgbt3 = (Number(obj.moduleLgbt3) / 10).toFixed(2);
          obj.moduleLgbt4 = (Number(obj.moduleLgbt4) / 10).toFixed(2);
          obj.moduleLgbt5 = (Number(obj.moduleLgbt5) / 10).toFixed(2);
          obj.moduleAirInlet = (Number(obj.moduleAirInlet) / 10).toFixed(2);
          obj.moduleAirOutlet = (Number(obj.moduleAirOutlet) / 10).toFixed(2);
          obj.theAcPowerA = (Number(obj.theAcPowerA) / 10).toFixed(2);
          obj.theAcPowerB = (Number(obj.theAcPowerB) / 10).toFixed(2);
          obj.theAcPowerC = (Number(obj.theAcPowerC) / 10).toFixed(2);
          obj.dcBusVoltage = (Number(obj.dcBusVoltage) / 10).toFixed(2);

          return obj;
        });

        this.table = table;
        this.$message.success("ACDC数据读取成功");
      } catch (err) {
        this.$message.warning("ACDC数据读取失败");
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>