var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-l-15" },
    [
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "fixed-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.table,
            height: _vm.height ? _vm.height : undefined,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "rackRunState",
              label: "Rack运行状态",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackRunState
                          )
                            ? scope.row.rackRunState
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackPrechargePhase",
              label: "Rack预充电阶段",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackPrechargePhase
                          )
                            ? scope.row.rackPrechargePhase
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackConnectionState",
              label: "Rack接触器状态",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackConnectionState
                          )
                            ? scope.row.rackConnectionState
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "rackFault", label: "Rack故障", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.rackFault)
                            ? scope.row.rackFault
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reasonRackStep",
              label: "Rack切入失败原因",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.reasonRackStep
                          )
                            ? scope.row.reasonRackStep
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "theReasonRackDid",
              label: "Rack未启动切入原因",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.theReasonRackDid
                          )
                            ? scope.row.theReasonRackDid
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackPrechargeTotalVol",
              label: "Rack预充总压(V)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackPrechargeTotalVol
                          )
                            ? (scope.row.rackPrechargeTotalVol / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackRackVoltage",
              label: "Rack电池总电压(V)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackRackVoltage
                          )
                            ? (scope.row.rackRackVoltage / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackCurrent",
              label: "Rack电池总电流(A)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.rackCurrent)
                            ? (scope.row.rackCurrent / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackChargeDischargeState",
              label: "Rack充放电指示",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackChargeDischargeState
                          )
                            ? _vm.rackChargeDischargeStateList[
                                scope.row.rackChargeDischargeState
                              ] || "无数据"
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "rackSoc", label: "SOC(%)", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.rackSoc)
                            ? (scope.row.rackSoc / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "rackSoh", label: "SOH(%)", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.rackSoh)
                            ? scope.row.rackSoh
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackInsulationValue",
              label: "Rack绝缘值(KΩ)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackInsulationValue
                          )
                            ? scope.row.rackInsulationValue.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackPositiveLnsulationValue",
              label: "Rack正极绝缘值(KΩ)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackPositiveLnsulationValue
                          )
                            ? scope.row.rackPositiveLnsulationValue.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackNegativeLnsulationValue",
              label: "Rack负极绝缘值(KΩ)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackNegativeLnsulationValue
                          )
                            ? scope.row.rackNegativeLnsulationValue.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMaxVolCellId",
              label: "Rack单体电压最高节序号",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMaxVolCellId
                          )
                            ? scope.row.rackMaxVolCellId
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMaxCellVoltage",
              label: "Rack单体最高电压值(mV)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMaxCellVoltage
                          )
                            ? scope.row.rackMaxCellVoltage.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMinVolCellId",
              label: "Rack单体电压最低节序号",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMinVolCellId
                          )
                            ? scope.row.rackMinVolCellId
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMinCellVoltage",
              label: "Rack单体最低电压值(mV)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMinCellVoltage
                          )
                            ? scope.row.rackMinCellVoltage.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMaxTemperatureCellId",
              label: "Rack单体温度最高节序号",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMaxTemperatureCellId
                          )
                            ? scope.row.rackMaxTemperatureCellId
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMaxCellTemperature",
              label: "Rack单体温度最高值(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMaxCellTemperature
                          )
                            ? (scope.row.rackMaxCellTemperature / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMinTemperatureCellId",
              label: "Rack单体温度最低节序号",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMinTemperatureCellId
                          )
                            ? scope.row.rackMinTemperatureCellId
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackMinCellTemperature",
              label: "Rack单体温度最低值(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackMinCellTemperature
                          )
                            ? (scope.row.rackMinCellTemperature / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackAverageVoltage",
              label: "Rack平均电压(mV)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackAverageVoltage
                          )
                            ? scope.row.rackAverageVoltage.toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackAverageTemperature",
              label: "Rack平均温度(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackAverageTemperature
                          )
                            ? (scope.row.rackAverageTemperature / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rackCellVoltageCumulativeSum",
              label: "Rack单体累加和总压(V)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(
                            scope.row.rackCellVoltageCumulativeSum
                          )
                            ? (
                                scope.row.rackCellVoltageCumulativeSum / 10
                              ).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp1",
              label: "Rack高压箱温度1(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp1)
                            ? (scope.row.hvbTemp1 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp2",
              label: "Rack高压箱温度2(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp2)
                            ? (scope.row.hvbTemp2 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp3",
              label: "Rack高压箱温度3(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp3)
                            ? (scope.row.hvbTemp3 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp4",
              label: "Rack高压箱温度4(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp4)
                            ? (scope.row.hvbTemp4 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp5",
              label: "Rack高压箱温度5(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp5)
                            ? (scope.row.hvbTemp5 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp6",
              label: "Rack高压箱温度6(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp6)
                            ? (scope.row.hvbTemp6 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp7",
              label: "Rack高压箱温度7(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp7)
                            ? (scope.row.hvbTemp7 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hvbTemp8",
              label: "Rack高压箱温度8(℃)",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.utils.checkObjectIsNotEmpty(scope.row.hvbTemp8)
                            ? (scope.row.hvbTemp8 / 10).toFixed(2)
                            : "无数据"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "创建时间",
              width: "160",
              align: "center",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }