import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-LogMonomerTemperature/list?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.post(`module-LogMonomerTemperature/save?${rand}`, data);
}

export default {
    list,
    save
}
