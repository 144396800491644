<template>
  <div v-loading="loading" class="m-l-15">
    <el-form label-width="80px" label-position="left">
      <el-row :span="24">
        <el-col :span="4">
          <el-form-item label="查询月份">
            <el-date-picker
              size="mini"
              v-model="timeRange"
              :clearable="false"
              type="month"
              style="width: 200px;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <div class="right-btns">
            <el-button size="mini" type="primary" @click="getDatas">查询</el-button>
            <el-button size="mini" type="primary" @click="onExport">导出</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="table" :height="height">
      <el-table-column label="日期" prop="createTime" width="160" align="center"></el-table-column>
      <el-table-column label="日充电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="topCharging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="peakCharging"></el-table-column>
        <el-table-column label="平段" align="center" prop="normalCharging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="valleyCharging"></el-table-column>
      </el-table-column>
      <el-table-column label="日放电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="topDischarging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="peakDischarging"></el-table-column>
        <el-table-column label="平段" align="center" prop="normalDischarging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="valleyDischarging"></el-table-column>
      </el-table-column>
      <el-table-column label="总充电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="totalTopCharging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="totalPeakCharging"></el-table-column>
        <el-table-column label="平段" align="center" prop="totalNormalCharging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="totalValleyCharging"></el-table-column>
      </el-table-column>
      <el-table-column label="总放电量（KWH）" align="center">
        <el-table-column label="尖段" align="center" prop="totalTopDischarging"></el-table-column>
        <el-table-column label="峰段" align="center" prop="totalPeakDischarging"></el-table-column>
        <el-table-column label="平段" align="center" prop="totalNormalDischarging"></el-table-column>
        <el-table-column label="谷段" align="center" prop="totalValleyDischarging"></el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :pager-count="7" :current-page="pageData.current" @current-change="pageChange" />
  </div>
</template>
<script>
import ElectricityPeaksValleysApi from "@/api/470/ElectricityPeaksValleys";

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      table: [],
      timeRange: null,
      height: 0,
      pageData: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  created () {
    this.timeRange = new Date();
    this.height = document.body.scrollHeight - 220;
    this.getDatas();
  },
  methods: {
    onSizeChange (e) {
      this.pageData.size = e;
      this.getDatas();
    },
    pageChange (e) {
      this.pageData.current = e;
      this.getDatas();
    },
    async getDatas () {

      try {
        this.loading = true;
        this.timeVal = $utils.formatTime(this.timeRange, "YYYY-MM");

        const params = {
          code: this.deviceInfo.code,
          createTime: $utils.formatTime(this.timeVal, "YYYY-MM")
          // page: this.pageData.size * (this.pageData.current - 1),
          // size: this.pageData.size
        };
        let res = await ElectricityPeaksValleysApi.month(params);

        res = res.map(obj => {
          const keys = Object.keys(obj);
          for (const key of keys) {
            if (key !== "id" && key !== "code" && key !== "createTime" && key !== "updateTime") {
              obj[key] = Number(obj[key]).toFixed(2);
            }
          }
          return obj;
        });

        this.table = res;
        this.$message.success("尖峰平谷数据读取成功");
      } catch (err) {
        this.$message.warning("尖峰平谷数据读取失败");
      } finally {
        this.loading = false;
      }

      // this.pageData.current = res.currentPage;
      // this.pageData.size = res.pageSize;
      // this.pageData.total = res.totalCount;
    },
    async onExport () {
      try {
        this.loading = true;
        this.timeVal = $utils.formatTime(this.timeRange, "YYYY-MM");

        const params = {
          code: this.deviceInfo.code,
          createTime: this.timeVal
        };

        const res = await ElectricityPeaksValleysApi.download(params);
        $utils.exportExcel(res);

      } finally {
        this.loading = false;
      }
    }
  }
}
</script>