<template>
  <div>
    <div v-for="n in items.length" class="list-box">
      <div v-if="items.data[n - 1].type !== 'ban'">
        <div :class="`val-box ${ getName(items.data[n - 1].type) }-bg`">
          <div class="val-box-name">{{ type }}#{{ length + n }}</div>
          <div class="val-box-value">
            <span>{{ (items.data[n - 1].value).toFixed(2) }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div :class="`val-box ${ getName(items.data[n - 1].type) }-bg`">
          <div class="val-box-name">{{ type }}#{{ length + n }}</div>
          <div class="val-box-value">
            <span>{{ (items.data[n - 1].value).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user']),
  },
  props: {
    items: {
      type: Array,
      default: {}
    },
    type: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    length: {
      type: Number,
      default: 0
    },
    times: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getName (name) {
      if (name === "min" || name === "max") {
        return `${this.name}-${name}`
      } else {
        return name;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list-box {
  display: inline-table;
}

.val-box {
  display: inline-table;
  vertical-align: top;
  margin: 5px;
  width: 120px;
  line-height: 23px;
  color: #F7F7F7;
  font-weight: bold;

  div {
    display: inline-block;
  }

  .val-box-name {
    width: 50px;
    padding: 0px 2px;
  }

  .val-box-value {
    width: calc(100% - 60px);
  }
}
</style>