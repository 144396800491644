var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items.length, function (n) {
      return _c("div", { staticClass: "list-box" }, [
        _vm.items.data[n - 1].type !== "ban"
          ? _c("div", [
              _c(
                "div",
                {
                  class: `val-box ${_vm.getName(
                    _vm.items.data[n - 1].type
                  )}-bg`,
                },
                [
                  _c("div", { staticClass: "val-box-name" }, [
                    _vm._v(_vm._s(_vm.type) + "#" + _vm._s(_vm.length + n)),
                  ]),
                  _c("div", { staticClass: "val-box-value" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.items.data[n - 1].value.toFixed(2))),
                    ]),
                  ]),
                ]
              ),
            ])
          : _c("div", [
              _c(
                "div",
                {
                  class: `val-box ${_vm.getName(
                    _vm.items.data[n - 1].type
                  )}-bg`,
                },
                [
                  _c("div", { staticClass: "val-box-name" }, [
                    _vm._v(_vm._s(_vm.type) + "#" + _vm._s(_vm.length + n)),
                  ]),
                  _c("div", { staticClass: "val-box-value" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.items.data[n - 1].value.toFixed(2))),
                    ]),
                  ]),
                ]
              ),
            ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }