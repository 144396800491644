var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "alarm-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "alarm-btn",
              on: {
                click: function ($event) {
                  return _vm.showDialog("alarm")
                },
              },
            },
            [_vm._v("一级告警")]
          ),
          _c(
            "el-button",
            {
              staticClass: "alarm-btn",
              on: {
                click: function ($event) {
                  return _vm.showDialog("warning")
                },
              },
            },
            [_vm._v("二级告警")]
          ),
          _c(
            "el-button",
            {
              staticClass: "alarm-btn",
              on: {
                click: function ($event) {
                  return _vm.showDialog("criticalAlarm")
                },
              },
            },
            [_vm._v("三级告警")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "一级告警",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "append-to-body": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.alarm,
            width: "60%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("alarm", false)
            },
          },
        },
        [
          _c("alarms", {
            ref: "alarm",
            attrs: {
              "device-info": _vm.deviceInfo,
              pre: "alarm",
              position: 0,
              "is-new-ver": _vm.gwIsNewSoft,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "二级告警",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "append-to-body": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.warning,
            width: "60%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("warning", false)
            },
          },
        },
        [
          _c("alarms", {
            ref: "warning",
            attrs: {
              "device-info": _vm.deviceInfo,
              pre: "warning",
              position: 1,
              "is-new-ver": _vm.gwIsNewSoft,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "三级告警",
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "show-close": "",
            "append-to-body": "",
            "close-on-click-modal": true,
            visible: _vm.dialogVisible.criticalAlarm,
            width: "60%",
          },
          on: {
            close: function ($event) {
              return _vm.onCloseDialog("criticalAlarm", false)
            },
          },
        },
        [
          _c("alarms", {
            ref: "criticalAlarm",
            attrs: {
              "device-info": _vm.deviceInfo,
              pre: "criticalAlarm",
              position: 2,
              "is-new-ver": _vm.gwIsNewSoft,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }