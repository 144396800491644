var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "device-tabs",
        {
          attrs: {
            "device-info": _vm.deviceInfo,
            "tab-name": _vm.tabName,
            height: _vm.height,
          },
          on: { "tab-click": _vm.onTabClick },
        },
        [
          [
            _c(
              "el-tab-pane",
              { attrs: { name: "basic", label: "基本数据" } },
              [
                _vm.tabName === "basic"
                  ? _c("detail", {
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { name: "time-table", label: "时段表" } },
              [
                _vm.tabName === "time-table"
                  ? _c("time-table", {
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { name: "others", label: "其他数据" } },
              [
                _vm.tabName === "others"
                  ? _c("others", {
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { name: "soe-log", label: "SOE 事件记录" } },
              [
                _vm.tabName === "soe-log"
                  ? _c("soe-log", {
                      attrs: {
                        "device-info": _vm.deviceInfo,
                        height: _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }