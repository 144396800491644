var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vt-sub-content" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c("viewvt", {
          ref: "viewvt",
          attrs: {
            "is-small-screen": _vm.isSmallScreen,
            "device-info": _vm.deviceInfo,
            data: _vm.form,
            height: _vm.height - 20,
            vLen: _vm.vLen,
            tLen: _vm.tLen,
            vDataList: _vm.vDataList,
            tDataList: _vm.tDataList,
            mode: "single",
          },
          on: { read: _vm.getDatas, show: _vm.onShowTime },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }