var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "left", "label-width": "100" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择文件" } },
            [
              _c(
                "el-select",
                {
                  attrs: { size: "mini" },
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.form.fileName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "fileName", $$v)
                    },
                    expression: "form.fileName",
                  },
                },
                _vm._l(_vm.fileList, function (file) {
                  return _c(
                    "el-tooltip",
                    {
                      key: file.id,
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: file.description || "无说明",
                        placement: "right",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: file.fileName, value: file.fileName },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("选择")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }