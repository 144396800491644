import { getDevicesTemperature } from '@/api/device';
const {  decAppendZero } = $numberMatrixing;

export default {
  data () {
    return {
      getDevicesObject: getDevicesTemperature,
      method: "calcSignedDecFromHex",
      address: 0x2600,
      keyName: "temperature",
      keyValue: "temperature",
      name: "温度",
      length: 16,
      callback: (value, callback, length) => {
        const mask = callback(value, length);

        let equalize = null;
        switch (value.slice(4)) {
          case "0000":
            equalize = new Set([6, 7]);
            break;
          case "0100":
            equalize = new Set([7, 15]);
            break;
        }

        return { mask, equalize };
      }
    }
  },
  methods: {
    onChange (index, that) {
      that = that || this;
      const obj = that.options[index];
      const values = obj[that.keyValue] || obj[that.keyName];
      let { bmuNo, uploadTime, createTime } = obj;
      createTime = uploadTime || createTime;

      /**
        [20231227]
        数据结构例：007B0078FE70FE70FE70FE70FE9F0090FE70FE70FE70FE70FE70FE70FE70FE70FE9F0090FFFE0000
        其中
        007B0078FE70FE70FE70FE70FE9F0090FE70FE70FE70FE70FE70FE70FE70FE70FE9F0090 是真实数据
        FFFE 是屏蔽位
        0000 均衡温度位

        下发的段数可能都不一致，所以会出现大量空白需要处理
      */
      that.length = (obj.digit || that.length);
      if (that.length === 18) {
        that.length -= 2; // 下发的数据中带有 17、18 的值，但这个值是假的， 所以要去掉
      }
      const times = values.length / 80; // 因为数据长度是 16 个值（72位） + 4 屏蔽位 + 4均衡温度（共 76 个字符）
      const method = $numberMatrixing[that.method];
      const data = { feNum: { value: obj.num }, createTime: { value: createTime } };
      const nums = [];

      let dataLen = 0;
      for (let t = 0; t < times; t++) {
        const part = values.slice(t * 80, (t + 1) * 80);
        const val = part.slice(0, 72); // 剪掉屏蔽位和均衡温度
        const mask = this.getMask(part, that);
        const equalize = part.slice(4);

        for (let i = 0, len = val.length / 4; i < len; i++) {
          const tmp = val.slice(i * 4, (i + 1) * 4);
          const m = mask[i];
          const q = equalize[i];
          let res = Number(method(tmp));

          let type = (m === "0") ? ((q === "1") ? "equalize" : "normal") : "ban";
          // let type = (m === "0") ? "normal" : "ban"; // 去掉均衡温度
          
          // if (that.user.tenantId !== 0) {
          //   // [20240126] 因为网关设置 32766 为假值，所以当遇到这个值的时候，直接当做屏蔽位处理
          //   // [20240126] 因为 -400 都为被屏蔽值，所以当遇到这个值的时候，直接当做屏蔽位处理
          //   if (res > -400 && res <= 1510) { // TODO [20240207] 询问后，暂定温度范围为 -40 到 1510
          //     res = res / 10; // [20240101] 查表得知，温度的单位是 0.1  ℃，所以依然保留 / 10 操作
          //   } else if (type !== "ban") {
          //     res = null;
          //     type = "err";
          //   }
          // } else {
          //   res = res / 10; // [20240101] 查表得知，温度的单位是 0.1  ℃，所以依然保留 / 10 操作
          // }
          if ((res < -400 || res > 1510) && type !== "ban") { // TODO [20240207] 询问后，暂定温度范围为 -40 到 1510
            type = "err";
          }
          res /= 10; // [20240101] 查表得知，温度的单位是 0.1  ℃，所以依然保留 / 10 操作

          if (type !== "ban") {
            nums.push(res);
          }

          data[`index${decAppendZero(dataLen++, 5)}`] = { value: res, type };
        }
      }

      return {
        index: `index${decAppendZero(index, 5)}`, afe: obj.afe, size: nums.length, data, bmuNo, createTime, length: dataLen, chargingDischargingStatus: obj.chargingDischargingStatus || 0
      };
    },
    getMask (value, that) {
      that = that || this;
      const mask = parseInt(value.slice(value.length - 8, value.length - 4), 16).toString(2).split("");
      while (mask.length < that.length) {
        mask.unshift("0");
      }
      const output = mask.reverse().join("");
      return output;
    }
  }
}