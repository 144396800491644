<template>
  <div v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="152px">
      <div>
        <el-checkbox v-if="utils.isDevelopMode()" v-model="isDebugMode">开启调试模式</el-checkbox>
      </div>
      <div v-if="utils.isDevelopMode() && isDebugMode">
        <el-row :span="24">
          <el-col :span="4">
            <el-form-item label="主机地址">
              <el-select style="width:100%" v-model="form.lecuAddr" size="mini">
                <el-option v-for="n in lecuAddrSize + 1" :key="n" :label="n - 1" :value="n - 1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="地址调试窗口">
              <el-input v-model="debugData.input" size="mini" placeholder="请输入地址" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24">
          <el-col :span="4"></el-col>
          <el-col :span="20">
            <el-form-item label="设备返回信息">
              <el-input v-model="debugData.output" size="mini" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-collapse v-if="!isDebugMode" v-model="activeNames" @change="handleChange">
        <div class="config-box" :style="`height: ${height}px`">
          <el-collapse-item title="主机参数" name="main">
            <el-form-item label="主机地址">
              <el-select style="width:100%" v-model="form.lecuAddr" size="mini">
                <el-option v-for="n in lecuAddrSize + 1" :key="n" :label="n - 1" :value="n - 1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="新地址">
              <el-select style="width:100%" v-model="form.lecuNewAddr" size="mini">
                <el-option v-for="n in lecuNewAddrSize" :key="n" :label="n" :value="n"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="三级授权">
              <el-switch active-color="#13ce66" v-model="form.empower"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="电池类型">
              <el-select style="width:100%" v-model="form.batType" size="mini">
                <el-option v-for="obj in batTypes" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="电芯类型">
              <el-select style="width:100%" v-model="form.batCellType" size="mini">
                <el-option v-for="obj in batCellTypes" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="电池 AFE 数" prop="afeNum">
              <el-input v-model="form.afeNum" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="NTC 数量">
              <el-select style="width:100%" v-model="form.ntcCnt" size="mini">
                <el-option v-for="obj in ntcCnts" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="硬件类型">
              <el-select style="width:100%" v-model="form.hwType" size="mini">
                <el-option v-for="obj in hwTypes" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="通讯">
              <el-select style="width:100%" disabled v-model="form.channel" size="mini">
                <el-option v-for="obj in cans" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="上拉电阻">
              <el-select style="width:100%" v-model="form.pullUresistor" size="mini">
                <el-option v-for="obj in pullUresistors" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="NTC">
              <el-select style="width:100%" v-model="form.ntc" size="mini">
                <el-option v-for="obj in ntcs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="从控">
              <div class="slave-control-box">
                <div>
                  <el-input :disabled="deviceInfo.projectType !== 4" v-model="form.slaveCount" size="mini"></el-input>
                </div>
                <div>
                  <el-select style="width:100%" v-model="form.slaveControl" size="mini">
                    <el-option v-for="obj in slaveControls" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
                  </el-select>
                </div>
                <div v-if="form.slaveControl !== '0'">
                  <el-select style="width:100%" v-model="form.slaveAfeType" size="mini">
                    <el-option v-for="obj in slaveAfeTypes" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="总控">
              <el-select style="width:100%" disabled v-model="form.masterControl" size="mini">
                <el-option v-for="obj in cans" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="分流器参数" name="Diverter">
            <el-form-item label="电流值">
              <el-input v-model="form.shunt_I" size="mini">
                <template #append>A</template>
              </el-input>
            </el-form-item>
            <el-form-item label="电压值">
              <el-input v-model="form.shunt_mV" size="mini">
                <template #append>mV</template>
              </el-input>
            </el-form-item>
            <el-form-item label="偏置量">
              <el-input v-model="form.shunt_I_offset" size="mini">
                <template #append>0.1A</template>
              </el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="充放电流参数" name="ChgDsg">
            <el-form-item label="放电电流门限值">
              <el-input v-model="form.ipQueryDsgTHD" size="mini">
                <template #append>A</template>
              </el-input>
            </el-form-item>
            <el-form-item label="充电电流门限值">
              <el-input v-model="form.ipQueryChgCutTHD" size="mini">
                <template #append>A</template>
              </el-input>
            </el-form-item>
            <el-form-item label="国标最大充电流">
              <el-input v-model="form.gbChgMaxCur" size="mini">
                <template #append>0.1C</template>
              </el-input>
            </el-form-item>
            <el-form-item label="国标充报文延迟">
              <el-input v-model="form.gbMsgDelay" size="mini">
                <template #append>10S</template>
              </el-input>
            </el-form-item>
            <el-form-item label="放电切换充">
              <el-switch active-color="#13ce66" v-model="form.disChgChangeToChg"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="限值电流充">
              <el-switch active-color="#13ce66" v-model="form.limitCurrentchg"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="协议配置" name="Protocol">
            <el-form-item label="充电协议">
              <el-select style="width:calc(70% - 10px); margin-right:10px;" v-model="form.chgProtocol" size="mini">
                <el-option v-for="obj in chgProtocols" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
              <el-select style="width:30%" v-model="form.chargeCanType" :disabled="!user.canEditCanConfig" size="mini">
                <el-option v-for="obj in cans" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="VCU">
              <el-select style="width:calc(70% - 10px); margin-right:10px;" v-model="form.vcuProtocol" size="mini">
                <el-option v-for="obj in vcuProtocols" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
              <el-select style="width:30%" v-model="form.vcuCanType" size="mini">
                <el-option v-for="obj in cans" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="温控协议">
              <el-select style="width:calc(70% - 10px); margin-right:10px;" v-model="form.tempProtocol" size="mini">
                <el-option v-for="obj in tempProtocols" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
              <el-select style="width:30%" v-model="form.tempCanType" size="mini">
                <el-option v-for="obj in cans" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="COM1传输协议">
              <el-select style="width:100%" v-model="form.com1Protocol" size="mini">
                <el-option v-for="obj in comProtocols" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="COM2传输协议">
              <el-select style="width:100%" v-model="form.com2Protocol" size="mini">
                <el-option v-for="obj in comProtocols" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="接触器参数" name="Contactor">
            <el-form-item label="单次吸合延迟时间" prop="contactSingleTime">
              <el-input v-model="form.contactSingleTime" size="mini">
                <template slot="append">ms</template>
              </el-input>
            </el-form-item>
            <el-form-item label="放电单次吸合延迟时间" prop="chargeContactSingleTime">
              <el-input v-model="form.chargeContactSingleTime" size="mini">
                <template slot="append">ms</template>
              </el-input>
            </el-form-item>
            <el-form-item label="预充电压和总压比列" prop="preChargeContactRatio">
              <el-input v-model="form.preChargeContactRatio" size="mini">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-collapse-item>
      </div>
      <div class="config-box" :style="`height: ${height}px`">
          <el-collapse-item title="SOC 参数" name="soc">
            <el-form-item label="系统容量" prop="systemVolumetric">
              <el-input v-model="form.systemVolumetric" size="mini">
                <template slot="append">AH</template>
              </el-input>
            </el-form-item>
            <el-form-item label="是否初始 SOC">
              <el-switch active-color="#13ce66" active-text="初始" inactive-text="不初始" v-model="isConfiginitSoc"></el-switch>
            </el-form-item>
            <el-form-item label="初始 SOC" prop="initSoc">
              <el-input v-model="form.initSoc" :disabled="!isConfiginitSoc" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="静置电流误差" prop="staticEurrentError">
              <el-input v-model="form.staticEurrentError" size="mini">
                <template slot="append">mA</template>
              </el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="SOH 参数" name="soh">
          <el-form-item label="SOH循环次数" prop="sohCycle">
            <el-input v-model="form.sohCycle" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="循环终止百分比" prop="sohPercentage">
            <el-input v-model="form.sohPercentage" size="mini">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="均衡启动条件(mV)" name="Balance">
            <el-form-item label="动态阈值" prop="dynamicThreshold">
              <el-input v-model="form.dynamicThreshold" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="动态差值" prop="dynamicDifference">
              <el-input v-model="form.dynamicDifference" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="静态阈值" prop="staticThreshold">
              <el-input v-model="form.staticThreshold" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="静态差值" prop="staticDifference">
              <el-input v-model="form.staticDifference" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="最大供电通道数">
              <el-input disabled v-model="form.maxChargeChannel" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="最大均衡通道数" prop="maxBalanceChannel">
              <el-input v-model="form.maxBalanceChannel" size="mini" @blur="onChangeMaxBalanceChannel"></el-input>
            </el-form-item>
            <el-form-item label="均衡方式">
              <el-select style="width:100%" v-model="form.balanceMode" size="mini" @change="onChangeMaxBalanceChannel">
                <el-option v-for="obj in balanceModes" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="放电均衡">
              <el-switch active-color="#13ce66" v-model="form.chargeBanlance"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="UPS" name="ups">
            <el-form-item label="工作电压" prop="workVoltage">
              <el-input v-model="form.workVoltage" size="mini">
                <template slot="append">V</template>
              </el-input>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="一阶滤波系数（x100）" name="coefficient">
            <el-form-item label="电压" prop="ltc6811FilterRatio">
              <el-input v-model="form.ltc6811FilterRatio" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="温度" prop="mcuadFilterRatio">
              <el-input v-model="form.mcuadFilterRatio" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="电流" prop="ad7706FilterRatio">
              <el-input v-model="form.ad7706FilterRatio" size="mini"></el-input>
            </el-form-item>
          </el-collapse-item>
          <!-- <el-collapse-item title="告警信息" name="Alarm">
            <el-form-item label="一级告警间隔时间" prop="alarmInterval0">
              <el-select style="width:100%" v-model="form.alarmInterval0" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in alarmIntervals" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="二级告警间隔时间" prop="alarmInterval1">
              <el-select style="width:100%" v-model="form.alarmInterval1" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in alarmIntervals" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="三级告警间隔时间" prop="alarmInterval2">
              <el-select style="width:100%" v-model="form.alarmInterval2" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in alarmIntervals" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item> -->
      </div>
      <div class="config-box" :style="`height: ${height}px`">
          <el-collapse-item title="高低边开关" name="HighLow">
            <el-form-item label="预充接触器">
              <el-select style="width:100%" v-model="form.preChargeContactor" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="放电接触器">
              <el-select style="width:100%" v-model="form.dischargeContactor" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充电接触器">
              <el-select style="width:100%" v-model="form.chargeContactor" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主负接触器">
              <el-select style="width:100%" v-model="form.mainSlaveContactor" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="加热接触器">
              <el-select style="width:100%" v-model="form.heatingContactor" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="制冷接触器">
              <el-select style="width:100%" v-model="form.buzzer" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="外接指示灯">
              <el-select style="width:100%" v-model="form.externalIndicator" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="外接故障灯">
              <el-select style="width:100%" v-model="form.externalFaultLight" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="UPS 工作开关">
              <el-select style="width:100%" v-model="form.upsWorkSwitch" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="UPS 中线充放">
              <el-select style="width:100%" v-model="form.upsForceCharging" :disabled="level.isL0" size="mini">
                <el-option v-for="obj in hsdxCfgs" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充电二级恢复">
              <el-switch active-color="#13ce66" v-model="form.chargeLv2Back"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="外部并机">
              <el-switch active-color="#13ce66" v-model="form.externalParalleling"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="放电二级恢复">
              <el-switch active-color="#13ce66" v-model="form.disChgLv2Back"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="边充边放">
              <el-switch active-color="#13ce66" v-model="form.chgSameTimeDischg"
                active-text="使用" inactive-text="不使用" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="CAN速率" name="canBaud">
            <el-form-item label="CAN1">
              <el-select style="width:100%" v-model="form.canBaud1" size="mini">
                <el-option v-for="obj in canBauds" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="CAN2">
              <el-select style="width:100%" v-model="form.canBaud2" size="mini">
                <el-option v-for="obj in canBauds" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="COM速率" name="comBaud">
            <el-form-item label="COM1" prop="com1Baud">
              <el-select style="width:100%" v-model="form.com1Baud" size="mini">
                <el-option v-for="obj in comSpeeds" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="COM2" prop="com2Baud">
              <el-select style="width:100%" v-model="form.com2Baud" size="mini">
                <el-option v-for="obj in comSpeeds" :key="obj.value" :label="obj.label" :value="obj.value"></el-option>
              </el-select>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="风扇温度(0.1)" name="Fan_temperature">
            <el-form-item label="启动" prop="vanStartTemp">
              <el-input v-model="form.vanStartTemp" :disabled="level.isL0" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="停止" prop="vanStop">
              <el-input v-model="form.vanStop" :disabled="level.isL0" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="温差" prop="vanStartDiff">
              <el-input v-model="form.vanStartDiff" :disabled="level.isL0" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="风扇控制">
              <el-switch active-color="#13ce66" :disabled="level.isL0" v-model="form.vanControler"
                active-text="开启" inactive-text="停止" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item title="加热温度(0.1)" name="Hot_temperature">
            <el-form-item label="启动" prop="hotStartTemp">
              <el-input v-model="form.hotStartTemp" :disabled="level.isL0" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="停止" prop="hotStop">
              <el-input v-model="form.hotStop" :disabled="level.isL0" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="温升" prop="hotStartDiff">
              <el-input v-model="form.hotStartDiff" :disabled="level.isL0" size="mini">
                <template slot="append">c/m</template>
              </el-input>
            </el-form-item>
            <el-form-item label="加热控制">
              <el-switch active-color="#13ce66" :disabled="level.isL0" v-model="form.hotControler"
                active-text="开启" inactive-text="停止" active-value="1" inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-collapse-item>
      </div>
      </el-collapse>
    </el-form>
    <div class="right-btns m-t-20">
      <el-button size="mini" @click="onCreateDefaultConfig">缺省参数生成</el-button>
      <el-button size="mini" @click="onReadConfig">参数读取</el-button>
      <el-button size="mini" @click="onSaveConfig">保存配置</el-button>
      <el-button size="mini" :disabled="notConfiged" @click="onConfigBattery" type="primary">电池配置</el-button>
      <!-- <el-button size="mini" :disabled="(notConfiged || utils.userReadOnly(user))" @click="onSubmitConfig" type="primary">参数下传</el-button> -->
      <el-button size="mini" @click="onSubmitConfig" type="primary">参数下传</el-button>
    </div>
  </div>
</template>
<script>
import { systemConfig } from "@/api/device";
import { readSystemConfig, checkResult, addrValToObject } from "@/utils/device";
const { binaryAppendZero, binaryToDec, decChangeScale, calcHexFromUnsignedDec } = $numberMatrixing;
import { mapGetters } from 'vuex';

import modbusApi from "@/utils/modbus";

import HostParameterSettingsApi from "@/api/470/HostParameterSettings";

export default {
  props: {
    level: {
      type: Object,
      default: {}
    },
    deviceInfo: {
      type: Object,
      default: {}
    },
    height: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    },
    gwIsNewSoft: {
      type: Boolean,
      default: false
    },
    gwIsNewHard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['user', 'routes'])
  },
  data () {
    return {
      start: 0x3300,
      size: 109,
      utils: $utils,
      loading: false,
      notConfiged: true,
      fakeMode: false,
      isDebugMode: false,
      dialogVisible: false,
      lecuAddrSize: 120,
      lecuNewAddrSize: 120,
      batTypes: [
        { label: "磷酸铁锂", value: "0" },
        { label: "三元锂", value: "1" },
        { label: "碳酸锂", value: "2" },
        { label: "钠离子", value: "3" }
      ],
      batCellTypes: [
        { label: "Gen_Cell I", value: "0" },
        { label: "HT_Cell", value: "1" },
        { label: "Gen_Cell II", value: "2" },
        { label: "Gen_Cell III", value: "3" },
        { label: "TernaryI", value: "4" },
        { label: "碳酸锂", value: "5" },
        { label: "钠离子", value: "6" },
        { label: "云基慧", value: "7" }
      ],
      ntcCnts: [
        { label: "8", value: "0" },
        { label: "16", value: "1" },
        { label: "19", value: "2" }
      ],
      hwTypes: [
        { label: "B3-V1", value: "0"  },
        { label: "B3-V2", value: "1" },
        { label: "Q3", value: "2" },
        { label: "E2", value: "3" },
        { label: "B3-H", value: "4" },
        { label: "P-BOARD", value: "5" },
        { label: "After-Loading", value: "6" },
        { label: "Tooling", value: "7" }
      ],
      chgProtocols: [
        { label: "No Protocol", value: "0" },
        { label: "GBT27930-2015", value: "1" },
        { label: "EVCC", value: "2" },
        { label: "ShengHong_PCS", value: "3" },
        { label: "HT_PCS", value: "4" },
        { label: "SaiMeiTe", value: "5" },
        { label: "Tianhong_PCS", value: "6" },
        { label: "tailan", value: "7" },
        { label: "HaoBo", value: "8" },
        { label: "TieCheng", value: "9" },
        { label: "KeYao", value: "10" },
        // { label: "无协议", value: "0" },
        // { label: "国标", value: "1" },
        // { label: "欧标", value: "2" },
        // { label: "宏盛pcs", value: "3" },
        // { label: "航天pcs", value: "4" },
        // { label: "萨美特", value: "5" },
        // { label: "天宏", value: "6" },
        // { label: "钛蓝", value: "7" },
        // { label: "浩博", value: "8" },
        // { label: "铁城", value: "9" },
        // { label: "科耀", value: "10" }
      ],
      vcuProtocols: [
        { label: "无协议", value: "0" },
        { label: "Yi_Li", value: "1" },
        { label: "HC_VCU", value: "2" },
        { label: "MinFeng", value: "3" },
        { label: "WeiNeng", value: "4" }
      ],
      tempProtocols: [
        { label: "无协议", value: "0" },
        { label: "TMS", value: "1" },
        { label: "康普斯顿空调", value: "2" },
        { label: "科泰", value: "3" }
      ],
      cans: [
        { label: "CAN1", value: "0" },
        { label: "CAN2", value: "1" }
      ],
      pullUresistors: [
        { label: "33K", value: "0" },
        { label: "100K", value: "1" },
        { label: "10K", value: "2" }
      ],
      ntcs: [
        { label: "100K(-40-149)", value: "0" },
        { label: "10K(-40-149)", value: "1" },
        { label: "100K(-50-125)", value: "2" },
        { label: "10K(-40-125)", value: "3" }
      ],
      slaveControls: [
        { label: "无从控", value: "0" },
        { label: "CAN1", value: "1" },
        { label: "CAN2", value: "2" }
      ],
      slaveAfeTypes: [
        { label: "1afe-fan", value: "0" },
        { label: "2afe-fan", value: "1" },
        { label: "3afe-fan", value: "2" }
      ],
      balanceModes: [
        { label: "主动均衡", value: "0" },
        { label: "被动均衡", value: "1" },
        { label: "主动无均衡", value: "2" },
        { label: "被动无均衡", value: "3" }
      ],
      hsdxCfgs: [
        { label: "High side 1", value: "0" },
        { label: "High side 2", value: "1" },
        { label: "High side 3", value: "2" },
        { label: "High side 4", value: "3" },
        { label: "High side 5", value: "4" },
        { label: "High side 6", value: "5" },
        { label: "Low side 1", value: "6" },
        { label: "Low side 2", value: "7" },
        { label: "NC", value: "8" },
        // { label: "高边1", value: "9" },
        // { label: "高边2", value: "10" },
        // { label: "高边3", value: "11" },
        // { label: "高边4", value: "12" },
        // { label: "高边5", value: "13" },
        // { label: "高边6", value: "14" },
        // { label: "低边1", value: "15" },
        // { label: "低边2", value: "16" },
        // { label: "无", value: "17" }
      ],
      alarmIntervals: [
        { label: "100 ms", value: "0" },
        { label: "High side 1", value: "1" },
        { label: "High side 1", value: "2" },
        { label: "High side 1", value: "3" },
        { label: "High side 1", value: "4" },
      ],
      canBauds: [
        { label: "250K", value: "0" },
        { label: "500K", value: "1" },
        { label: "125K", value: "2" }
      ],
      comSpeeds: [
        { label: "9600", value: "0" },
        { label: "115200", value: "1" }
      ],
      comProtocols: [
        { label: "无协议", value: "0" },
        { label: "衡驰", value: "1" },
        { label: "国锂", value: "2" },
        { label: "蓝牙", value: "3" },
        { label: "智寻", value: "4" },
      ],
      isConfiginitSoc: false,
      form: {},
      debugData: {
        input: "",
        output: ""
      },
      activeNames: [],
      loadFromConfig: false,
      protoObj: {},
      rules: {
        afeNum: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入电池 AFE 个数");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              if (value < 0 || value > 64) {
                callback("电池 AFE 数请配置在 0 ~ 64 之间！");
                return;
              }

              callback();
            },
            trigger: "blur"
          }
        ],
        systemVolumetric: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入系统容量");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 0 || value > 1000) {
              //   callback("系统容量请配置在 0 ~ 1000 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        initSoc: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入初始 SOC 值");
                return;
              }

              if (!/^(\d)+$/.test(value)) {
                callback("请输入数字！");
                return;
              }

              value = Number(value);

              if (value < 0 || value > 1000) {
                callback("初始 SOC 值请配置在 0 ~ 1000 之间！");
                return;
              }

              callback();
            },
            trigger: "blur"
          }
        ],
        workVoltage: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入工作电压");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 0 || value > 1500) {
              //   callback("工作电压请配置在 0 ~ 1500 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        staticEurrentError: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入静置电流误差");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 0 || value > 3000) {
              //   callback("静置电流误差请配置在 0 ~ 3000 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        dynamicThreshold: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入动态阈值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 2500 || value > 4200) {
              //   callback("动态阈值请配置在 2500 ~ 4200 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        dynamicDifference: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入动态差值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 0 || value > 300) {
              //   callback("动态差值请配置在 0 ~ 300 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        staticThreshold: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入静态阈值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 2500 || value > 4200) {
              //   callback("静态阈值请配置在 2500 ~ 4200 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        staticDifference: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入静态差值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < 0 || value > 300) {
              //   callback("静态差值请配置在 0 ~ 300 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        maxBalanceChannel: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入最大均衡通道数");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              callback();
            },
            trigger: "blur"
          }
        ],
        vanStartTemp: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入启动的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < -400 || value > 1500) {
              //   callback("启动值请配置在 -400 ~ 1500 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        slientStartTemp: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入启动的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < -400 || value > 1500) {
              //   callback("启动值请配置在 -400 ~ 1500 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        vanStop: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入停止的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < -400 || value > 1500) {
              //   callback("停止值请配置在 -400 ~ 1500 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        slientStop: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入停止的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              // [20240125] 因为这配置和从设备中读取上来的数字之间有差异，且操作人员都是专业人员（应该？），所以暂时关闭大小检测
              // if (value < -400 || value > 1500) {
              //   callback("停止值请配置在 -400 ~ 1500 之间！");
              //   return;
              // }

              callback();
            },
            trigger: "blur"
          }
        ],
        vanStartDiff: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入温差的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              if (value > 150 || value < 0) {
                callback("温差不能大于 15 或者小于 0");
              };

              callback();
            },
            trigger: "blur"
          }
        ],
        slientStartDiff: [
          {
            validator : (rule, value, callback) => {
              
            if (value === null || value === undefined || value.length === 0) {
                callback("请输入温差的值");
                return;
              }

              value = parseInt(value);
              if (isNaN(value)) {
                callback("请输入数字！");
                return;
              }

              if (value > 150 || value < 0) {
                callback("温差不能大于 15 或者小于 0");
              };

              callback();
            },
            trigger: "blur"
          }
        ]
      }
    }
  },
  watch: {
    "form.slaveControl": function () {
      if (this.form.slaveControl === "0") {
        this.form.slaveAfeType = "";
      } else {
        if (!this.form.slaveAfeType) {
          this.form.slaveAfeType = "0";
        }
      }
    }
  },
  created () {
    console.log("device-admin-system-config");

    let _SystemParamdownloadStru = sessionStorage.getItem(`device-configs-_SystemParamdownloadStru:${this.deviceInfo.code}`);
    if (_SystemParamdownloadStru !== null) {
      const object = JSON.parse(_SystemParamdownloadStru);
      this.parse(object);
      this.loadFromConfig = true;
      return;
    }

    if (this.data !== null) {
      this.form = Object.assign({}, this.data);
    }
    this.protoObj = JSON.parse(JSON.stringify(this.form));
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    onCheckPassword () {
      this.onPassword = false;
      this.isL1 = this.password1 === "53180K2";
      if (!this.isL1) {
        this.isL2 = this.password2 === "65980th#";
        if (!this.isL2) {
          this.isL3 = this.password3 === "5*hC#09!6";
        }
      }

      if (this.isL1 || this.isL2 || this.isL3) {
        this.isL0 = false;
      }
    },
    onCreateDefaultConfig () {

      const afeNum = this.form.afeNum;

      this.form = {
        empower: "0",
        price: "",
        lecuAddr: "0",
        lecuNewAddr: "0",
        batType: "0",
        batCellType: "0",
        afeNum: 2,
        ntcCnt: "0",
        hwType: "0",
        chgProtocol: "0",
        chargeCanType: "0",
        vcuProtocol: "0",
        vcuCanType: "0",
        tempProtocol: "0",
        tempCanType: "0",
        channel: "0",
        pullUresistor: "0",
        ntc: "0",
        slaveControl: "0",
        masterControl: "1",
        clearAllConfigs: "0",
        systemVolumetric: "30",
        initSoc: "0",
        workVoltage: "0",
        staticEurrentError: "500",
        dynamicThreshold: "3400",
        dynamicDifference: "0",
        staticThreshold: "3280",
        staticDifference: "0",
        maxBalanceChannel: "5",
        balanceMode: "0",
        preChargeContactor: "0",
        dischargeContactor: "1",
        chargeContactor: "2",
        mainSlaveContactor: "3",
        heatingContactor: "4",
        buzzer: "5",
        externalIndicator: "6",
        externalFaultLight: "7",
        upsWorkSwitch: "8",
        upsForceCharging: "8",
        chargeLv2Back: "0",
        externalParalleling: "0",
        canBaud1: "1",
        canBaud2: "0",
        comSpeed1: "0",
        comSpeed2: "0",
        vanStart: "300",
        vanStop: "250",
        vanControler: "0",
        shunt_I: "0",
        shunt_I_offset: "0",
        shunt_mV: "0",
        com1Protocol: "0",
        com2Protocol: "0",
        preChargeContactRatio: "0",
        borderCheck: "0"
      }

      if (afeNum !== 0) {
        this.form.afeNum = afeNum;
      }

      if (this.gwIsNewSoft) {
        this.form.sohCycle = "5000";
        this.form.sohPercentage = "70";
      }

      this.notConfiged = false;

      this.onSaveConfig();
    },
    onChangeMaxBalanceChannel () {
      switch (this.form.balanceMode) {
        case "0":
          this.form.maxChargeChannel = this.form.maxBalanceChannel * 2;
          break;
        case "1":
          this.form.maxChargeChannel = this.form.maxBalanceChannel;
          break;
      }
    },
    parse (object) {
      let { canBaud, tempAndNumRes, canConfig, hsdxCfg0, hsdxCfg1, hsdxCfg2, hsdxCfg3, hsdxCfg4,
        controlBz, vcu_soh, balnaceDischarge, vanStart, hotStart } = object;

        canBaud = $utils.checkObjectIsNotEmpty(canBaud) ? binaryAppendZero(canBaud, 8) : "00000000";
        tempAndNumRes = $utils.checkObjectIsNotEmpty(tempAndNumRes) ? binaryAppendZero(tempAndNumRes, 8) : "00000000";
        canConfig = $utils.checkObjectIsNotEmpty(canConfig) ? binaryAppendZero(canConfig, 8) : "00000000";
        hsdxCfg0 = $utils.checkObjectIsNotEmpty(hsdxCfg0) ? binaryAppendZero(hsdxCfg0, 8) : "00000000";
        hsdxCfg1 = $utils.checkObjectIsNotEmpty(hsdxCfg1) ? binaryAppendZero(hsdxCfg1, 8) : "00000000";
        hsdxCfg2 = $utils.checkObjectIsNotEmpty(hsdxCfg2) ? binaryAppendZero(hsdxCfg2, 8) : "00000000";
        hsdxCfg3 = $utils.checkObjectIsNotEmpty(hsdxCfg3) ? binaryAppendZero(hsdxCfg3, 8) : "00000000";
        hsdxCfg4 = $utils.checkObjectIsNotEmpty(hsdxCfg4) ? binaryAppendZero(hsdxCfg4, 8) : "00000000";
        controlBz = $utils.checkObjectIsNotEmpty(controlBz) ? binaryAppendZero(controlBz, 8) : "00000000";
        vcu_soh = $utils.checkObjectIsNotEmpty(vcu_soh) ? binaryAppendZero(vcu_soh, 16) : "0000000000000000";
        balnaceDischarge = $utils.checkObjectIsNotEmpty(balnaceDischarge) ? binaryAppendZero(balnaceDischarge, 8) : "00000000";
        vanStart = $utils.checkObjectIsNotEmpty(vanStart) ? binaryAppendZero(vanStart, 16) : "0000000000000000";
        hotStart = $utils.checkObjectIsNotEmpty(hotStart) ? binaryAppendZero(hotStart, 16) : "0000000000000000";
      // 因为协议上规定是 0.1 所以这里需要 / 10
      object.ipQueryDsgTHD = $utils.checkNumberIsNotEmpty(object.ipQueryDsgTHD) ? object.ipQueryDsgTHD / 10 : 0;
      object.ipQueryChgCutTHD = $utils.checkNumberIsNotEmpty(object.ipQueryChgCutTHD) ? object.ipQueryChgCutTHD / 10 : 0;
      object.shunt_I = $utils.checkNumberIsNotEmpty(object.shunt_I) ? object.shunt_I / 10 : 0;
      object.systemVolumetric = $utils.checkNumberIsNotEmpty(object.systemVolumetric) ? object.systemVolumetric / 10 : 0;

      // 风扇控制
      // 风扇启动和恢复温度  FAN_Start_Temp 0-3 为温差 4-15为温度
      object.vanStartTemp =  binaryToDec(vanStart.slice(0, 12));
      object.vanStartDiff =  binaryToDec(vanStart.slice(12)) * 10;

      // 加热控制
      // HOT_Start_Temp 0-3 为温差 4-15为温度
      object.hotStartTemp =  binaryToDec(hotStart.slice(0, 12));
      object.hotStartDiff =  binaryToDec(hotStart.slice(12)) * 10;

      // 放电是否均衡
      // 0 bit放电是否均衡 1 是 0 否    1bit 限值电流充  1 是 0 否
      object.chargeBanlance = balnaceDischarge[7]; 
      object.limitCurrentchg = balnaceDischarge[6]; // 限值电流充
      object.disChgChangeToChg = balnaceDischarge[5]; // 放电切换充

      /*
      * vcu_soh
      * 高八位6-7 vcu can:0 can1,1-can2 0-5:vcu协议
      * 低八位SOH循环终止百分比
      */
      if (vcu_soh) {
        object.vcuCanType = binaryToDec(vcu_soh.slice(0, 2)); // 高八位6-7
        object.vcuProtocol = binaryToDec(vcu_soh.slice(2, 8)); // 高八位0-5
        object.sohPercentage = binaryToDec(vcu_soh.slice(8, 16)); // 低8位
      }

      // 系统控制标志
      // 0x01 外部并机 0x02 充电恢复 0x04 风扇控制 0x08 放电恢复 0x10 边充边放 0x20 加热控制 0x40 绝缘检测 0x80 授权 （0-2级 0 3级-1）
      object.empower = controlBz[0] // 授权
      object.borderCheck = controlBz[1] // 绝缘检测[TODO：未使用？]
      object.hotControler = controlBz[2] // 加热控制
      object.chgSameTimeDischg = controlBz[3] // 边充边放
      object.disChgLv2Back = controlBz[4] // 放电恢复
      object.vanControler = controlBz[5], // 风扇控制
      object.chargeLv2Back = controlBz[6], // 充电恢复,
      object.externalParalleling = controlBz[7]; // 外部并机

      /*
      * can通道分布（3364）
      * [20240429更新]
      * 位6-7 从控类型 H4 H4T H4R 位4-5 主控(上位机)0-can1 1-can2 位2-3从控 0-无从控 1-can1 2-can2 0-1位 充电 0-can1 1-can2
      * [以下废弃]
      * can通道分布 位4-5 主控(上位机）0-can1 1-can2  
      * 2-3从控 0-can1 1-can2 0-1位 充电 0-can1 1-can2
      */
      object.slaveAfeType = binaryToDec(canConfig.slice(0, 2)); // 从右向左截
      object.masterControl = binaryToDec(canConfig.slice(2, 4)); // 主控
      object.slaveControl = binaryToDec(canConfig.slice(4, 6)); // 从控
      object.chargeCanType = binaryToDec(canConfig.slice(6, 8)); // 充电

      /*
      * pbIoset_tranProtocol(3369)
      * [20240429更新]
      * 保护板IO口输入输出方向设置 bit7 bit6 0-输入  1-输出 bit 5-0 限流检测时间(分钟)0-63 如果是后装，则为个数0-31
      * 低八位
      * 5-7 通讯串口  0-3 com1口上的协议 4-7 com2口上的协议   具体协议 0-无协议，1-hengchi 2-guoli 3-蓝牙 4-智寻
      * 0-4 数据协议
      */
      let pbIoset, tranProtocol;
      if ($utils.checkObjectIsNotEmpty(object.pbIoset_tranProtocol)) {
        let pbIoset_tranProtocol = decChangeScale(object.pbIoset_tranProtocol, 2);
        pbIoset_tranProtocol = binaryAppendZero(pbIoset_tranProtocol, 16);
        pbIoset = pbIoset_tranProtocol.slice(0, 8); // 高八位
        tranProtocol = pbIoset_tranProtocol.slice(8); // 低八位
      } else {
        if ($utils.checkObjectIsNotEmpty(object.pbIoset)) {
          let _pbIoset = decChangeScale(object.pbIoset, 2);
          pbIoset = binaryAppendZero(_pbIoset, 8);
        } else {
          pbIoset = "0";
        }
        if ($utils.checkObjectIsNotEmpty(object.tranProtocol)) {
          let _tranProtocol = decChangeScale(object.tranProtocol, 2);
          tranProtocol = binaryAppendZero(_tranProtocol, 8);
        } else {
          tranProtocol = "0";
        }
      }
      // TODO pbIoset 好像暂时未用到
      object.slaveCount = binaryToDec(pbIoset);
      object.com1Protocol = binaryToDec(tranProtocol.slice(4, 8)); // 0-3 位
      object.com2Protocol = binaryToDec(tranProtocol.slice(0, 4)); // 4-7 位

      /**
        充放电协议、COM速率 336C
        高八位
        0-5bit 国标充电自动充电最大电流(0.1A) 6-7bit 国标充电协议延迟时间(10秒)
        低八位
        0-3 com2 baud rate 4-7 com1 baud rate 9600-0 1-115200
      */
      if ($utils.checkObjectIsNotEmpty(object.gbChgMaxCur_ComBaud)) {
        let gbChgMaxCur_ComBaud = decChangeScale(object.gbChgMaxCur_ComBaud, 2);//.slice(0, 8);
        gbChgMaxCur_ComBaud = binaryAppendZero(gbChgMaxCur_ComBaud, 16);
        let gbChgMaxCur = gbChgMaxCur_ComBaud.slice(0, 8); // 上8位
        let comBaud = gbChgMaxCur_ComBaud.slice(8); // 下8位
        object.gbChgMaxCur = binaryToDec(gbChgMaxCur.slice(2)); //  0-5位
        object.gbMsgDelay = binaryToDec(gbChgMaxCur.slice(0, 2)) // 6-7位
        object.com2Baud = binaryToDec(comBaud.slice(4)); //  下八位：0-3
        object.com1Baud = binaryToDec(comBaud.slice(0, 4)); // 下八位：4-7
      } else {
        let { comBaud, gbChgMaxCur } = object;
        if (comBaud) {
          comBaud = decChangeScale(comBaud, 2);
          comBaud = binaryAppendZero(comBaud, 8);
          object.com2Baud = binaryToDec(comBaud.slice(4)); //  下八位：0-3
          object.com1Baud = binaryToDec(comBaud.slice(0, 4)); // 下八位：4-7
        } else {
          object.com1Baud = "0";
          object.com2Baud = "0";
        }
        if (gbChgMaxCur) {
          gbChgMaxCur = decChangeScale(gbChgMaxCur, 2);
          gbChgMaxCur = binaryAppendZero(gbChgMaxCur, 8);
          object.gbChgMaxCur = binaryToDec(gbChgMaxCur.slice(4)); //  0-5位
          object.gbMsgDelay = binaryToDec(gbChgMaxCur.slice(0, 4)) // 6-7位
        } else {
          object.gbChgMaxCur = "0";
          object.gbMsgDelay = "0";
        }
      }

      // 温控协议
      // 6-7 温控can:0 can1,1-can2 bit 2-5:温控协议  bit 0-1 0-24串 1-48串 2-36串 3-60串
      let range = decChangeScale(object.range, 2);
      range = binaryAppendZero(range, 8);
      object.tempCanType = binaryToDec(range.slice(0, 2)); // 6-7
      object.tempProtocol = binaryToDec(range.slice(2, 6)); // 2-5
      object.strings = binaryToDec(range.slice(0, 2)); // 0-1 [TODO：未使用？]

      const obj = Object.assign(this.data, {
        canBaud1: binaryToDec(canBaud.slice(0, 4)),
        canBaud2: binaryToDec(canBaud.slice(4)),
        // 电池温度探头数量和上拉阻抗
        ntcCnt: binaryToDec(tempAndNumRes.slice(0, 4)), // 高四位
        pullUresistor: binaryToDec(tempAndNumRes.slice(4, 6)), // 2-3位
        ntc: binaryToDec(tempAndNumRes.slice(6)), // 0-1位
        // 高低边
        preChargeContactor: binaryToDec(hsdxCfg0.slice(4)),
        dischargeContactor: binaryToDec(hsdxCfg0.slice(0, 4)),
        chargeContactor: binaryToDec(hsdxCfg1.slice(4)),
        mainSlaveContactor: binaryToDec(hsdxCfg1.slice(0, 4)),
        heatingContactor: binaryToDec(hsdxCfg2.slice(4)),
        buzzer: binaryToDec(hsdxCfg2.slice(0, 4)),
        externalIndicator: binaryToDec(hsdxCfg3.slice(4)),
        externalFaultLight: binaryToDec(hsdxCfg3.slice(0, 4)),
        upsWorkSwitch: binaryToDec(hsdxCfg4.slice(4)),
        upsForceCharging: binaryToDec(hsdxCfg4.slice(0, 4)),
      });

      delete object.canBaud;
      delete object.tempAndNumRes;
      delete object.canConfig;
      delete object.hsdxCfg0;
      delete object.hsdxCfg1;
      delete object.hsdxCfg2;
      delete object.hsdxCfg3;
      delete object.hsdxCfg4;
      delete object.controlBz;
      delete object.pbIoset_tranProtocol;
      delete object.gbChgMaxCur_ComBaud;
      delete object.range;

      // 覆盖当前数据
      const assigned =  Object.assign(obj, object);
      this.protoObj = JSON.parse(JSON.stringify(assigned));
      this.form = assigned;

      this.activeNames = [ "main", "Diverter", "ChgDsg", "Protocol", "Contactor", "soc", "soh", "Balance", "Alarm",
                            "ups", "coefficient", "HighLow", "canBaud", "comBaud",
                            "Fan_temperature", "slient_Fan_temperature", "Hot_temperature", "dsg_Hot_temperature" ]; // 展开所有折叠面板
      this.notConfiged = false;
      this.onSaveConfig(); // 保存读取到的配置
    },
    async onReadConfig () {

      if (this.deviceInfo.type === 1) {
        this.$message.warning("设备升级中，无法读取参数");
        return;
      }

      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code,
          type: 1,
          size: 1,
          page: 1
        };

        const res = await HostParameterSettingsApi.list(params);

        const object = modbusApi.parse(this.start, this.size, res.data[0].data, true);
        this.parse(object);

        this.$message.success("数据读取成功");
      } catch (err) {
        console.log(err);
        this.$message.warning("数据读取失败");
      } finally {
        this.loading = false;
      }
    },
    async doRead () {
      try {
        const masterID = $utils.masterIDAppendZero(this.form.lecuAddr);
        const keys = Object.keys(Object.assign({
        // 这些是只配置地址，页面中没有的
        hotStart: "",
        hsdxCfg0: "",
        hsdxCfg1: "",
        hsdxCfg2: "",
        hsdxCfg3: "",
        hsdxCfg4: "",
        canBaud: "",
        canConfig: "", // can通道分布
        tempAndNumRes: "", // 电池温度探头数量和上拉阻抗
        vcu_soh: "",
        controlBz: "",
        pbIoset_tranProtocol: "" // 保护板IO、COM传输协议
        }, this.data));
        sessionStorage.setItem(`using-master-id:${this.deviceInfo.code}`, masterID);

        $utils.setDeviceDoing(this.deviceInfo);
        let res = await readSystemConfig(this, keys, masterID);
        $utils.setDeviceDone(this.deviceInfo);

        checkResult(this, res, { error: true, success: true, type: "read" }, valid => {
          if (valid) {
            this.deviceInfo.masterID = $utils.masterIDAppendZero(this.form.lecuAddr);
            const object = addrValToObject(res);
            this.parse(object);
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    onSaveConfig () {
      this.$emit("save", this.form);
    },
    async onSubmitConfig () {
      this.loading = true;
      $utils.setDeviceDoing(this.deviceInfo);
      this.$refs.form.validate(async valid => {

        if (!valid) {
          this.loading = false;
          return;
        }

        if (this.isDebugMode) {
          this.loading = true;
          const data = {
            code: this.deviceInfo.code,
            masterID: $utils.calcMasterIDToHex(this.data.lecuAddr),
            price: this.debugData.input
          };
          const res = await systemConfig.write(data);
          checkResult(this, res, { error: true, success: true, type: "read" });
          this.loading = false;
        } else {
          let output = null;
          const saveVal = [];

          let obj = JSON.parse(JSON.stringify(this.form))
          this.deviceInfo.masterID = $utils.masterIDAppendZero(obj.lecuAddr);

          // 因为显示的时候除以了 10 ，所以这里再乘回来
          if ($utils.checkNumberIsNotEmpty(obj.ipQueryDsgTHD)) {
            obj.ipQueryDsgTHD *= 10;
          }
          if ($utils.checkNumberIsNotEmpty(obj.ipQueryChgCutTHD)) {
            obj.ipQueryChgCutTHD *= 10;
          }
          if ($utils.checkNumberIsNotEmpty(obj.shunt_I)) {
            obj.shunt_I *= 10;
          }
          if ($utils.checkNumberIsNotEmpty(obj.systemVolumetric)) {
            obj.systemVolumetric *= 10;
          }

          // SOC 初始值
          // [20240507]
          // 1. 如果界面上选择了初始化，则最高位置1，否则不做处理
          const protoInitSoc = obj.initSoc;
          if (this.isConfiginitSoc) {
            let initSoc = decChangeScale(obj.initSoc, 2);
            initSoc = "1" + binaryAppendZero(initSoc, 15);
            obj.initSoc = binaryToDec(initSoc);
          }

          // 风扇控制
          if (obj.vanStartTemp || obj.vanStartDiff) {
            const vanStartTemp = obj.vanStartTemp || this.form.vanStartTemp;
            let vanStartDiff = obj.vanStartDiff || this.form.vanStartDiff;
            vanStartDiff = parseInt(vanStartDiff / 10);

            const vanStart  = binaryAppendZero(Number(vanStartTemp), 12)
                            + binaryAppendZero(Number(vanStartDiff), 4)

                          obj.vanStart = binaryToDec(vanStart);

            delete obj.vanStartTemp;
            delete obj.vanStartDiff;
          }

          // 加热控制
          if (obj.hotStartTemp || obj.hotStartDiff) {
            const hotStartTemp = obj.hotStartTemp || this.form.hotStartTemp;
            let hotStartDiff = obj.hotStartDiff || this.form.hotStartDiff;
            hotStartDiff = parseInt(hotStartDiff / 10);

            const hotStart  = binaryAppendZero(Number(hotStartTemp), 12)
                            + binaryAppendZero(Number(hotStartDiff), 4)

            obj.hotStart = binaryToDec(hotStart);

            delete obj.hotStartTemp;
            delete obj.hotStartDiff;
          }

          // 放电是否均衡
          if (obj.limitCurrentchg || obj.chargeBanlance || obj.disChgChangeToChg) {
            const disChgChangeToChg = obj.disChgChangeToChg || this.form.disChgChangeToChg;
            const limitCurrentchg = obj.limitCurrentchg || this.form.limitCurrentchg;
            const chargeBanlance = obj.chargeBanlance || this.form.chargeBanlance;

            const balnaceDischarge = `${disChgChangeToChg}${limitCurrentchg}${chargeBanlance}`;

            obj.balnaceDischarge = binaryToDec(balnaceDischarge);

            delete obj.limitCurrentchg;
            delete obj.chargeBanlance;
            delete obj.disChgChangeToChg;
          }

          // vcu_soh
          if (obj.vcuProtocol || obj.vcuCanType || obj.sohPercentage) {
            const vcuProtocol = obj.vcuProtocol = this.form.vcuProtocol;
            const vcuCanType = obj.vcuCanType = this.form.vcuCanType;
            const sohPercentage = obj.sohPercentage = this.form.sohPercentage;

            const vcu_soh =  binaryAppendZero(Number(vcuCanType), 2) // 高八位6-7
                          + binaryAppendZero(Number(vcuProtocol), 6) // 高八位0-5
                          + binaryAppendZero(Number(sohPercentage), 8); // 低8位

            obj.vcu_soh = binaryToDec(vcu_soh);

            delete obj.vcuProtocol;
            delete obj.vcuCanType;
            delete obj.sohPercentage;
          }

          // 系统控制标志
          if (obj.empower || obj.borderCheck || obj.hotControler || obj.chgSameTimeDischg || obj.disChgLv2Back ||
            obj.vanControler || obj.chargeLv2Back || obj.externalParalleling) {
            const empower = obj.empower || this.form.empower;
            const borderCheck = obj.borderCheck || this.form.borderCheck;
            const hotControler = obj.hotControler || this.form.hotControler;
            const chgSameTimeDischg = obj.chgSameTimeDischg || this.form.chgSameTimeDischg;
            const disChgLv2Back = obj.disChgLv2Back || this.form.disChgLv2Back;
            const vanControler = obj.vanControler || this.form.vanControler;
            const chargeLv2Back = obj.chargeLv2Back || this.form.chargeLv2Back;
            const externalParalleling = obj.externalParalleling || this.form.externalParalleling;

            const controlBz = `${empower}${borderCheck}${hotControler}${chgSameTimeDischg}${disChgLv2Back}${vanControler}${chargeLv2Back}${externalParalleling}`;

            obj.controlBz = binaryToDec(controlBz);

            delete obj.empower;
            delete obj.borderCheck;
            delete obj.hotControler;
            delete obj.chgSameTimeDischg;
            delete obj.disChgLv2Back;
            delete obj.vanControler;
            delete obj.chargeLv2Back;
            delete obj.externalParalleling;
          }

          // can通道分布
          if (obj.slaveAfeType || obj.masterControl || obj.slaveControl || obj.chargeCanType) {
            const slaveAfeType = obj.slaveAfeType || this.form.slaveAfeType;
            const slaveControl = obj.slaveControl || this.form.slaveControl;
            const masterControl = obj.masterControl || this.form.masterControl;
            const chargeCanType = obj.chargeCanType || this.form.chargeCanType;

            const canConfig = binaryAppendZero(Number(slaveAfeType), 2)
                            + binaryAppendZero(Number(masterControl), 2)
                            + binaryAppendZero(Number(slaveControl), 2)
                            + binaryAppendZero(Number(chargeCanType), 2)

            obj.canConfig = binaryToDec(canConfig);

            delete obj.chargeCanType;
            delete obj.masterControl;
            delete obj.slaveControl;
            delete obj.slaveAfeType;
          }

          // pbIoset_tranProtocol
          if (obj.com1Protocol || obj.com2Protocol) {
            const com1Protocol = obj.com1Protocol || this.form.com1Protocol;
            const com2Protocol = obj.com2Protocol || this.form.com2Protocol;

            const pbIoset_tranProtocol  = binaryAppendZero(Number(com2Protocol), 4)
                                        + binaryAppendZero(Number(com1Protocol), 4);

            obj.pbIoset_tranProtocol = binaryToDec(pbIoset_tranProtocol);

            delete obj.com1Protocol;
            delete obj.com2Protocol;
          }

          // 充放电协议、COM速率
          if (obj.gbChgMaxCur || obj.gbMsgDelay || obj.com1Baud || obj.com2Baud) {
            const gbChgMaxCur = obj.gbChgMaxCur || this.form.gbChgMaxCur;
            let gbMsgDelay = obj.gbMsgDelay || this.form.gbMsgDelay;
            const com1Baud = obj.com1Baud || this.form.com1Baud;
            const com2Baud = obj.com2Baud || this.form.com2Baud;

            // 因为延迟只有两位，所以溢出的部分要全部去掉
            gbMsgDelay = binaryAppendZero(Number(gbMsgDelay), 4);
            gbMsgDelay = gbMsgDelay.slice(gbMsgDelay.length - 2);

            const gbChgMaxCur_ComBaud = gbMsgDelay
                                      + binaryAppendZero(Number(gbChgMaxCur), 6)
                                      + binaryAppendZero(Number(com1Baud), 4)
                                      + binaryAppendZero(Number(com2Baud), 4);

            obj.gbChgMaxCur_ComBaud = binaryToDec(gbChgMaxCur_ComBaud);

            delete obj.gbChgMaxCur;
            delete obj.gbMsgDelay;
            delete obj.com1Baud;
            delete obj.com2Baud;
          }

          // 温控协议
          if (obj.tempCanType || obj.tempProtocol || obj.strings) {
            const tempCanType = obj.tempCanType || this.form.tempCanType;
            const tempProtocol = obj.tempProtocol || this.form.tempProtocol;
            const strings = obj.strings || this.form.strings;

            const range = binaryAppendZero(Number(tempCanType), 2)
                        + binaryAppendZero(Number(tempProtocol), 4)
                        + binaryAppendZero(Number(strings), 2);

            obj.range = binaryToDec(range);

            delete obj.tempCanType;
            delete obj.tempProtocol;
            delete obj.strings;
          }

          // can Speed
          if (obj.canBaud1 || obj.canBaud2 ) {
            const canBaud1 = obj.canBaud1 || this.form.canBaud1;
            const canBaud2 = obj.canBaud2 || this.form.canBaud2;

            const canBaud  = binaryAppendZero(Number(canBaud1), 4)
                            + binaryAppendZero(Number(canBaud2), 4);
            obj.canBaud = binaryToDec(canBaud);

            delete obj.canBaud1;
            delete obj.canBaud2;
          }

          if (obj.ntcCnt || obj.pullUresistor || obj.ntc) {
            const ntcCnt = obj.ntcCnt || this.form.ntcCnt;
            const pullUresistor = obj.pullUresistor || this.form.pullUresistor;
            const ntc = obj.ntc || this.form.ntc;

            const tempAndNumRes = binaryAppendZero(Number(ntcCnt), 4)
                                + binaryAppendZero(Number(pullUresistor), 2)
                                + binaryAppendZero(Number(ntc), 2);

            obj.tempAndNumRes = binaryToDec(tempAndNumRes);

            delete obj.pullUresistor;
            delete obj.ntc;
            delete obj.ntcCnt;
          }

          // 高低边
          if (obj.dischargeContactor || obj.preChargeContactor) {
            const dischargeContactor = obj.dischargeContactor || this.form.dischargeContactor;
            const preChargeContactor = obj.preChargeContactor || this.form.preChargeContactor;

            const hsdxCfg0  = binaryAppendZero(Number(dischargeContactor), 4)
                            + binaryAppendZero(Number(preChargeContactor), 4);

            obj.hsdxCfg0 = binaryToDec(hsdxCfg0);

            delete obj.dischargeContactor;
            delete obj.preChargeContactor;
          }

          if (obj.mainSlaveContactor || obj.chargeContactor) {
            const mainSlaveContactor = obj.mainSlaveContactor || this.form.mainSlaveContactor;
            const chargeContactor = obj.chargeContactor || this.form.chargeContactor;

            const hsdxCfg1  = binaryAppendZero(Number(mainSlaveContactor), 4)
                            + binaryAppendZero(Number(chargeContactor), 4);

            obj.hsdxCfg1 = binaryToDec(hsdxCfg1);

            delete obj.mainSlaveContactor;
            delete obj.chargeContactor;
          }

          if (obj.buzzer || obj.heatingContactor) {
            const buzzer = obj.buzzer || this.form.buzzer;
            const heatingContactor = obj.heatingContactor || this.form.heatingContactor;

            const hsdxCfg2  = binaryAppendZero(Number(buzzer), 4)
                            + binaryAppendZero(Number(heatingContactor), 4);

            obj.hsdxCfg2 = binaryToDec(hsdxCfg2);

            delete obj.buzzer;
            delete obj.heatingContactor;
          }

          if (obj.externalFaultLight || obj.externalIndicator) {
            const externalFaultLight = obj.externalFaultLight || this.form.externalFaultLight;
            const externalIndicator = obj.externalIndicator || this.form.externalIndicator;

            const hsdxCfg3  = binaryAppendZero(Number(externalFaultLight), 4)
                            + binaryAppendZero(Number(externalIndicator), 4);

            obj.hsdxCfg3 = binaryToDec(hsdxCfg3);

            delete obj.externalFaultLight;
            delete obj.externalIndicator;
          }

          if (obj.upsWorkSwitch || obj.upsForceCharging) {
            const upsForceCharging = obj.upsForceCharging || this.form.upsForceCharging;
            const upsWorkSwitch = obj.upsWorkSwitch || this.form.upsWorkSwitch;

            const hsdxCfg4  = binaryAppendZero(Number(upsForceCharging), 4)
                            + binaryAppendZero(Number(upsWorkSwitch), 4);
            obj.hsdxCfg4 = binaryToDec(hsdxCfg4);

            delete obj.upsWorkSwitch;
            delete obj.upsForceCharging;
          }

          output = modbusApi.objectToAddrVal(this.start, this.size, obj);

          // 这里对显示值进行还原
          if (this.isConfiginitSoc) {
            obj.initSoc = protoInitSoc;
          }

          this.protoObj = JSON.parse(JSON.stringify(obj));

          if (this.fakeMode) {
            setTimeout(() => {
              if (callback && typeof callback === "function")  {
                callback();
              }
            }, 2000);
          } else {
            try {
              const params = {
                code: this.deviceInfo.code,
                origin: calcHexFromUnsignedDec(this.start),
                size: output.length,
                type: 7, // 参数配置
                data: output.join("").toUpperCase()
              };
              
              await modbusApi.write(params);
              this.$message.success("参数下发送成功");
            } catch(err) {
              console.log(err);
              this.$message.warning("参数下发送失败");
            }
            $utils.setDeviceDone(this.deviceInfo);
            this.loading = false;
          }
        }
      });
    },
    onConfigBattery () {
      this.onSaveConfig();
      this.$emit("battery");
    },
  }
}
</script>
<style lang="scss" scoped>
.config-box {
  display: inline-block;
  width: calc(100% / 3);
  overflow: auto;
  padding: 10px;
  vertical-align: top;
}

::v-deep .el-input-group__append {
  width: 70px;
  text-align: center;
}

.slave-control-box {
  >div {
    display: inline-block;
  }
  >div:first-child {
    width: 70px;
  }
  >div:nth-child(2), >div:nth-child(3) {
    width: calc((100% - 86px) / 2);
    margin-left: 8px;
  }
}
</style>