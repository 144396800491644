<template>
  <div v-loading="loading">
    <div>
      <div class="data-box">
        <div>
          <div class="legend-box">
            <div class="f-right">
              <i class="icon-obj v-min-bg" />
              <span>最低电压</span>
            </div>
            <div class="f-right m-r-20">
              <i class="icon-obj v-max-bg" />
              <span>最高电压</span>
            </div>
            <div class="f-right m-r-20">
              <i class="icon-obj ban-bg" />
              <span>被屏蔽</span>
            </div>
            <div class="f-right m-r-20">&nbsp;</div>
            <div class="f-right m-r-20">
              <span class="legend-span">图例</span>
            </div>
            <div>
              <span class="legend-span">电压</span>
            </div>
          </div>
        </div>
        <el-table :data="vDataList" border style="width: 100%" :height="height" :show-header="false">
          <el-table-column type="index" width="55" align="center">
            <template slot-scope="scope">
              {{ (scope.$index + 1) }}#
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div class="item-box">
                <template v-if="scope.row !== null">
                  <div>上报时间：{{ scope.row.createTime }}</div>
                  <viewvt-item :items="scope.row" type="C" name="v" :length="getlength('v', scope.$index)" />
                </template>
                <template v-else>暂无数据</template>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="data-box">
        <div>
          <div class="legend-box">
            <div class="f-right">
              <i class="icon-obj t-min-bg" />
              <span>最低温度</span>
            </div>
            <div class="f-right m-r-20">
              <i class="icon-obj t-max-bg" />
              <span>最高温度</span>
            </div>
            <div class="f-right m-r-20">
              <i class="icon-obj equalize-bg" />
              <span>均衡温度</span>
            </div>
            <div class="f-right m-r-20">
              <i class="icon-obj ban-bg" />
              <span>被屏蔽</span>
            </div>
            <div class="f-right m-r-20">
              <span class="legend-span">图例</span>
            </div>
            <div>
              <span class="legend-span">温度</span>
            </div>
          </div>
        </div>
        <el-table :data="tDataList" border style="width: 100%" :height="height" :show-header="false">
          <el-table-column type="index" width="55" align="center">
            <template slot-scope="scope">
              {{ (scope.$index + 1) }}#
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div class="item-box">
                <template v-if="scope.row !== null">
                  <div>上报时间：{{ scope.row.createTime }}</div>
                  <viewvt-item :items="scope.row" type="T" name="t" :length="getlength('t', scope.$index)" />
                </template>
                <template v-else>暂无数据</template>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="right-btns m-t-20">
      <el-button size="small" :type=" visible ? '' : 'primary'" @click="onReadConfig">参数读取</el-button>
      <el-button size="small" :disabled="!canConfig" @click="onConfigBattery" v-if="visible" type="primary">参数配置</el-button>
    </div>
  </div>
</template>
<script>
import { systemConfig, getDevicesTemperature, getDevicesVoltages } from '@/api/device';
import { checkResult } from "@/utils/device";

import TemperatureMixin from "@/components/battery-template/mixin/temperature";
import VoltageMixin from "@/components/battery-template/mixin/voltage";

import ViewvtItem from "./viewvt-item";
import { mapGetters } from 'vuex';

const { calcUnsignedDecFromHex, decAppendZero } = $numberMatrixing;

export default {
  computed: {
    ...mapGetters(['user'])
  },
  props: {
    deviceInfo: {
      type: Object,
      default: {}
    },
    height: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    },
    voLen: {
      type: Number,
      default: 12
    },
    tpLen: {
      type: Number,
      default: 16
    },
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ""
    }
  },
  components: {
    ViewvtItem
  },
  data () {
    return {
      loading: false,
      canConfig: false,
      masterID: "00",
      vParams: VoltageMixin.data(),
      tParams: TemperatureMixin.data(),
      btnType: {
        voltage: "primary",
        temperature: ""
      },
      viewType: "voltage",
      vDataList: [],
      tDataList: [],
      vLen: 12,
      tLen: 16
    }
  },
  watch: {
    data: function (v1, v2) {
      this.data = v1;
    }
  },
  created () {
    this.vLen = this.voLen;
    this.tpLen = this.tpLen;

    if (this.mode === "single") {
      // TODO 实时刷新
      this.readData();
      // setInterval(() => {
        // this.readData();
      // }, 30 * 1000);
    }
  },
  methods: {
    readData () {
      this.$emit("show");
      try {
        this.loading = true;
        this.getDatas();
      } finally {
        this.loading = false;
      }
    },
    async getDatas () {
      let [ temperatures, voltages ] = await Promise.all([
        getDevicesTemperature(this.deviceInfo.code, this.masterID),
        getDevicesVoltages(this.deviceInfo.code, this.masterID)
      ]);

      temperatures = temperatures.sort((obj1, obj2) => {
        return obj1.bmuNo - obj2.bmuNo;
      });
      voltages = voltages.sort((obj1, obj2) => {
        return obj1.bmuNo - obj2.bmuNo;
      });


      if (temperatures.length > 0) {
        let keys = [];
        this.options = temperatures;
        const tDataMap = {};
        for (let i = 0, len = this.options.length; i < len; i++) {
          let mixObj = Object.assign({}, this.tParams);
          mixObj = Object.assign(this.tParams, this);
          mixObj.user = this.user;
          const tmp = TemperatureMixin.methods.onChange(i, mixObj);
          let index = tmp.index;
          tDataMap[index] = tmp;

          const tValues = [], tObjs = [];

          for (const key in tmp.data) {
            if (key.indexOf("index") >= 0) {
              const obj = tmp.data[key];
              if (obj.type !== "ban") {
                tValues.push(obj.value);
                tObjs.push(obj);
              }
            }
          }

          if (tValues.length > 0) {

            const tMaxVal = Math.max(... tValues);
            const tMaxIndex = tValues.indexOf(tMaxVal);
            tObjs[tMaxIndex].type = "max";

            const tMinVal = Math.min(... tValues);
            const tMinIndex = tValues.indexOf(tMinVal);
            tObjs[tMinIndex].type = "min";
          }

          keys.push(index);
        }

        keys = keys.sort();

        const tDataList = [];
        for (const key of keys) {
          const item = tDataMap[key];
          const tData = [];
          for (let i = 0; i < item.length; i++) {
            const data = item.data[`index${i}`] || item.data[`index${decAppendZero(i, 5)}`];
            if (data) {
              if (data.type === "ban") {
                if (this.user.tenantId === 0) {
                  tData.push(data);
                }
              } else {
                tData.push(data);
              }
            } else {
              tData.push(null);
            }
          }
          let createTime = item.createTime;
          if (Array.isArray(createTime)) {
            createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));
          }
          tDataList.push({data: tData, createTime, length: tData.length});
        }

        this.tDataList = tDataList;
      } else {
        this.tDataList = [];
      }

      if (voltages.length > 0) {
        let keys = [];
        this.options = voltages;
        const vDataMap = {};
        for (let i = 0, len = this.options.length; i < len; i++) {
          let mixObj = Object.assign({}, this.vParams);
          mixObj = Object.assign(this.vParams, this);
          mixObj.user = this.user;
          const tmp = VoltageMixin.methods.onChange(i, mixObj);
          let index = tmp.index;
          vDataMap[index] = tmp;
        const vValues = [], vObjs = [];

          for (const key in tmp.data) {
            if (key.indexOf("index") >= 0) {
              const obj = tmp.data[key];
              if (obj.type !== "ban" && obj.type !== "err") {
                vValues.push(obj.value);
                vObjs.push(obj);
              }
            }
          }

          if (vValues.length > 0) {
            const vMaxVal = Math.max(... vValues);
            const vMaxIndex = vValues.indexOf(vMaxVal);
            vObjs[vMaxIndex].type = "max";

            const vMinVal = Math.min(... vValues);
            const vMinIndex = vValues.indexOf(vMinVal);
            vObjs[vMinIndex].type = "min";
          }

          keys.push(index);
        }

        keys = keys.sort();

        const vDataList = [];
        for (const key of keys) {
          const item = vDataMap[key];
          const vData = [];
          for (let i = 0; i < item.length; i++) {
            const data = item.data[`index${i}`] || item.data[`index${decAppendZero(i, 5)}`];
            if (data) {
              if (data.type === "ban") {
                if (this.user.tenantId === 0) {
                  vData.push(data);
                }
              } else {
                vData.push(data);
              }
            } else {
              vData.push(null);
            }
          }
          let createTime = item.createTime;
          if (Array.isArray(createTime)) {
            createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));
          }
          vDataList.push({data: vData, createTime, length: vData.length});
        }

        this.vDataList = vDataList;
      } else {
        this.vDataList = [];
      }

      this.$forceUpdate();
    },
    calcData (data) {
      const output = [];
      let index = 0;
      for (const key in data) {
        const obj = data[key];
        if (obj.type !== "ban" || this.user.businessId === 1) {
          output.push(obj);
        }
        if (index++ > 14) {
          break;
        }
      }

      return {
        data: output,
        createTime: data.createTime,
        length: output.length
      }
    },
    async onReadConfig () {

      this.loading = true;
      $utils.setDeviceDoing(this.deviceInfo);
      this.masterID = $utils.calcMasterIDToHex(this.data.lecuAddr);

      const params = {
        code: this.deviceInfo.code,
        masterID: this.masterID,
        price: "28160001" // 读取电压和温度前向设备写入这个值
      }

      try {
        const type = "write";
        const res = await systemConfig[type](params);

        // success 写 false，是不在成功后显示 成功提示
        checkResult(this, res, { error: true, success: false, type }, (flag) => {
          if (flag) {
            setTimeout(async () => {
              try {
                await this.getDatas();
              } catch (e) {
                console.log(e);
              }
              $utils.setDeviceDone(this.deviceInfo);
              this.loading = false;
            }, 600 * this.data.afeNum);
          } else {
            $utils.setDeviceDone(this.deviceInfo);
            this.loading = false;
          }
          this.canConfig = true;
        });
      } catch (e) {
        $utils.setDeviceDone(this.deviceInfo);
        this.loading = false;
      }
    },
    calc (res, lenName, { address, length, keyValue }) {
      const output = {};
      res.map((obj) => {

        this[`${lenName}Len`] = obj.digit;
        this[`${lenName}Params`].length = obj.digit;

        const key = calcUnsignedDecFromHex(obj.batteryNo);

        let createTime = obj.createTime;
        createTime = $utils.formatTime(new Date(createTime[0], createTime[1] - 1, createTime[2], createTime[3], createTime[4], createTime[5]));

        output[obj.bmuNo] = {
          num: ((key - address) / length + 1),
          values: obj[keyValue],
          createTime: createTime
        };
      });

      return output;
    },
    calc2 ({ values, createTime }, { length, method, callback }, maskList, timesFlg) {
      const size = length * 4;
      const val = values.slice(0, size);
      const { mask, equalize } = callback(values.slice(size), this.getMask, length);
      maskList.push(mask);

      const data = {}, maxNums = [], minNums = [];

      for (let i = 0; i < length; i++) {
        const value = val.slice(i * 4, (i + 1) * 4);
        const append = equalize ? (equalize.has(i) ? "（均衡温度）": "") : "";
        const m = mask[i];
        const res = $numberMatrixing[method](value) / (timesFlg ? 10 : 1);
        const type = (m === "0") ? (append ? "equalize" : "normal") : "ban";

        if (type !== "ban") {
          maxNums.push(res);
          minNums.push(res);
        } else {
          maxNums.push(-Infinity);
          minNums.push(Infinity);
        }

        data[`index${i}`] = { value: res, type };
      }

      const max = Math.max(... maxNums);
      const min = Math.min(... minNums);
      const maxIndex = maxNums.indexOf(max);
      const minIndex = minNums.indexOf(min);

      data.createTime = createTime;

      return {
        data, length,
        max: {
          value: max,
          index: maxIndex
        },
        min: {
          value: min,
          index: minIndex
        }
      };
    },
    getlength (pre, index) {

      const list = this[`${pre}DataList`];

      let length = 0;
      for (let i = 0; i < index; i++) {
        length += list[index].length;
      }

      return length;
    },
    getMask (value, length) {
      const mask = parseInt(value.slice(0, 4), 16).toString(2).split("");
      while (mask.length < length) {
        mask.unshift("0");
      }
      const output = mask.reverse().join("");
      return output;
    },
    changeDiv (name) {
      this.viewType = name;
      for (const key in this.btnType) {
        this.btnType[key] = "";
      }
      this.btnType[name] = "primary";
    },
    onClosed () {
      return true;
    },
    onConfigBattery () {
      this.$emit("config");
    }
  }
}
</script>
<style lang="scss" scoped>

.data-box {
  display: inline-block;
  width: 50%;
}

::v-deep .el-table__cell {
  padding: 0px;
}

::v-deep .el-table__cell:nth-child(2) .cell,
::v-deep .el-table__cell:nth-child(3) .cell
{
  padding: 0px;
}

.icon-obj {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-right: 10px;
}

.legend-box {
  display: block;
  height: 35px;
  line-height: 20px;

  .legend-span {
    font-weight: bold;
  }
}

.f-right {
  width: 100px;
}

</style>
