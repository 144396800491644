var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "evt-box" }, [
        _c(
          "div",
          { staticClass: "evt-btns-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "evt-btns",
                attrs: {
                  icon: "el-icon-upload2",
                  disabled: _vm.utils.userReadOnly(_vm.user),
                  type: "primary",
                  size: "mini",
                },
                on: { click: _vm.onUpgrade },
              },
              [_vm._v("设备升级")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "evt-btns-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "evt-btns",
                attrs: {
                  icon: "el-icon-d-arrow-right",
                  disabled: _vm.utils.userReadOnly(_vm.user),
                  type: "primary",
                  size: "mini",
                },
                on: {
                  click: function ($event) {
                    return _vm.onShowDialog("synchronizetime")
                  },
                },
              },
              [_vm._v("时间同步")]
            ),
          ],
          1
        ),
      ]),
      _vm.dialogVisible.synchronizetime
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "时间同步",
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.synchronizetime,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("synchronizetime")
                },
              },
            },
            [
              _c("synchronize-time", {
                ref: "synchronizetime",
                attrs: { "device-info": _vm.deviceInfo },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible.upgrade
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.upgradeTitle,
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "show-close": "",
                "close-on-click-modal": true,
                visible: _vm.dialogVisible.upgrade,
                width: "600px",
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseDialog("upgrade")
                },
              },
            },
            [
              _c("upgrade", {
                ref: "upgrade",
                attrs: {
                  data: _vm.rowData,
                  "device-info": _vm.deviceInfo,
                  type: _vm.type,
                  visible: _vm.dialogVisible.upgrade,
                },
                on: {
                  "close-edit-modal": (cb) =>
                    _vm.onCloseDialog("upgrade", true, cb),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "two-select-dialog",
        {
          ref: "twoSelect",
          attrs: { options: _vm.options },
          on: {
            left: function ($event) {
              return _vm.upgrade(false)
            },
            right: function ($event) {
              return _vm.upgrade(true)
            },
            close: _vm.onClose,
          },
        },
        [
          _c("danger-event-ask", {
            ref: "dangerEventAsk",
            attrs: {
              message: `为当前设备（设备编号：${this.deviceInfo.code}）进行升级`,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }