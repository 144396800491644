<template>
  <div class="m-l-15" :style="`height:${height}px; overflow:auto;`">
    <div>
      <ds-title>功率设置</ds-title>
      <set-charging-power :device-info="deviceInfo" />
    </div>
    <div m-t-10>
      <ds-title>电价设置</ds-title>
      <set-electricity-bill :device-info="deviceInfo" />
    </div>
  </div>
</template>
<script>
import SetChargingPower from "./set-charging-power";
import SetElectricityBill from "./set-electricity-bill";

export default {
  components: {
    SetChargingPower,
    SetElectricityBill
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  }
}
</script>
<style lang="scss" scoped>
::v-deep .set-power-box {
  width: 1200px;
}
</style>