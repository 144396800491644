<template>
  <div>
    <div v-if="list.length > 0">
      <div class="list-table" style="margin-left: 10px;">
        <el-table :data="list" :height="height" :style="`width: 100%; overflow:auto;`" @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
          <el-table-column type="selection" width="45" align="center" />
          <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
          <el-table-column label="升级类型" prop="time" width="100" align="center">
            <template slot-scope="scope">
              {{ typeMap[scope.row.type].label }}
            </template>
          </el-table-column>
          <el-table-column label="升级版本号" prop="version" width="150" align="center"></el-table-column>
          <el-table-column label="主控ID" prop="masterId" width="80" align="center"></el-table-column>
          <el-table-column label="升级花费时间" prop="time" align="center"></el-table-column>
          <el-table-column label="升级状态" prop="state" width="100" align="center">
            <template slot-scope="scope">
              <el-tag :type="getStatusVal(scope.row, 'tag')"
                :class="getStatusVal(scope.row, 'tag')"
                size="small" effect="dark">{{ getStatusVal(scope.row, 'label') }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="申请人" prop="applicant"align="center"></el-table-column>
          <el-table-column label="升级时间" prop="createTime" width="180" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <el-empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['routes', 'user']),
  },
  mixins: [ HistoryMixin ],
  data () {
    return {
      statusTxt: $statics.DEVICE_UPGRADE_RESULT_STATUS_LIST,
      typeMap: $statics.FIRMWARE_TYPE_MAP,
      form: {
        state: "",
        type: ""
      }
    }
  },
  methods: {
    setDatas () {
    },
    onSelect () {
      this.$emit("select", this.form);
    },
    getStatusVal (row, key) {
      const { state } = row;
      const val = this.statusTxt[state][key];
      return val;
    },
  }
};
</script>