var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "m-l-15 p-r-10",
      style: `height: ${_vm.height}px; overflow:auto;`,
    },
    [
      _c(
        "el-descriptions",
        {
          attrs: {
            column: 4,
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合有功总电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.totalElectricityCurrentCoipmbinationActive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合有功尖电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.spikeElectricityCurrentCoipmbinationActive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合有功峰电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.peakElectricityCurrentCoipmbinationActive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合有功平电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.meanElectricityCurrentCoipmbinationActive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合有功谷电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.valleyElectricityCurrentCoipmbinationActive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向有功总电能" } },
            [_vm._v(_vm._s(_vm.object1.totalElectricityCurrentPositiveActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向有功尖电能" } },
            [_vm._v(_vm._s(_vm.object1.spikeElectricityCurrentPositiveActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向有功峰电能" } },
            [_vm._v(_vm._s(_vm.object1.peakElectricityCurrentPositiveActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向有功平电能" } },
            [_vm._v(_vm._s(_vm.object1.meanElectricityCurrentPositiveActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向有功谷电能" } },
            [_vm._v(_vm._s(_vm.object1.valleyElectricityCurrentPositiveActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向有功总电能" } },
            [_vm._v(_vm._s(_vm.object1.totalElectricityCurrentReverseActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向有功尖电能" } },
            [_vm._v(_vm._s(_vm.object1.spikeElectricityCurrentReverseActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向有功峰电能" } },
            [_vm._v(_vm._s(_vm.object1.peakElectricityCurrentReverseActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向有功平电能" } },
            [_vm._v(_vm._s(_vm.object1.meanElectricityCurrentReverseActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向有功谷电能" } },
            [_vm._v(_vm._s(_vm.object1.valleyElectricityCurrentReverseActive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合无功总电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.totalElectricityCurrentCoipmbinationReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合无功尖电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.spikeElectricityCurrentCoipmbinationReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合无功峰电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.peakElectricityCurrentCoipmbinationReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合无功平电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.meanElectricityCurrentCoipmbinationReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前组合无功谷电能" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object1.valleyElectricityCurrentCoipmbinationReactive
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向无功总电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.totalElectricityCurrentPositiveReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向无功尖电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.spikeElectricityCurrentPositiveReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向无功峰电能" } },
            [_vm._v(_vm._s(_vm.object1.peakElectricityCurrentPositiveReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向无功平电能" } },
            [_vm._v(_vm._s(_vm.object1.meanElectricityCurrentPositiveReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前正向无功谷电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.valleyElectricityCurrentPositiveReactive)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向无功总电能" } },
            [_vm._v(_vm._s(_vm.object1.totalElectricityCurrentReverseReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向无功尖电能" } },
            [_vm._v(_vm._s(_vm.object1.spikeElectricityCurrentReverseReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向无功峰电能" } },
            [_vm._v(_vm._s(_vm.object1.peakElectricityCurrentReverseReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向无功平电能" } },
            [_vm._v(_vm._s(_vm.object1.meanElectricityCurrentReverseReactive))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当前反向无功谷电能" } },
            [
              _vm._v(
                _vm._s(_vm.object1.valleyElectricityCurrentReverseReactive)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "时间" } }, [
            _vm._v(_vm._s(_vm.object1.time)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "脉冲常数" } }, [
            _vm._v(_vm._s(_vm.object1.pulseConst)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第一路通讯：通信地址" } },
            [
              _vm._v(
                _vm._s(_vm.object1.firstLineCommunicationCommunicationAddress)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第一路通讯：波特率" } },
            [_vm._v(_vm._s(_vm.object1.firstLineCommunicationBaudRate))]
          ),
          _c("el-descriptions-item", { attrs: { label: "第1时区时段表号" } }, [
            _vm._v(_vm._s(_vm.object1.timeTableNumberFirstZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第1时区起始日期" } }, [
            _vm._v(_vm._s(_vm.object1.startDateFirstZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第2时区时段表号" } }, [
            _vm._v(_vm._s(_vm.object1.timeTableNumberSecondZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第2时区起始日期" } }, [
            _vm._v(_vm._s(_vm.object1.startDateSecondZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第3时区时段表号" } }, [
            _vm._v(_vm._s(_vm.object1.timeTableNumberThirdZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第3时区起始日期" } }, [
            _vm._v(_vm._s(_vm.object1.startDateThirdZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第4时区时段表号" } }, [
            _vm._v(_vm._s(_vm.object1.timeTableNumberFourthZone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "第4时区起始日期" } }, [
            _vm._v(_vm._s(_vm.object1.startDateFourthZone)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第一路通讯：校验位" } },
            [_vm._v(_vm._s(_vm.object1.firstLineCommunicationCheckBit))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第一路通讯：停止位" } },
            [_vm._v(_vm._s(_vm.object1.firstLineCommunicationstopBit))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第二路通讯：通信地址" } },
            [
              _vm._v(
                _vm._s(_vm.object1.secondLineCommunicationCommunicationAddress)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第二路通讯：波特率" } },
            [_vm._v(_vm._s(_vm.object1.secondLineCommunicationBaudRate))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第二路通讯：校验位" } },
            [_vm._v(_vm._s(_vm.object1.secondLineCommunicationCheckBit))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "第二路通讯：停止位" } },
            [_vm._v(_vm._s(_vm.object1.secondLineCommunicationstopBit))]
          ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "m-t-10",
          attrs: {
            column: 4,
            border: "",
            size: "mini",
            "label-class-name": "descriptions-label-box",
            "content-class-name": "descriptions-content-box",
          },
        },
        [
          _c("el-descriptions-item", { attrs: { label: "A相电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相电流" } }, [
            _vm._v(_vm._s(_vm.object2.currentAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相电流" } }, [
            _vm._v(_vm._s(_vm.object2.currentBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相电流" } }, [
            _vm._v(_vm._s(_vm.object2.currentCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "频率" } }, [
            _vm._v(_vm._s(_vm.object2.frequency)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A_B线电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageABLine)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B_C线电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageBCLine)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A_C线电压" } }, [
            _vm._v(_vm._s(_vm.object2.voltageACLine)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "正向有功最大需量" } }, [
            _vm._v(_vm._s(_vm.object2.positiveActiveMaximumDemand)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "正向有功最大需量发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurTimePositiveActiveMaximumDemand))]
          ),
          _c("el-descriptions-item", { attrs: { label: "反向有功最大需量" } }, [
            _vm._v(_vm._s(_vm.object2.reverseActiveMaximumDemand)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "反向有功最大需量发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurTimeReverseActiveMaximumDemand))]
          ),
          _c("el-descriptions-item", { attrs: { label: "正向无功最大需量" } }, [
            _vm._v(_vm._s(_vm.object2.positiveReactiveMaximumDemand)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "正向无功最大需量发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurTimePositiveReactiveMaximumDemand))]
          ),
          _c("el-descriptions-item", { attrs: { label: "反向无功最大需量" } }, [
            _vm._v(_vm._s(_vm.object2.reverseReactiveMaximumDemand)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "反向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2.occurTimeReverseReactiveMaximumDemandHourMinute
                )
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "A相正向有功电能" } }, [
            _vm._v(_vm._s(_vm.object2.positiveActiveElectricityAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相正向有功电能" } }, [
            _vm._v(_vm._s(_vm.object2.positiveActiveElectricityBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相正向有功电能" } }, [
            _vm._v(_vm._s(_vm.object2.positiveActiveElectricityCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "电压变比PT" } }, [
            _vm._v(_vm._s(_vm.object2.voltageRatioPT)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "电流变比CT" } }, [
            _vm._v(_vm._s(_vm.object2.currentRatioCT)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "DIDO状态,失压状态" } },
            [_vm._v(_vm._s(_vm.object2.didoStateNoVoltageState))]
          ),
          _c("el-descriptions-item", { attrs: { label: "运行状态" } }, [
            _vm._v(_vm._s(_vm.object2.runningState)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "零序电流" } }, [
            _vm._v(_vm._s(_vm.object2.zeroSequenceCurrent)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "电压不平衡度" } }, [
            _vm._v(_vm._s(_vm.object2.voltageUnbalanceDegree)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "电流不平衡度" } }, [
            _vm._v(_vm._s(_vm.object2.currentUnbalanceDegree)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相有功功率" } }, [
            _vm._v(_vm._s(_vm.object2.activePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相有功功率" } }, [
            _vm._v(_vm._s(_vm.object2.activePowerBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相有功功率" } }, [
            _vm._v(_vm._s(_vm.object2.activePowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总有功功率" } }, [
            _vm._v(_vm._s(_vm.object2.totalActivePower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相无功功率" } }, [
            _vm._v(_vm._s(_vm.object2.reactivePowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相无功功率" } }, [
            _vm._v(_vm._s(_vm.object2.reactivePowerBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相无功功率" } }, [
            _vm._v(_vm._s(_vm.object2.reactivePowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总无功功率" } }, [
            _vm._v(_vm._s(_vm.object2.totalReactivePower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相视在功率" } }, [
            _vm._v(_vm._s(_vm.object2.apparentPowerAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相视在功率" } }, [
            _vm._v(_vm._s(_vm.object2.apparentPowerBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相视在功率" } }, [
            _vm._v(_vm._s(_vm.object2.apparentPowerCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总视在功率" } }, [
            _vm._v(_vm._s(_vm.object2.totalApparentPower)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相功率因数" } }, [
            _vm._v(_vm._s(_vm.object2.powerFactorAPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "B相功率因数" } }, [
            _vm._v(_vm._s(_vm.object2.powerFactorBPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "C相功率因数" } }, [
            _vm._v(_vm._s(_vm.object2.powerFactorCPhase)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "总功率因数" } }, [
            _vm._v(_vm._s(_vm.object2.totalPowerFactor)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日正向有功最大需量" } },
            [_vm._v(_vm._s(_vm.object2.maximumPositiveActivePowerDemandToday))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日正向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeTodayMaximumPositiveActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日反向有功最大需量" } },
            [_vm._v(_vm._s(_vm.object2.maximumReverseActivePowerDemandToday))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日反向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2.occurrenceTimeTodayMaximumReverseActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日正向无功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumPositiveReactivePowerDemandToday)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日正向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeTodayMaximumPositiveReactivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日反向无功最大需量" } },
            [_vm._v(_vm._s(_vm.object2.maximumReverseReactivePowerDemandToday))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "当日反向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeTodayMaximumReverseReactivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日正向有功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumPositiveActivePowerDemandLastOneDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日正向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastOneDayMaximumPositiveActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日反向有功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumReverseActivePowerDemandLastOneDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日反向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastOneDayMaximumReverseActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日正向无功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumPositiveReactivePowerDemandLastOneDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日正向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastOneDayMaximumPositiveReactivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日反向无功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumReverseReactivePowerDemandLastOneDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上1日反向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastOneDayMaximumReverseReactivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日正向有功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumPositiveActivePowerDemandLastTwoDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日正向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastTwoDayMaximumPositiveActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日反向有功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumReverseActivePowerDemandLastTwoDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日反向有功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastTwoDayMaximumReverseActivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日正向无功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumPositiveReactivePowerDemandLastTwoDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日正向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastTwoDayMaximumPositiveReactivePowerDemand
                )
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日反向无功最大需量" } },
            [
              _vm._v(
                _vm._s(_vm.object2.maximumReverseReactivePowerDemandLastTwoDay)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "上2日反向无功最大需量发生时间" } },
            [
              _vm._v(
                _vm._s(
                  _vm.object2
                    .occurrenceTimeLastTwoDayMaximumReverseReactivePowerDemand
                )
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "当前正向有功需量" } }, [
            _vm._v(_vm._s(_vm.object2.currentPositiveActiveDemand)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "当前反向有功需量" } }, [
            _vm._v(_vm._s(_vm.object2.currentReverseActiveDemand)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "当前正向无功需量" } }, [
            _vm._v(_vm._s(_vm.object2.currentPositiveReactiveDemand)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "当前反向无功需量" } }, [
            _vm._v(_vm._s(_vm.object2.currentReverseReactiveDemand)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "A相电压极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumVoltageAPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相电压极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMaximumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "B相电压极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumVoltageBPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相电压极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMaximumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "C相电压极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumVoltageCPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相电压极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMaximumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "A相电流极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumCurrentAPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相电流极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMaximumCurrent))]
          ),
          _c("el-descriptions-item", { attrs: { label: "B相电流极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumCurrentBPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相电流极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMaximumCurrent))]
          ),
          _c("el-descriptions-item", { attrs: { label: "C相电流极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumCurrentCPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相电流极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMaximumCurrent))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相有功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumActivePowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相有功功率极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMaximumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相有功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumActivePowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相有功功率极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMaximumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相有功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumActivePowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相有功功率极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMaximumActivePower))]
          ),
          _c("el-descriptions-item", { attrs: { label: "总有功功率极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumTotalActivePower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总有功功率极大值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeTotalMaximumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相无功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumReactivePowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相无功功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeAPhaseMaximumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相无功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumReactivePowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相无功功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeBPhaseMaximumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相无功功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumReactivePowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相无功功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeCPhaseMaximumReactivePower)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "总无功功率极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumTotalReactivePower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总无功功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeTotalMaximumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相视在功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumApparentPowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相视在功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeAPhaseMaximumApparentPower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相视在功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumApparentPowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相视在功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeBPhaseMaximumApparentPower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相视在功率极大值" } },
            [_vm._v(_vm._s(_vm.object2.maximumApparentPowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相视在功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeCPhaseMaximumApparentPower)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "总视在功率极大值" } }, [
            _vm._v(_vm._s(_vm.object2.maximumTotalApparentPower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总视在功率极大值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeTotalMaximumApparentPower)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "A相电压极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumVoltageAPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相电压极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMinimumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "B相电压极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumVoltageBPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相电压极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMinimumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "C相电压极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumVoltageCPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相电压极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMinimumVoltage))]
          ),
          _c("el-descriptions-item", { attrs: { label: "A相电流极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumCurrentAPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相电流极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMinimumCurrent))]
          ),
          _c("el-descriptions-item", { attrs: { label: "B相电流极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumCurrentBPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相电流极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMinimumCurrent))]
          ),
          _c("el-descriptions-item", { attrs: { label: "C相电流极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumCurrentCPhase)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相电流极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMinimumCurrent))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相有功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumActivePowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相有功功率极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeAPhaseMinimumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相有功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumActivePowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相有功功率极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeBPhaseMinimumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相有功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumActivePowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相有功功率极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeCPhaseMinimumActivePower))]
          ),
          _c("el-descriptions-item", { attrs: { label: "总有功功率极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumTotalActivePower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总有功功率极小值发生时间" } },
            [_vm._v(_vm._s(_vm.object2.occurrenceTimeTotalMinimumActivePower))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相无功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumReactivePowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相无功功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeAPhaseMinimumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相无功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumReactivePowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相无功功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeBPhaseMinimumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相无功功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumReactivePowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相无功功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeCPhaseMinimumReactivePower)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "总无功功率极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumTotalReactivePower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总无功功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeTotalMinimumReactivePower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相视在功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumApparentPowerAPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "A相视在功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeAPhaseMinimumApparentPower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相视在功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumApparentPowerBPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "B相视在功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeBPhaseMinimumApparentPower)
              ),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相视在功率极小值" } },
            [_vm._v(_vm._s(_vm.object2.minimumApparentPowerCPhase))]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "C相视在功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeCPhaseMinimumApparentPower)
              ),
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "总视在功率极小值" } }, [
            _vm._v(_vm._s(_vm.object2.minimumTotalApparentPower)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "总视在功率极小值发生时间" } },
            [
              _vm._v(
                _vm._s(_vm.object2.occurrenceTimeTotalMinimumApparentPower)
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }