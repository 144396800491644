<template>
  <div class="m-l-10">
    <div v-loading="loading.main">
      <el-descriptions :title="`主控基本信息【更新时间：${mainCtrl.updateTime || mainCtrl.createTime  || ''}】`" :column="3"
        label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" border size="mini">
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getMainControlData">数据读取</el-button>
        </template>
        <el-descriptions-item label="总电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.voltageTotal) ? (mainCtrl.voltageTotal / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="辅助电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.auxPower) ? (mainCtrl.auxPower / 1000).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="SOC">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.soc) ? (mainCtrl.soc / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="SOH">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.soh) ? (mainCtrl.soh / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="SOP">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.sop) ? (mainCtrl.sop / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="平均 SOC">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.socAvg) ? (mainCtrl.socAvg / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="高边开关状态" :span="3">
          <div class="hlsd-box">
            <div v-for="n in hlsdsName.length" :class="mainCtrl.hlsds[n - 1] ? 'main-color-green': 'main-color-gray'" :key="n">
              <i :class="mainCtrl.hlsds[n - 1]? 'el-icon-circle-check': 'el-icon-circle-close'"></i>
              <span>{{ hlsdsName[n - 1] }}</span>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="绝缘-阻值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.highResistanceBATP) ? (mainCtrl.highResistanceBATP / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="绝缘-阻值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.highResistanceBATN) ? (mainCtrl.highResistanceBATN / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="工作模式">
          <chg-dischg-status :object="mainCtrl" name="workMode" :device-info="deviceInfo" />
        </el-descriptions-item>
        <el-descriptions-item label="总电流">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.current) ? (mainCtrl.current / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="最高电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.maxVol) ? (mainCtrl.maxVol / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">mV</div>
        </el-descriptions-item>
        <el-descriptions-item label="最低电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.minVol) ? (mainCtrl.minVol / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">mV</div>
        </el-descriptions-item>
        <el-descriptions-item label="最高温度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.maxTemp) ? (mainCtrl.maxTemp / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="最低温度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.minTemp) ? (mainCtrl.minTemp / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="最高电压编号">
          {{ utils.checkNumberIsNotEmpty(mainCtrl.maxVolNo) ? (mainCtrl.maxVolNo) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="最低电压编号">
          {{ utils.checkNumberIsNotEmpty(mainCtrl.minVolNo) ? (mainCtrl.minVolNo) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="最高温度编号">
          {{ utils.checkNumberIsNotEmpty(mainCtrl.maxTempNo) ? (mainCtrl.maxTempNo) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="最低温度编号">
          {{ utils.checkNumberIsNotEmpty(mainCtrl.minTempNo) ? (mainCtrl.minTempNo) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="平均电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.avgVol) ? (mainCtrl.avgVol / 1000).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KWH</div>
        </el-descriptions-item>
        <el-descriptions-item label="充电量">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.chgSumKw) ? (mainCtrl.chgSumKw / 1000).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KWH</div>
        </el-descriptions-item>
        <el-descriptions-item label="放电量">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(mainCtrl.dsgSumKw) ? (mainCtrl.dsgSumKw / 1000).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KWH</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-loading="loading.host" class="m-t-20">
      <el-descriptions :title="`主控系统参数信息【更新时间：${systemParam.updateTime || systemParam.createTime || ''}】`"  :column="2"
        label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" border size="mini">
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="getHostSystemParameterData">数据读取</el-button>
        </template>
        <el-descriptions-item label="CO浓度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.coConcentration) ? (systemParam.coConcentration / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="H2浓度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.hconcentration) ? (systemParam.hconcentration / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="VOC浓度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.vocConcentration) ? (systemParam.vocConcentration / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">PPM</div>
        </el-descriptions-item>
        <el-descriptions-item label="消防模块温度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.fireFighting) ? (systemParam.fireFighting / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="消防模块告警">
          {{ utils.checkNumberIsNotEmpty(systemParam.fireFightingAlarm) ? (Number(systemParam.fireFightingAlarm) === 0 ? "无告警" : "有告警") : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="消防告警等级">
          {{ utils.checkNumberIsNotEmpty(systemParam.fireAlarmLevel) ? getAlarmVal(systemParam.fireAlarmLevel) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="CO浓度告警等级">
          {{ utils.checkNumberIsNotEmpty(systemParam.coConcentrationAlarm) ? getAlarmVal2(systemParam.coConcentrationAlarm) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="H2浓度告警等级">
          {{ utils.checkNumberIsNotEmpty(systemParam.hconcentrationAlarm) ? getAlarmVal2(systemParam.hconcentrationAlarm) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="VOC浓度告警等级">
          {{ utils.checkNumberIsNotEmpty(systemParam.vocConcentrationAlarm) ? getAlarmVal2(systemParam.vocConcentrationAlarm) : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="当前过热度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.currentSuperheat) ? (systemParam.currentSuperheat / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="出口冷煤温度变化量">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(systemParam.exportColdCoal) ? (systemParam.exportColdCoal / 1).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="当前开度调节量">
          {{ utils.checkNumberIsNotEmpty(systemParam.currentOpening) ? systemParam.currentOpening : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="上次开度条件量">
          {{ utils.checkNumberIsNotEmpty(systemParam.openingCondition) ? systemParam.openingCondition : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="总调节量">
          {{ utils.checkNumberIsNotEmpty(systemParam.totalAdjustment) ? systemParam.totalAdjustment : "无数据" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import MainControlApi from "@/api/470/MainControl";
import HostSystemParameterApi from "@/api/470/HostSystemParameter";
import modbusApi from "@/utils/modbus";

import ChgDischgStatus from "@/components/chg-dischg-status";
import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

const { decChangeScale, binaryAppendZero, calcHexFromUnsignedDec, calcUnsignedDecFromHex, hexAppendZero } = $numberMatrixing;

export default {
  components: {
    ChgDischgStatus
  },
  mixins: [ TimerPickerSettingsMixin ],
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      utils: $utils,
      loading: {
        basic: false,
        host: false
      },
      hlsdsName: [ "高边1", "高边2", "高边3", "高边4", "高边5", "高边6", "低边1", "低边2" ],
      calcHeight: 0,
      mainCtrl: {
        workMode: "",
        voltageTotal: "",
        auxPower: "",
        soc: "",
        soh: "",
        sop: "",
        socAvg: "",
        hlsds: [ 0, 0, 0, 0, 0, 0, 0, 0 ],
        highResistanceBATP: "",
        current: "",
        maxVol: "",
        minVol: "",
        maxTemp: "",
        minTemp: "",
        maxVolNo: "",
        minVolNo: "",
        maxTempNo: "",
        minTempNo: "",
        avgVol: "",
        chgSumKw: "",
        dsgSumKw: ""
      },
      systemParam: {
        adjustmentAmount: "",
        closedState: "",
        coConcentration: "",
        coConcentrationAlarm: "",
        currentOpening: "",
        currentSuperheat: "",
        delFlag: "",
        exportColdCoal: "",
        fireAlarmLevel: "",
        fireFighting: "",
        fireFightingAlarm: "",
        firstLevelConcentration: "",
        firstStageOverheating: "",
        hconcentration: "",
        hconcentrationAlarm: "",
        heatBattery: "",
        hfirstLevelConcentration: "",
        hsecondaryConcentration: "",
        hthirdLevelConcentration: "",
        opening: "",
        openingCondition: "",
        secondaryConcentration: "",
        secondaryOverheating: "",
        thirdLevelConcentration: "",
        totalAdjustment: "",
        updateTime: "",
        vfirstLevelConcentration: "",
        vocConcentration: "",
        vocConcentrationAlarm: "",
        vsecondaryConcentration: "",
        vthirdLevelConcentration: ""
      },
      start: {
        main: 0x3370,
        host: 0x3900
      },
      size: {
        main: 48
      },
      basicBk: null,
      object: {},
      objectBk: null,
      rackChargeDischargeStateList: $statics.RACK_CHARGE_DISCHARGE_STATE_LIST,
      rackBk: null,
      timeRange: []
    }
  },
  created () {
    console.log("master-base-detail");
    this.calcHeight = document.body.scrollHeight - 418;
    this.geDatas();
    this.mainBk = JSON.parse(JSON.stringify(this.mainCtrl));
    this.objectBk = JSON.parse(JSON.stringify(this.object));
  },
  methods: {
    async geDatas () {
      this.getMainControlData();
      this.getHostSystemParameterData();
    },
    async getMainControlData () {
      try {
        this.loading.main = true;

        const params = {
          code: this.deviceInfo.code,
          size: 1,
          page: 1
        };

        const res1 = await MainControlApi.list(params);
        const data = res1.data[0];

        const mainCtrl = modbusApi.parse(this.start.main, this.size.main, data.data, true);
        mainCtrl.updateTime = data.updateTime;

        this.mainCtrl = this.parseMainControl(mainCtrl);
        this.$message.success("主控基本信息读取成功");
      } catch (err) {
        this.$message.warning("主控基本信息读取失败");
        console.log(err);
      } finally {
        this.loading.main = false;
      }
    },
    parseMainControl (object) {

      object.chgSumKw1 = calcHexFromUnsignedDec(object.chgSumKw1, 4);
      object.chgSumKw2 = calcHexFromUnsignedDec(object.chgSumKw2, 4);
      object.dsgSumKw1 = calcHexFromUnsignedDec(object.dsgSumKw1, 4);
      object.dsgSumKw2 = calcHexFromUnsignedDec(object.dsgSumKw2, 4);
      object.chgSumKw = Number(`0x${object.chgSumKw1}${object.chgSumKw2}`);
      object.dsgSumKw = Number(`0x${object.dsgSumKw1}${object.dsgSumKw2}`);
      object.workMode = Number(object.workMode);

      delete object.chgSumKw1;
      delete object.chgSumKw2;
      delete object.dsgSumKw1;
      delete object.dsgSumKw2;

      const swVer1 = calcUnsignedDecFromHex(calcHexFromUnsignedDec(object.swVer1 || "0"));
      const swVer2 = hexAppendZero(calcHexFromUnsignedDec(object.swVer2 || "0"));
      const swVerMinor = calcUnsignedDecFromHex(swVer2.slice(0, 2));
      const swVerBuild = calcUnsignedDecFromHex(swVer2.slice(2));

      const hwVer1 = calcUnsignedDecFromHex(calcHexFromUnsignedDec(object.hwVer1 || "0"));
      const hwVer2 = hexAppendZero(calcHexFromUnsignedDec(object.hwVer2 || "0"));
      const hwVerMinor = calcUnsignedDecFromHex(hwVer2.slice(0, 2));
      const hwVerBuild = calcUnsignedDecFromHex(hwVer2.slice(2));

      object.swVer = `${swVer1}.${swVerMinor}.${swVerBuild}`;
      object.hwVer = `${hwVer1}.${hwVerMinor}.${hwVerBuild}`;

      delete object.hwVer1;
      delete object.hwVer2;
      delete object.swVer1;
      delete object.swVer2;

      object.HLSD_state = decChangeScale(object.HLSD_state || "0", 2);
      object.HLSD_state = binaryAppendZero(object.HLSD_state, 8);
      object.HLSD_state = String.reverse(object.HLSD_state);

      object.hlsds = new Array(8).fill(false);
      for (let i = 0; i < 8; i++) {
        if (object.HLSD_state && object.HLSD_state[i]) {
          object.hlsds[i] = object.HLSD_state[i] ==="1";
        }
      }

      delete object.HLSD_state;

      return object;
    },
    async getHostSystemParameterData () {
      try {
        this.loading.host = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await HostSystemParameterApi.last(params);
        if ($utils.checkObjectIsNotEmpty(res)) {
          this.systemParam = res;
          this.$message.success("主控系统参数信息读取成功");
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$message.warning("主控系统参数信息读取失败");
        console.log(err);
      } finally {
        this.loading.host = false;
      }
    },
    getAlarmVal (val) {
      val = Number(val);
      switch (val) {
        case 0:
          return "无告警";
        case 1:
          return "一级告警";
        case 2:
          return "二级告警";
        case 3:
          return "三级告警";
      }
    },
    getAlarmVal2 (val) {
      val = Number(val);
      if (val === 0) {
        return "无告警";
      }

      const bin = binaryAppendZero(decChangeScale(val), 3);
      const output = [];

      if (bin[2] === "1") { // bit0
        output.push("一级告警");
      }
      if (bin[1] === "1") {
        output.push("二级告警");
      }
      if (bin[0] === "1") {
        output.push("伞级告警");
      }

      return output.join();
    }
  }
}
</script>
<style lang="scss" scoped>
.hlsd-box {
  font-size: 14px;
  >div {
    display: inline-block;
    width: 12%;
    span {
      margin-left: 4px;
    }
  }
}

.chg-dischg-data-box {
  .chg-dischg-data-head-box {
    >div {
      display: inline-block;
      vertical-align: middle;
    }
    >div:first-child {
      width: calc(100% - 520px);
    }
    >div:last-child {
      text-align: right;
      width: 500px;
      padding-right: 20px;
    }
  }
}
</style>