<template>
  <div class="content" v-loading="loading">
    <el-form>
      <el-row>
        <el-col :span="8">
          <el-form-item label="查询时间">
            <el-date-picker
              size="mini"
              v-model="timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              @change="onSearch"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <div class="right-btns">
            <el-button class="search-box-btn" type="primary" size="small" @click="onSearch">查询</el-button>
            <el-button class="search-box-btn" type="primary" size="small" style="margin-left: 10px;" @click="onExport">导出</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div v-if="isEmpty">
        <el-empty description="暂无数据" />
      </div>
      <div v-else>
        <el-table :data="sList" :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }"
          border style="width: 100%;" show-summary @selection-change="handleSelectionChange">
          <el-table-column prop="createTime" width="200" label="创建时间"></el-table-column>
          <el-table-column prop="chgVal" label="充电量(KWH)" align="center"></el-table-column>
          <el-table-column prop="chgBill" label="充电电费(元)" align="center"></el-table-column>
          <el-table-column prop="dischgVal" label="放电量(KWH)" align="center"></el-table-column>
          <el-table-column prop="dischgBill" label="放电电费(元)" align="center"></el-table-column>
        </el-table>
        <div>
          <div ref="electricityChart" class="bottom-chart" :style="`height: ${height}px;`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import electricityApi from "@/api/electricity";
import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

export default {
  mixins: [ TimerPickerSettingsMixin ],
    props: {
        deviceInfo: undefined
    },
    data () {
      return {
        isEmpty: false,
        loading: false,
        utils: $utils,
        timeRange: null,
        sList: [],
        eList: [],
        dates: [],
        height: 0
      }
    },
    mounted () {
      this.height = document.body.scrollHeight - 655;
      this.onSearch();
    },
    methods: {
      async onSearch  () {
        await this.getDatas();
        this.draw();
      },
      async onExport () {
        await this.getDatas();
        const sheetNames = ["充放电价"];
        $utils.createExcel(this.eList, sheetNames, `${this.deviceInfo.code}-充放电价报表.xlsx`);
      },
      async getDatas() {
        try {
          this.isEmpty = false;
          this.loading = true;

          const params = this.getParams(undefined, 1, 100);
          if (params === null) return;
      
          const res = await Promise.all([
              electricityApi.chgPrice(params),
              electricityApi.dischgPrice(params)
          ]);

          const keys = Object.keys(res[0]).sort();
          const sList = [], eList = [];
          let tChgBill = 0, tChgVal = 0, tDischgBill = 0, tDischgVal = 0;

          for (const key of keys) {
            let chgBill = res[0][key]["电费"], chgVal = res[0][key]["充电"], 
                  dischgBill = res[1][key]["电费"], dischgVal = res[1][key]["放电"];

            const sObj = {
              createTime: key,
              chgBill, chgVal, dischgBill, dischgVal
            };

            tChgBill += chgBill;
            tChgVal += chgVal;
            tDischgBill += dischgBill;
            tDischgVal += dischgVal;

            const eObj = {
              "时间": key,
              "充电电量": chgVal,
              "充电电费": chgBill,
              "放电电量": dischgVal,
              "放电电费": dischgBill
            };

            sList.push(sObj);
            eList.push(eObj);
          }

          eList.push({
            "时间": "合计",
            "充电电量": tChgVal,
            "充电电费": tChgBill,
            "放电电量": tDischgVal,
            "放电电费": tDischgBill
          });

          this.sList = sList;
          this.eList = eList;
          this.dates = keys;
        } catch (e) {
          this.isEmpty = true;
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      draw () {
        const chart = echarts.init(this.$refs.electricityChart);
        chart.clear();

        const xDatas = [];
        const chgBillList = [], chgValList = [], dischgBillList = [], dischgValList = [];

        for (const obj of this.sList) {
          xDatas.push(obj.createTime);
          if($utils.isDevelopMode() && !obj.chgBill) {
            chgBillList.push((Math.random() * 20).toFixed(2));
          } else {
            chgBillList.push(obj.chgBill);
          }
          if($utils.isDevelopMode() && !obj.chgVal) {
            chgValList.push((Math.random() * 100).toFixed(2));
          } else {
            chgValList.push(obj.chgVal);
          }
          if($utils.isDevelopMode() && !obj.dischgBill) {
            dischgBillList.push((Math.random() * 20).toFixed(2));
          } else {
            dischgBillList.push(obj.dischgBill);
          }
          if($utils.isDevelopMode() && !obj.dischgVal) {
            dischgValList.push((Math.random() * 100).toFixed(2));
          } else {
            dischgValList.push(obj.dischgVal);
          }
        }

        const bills = chgBillList.concat(dischgBillList);
        const vals = chgValList.concat(dischgValList);

        const billMax = Math.ceil(Math.max(... bills));
        const valMax =  Math.ceil(Math.max(... vals));

        const options = {
        grid: {
          right: "50px",
          bottom: "40px",
          left: "50px",
        },
        legend: {
          data: [ '充电电费', '充电量', '放电电费', '放电量' ],
          right: "center", //组件离容器左侧的距离，可以是left,center,right，也可以是像素px和百分比10%
          top: "20px"
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [
          {
            type: 'category',
            data: xDatas
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'left',
            name: "充电量(KWH)",
            show: true,
            min: 0,
            max: valMax,
            plitNumber: 5, //设置坐标轴的分割段数
          },
          {
            type: 'value',
            position: 'right',
            align:'left',
            name: "电费(元)",
            min: 0,
            max: billMax,
            plitNumber: 5, //设置坐标轴的分割段数
            axisLabel: { // y轴文字的配置
              textStyle: {
                align:'left',
              }
            },
          },
        ],
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {

            const output = [];

            for (const obj of params) {
              const unit = (obj.seriesName.indexOf("电费") > 0) ? "元" : "KWH";
              output.push(`${obj.marker}<span style="display:inline-block;width:70px;">${obj.seriesName}</span><span style="display:inline-block;width:110px;">${obj.value}</span>${unit}`);
            }

            return`<p><b>${params[0].axisValueLabel}</b></p>`+  output.join("<br />");
          }
        },
        series: [
          {
            name: '充电电费',
            type: 'bar',
            data: chgBillList,
            yAxisIndex: 1,
            label: {
              show: true
            }
          },
          {
            name: '充电量',
            type: 'bar',
            data: chgValList,
            label: {
              show: true
            }
          },
          {
            name: '放电电费',
            type: 'bar',
            yAxisIndex: 1,
            data: dischgBillList,
            label: {
              show: true
            }
          },
          {
            name: '放电量',
            type: 'bar',
            data: dischgValList,
            label: {
              show: true
            }
          },
        ]
      };

        chart.setOption(options);
      },
      getParams (ids, page, size) {

        const params ={
          code: this.deviceInfo.code,
          page: page || this.pageData.current,
          size: size || this.pageData.size,
        };

        if (ids) {
          params.ids = ids;
        }

        // 如果时间没有数据，则把时间设置为最近一周
        // 这么设置的原因是后台如果不传入时间可能会有奇怪的问题
        if (this.timeRange === null) {
          const weekAgo = new Date();
          weekAgo.setDate(weekAgo.getDate() - 7);
          this.timeRange = [weekAgo, new Date()];
        }

        if (this.timeRange[1].getTime() - this.timeRange[0].getTime() > 7 * 24 * 3600 * 1000) {
          this.$message.warning("查询时间间隔请勿超过7天");
          return null;
        }

        params.createTime = $utils.formatTime(this.timeRange[0], "YYYY-MM-DD 00:00:00");
        params.updateTime = $utils.formatTime(this.timeRange[1], "YYYY-MM-DD 23:59:59");

        return params;
      }
    }
}
</script>
<style lang="scss" scoped>
.bottom-chart {
  // background: #00FF00;
  width: 100%;
  margin-top: 10px;
}
</style>