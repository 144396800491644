var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-l-10" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.main,
            expression: "loading.main",
          },
        ],
      },
      [
        _c(
          "el-descriptions",
          {
            attrs: {
              title: `主控基本信息【更新时间：${
                _vm.mainCtrl.updateTime || _vm.mainCtrl.createTime || ""
              }】`,
              column: 3,
              "label-class-name": "descriptions-label-box",
              "content-class-name": "descriptions-content-box",
              border: "",
              size: "mini",
            },
          },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-refresh-right",
                      type: "primary",
                      size: "mini",
                    },
                    on: { click: _vm.getMainControlData },
                  },
                  [_vm._v("数据读取")]
                ),
              ],
              1
            ),
            _c("el-descriptions-item", { attrs: { label: "总电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.voltageTotal)
                      ? (_vm.mainCtrl.voltageTotal / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("V")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "辅助电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.auxPower)
                      ? (_vm.mainCtrl.auxPower / 1000).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("V")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "SOC" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.soc)
                      ? (_vm.mainCtrl.soc / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "SOH" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.soh)
                      ? (_vm.mainCtrl.soh / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "SOP" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.sop)
                      ? (_vm.mainCtrl.sop / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "平均 SOC" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.socAvg)
                      ? (_vm.mainCtrl.socAvg / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "高边开关状态", span: 3 } },
              [
                _c(
                  "div",
                  { staticClass: "hlsd-box" },
                  _vm._l(_vm.hlsdsName.length, function (n) {
                    return _c(
                      "div",
                      {
                        key: n,
                        class: _vm.mainCtrl.hlsds[n - 1]
                          ? "main-color-green"
                          : "main-color-gray",
                      },
                      [
                        _c("i", {
                          class: _vm.mainCtrl.hlsds[n - 1]
                            ? "el-icon-circle-check"
                            : "el-icon-circle-close",
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.hlsdsName[n - 1]))]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c("el-descriptions-item", { attrs: { label: "绝缘-阻值" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.mainCtrl.highResistanceBATP
                    )
                      ? (_vm.mainCtrl.highResistanceBATP / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "绝缘-阻值" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.mainCtrl.highResistanceBATN
                    )
                      ? (_vm.mainCtrl.highResistanceBATN / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("%")]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "工作模式" } },
              [
                _c("chg-dischg-status", {
                  attrs: {
                    object: _vm.mainCtrl,
                    name: "workMode",
                    "device-info": _vm.deviceInfo,
                  },
                }),
              ],
              1
            ),
            _c("el-descriptions-item", { attrs: { label: "总电流" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.current)
                      ? (_vm.mainCtrl.current / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("A")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最高电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.maxVol)
                      ? (_vm.mainCtrl.maxVol / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("mV"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最低电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.minVol)
                      ? (_vm.mainCtrl.minVol / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("mV"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最高温度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.maxTemp)
                      ? (_vm.mainCtrl.maxTemp / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("℃")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最低温度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.minTemp)
                      ? (_vm.mainCtrl.minTemp / 10).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("℃")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最高电压编号" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.maxVolNo)
                      ? _vm.mainCtrl.maxVolNo
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最低电压编号" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.minVolNo)
                      ? _vm.mainCtrl.minVolNo
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最高温度编号" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.maxTempNo)
                      ? _vm.mainCtrl.maxTempNo
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "最低温度编号" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.minTempNo)
                      ? _vm.mainCtrl.minTempNo
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "平均电压" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.avgVol)
                      ? (_vm.mainCtrl.avgVol / 1000).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KWH"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "充电量" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.chgSumKw)
                      ? (_vm.mainCtrl.chgSumKw / 1000).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KWH"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "放电量" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(_vm.mainCtrl.dsgSumKw)
                      ? (_vm.mainCtrl.dsgSumKw / 1000).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("KWH"),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.host,
            expression: "loading.host",
          },
        ],
        staticClass: "m-t-20",
      },
      [
        _c(
          "el-descriptions",
          {
            attrs: {
              title: `主控系统参数信息【更新时间：${
                _vm.systemParam.updateTime || _vm.systemParam.createTime || ""
              }】`,
              column: 2,
              "label-class-name": "descriptions-label-box",
              "content-class-name": "descriptions-content-box",
              border: "",
              size: "mini",
            },
          },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-refresh-right",
                      type: "primary",
                      size: "mini",
                    },
                    on: { click: _vm.getHostSystemParameterData },
                  },
                  [_vm._v("数据读取")]
                ),
              ],
              1
            ),
            _c("el-descriptions-item", { attrs: { label: "CO浓度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.coConcentration
                    )
                      ? (_vm.systemParam.coConcentration / 1).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("PPM"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "H2浓度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.hconcentration
                    )
                      ? (_vm.systemParam.hconcentration / 1).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("PPM"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "VOC浓度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.vocConcentration
                    )
                      ? (_vm.systemParam.vocConcentration / 1).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [
                _vm._v("PPM"),
              ]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "消防模块温度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.fireFighting
                    )
                      ? (_vm.systemParam.fireFighting / 1).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("℃")]),
            ]),
            _c("el-descriptions-item", { attrs: { label: "消防模块告警" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.fireFightingAlarm
                    )
                      ? Number(_vm.systemParam.fireFightingAlarm) === 0
                        ? "无告警"
                        : "有告警"
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "消防告警等级" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.fireAlarmLevel
                    )
                      ? _vm.getAlarmVal(_vm.systemParam.fireAlarmLevel)
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "CO浓度告警等级" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.coConcentrationAlarm
                    )
                      ? _vm.getAlarmVal2(_vm.systemParam.coConcentrationAlarm)
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "H2浓度告警等级" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.hconcentrationAlarm
                    )
                      ? _vm.getAlarmVal2(_vm.systemParam.hconcentrationAlarm)
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "VOC浓度告警等级" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.systemParam.vocConcentrationAlarm
                      )
                        ? _vm.getAlarmVal2(
                            _vm.systemParam.vocConcentrationAlarm
                          )
                        : "无数据"
                    ) +
                    " "
                ),
              ]
            ),
            _c("el-descriptions-item", { attrs: { label: "当前过热度" } }, [
              _c("div", { staticClass: "description-value-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.currentSuperheat
                    )
                      ? (_vm.systemParam.currentSuperheat / 1).toFixed(2)
                      : "无数据"
                  )
                ),
              ]),
              _c("div", { staticClass: "description-unit-box" }, [_vm._v("℃")]),
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "出口冷煤温度变化量" } },
              [
                _c("div", { staticClass: "description-value-box" }, [
                  _vm._v(
                    _vm._s(
                      _vm.utils.checkNumberIsNotEmpty(
                        _vm.systemParam.exportColdCoal
                      )
                        ? (_vm.systemParam.exportColdCoal / 1).toFixed(2)
                        : "无数据"
                    )
                  ),
                ]),
                _c("div", { staticClass: "description-unit-box" }, [
                  _vm._v("℃"),
                ]),
              ]
            ),
            _c("el-descriptions-item", { attrs: { label: "当前开度调节量" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.currentOpening
                    )
                      ? _vm.systemParam.currentOpening
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "上次开度条件量" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.openingCondition
                    )
                      ? _vm.systemParam.openingCondition
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
            _c("el-descriptions-item", { attrs: { label: "总调节量" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.utils.checkNumberIsNotEmpty(
                      _vm.systemParam.totalAdjustment
                    )
                      ? _vm.systemParam.totalAdjustment
                      : "无数据"
                  ) +
                  " "
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }