import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-RackAlarm/list?${rand}`, { params });
}

export const codeList = (code, params) => {
    const rand = Date.now();
    return request.get(`module-RackAlarm/rackAlarm/${code}/?${rand}`, { params });
}

export const detail = (params) => {
    const rand = Date.now();
    return request.get(`module-RackAlarm/details?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.get(`module-RackAlarm/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.get(`module-RackAlarm/update?${rand}`, data);
}

export default {
    list,
    codeList,
    detail,
    save,
    update
}