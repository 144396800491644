<template>
  <div class="m-l-15">
    <div>
      <el-select style="width: 240px;" size="mini" v-model="selectVal" @change="onChangeSelect">
        <el-option v-for="(option, index) in selectOptions" :key="index" :label="option.label" :value="option.value"></el-option>
      </el-select>
      <div class="m-t-10" :style="`height: ${height - 38}px; overflow:auto;`">
        <div v-if="selectVal === '100events'">
          <events />
        </div>
        <div v-if="selectVal === '48months'">
          <months />
        </div>
        <div v-if="selectVal === '90days'">
          <days />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import events from "./100-events";
import months from "./48-months";
import days from "./90-days";

export default {
  components: {
    events,
    months,
    days
  },
  props: {
    deviceInfo: undefined,
    height: undefined
  },
  data () {
    return {
      selectVal: "100events",
      selectOptions: [
        {
          value: "100events",
          label: "上1次至上100次事件记录"
        },
        {
          value: "48months",
          label: "上1月至上48月电能及需量块"
        },
        {
          value: "90days",
          label: "上1日至上90日电能及需量块"
        }
      ]
    }
  },
  methods: {
    onChangeSelect () {
      console.log(this.selectVal);
    }
  }
}
</script>