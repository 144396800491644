var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.table,
            border: "",
            height: _vm.height,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "上N月" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" 上" + _vm._s(scope.row.$index) + "月 ")]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "冻结时间" } }),
          _c("el-table-column", { attrs: { label: "正向总有功电能" } }),
          _c("el-table-column", { attrs: { label: "正向有功尖电能" } }),
          _c("el-table-column", { attrs: { label: "正向有功峰电能" } }),
          _c("el-table-column", { attrs: { label: "正向有功平电能" } }),
          _c("el-table-column", { attrs: { label: "正向有功谷电能" } }),
          _c("el-table-column", { attrs: { label: "反向总有功电能" } }),
          _c("el-table-column", { attrs: { label: "反向有功尖电能" } }),
          _c("el-table-column", { attrs: { label: "反向有功峰电能" } }),
          _c("el-table-column", { attrs: { label: "反向有功平电能" } }),
          _c("el-table-column", { attrs: { label: "反向有功谷电能" } }),
          _c("el-table-column", { attrs: { label: "正向总无功电能" } }),
          _c("el-table-column", { attrs: { label: "正向无功尖电能" } }),
          _c("el-table-column", { attrs: { label: "正向无功峰电能" } }),
          _c("el-table-column", { attrs: { label: "正向无功平电能" } }),
          _c("el-table-column", { attrs: { label: "正向无功谷电能" } }),
          _c("el-table-column", { attrs: { label: "反向总无功电能" } }),
          _c("el-table-column", { attrs: { label: "反向无功尖电能" } }),
          _c("el-table-column", { attrs: { label: "反向无功峰电能" } }),
          _c("el-table-column", { attrs: { label: "反向无功平电能" } }),
          _c("el-table-column", { attrs: { label: "反向无功谷电能" } }),
          _c("el-table-column", { attrs: { label: "A相有功电能" } }),
          _c("el-table-column", { attrs: { label: "B相有功电能" } }),
          _c("el-table-column", { attrs: { label: "C相有功电能" } }),
          _c("el-table-column", { attrs: { label: "正向有功最大需量" } }),
          _c("el-table-column", {
            attrs: { label: "正向有功最大需量发生时间" },
          }),
          _c("el-table-column", { attrs: { label: "反向有功最大需量" } }),
          _c("el-table-column", {
            attrs: { label: "反向有功最大需量发生时间" },
          }),
          _c("el-table-column", { attrs: { label: "正向无功最大需量" } }),
          _c("el-table-column", {
            attrs: { label: "正向无功最大需量发生时间" },
          }),
          _c("el-table-column", { attrs: { label: "反向无功最大需量" } }),
          _c("el-table-column", {
            attrs: { label: "反向无功最大需量发生时间" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }