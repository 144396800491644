<template>
  <div v-loading="loading">
    <div class="alarm-box">
      <el-button class="alarm-btn" @click="showDialog('alarm')">一级告警</el-button>
      <el-button class="alarm-btn" @click="showDialog('warning')">二级告警</el-button>
      <el-button class="alarm-btn" @click="showDialog('criticalAlarm')">三级告警</el-button>
    </div>
    <el-dialog title="一级告警" destroy-on-close :close-on-press-escape="false" show-close append-to-body
      :close-on-click-modal="true" :visible="dialogVisible.alarm" @close="onCloseDialog('alarm', false)" width="60%">
      <alarms ref="alarm" :device-info="deviceInfo" pre="alarm" :position="0" :is-new-ver="gwIsNewSoft" />
    </el-dialog>
    <el-dialog title="二级告警" destroy-on-close :close-on-press-escape="false" show-close append-to-body
      :close-on-click-modal="true" :visible="dialogVisible.warning"  @close="onCloseDialog('warning', false)" width="60%">
      <alarms ref="warning" :device-info="deviceInfo" pre="warning" :position="1" :is-new-ver="gwIsNewSoft" />
    </el-dialog>
    <el-dialog title="三级告警" destroy-on-close :close-on-press-escape="false" show-close append-to-body
      :close-on-click-modal="true" :visible="dialogVisible.criticalAlarm" @close="onCloseDialog('criticalAlarm', false)" width="60%">
      <alarms ref="criticalAlarm" :device-info="deviceInfo" pre="criticalAlarm" :position="2" :is-new-ver="gwIsNewSoft" />
    </el-dialog>
  </div>
</template>
<script>
import alarms from "./alarms";

export default {
  components: {
    alarms
  },
  props: {
    deviceInfo: undefined,
    level: undefined,
    data: undefined
  },
  data () {
    return {
      dialogVisible: {
        alarm: false,
        warning: false,
        criticalAlarm: false
      }
    }
  },
  methods: {
    onClosed () {
      return true;
    },
    showDialog (refName) {
      this.dialogVisible[refName] = true;
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.alarm-box {
  text-align: center;

  .alarm-btn {
    width: calc(30% - 60px);
    margin: 30px;
  }
}
</style>