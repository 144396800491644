<template>
  <div style="height: 100%;">
    <device-tabs  :device-info="deviceInfo" :tab-name="tabName" :height="height" @tab-click="onTabClick">
      <template>
        <el-tab-pane name="basic" label="基本数据">
          <detail v-if="tabName === 'basic'" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="time-table" label="时段表">
          <time-table v-if="tabName === 'time-table'" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="others" label="其他数据">
          <others v-if="tabName === 'others'" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="soe-log" label="SOE 事件记录">
          <soe-log v-if="tabName === 'soe-log'" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
      </template>
    </device-tabs>
  </div>
</template>
<script>
// import basic from "./basic";
import detail from "./detail";
import TimeTable from "./time-table";
import others from "./others";
import SoeLog from "./soe-log";
import DeviceTabs from "./../comnponents/device-tabs";

export default {
  components: {
    // basic,
    detail,
    TimeTable,
    others,
    DeviceTabs,
    SoeLog
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      tabName: "basic",
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  },
  methods: {
    onTabClick (evt) {
      this.tabName = evt;
    }
  }
}
</script>