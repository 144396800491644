var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.table,
            "header-cell-style": {
              background: "#F7F7F7",
              color: "#2d2d2d",
              "text-align": "center",
            },
            border: "",
            height: _vm.height,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "45", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            1 +
                            (_vm.page.current - 1) * _vm.page.size
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "设备编号",
              prop: "code",
              width: "100",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "主控 ID",
              prop: "masterId",
              width: "80",
              align: "center",
            },
          }),
          _c("el-table-column", { attrs: { label: "修改值", prop: "price" } }),
          _c("el-table-column", {
            attrs: {
              label: "操作对象",
              prop: "type",
              width: "100",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("span", [_vm._v("系统配置")])
                      : _vm._e(),
                    scope.row.type === 2
                      ? _c("span", [_vm._v("告警参数")])
                      : _vm._e(),
                    scope.row.type === 3
                      ? _c("span", [_vm._v("电池选项")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作人",
              prop: "username",
              width: "150",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作时间", width: "160", prop: "createTime" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }