<template>
  <div style="height: 100%;">
    <device-tabs  :device-info="deviceInfo" :tab-name="tabName" :height="height" @tab-click="onTabClick">
      <template>
        <el-tab-pane name="basic" label="实时数据" style="height: 100%;">
          <detail v-if="tabName === 'basic'" :device-info="deviceInfo" :height="height" style="height: 100%;" />
        </el-tab-pane>
        <el-tab-pane name="viewvt" label="电压温度" style="height: 100%">
          <viewvt v-if="tabName === 'viewvt'" ref="vt" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="device-alarm" label="设备告警" style="height: 100%">
          <device-alarm v-if="tabName === 'device-alarm'" ref="alarm" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="peak-valley" label="尖峰平谷" style="height: 100%">
          <peak-valley v-if="tabName === 'peak-valley'" ref="peakvalley" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
        <el-tab-pane name="history-data" label="历史数据" style="height: 100%">
          <history-data v-if="tabName === 'history-data'" ref="history" :device-info="deviceInfo" :height="height" />
        </el-tab-pane>
      </template>
    </device-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import detail from "./detail";
import viewvt from "./viewvt";
import DeviceAlarm from "./device-alarm";
import HistoryData from "./../comnponents/history-data";
import PeakValley from './peak-valley';
import DeviceTabs from "./../comnponents/device-tabs";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    detail,
    viewvt,
    DeviceAlarm,
    HistoryData,
    PeakValley,
    DeviceTabs
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      tabName: "basic",
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  },
  methods: {
    onTabClick (evt) {
      this.tabName = evt;
    }
  }
}
</script>