import LogMonomerTemperature from "./LogMonomerTemperature";
import LogMonomerVoltage from "./LogMonomerVoltage";
import LogRackAlarm from "./LogRackAlarm";
import MainRackInformation from "../MainRackInformation";
import DayChargingDischarging from "../DayChargingDischarging";
import MasterControlAlarm from "../MasterControlAlarm";
import LogHostTemperature from "./LogHostTemperature";
import LogHostVoltage from "./LogHostVoltage";
import ElectricityPeaksValley from "../ElectricityPeaksValleys";

export default {
    temperature: LogMonomerTemperature,
    voltage: LogMonomerVoltage,
    rackAlarm: LogRackAlarm,
    rack: MainRackInformation,
    daily: DayChargingDischarging,
    masterAlarm: MasterControlAlarm,
    hostTemperature: LogHostTemperature,
    hostVoltage: LogHostVoltage,
    peakValley: ElectricityPeaksValley
}