<template>
  <div>
    <div v-if="list.length > 0">
      <el-form class="tool-bar-box">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item label="编号">
              <el-select size="mini" v-model="selectedKey" @change="onSelect">
                <el-option v-for="(key, index) in productKeys" :key="index" :label="key" :value="key"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="只显示最高电压、最低电压、中间电压">
              <el-checkbox v-model="onlyMaxMin" @change="draw"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="charts-box" :style="`height: ${height - 60}px;`" ref="chart"></div>
      </div>
    <div v-else>
      <el-empty description="暂无数据" />
    </div>
  </div>
</template>
<script>
import HistoryMixin from "./mixin";
import VoltageMixin from "@/components/battery-template/mixin/voltage";
import { mapGetters } from 'vuex';
const { decAppendZero } = $numberMatrixing;

let selectSeries = null;

export default {
  mixins: [ HistoryMixin, VoltageMixin ],
  data () {
    return {
      onlyMaxMin: false,
      table: [],
      columns: [],
      banned: {},
      chargingDischargingStatusMap: {},
      keyValue: "voltage",
      method: "calcUnsignedDecFromHex",
      length: 16,
      isMounted: false,
      selectedKey: "",
      productMap: {},
      productKeys: []
    }
  },
  computed: {
    ...mapGetters(['routes', 'user']),
  },
  methods: {
    setDatas () {

      if (this.list.length > 0) {
        this.list.map((o) => {
          o.num = o.bmuNo;
        });

        const productMap = {}, productKeys = [];

        for (const item of this.list) {
          let product = productMap[item.productId];
          if (product === undefined) {
            product = productMap[item.productId] = [];
            productKeys.push(item.productId);
          }
          product.push(item);
        }

        this.productKeys = productKeys.sort();
        this.productMap = productMap;
        this.selectedKey = productKeys[0];
      }
      this.onSelect();
    },
    onSelect () {
      this.options = this.productMap[this.selectedKey];
      const data = {}, chargingDischargingStatusMap = {}, maxLen = [];
      for (let i = 0, len = this.options.length; i < len; i++) {
        const tmp = this.onChange(i);
        data[tmp.createTime] = tmp;
        chargingDischargingStatusMap[tmp.createTime] = tmp.chargingDischargingStatus || 0;
        maxLen.push(tmp.size);
      }

      this.maxLen = Math.max(... maxLen);
      this.table = data;
      this.chargingDischargingStatusMap = chargingDischargingStatusMap;

      this.$nextTick(() => {
        this.draw();
      });
    },
    draw () {
      try {
        if (!this.list.length > 0) return;
        this.loading = true;

        const chart = echarts.init(this.$refs.chart);
        chart.clear();
        const options = this.initOption();

        chart.on('mousemove',function(params){
          selectSeries = params;
        }); 
        //当 鼠标移出线条时触发 ，如果不处理这个，鼠标移到空白上还有tooltip显示。
        chart.on('mouseout', function (params) {
          selectSeries = null;
        });

        chart.setOption(options);
      } finally {
        this.loading = false;
      }
    },
    initOption () {

      const xDatas = $utils.createXDatas(this.table);
      const pyDatas = [];

      for (let i = 0; i < this.maxLen; i++) {
        const yData = [];
        for (const key of xDatas) {
          const item = this.table[key];
          const data = item.data[`index${i}`] || item.data[`index${decAppendZero(i, 5)}`];
          if (data) {
            // 因为每个数据都会被单独屏蔽，所以需要把每个点的编号和时间单独记录再在页面中显示出屏蔽状态
            if (data.type === "ban") {
              yData.push(null);
            } else {
              yData.push(data.value);
            }
          } else {
            yData.push(null);
          }
        }
        pyDatas.push(yData);
      }

      const legendData = [];

      for (let i = 0; i < this.maxLen; ) {
        legendData.push(`C#${++i}`);
      }

      const options = this.createOptions(xDatas, legendData, pyDatas, "电压(mV)");

      return options;
    },
    createOptions (xAxis, legendData, metaData, yTitleName) {

      xAxis = xAxis.map(obj => {
        obj = obj.replace(" ", "\n");
        return obj;
      });

      const that = this;
      const serieData = [];
      let object = null;
      let max = 0;
      let min = 0;
      let colors = [ "#f59a23", "#1890ff" ];

      if (this.onlyMaxMin) {
        colors = [ "#f54623", "#fac858", "#1890ff" ];
        legendData = ["最高电压", "中间电压" , "最低电压"];

        const res = this.calcMaxMin(metaData, xAxis);
        object = res.object;
        max = Math.max(... res.max);
        min = Math.min(... res.min);

        serieData.push({
          name: legendData[0],
          type: 'line',
          symbol: "circle",
          symbolSize: 10,
          data: res.max
        });
        serieData.push({
          name: legendData[1],
          type: 'line',
          symbol: "circle",
          symbolSize: 10,
          data: res.middle
        });
        serieData.push({
          name: legendData[2],
          type: 'line',
          symbol: "circle",
          symbolSize: 10,
          data: res.min
        });
      } else {
        let maxVals = [];
        for (let i = 0; i < metaData.length; i++) {
          let serie = {
              name: legendData[i],
              type: 'line',
              symbol: "circle",
              symbolSize: 10,
              data: metaData[i]
          };
          serieData.push(serie);

          // 因为有些时候设备会读不到数据获得 null，这里就是要排除 null(为了保险，一并排除 undefined)
          maxVals = maxVals.concat(metaData[i].filter(obj => {
            return obj !== null && obj !== undefined;
          }));
        }
        // max = Math.max(... maxVals);
        // min = Math.min(... maxVals);
        const ret = $utils.arrayMinMax(maxVals);
        min = ret[0];
        max = ret[1];
      }

      let { maxTimes, minTimes } = this.calcMaxMinTimes({max, min});

      const options = {
        legend: {
          show: true,
          data: legendData,
          y: "5%",
          left: "40px",
          orient: 'vertical',
          type: "scroll",
          itemWidth: 18,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 14
          }
        },
        color: colors,
        grid: {
          top: '7%',
          left: '130px',
          right: '50px',
          bottom: '50px',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',
            orient: 'horizontal',
            start: (xAxis.length - 8) / xAxis.length * 100,
            end: 100,
          },
          {
            orient: 'vertical',
            start: Math.trunc(min * minTimes) / max * 100,
            end: 100
          }
        ],
        tooltip: {
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            let x = 0, y = 0;
            let pointX = point[0], pointY = point[1];
            let boxWidth = size.contentSize[0], boxHeight = size.contentSize[1];

            if (boxWidth > pointX) {
              x = "5%";
            } else {
              x = pointX - boxWidth;
            }

            if (boxHeight > pointY) {
              y = 5;
            } else {
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (params) {

            if (selectSeries !== null) {

              const obj = params.filter(item => {
                return item.seriesName === selectSeries.seriesName;
              })[0];

              let chgStatusLabel = null;
              let selectSeriesName = selectSeries.name;
              selectSeriesName = selectSeriesName.replace(/(\r\n|\r|\n)/, " ");
              const chargingDischargingStatus = that.chargingDischargingStatusMap[selectSeriesName] || 0;
              switch (chargingDischargingStatus) {
                case 0:
                  chgStatusLabel = "静置";
                  break;
                case 1:
                  chgStatusLabel = "放电";
                  break;
                case 2:
                  chgStatusLabel = "充电";
                  break;
                default:
                chgStatusLabel = chargingDischargingStatus;
              }

              let bannedLabel = "";

              let seriesName = obj.seriesName;
              if (that.onlyMaxMin) {
                if (seriesName === "最高电压") {
                  seriesName += `(C#${object[selectSeries.name].max})`;
                } else {
                  seriesName += `(C#${object[selectSeries.name].min})`;
                }
              }

              const str = `<div>${selectSeriesName}</div><div style="margin:5px 0px;">${obj.marker} ${seriesName}${bannedLabel}： ${obj.value.toFixed(2)} mV</div><div>工作状态：${chgStatusLabel}</div>`;
              return str;
            }
          },
          axisPointer: {
            type: 'shadow',
            snap: true
          }
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { show: true, lineStyle: { color: '#000' } },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisTick: { show: false },
            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yTitleName,
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { textStyle: { color: '#000', fontSize: 14 } },
            axisLine: { show: true, lineStyle: { color: '#000' } }
          },
        ],
        series: serieData
      };

      return options;
    },
    createXAxis (xDatas, callback) {
      let startTime = this.timeRange[0].getTime();
      let endTime = this.timeRange[1].getTime();

      const xAxisData = [];

      for (; startTime <= endTime; startTime += 3600 * 24 * 1000) {
        const date = $utils.formatTime(new Date(startTime), "YYYY-MM-DD");
        xAxisData.push(date);

        const index = xDatas.indexOf(date);

        callback(index);
      }

      return xAxisData;
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .label-box {
  width: 80px !important;
}

.val-box-name {
  padding: 0px 5px;
}
</style>