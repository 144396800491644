<template>
  <div class="m-l-10">
    <div class="m-t-20 chg-dischg-data-box" v-loading="loading.rack">
      <el-descriptions :title="`Rack【更新时间：${rackObj.updateTime || ''}】`" :column="2" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" border size="mini">
        <template slot="extra">
          <el-button icon="el-icon-refresh-right" type="primary" size="mini" @click="onReadRackData">数据读取</el-button>
        </template>
        <el-descriptions-item label="Rack运行状态">
          {{ utils.checkObjectIsNotEmpty(rackObj.rackRunState) ? rackObj.rackRunState: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack预充电阶段">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackPrechargePhase) ? rackObj.rackPrechargePhase: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack接触器状态">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackConnectionState) ? rackObj.rackConnectionState: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack故障">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackFault) ? rackObj.rackFault: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack切入失败原因">
            {{ utils.checkObjectIsNotEmpty(rackObj.reasonRackStep) ? rackObj.reasonRackStep: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack未启动切入原因">
            {{ utils.checkObjectIsNotEmpty(rackObj.theReasonRackDid) ? rackObj.theReasonRackDid: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack预充总压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackPrechargeTotalVol) ? (rackObj.rackPrechargeTotalVol / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack电池总电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackRackVoltage) ? (rackObj.rackRackVoltage / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack电池总电流">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackCurrent) ? (rackObj.rackCurrent / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">A</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack充放电指示">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackChargeDischargeState) ? (rackChargeDischargeStateList[rackObj.rackChargeDischargeState] || "无数据") : "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="SOC">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackSoc) ? (rackObj.rackSoc / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="SOH">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackSoh) ? (rackObj.rackSoh / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">%</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack绝缘值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackInsulationValue) ? (rackObj.rackInsulationValue).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KΩ</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack正极绝缘值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackPositiveLnsulationValue) ? (rackObj.rackPositiveLnsulationValue).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KΩ</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack负极绝缘值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackNegativeLnsulationValue) ? (rackObj.rackNegativeLnsulationValue).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">KΩ</div>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="Rack最大充电电流">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMaxChargeCurrent) ? rackObj.rackMaxChargeCurrent: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack最大放电电流">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMaxDischargeCurrent) ? rackObj.rackMaxDischargeCurrent: "无数据" }}
        </el-descriptions-item> -->
        <el-descriptions-item label="Rack单体电压最高节序号">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMaxVolCellId) ? rackObj.rackMaxVolCellId: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体最高电压值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackMaxCellVoltage) ? (rackObj.rackMaxCellVoltage).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">mV</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体电压最低节序号">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMinVolCellId) ? rackObj.rackMinVolCellId: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体最低电压值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackMinCellVoltage) ? (rackObj.rackMinCellVoltage).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">mV</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体温度最高节序号">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMaxTemperatureCellId) ? rackObj.rackMaxTemperatureCellId: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体温度最高值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackMaxCellTemperature) ? (rackObj.rackMaxCellTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体温度最低节序号">
            {{ utils.checkObjectIsNotEmpty(rackObj.rackMinTemperatureCellId) ? rackObj.rackMinTemperatureCellId: "无数据" }}
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体温度最低值">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackMinCellTemperature) ? (rackObj.rackMinCellTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack平均电压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackAverageVoltage) ? (rackObj.rackAverageVoltage).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">mV</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack平均温度">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackAverageTemperature) ? (rackObj.rackAverageTemperature / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack单体累加和总压">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.rackCellVoltageCumulativeSum) ? (rackObj.rackCellVoltageCumulativeSum / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">V</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度1">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp1) ? (rackObj.hvbTemp1 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度2">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp2) ? (rackObj.hvbTemp2 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度3">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp3) ? (rackObj.hvbTemp3 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度4">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp4) ? (rackObj.hvbTemp4 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度5">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp5) ? (rackObj.hvbTemp5 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度6">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp6) ? (rackObj.hvbTemp6 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度7">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp7) ? (rackObj.hvbTemp7 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
        <el-descriptions-item label="Rack高压箱温度8">
          <div class="description-value-box">{{ utils.checkNumberIsNotEmpty(rackObj.hvbTemp8) ? (rackObj.hvbTemp8 / 10).toFixed(2) : "无数据" }}</div>
          <div class="description-unit-box">℃</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import MainRackInformationApi from "@/api/470/MainRackInformation";

import ChgDischgStatus from "@/components/chg-dischg-status";
import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

export default {
  components: {
    ChgDischgStatus
  },
  mixins: [ TimerPickerSettingsMixin],
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      utils: $utils,
      loading: {
        basic: false,
        rack: false
      },
      calcHeight: 0,
      rackChargeDischargeStateList: $statics.RACK_CHARGE_DISCHARGE_STATE_LIST,
      rackObj: {
        hvbTemp1: "",
        hvbTemp2: "",
        hvbTemp3: "",
        hvbTemp4: "",
        hvbTemp5: "",
        hvbTemp6: "",
        hvbTemp7: "",
        hvbTemp8: "",
        rackAverageTemperature: "",
        rackAverageVoltage: "",
        rackCellVoltageCumulativeSum: "",
        rackChargeDischargeState: "",
        rackConnectionState: "",
        rackCurrent: "",
        rackFault: "",
        rackInsulationValue: "",
        rackMaxCellTemperature: "",
        rackMaxCellVoltage: "",
        rackMaxChargeCurrent: "",
        rackMaxDischargeCurrent: "",
        rackMaxTemperatureCellId: "",
        rackMaxVolCellId: "",
        rackMinCellTemperature: "",
        rackMinCellVoltage: "",
        rackMinTemperatureCellId: "",
        rackMinVolCellId: "",
        rackNegativeLnsulationValue: "",
        rackPositiveLnsulationValue: "",
        rackPrechargePhase: "",
        rackPrechargeTotalVol: "",
        rackRackVoltage: "",
        rackRunState: "",
        rackSoc: "",
        rackSoh: "",
        reasonRackStep: "",
        theReasonRackDid: "",
      },
      rackBk: null,
      timeRange: []
    }
  },
  created () {
    console.log("master-rack");
    this.calcHeight = document.body.scrollHeight - 418;
    this.onReadRackData();
    this.rackBk = JSON.parse(JSON.stringify(this.rackObj));
  },
  methods: {
    async onReadRackData () {
      try {
        this.loading.rack = true;
        const params = {
          code: this.deviceInfo.code
        };
        const res = await MainRackInformationApi.least(params);
        this.rackObj = res;
        this.$message.success("Rack 读取成功");
      } catch (err) {
        this.$message.warning("Rack 读取失败");
      } finally {
        this.loading.rack = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hlsd-box {
  font-size: 14px;
  >div {
    display: inline-block;
    width: 12%;
    span {
      margin-left: 4px;
    }
  }
}

.chg-dischg-data-box {
  .chg-dischg-data-head-box {
    >div {
      display: inline-block;
      vertical-align: middle;
    }
    >div:first-child {
      width: calc(100% - 520px);
    }
    >div:last-child {
      text-align: right;
      width: 500px;
      padding-right: 20px;
    }
  }
}
</style>