var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _vm.list.length > 0
        ? _c("div", [
            _c("div", { staticClass: "charts-box" }, [
              _c("div", {
                ref: "cTotalconsoleChart",
                style: `height: ${_vm.height * 0.3 - 5}px; margin-top:10px;`,
              }),
              _c("div", {
                ref: "vTotalconsoleChart",
                style: `height: ${_vm.height * 0.3 - 5}px; margin-top:10px;`,
              }),
              _c("div", {
                ref: "tTotalconsoleChart",
                style: `height: ${_vm.height * 0.4 - 10}px; margin-top:10px;`,
              }),
            ]),
          ])
        : _c(
            "div",
            [_c("el-empty", { attrs: { description: "暂无数据" } })],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }