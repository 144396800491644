var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "padding-left": "10px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "时间范围" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  align: "left",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  "default-time": _vm.defaultTime,
                                  "picker-options": _vm.pickerOptions,
                                  size: "mini",
                                  format: "yyyy-MM-dd HH:mm",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.timeRange,
                                  callback: function ($$v) {
                                    _vm.timeRange = $$v
                                  },
                                  expression: "timeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticClass: "seach-btn-box" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.getLineMode },
                              },
                              [_vm._v("轨迹查询")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.getLastGps },
                              },
                              [_vm._v("末次GPS定位")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "行驶轨迹" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    disabled: _vm.startDisable,
                                  },
                                  on: { click: _vm.startTimer },
                                },
                                [_vm._v("开始")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    disabled: _vm.pauseDisable,
                                  },
                                  on: { click: _vm.pauseTimer },
                                },
                                [_vm._v(_vm._s(_vm.timerPauseBtnTxt))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    disabled: _vm.stopDisable,
                                  },
                                  on: { click: _vm.stopTimer },
                                },
                                [_vm._v("结束")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "行驶轨迹时间" } },
                            [
                              _c("el-input", {
                                staticClass: "track-input-box",
                                attrs: { size: "mini", readonly: "" },
                                model: {
                                  value: _vm.trackInfo.time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trackInfo, "time", $$v)
                                  },
                                  expression: "trackInfo.time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "GPS信号" } },
                            [
                              _c("el-input", {
                                staticClass: "gps-input-box",
                                attrs: { size: "mini", readonly: "" },
                                model: {
                                  value: _vm.trackInfo.signal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trackInfo, "signal", $$v)
                                  },
                                  expression: "trackInfo.signal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "距离(米)" } },
                            [
                              _c("el-input", {
                                staticClass: "distance-input-box",
                                attrs: { size: "mini", readonly: "" },
                                model: {
                                  value: _vm.trackInfo.distance,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trackInfo, "distance", $$v)
                                  },
                                  expression: "trackInfo.distance",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { style: `height:${_vm.boxHeight} ;` }, [
        _c("div", { staticClass: "track-map" }, [
          _vm.noData
            ? _c(
                "div",
                [_c("el-empty", { attrs: { description: "暂无数据" } })],
                1
              )
            : _c(
                "div",
                { style: `height:${_vm.height}px;` },
                [
                  _c(
                    "amap",
                    {
                      ref: "map",
                      attrs: {
                        "cache-key": "home-map",
                        zoom: _vm.zoom,
                        center: _vm.center,
                      },
                    },
                    [
                      _c("amap-marker", { attrs: { position: _vm.center } }),
                      _c("amap-tool-bar", {
                        attrs: { extraOptions: _vm.extraOptions },
                      }),
                      _c("amap-scale", { attrs: { position: "LB" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }