import request from '@/utils/request';

export const least = (params) => {
    const rand = Date.now();
    return request.get(`module-DayChargingDischarging/DailyRecharge/count?${rand}`, { params });
}

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-DayChargingDischarging/list?${rand}`, { params });
}

export const save = (params) => {
    const rand = Date.now();
    return request.post(`module-DayChargingDischarging/save?${rand}`, { params });
}

export default {
    least,
    list,
    save
}