<template>
  <div style="height: 100%;">
    <device-tabs  :device-info="deviceInfo" :tab-name="tabName" :height="height" @tab-click="onTabClick">
      <template>
        <el-tab-pane name="detail" label="消防数据" style="height: 100%;">
          <detail v-if="tabName === 'detail'" :device-info="deviceInfo" :height="height" style="height: 100%;" />
        </el-tab-pane>
      </template>
    </device-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import detail from "./detail";
import DeviceTabs from "./../comnponents/device-tabs";

export default {
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    detail,
    DeviceTabs,
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      tabName: "detail",
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  },
  methods: {
    onTabClick (evt) {
      this.tabName = evt;
    }
  }
}
</script>