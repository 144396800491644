var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "view-alarm-box",
    },
    [
      _c(
        "div",
        { style: `overflow: auto; height: ${_vm.height}px;` },
        [
          _c(
            "el-descriptions",
            {
              attrs: {
                title: "三级告警信息",
                column: 5,
                contentClassName: "des-content-box",
                border: "",
                size: "small",
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "总压过高(0.1V)" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[0]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[1]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[2]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "总压过低(0.1V)" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[3]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[4]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[5]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "单体过高(mV)" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[6]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[7]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[8]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "单体过低(mV)" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[9]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[10]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[11]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "高段压差过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[12]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[13]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[14]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "中段压差过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[15]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[16]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[17]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "低段压差过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[18]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[19]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[20]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "辅助电压过低" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[21]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[22]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[23]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "充电电流过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[24]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[25]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[26]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "放电电流过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[27]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[28]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[29]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "反充电流过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[30]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[31]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[31]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "SOC 值过低(‰)" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[33]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[34]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[35]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "SOH 值过低(‰)" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[36]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[37]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[38]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "继电器温度过高" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[39]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[40]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[41]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "充电温度过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[42]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[43]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[44]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "放电温度过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[45]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[46]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[47]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "充电温度过低" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[48]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[49]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[50]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "放电温度过低" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[51]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[52]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[53]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "温差过大(0.1℃)" } },
                [
                  _c(
                    "div",
                    {
                      class: _vm.warningData[54]
                        ? "warning-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("一级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[55]
                        ? "alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("二级告警")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.warningData[56]
                        ? "critical-alarm-color"
                        : "normal-icon-box",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [_vm._v("三级告警")]),
                    ]
                  ),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "均衡温度过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[57]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[58]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[59]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "绝缘电阻过低" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[60]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[61]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[62]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "总压采样异常" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[63]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[64]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[65]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "静置温度过高" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[66]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[67]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[68]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "静置温度过低" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[69]
                      ? "warning-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("一级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[70]
                      ? "alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("二级告警")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[71]
                      ? "critical-alarm-color"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("三级告警")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "主正接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[72]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[73]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "主负接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[74]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[75]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "充电接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[76]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[77]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "预热接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[78]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[79]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "风扇接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[80]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[81]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
              _c("el-descriptions-item", { attrs: { label: "预充接触器" } }, [
                _c(
                  "div",
                  {
                    class: _vm.warningData[82]
                      ? "warning-icon-box_1"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("无法吸合")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    class: _vm.warningData[83]
                      ? "warning-icon-box_2"
                      : "normal-icon-box",
                  },
                  [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _c("span", [_vm._v("粘连故障")]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", [_vm._v("自检告警")]),
          _vm._l(_vm.faultsNames, function (faultsName) {
            return _c(
              "div",
              {
                class:
                  "faults-box " +
                  (_vm.faults[faultsName.key]
                    ? "warning-icon-box_1"
                    : "normal-icon-box"),
              },
              [
                _c("i", { staticClass: "el-icon-warning" }),
                _c("span", [_vm._v(_vm._s(faultsName.label))]),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-20" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onReadConfig } },
            [_vm._v("参数读取")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }