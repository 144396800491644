<template>
  <div v-loading="loading">
    <div>
      <el-row>
        <el-col span="12">
          <div class="legend-box">
            <div class="legend-name-box" :style="`width:${isSmallScreen ? 15 : 25}%;`">
              <span class="legend-span">图例</span>
            </div>
            <div class="legend-val-box"></div>
            <div class="legend-val-box"></div>
            <div class="legend-val-box">
              <i class="icon-obj v-min-bg" />
              <span>最低电压</span>
            </div>
            <div class="legend-val-box">
              <i class="icon-obj v-max-bg" />
              <span>最高电压</span>
            </div>
            <!-- <div class="legend-val-box">
              <i class="icon-obj ban-bg" />
              <span>被屏蔽</span>
            </div> -->
          </div>
          <div class="vt-data-box-wrapper" :style="`height: ${height}px;`">
            <div class="vt-data-name">电压</div>
            <div v-if="vObjList.length > 0">
              <div v-if="vData.effective > 0" v-for="(vData, index) in vObjList" class="vt-data-big-box" :key="index">
                <div class="vt-data-afe-box">{{ index + 1 }}#</div>
                <div class="vt-data-afe-box">
                  <!-- <el-tooltip content="Bottom center" placement="bottom" effect="light"> -->
                    <div class="upload-time-box">上报时间：{{ vData.createTime }}</div>
                    <div class="vt-data-afe-data-box" v-if="vData.size > 0">
                      <div v-if="vData.data.length > 0" v-for="(data, vIndex) in vData.data" :key="vIndex">
                        <div v-if="data.type !== 'ban' && data.type !== 'err'" class="vt-data-inner-box">
                          <!-- <el-tooltip :content="getTypeVal('v', data.type)" placement="right" effect="light"> -->
                          <div :class="'vt-data-box voltage-data-box ' + getClassName('v', data.type)">
                            <div>C#{{ getlength('v', index, vIndex + 1) }}</div>
                            <div>{{ (data.value / times).toFixed(2) }} mV</div>
                          </div>
                        <!-- </el-tooltip> -->
                        </div>
                      </div>
                      <div v-else>暂无数据</div>
                    </div>
                    <div v-else style="padding-top:10px;">暂无数据</div>
                  <!-- </el-tooltip> -->
                </div>
              </div>
              <el-empty v-else description="暂无数据" />
            </div>
            <el-empty v-else description="暂无数据" />
          </div>
        </el-col>
        <el-col span="12">
          <div class="legend-box">
            <div class="legend-name-box" :style="`width:${isSmallScreen ? 15 : 25}%;`">
              <span class="legend-span">图例</span>
            </div>
            <div class="legend-val-box"></div>
            <div class="legend-val-box" v-if="deviceInfo.projectType === 1 || this.deviceInfo.projectType === 6"></div>
            <div class="legend-val-box">
              <i class="icon-obj t-min-bg" />
              <span>最低温度</span>
            </div>
            <div class="legend-val-box">
              <i class="icon-obj t-max-bg" />
              <span>最高温度</span>
            </div>
            <div v-if="deviceInfo.projectType === 0" class="legend-val-box">
              <i class="icon-obj equalize-bg" />
              <span>从控温度</span>
            </div>
            <!-- <div class="legend-val-box">
              <i class="icon-obj ban-bg" />
              <span>被屏蔽</span>
            </div> -->
          </div>
          <div class="vt-data-box-wrapper" :style="`height: ${height}px;`">
            <div class="vt-data-name">温度</div>
            <div v-if="tObjList.length > 0">
              <div v-if="tData.effective > 0" v-for="(tData, index) in tObjList" class="vt-data-big-box" :key="index">
                <div class="vt-data-afe-box">{{ index + 1 }}#</div>
                <div class="vt-data-afe-box">
                  <div class="upload-time-box">上报时间：{{ tData.createTime }}</div>
                  <div class="vt-data-afe-data-box" v-if="tData.size > 0">
                    <div v-for="(data, tIndex) in tData.data" :key="tIndex">
                      <div v-if="data.type !== 'ban' && data.type !== 'err'" class="vt-data-inner-box">
                        <div :class="'vt-data-box temperature-data-box ' + getClassName('t', data.type)">
                          <div>T#{{ getlength('t', index, tIndex + 1) }}</div>
                          <div>{{ (data.value / times).toFixed(2) }} ℃</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else style="padding-top:10px;">暂无数据</div>
                </div>
              </div>
              <el-empty v-else description="暂无数据" />
            </div>
            <el-empty v-else description="暂无数据" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="vt-btn-box right-btns m-t-10">
      <el-button size="small" :type=" visible ? '' : 'primary'" @click="onReadConfig">参数读取</el-button>
      <el-button size="small" :disabled="!canConfig" @click="onConfigBattery" v-if="visible" type="primary">参数配置</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import bizApi from "@/api/business";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  props: {
    deviceInfo: {
      type: Object,
      default: {}
    },
    times: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    },
    vLen: {
      type: Number,
      default: 12
    },
    tLen: {
      type: Number,
      default: 16
    },
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ""
    },
    isSmallScreen: {
      type: Boolean,
      default: false
    },
    vDataList: {
      type: Array,
      default: []
    },
    tDataList: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      config: {
        v: 24,
        t: 16
      },
      configBak: "",
      vObjList: [],
      tObjList: [],
      bizData: null,
      loading: false
    }
  },
  watch: {
    "vDataList": function(v1, v2) {
      this.vDataList = v1;
      this.resize("v");
    },
    "tDataList": function(v1, v2) {
      this.tDataList = v1;
      this.resize("t");
    }
  },
  created () {
    console.log("viewvt");
    this.configBak = JSON.stringify(this.config);
    // this.getBizData();
  },
  methods: {
    async getBizData () {
      try {
        this.loading = true;
        const businessId = this.deviceInfo.staffCode.split(","); // 从 staffCode 中取值，是为了防止 businessId 被上级公司的 businessId 污染
        const res = await bizApi.detail(businessId[businessId.length - 2]);
        this.bizData = res;

        const configs = res.configs;
        const vtConfigMatched = configs.match($statics.VT_CONFIG_REGX);
        if (vtConfigMatched !== null) {
          const vtConfig = JSON.parse(vtConfigMatched[1]);
          this.config = vtConfig;
          this.configBak = vtConfigMatched[1];
        }
      } finally {
        this.loading = false;
      }
    },
    resize (pre) {
      const objList = [];
      const dataList = this[`${pre}DataList`];

      if (this.mode === "assemble") {
        if (dataList.length > 0) {
          const valueList = [], tmpDataList = [];
          const size = Number(this.config[`${pre}`]);
          let tmpList = [];
          let tmpData = null;

          for (const loopData of dataList) {
            tmpData = loopData;
            for (const item of loopData.data) {
              if (item.type === "ban" || item.type === "err") continue;
              tmpList.push(item);
              if (pre === "v" || (pre === "t" && item.type !== "equalize")) {
                tmpDataList.push(item);
                valueList.push(item.value);
              }

              if (tmpList.length === size) {
                objList.push({
                  createTime: loopData.createTime,
                  size: loopData.size,
                  data: tmpList,
                  length: tmpList.length
                });
                tmpList = [];
              }
            }
          }
          if (tmpList.length > 0) {
            objList.push({
              createTime: tmpData.createTime,
              size: tmpData.size,
              data: tmpList,
              length: tmpList.length
            });
          }

          if (valueList.length > 0) {
            const max = Math.max(... valueList);
            const min = Math.min(... valueList);

            const maxIndex = valueList.indexOf(max);
            const minIndex = valueList.indexOf(min);

            tmpDataList[maxIndex].type = "max";
            tmpDataList[minIndex].type = "min";
          }
        }

        this[`${pre}ObjList`] = objList;
      } else {
        for (const item of dataList) {
          const valueList = [], indexList = [];
          for (const data of item.data) {
            if (data.type !== "ban" && data.type !== "err") {
              valueList.push(data.value);
              indexList.push(data);
            }
          }

          if (valueList.length > 0) {
            const max = Math.max(... valueList);
            const min = Math.min(... valueList);

            const maxIndex = valueList.indexOf(max);
            const minIndex = valueList.indexOf(min);

            indexList[maxIndex].type = "max";
            indexList[minIndex].type = "min";
          }
        }
        this[`${pre}ObjList`] = dataList;
      }

      this.$forceUpdate();
    },
    getlength (pre, index, now) {
      const list = this[`${pre}ObjList`];

      let length = 0;
      for (let i = 0; i < index; i++) {
        let subList = list[i];
        for (const data of subList.data) {
          if (data.type !== "ban") {
            length++;
          }
        }
      }

      const thisRow = list[index];
      for (let i = 0; i < now; i++) {
        const data = thisRow.data[i];
        if (data.type !== "ban") {
          length++;
        }
      }

      return length;
    },
    getClassName (pre, type) {
      if (type === "ban" || type === "normal" || type === "equalize") {
        return `${type}-color`;
      } else {
        return `${pre}-${type}-color`;
      }
    },
    getMask (value, length) {
      const mask = parseInt(value.slice(0, 4), 16).toString(2).split("");
      while (mask.length < length) {
      mask.unshift("0");
      }
      const output = mask.reverse().join("");
      return output;
    },
    onReadConfig () {
      this.$emit("read");
    },
    onClosed () {
      return true;
    },
    onConfigBattery () {
      this.$emit("config");
    },
    getTypeVal (type, name) {
      const diff = null;
      if (type === 'v') {
        type = "电压";
      } else if (type === 't') {
        type = "温度";
      }

      switch (name) {
        case "max":
          name = "最高";
          break;
        case "min":
          name = "最低";
          break;
        case "normal":
          name = "常规";
          break;
        case "ban":
          name = "屏蔽";
          break;
        case "equlize":
          name = "均衡";
          break;
        default:
      }

      return `${name}${type}`;
    },
  }
}
</script>