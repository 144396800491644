<template>
  <div v-loading="loading">
    <el-form label-width="250px" ref="form" :model="form" :rules="rules" class="fire-protection-items-box">
      <el-form-item label="CO一级浓度告警值" prop="firstLevelConcentration">
        <el-input v-model="form.firstLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="CO二级浓度告警值" prop="secondaryConcentration">
        <el-input v-model="form.secondaryConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="CO三级浓度告警值" prop="thirdLevelConcentration">
        <el-input v-model="form.thirdLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="H2一级浓度告警值" prop="hfirstLevelConcentration">
        <el-input v-model="form.hfirstLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="H2二级浓度告警值" prop="hsecondaryConcentration">
        <el-input v-model="form.hsecondaryConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="H2三级浓度告警值" prop="hthirdLevelConcentration">
        <el-input v-model="form.hthirdLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="VOC一级浓度告警值" prop="vfirstLevelConcentration">
        <el-input v-model="form.vfirstLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="VOC二级浓度告警值" prop="vsecondaryConcentration">
        <el-input v-model="form.vsecondaryConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="VOC三级浓度告警值" prop="vthirdLevelConcentration">
        <el-input v-model="form.vthirdLevelConcentration" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="一级过热阈值" prop="firstStageOverheating">
        <el-input v-model="form.firstStageOverheating" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="二级过热阈值" prop="secondaryOverheating">
        <el-input v-model="form.secondaryOverheating" size="mini">
          <template #append>0.1 PPM</template>
        </el-input>
      </el-form-item>
      <el-form-item label="关闭状态的时间" prop="closedState">
        <el-input v-model="form.closedState" size="mini">
          <template #append>秒</template>
        </el-input>
      </el-form-item>
      <el-form-item label="调节量确定间隔" prop="adjustmentAmount">
        <el-input v-model="form.adjustmentAmount" size="mini">
          <template #append>秒</template>
        </el-input>
      </el-form-item>
      <el-form-item label="热管和电芯温度的差值可调控系数k" prop="heatBattery">
        <el-input v-model="form.heatBattery" size="mini" />
      </el-form-item>
      <el-form-item label="一个开度多少pls" prop="opening">
        <el-input v-model="form.opening" size="mini" />
      </el-form-item>
    </el-form>
    <div class="right-btns">
      <el-button round type="primary" size="mini" @click="onReadData">数据读取</el-button>
      <el-button round type="primary" size="mini" @click="onSubmitData">数据下发</el-button>
    </div>
  </div>
</template>
<script>
import HostSystemParameterApi from "@/api/470/HostSystemParameter";
import modbusApi from "@/utils/modbus";
import { validateNumber } from "@/utils/validate";

const { calcHexFromUnsignedDec } = $numberMatrixing;

export default {
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      size: 15,
      start: 0x38C8,
      loading: false,
      form: {
        firstLevelConcentration: "",
        secondaryConcentration: "",
        thirdLevelConcentration: "",
        hfirstLevelConcentration: "",
        hsecondaryConcentration: "",
        hthirdLevelConcentration: "",
        vfirstLevelConcentration: "",
        vsecondaryConcentration: "",
        vthirdLevelConcentration: "",
        firstStageOverheating: "",
        secondaryOverheating: "",
        closedState: "",
        adjustmentAmount: "",
        heatBattery: "",
        opening: "",
      },
      rules: {
        firstLevelConcentration: [
          { required: true, message: '请输入CO一级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        secondaryConcentration: [
          { required: true, message: '请输入CO二级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        thirdLevelConcentration: [
          { required: true, message: '请输入CO三级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        hfirstLevelConcentration: [
          { required: true, message: '请输入H2一级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        hsecondaryConcentration: [
          { required: true, message: '请输入H2二级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        hthirdLevelConcentration: [
          { required: true, message: '请输入H2三级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        vfirstLevelConcentration: [
          { required: true, message: '请输入VOC一级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        vsecondaryConcentration: [
          { required: true, message: '请输入VOC二级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        vthirdLevelConcentration: [
          { required: true, message: '请输入VOC三级浓度告警值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        firstStageOverheating: [
          { required: true, message: '请输入一级过热阈值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        secondaryOverheating: [
          { required: true, message: '请输入二级过热阈值', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        closedState: [
          { required: true, message: '请输入关闭状态的时间', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        adjustmentAmount: [
          { required: true, message: '请输入调节量确定间隔', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        heatBattery: [
          { required: true, message: '请输入热管和电芯温度的差值可调控系数k', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
        opening: [
          { required: true, message: '请输入一个开度多少pls', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateNumber(null, value, [], callback);
            },
            trigger: "blur"
          },
        ],
      }
    }
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    async onReadData () {
      try {
        this.loading = true;

        const params = {
          code: this.deviceInfo.code
        };

        const res = await HostSystemParameterApi.last(params);
        if ($utils.checkObjectIsNotEmpty(res)) {
          this.form = res;
          this.$message.success("主控系统参数信息读取成功");
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$message.warning("主控系统参数信息读取失败");
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    onSubmitData () {

      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }

        try {
          this.loading = true;

          const price = modbusApi.objectToAddrVal(this.start, this.size, this.form);

          const params = {
            code: this.deviceInfo.code,
            origin: calcHexFromUnsignedDec(this.start),
            size: price.length,
            type: 12, // 时间同步
            data: price.join("")
          };

          await modbusApi.write(params);
          this.$message.success("参数下发成功");
        } catch (err) {
          console.log(err);
          this.$message.warning("参数下发失败");
        } finally {
          this.loading = false;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.fire-protection-items-box {
  >div {
    display: inline-block;
    width: calc(33% - 12px);
    margin: 0px 6px;
    margin-bottom: 22px;
    >div {
      vertical-align: middle;
    }
  }
}
</style>