<template>
    <div :style="`height:${height}px;`" v-loading="loading">
      <div v-if="upgrading">
        <div v-if="deviceInfo.type === 1 || deviceInfo.type === 9"><!-- 升级中、传送中 -->
          <div style="margin:50px 20%;">
            <el-result :title="upgradeMsg">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
            <div style="margin-top: 20px;">
              <el-progress text-color="#2d2d2d" :text-inside="true" :stroke-width="25" :percentage="percentage" :color="customColors"></el-progress>
            </div>
          </div>
        </div>
        <div v-else-if="deviceInfo.type === 3"><!-- 等待中 -->
          <div style="margin:50px 20%;">
            <el-result title="设备升级等待中，请稍候……">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
          </div>
        </div>
        <div v-else-if="deviceInfo.type === 0 && !deviceInfo.online">
          <div style="margin:50px 20%;">
            <el-result title="升级完毕，设备重启中，请稍候……">
              <template slot="icon">
                <el-image style="width: 40%; height: 40%" :src="require('@/assets/images/upgrading.gif')"></el-image>
              </template>
            </el-result>
            <div style="margin-top: 20px;">
              <el-progress text-color="#2d2d2d" :text-inside="true" :stroke-width="25" :percentage="percentage" :color="customColors"></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="height: 100%;">
        <master v-if="deviceInfo.projectType === 0" :device-info="deviceInfo" :height="height" />
        <power v-else-if="deviceInfo.projectType === 9" :device-info="deviceInfo" :height="height" />
        <fire-protection v-else-if="deviceInfo.projectType === 10" :device-info="deviceInfo" :height="height" />
        <water-logging v-else-if="deviceInfo.projectType === 11" :device-info="deviceInfo" :height="height" />
        <aircondition v-else-if="deviceInfo.projectType === 12" :device-info="deviceInfo" :height="height" />
        <enerty-meter v-else-if="deviceInfo.projectType === 8" :device-info="deviceInfo" :height="height" />
        <ems v-else-if="deviceInfo.projectType === 1 || deviceInfo.projectType === 6" :device-info="deviceInfo" :height="height" />
        <pcs v-else-if="deviceInfo.projectType === 7" :device-info="deviceInfo" :height="height" />
      </div>
  </div>
</template>
<script>
import master from "./master";
import power from "./power";
import FireProtection from "./fire-protection";
import WaterLogging from "./water-logging";
import aircondition from "./aircondition";
import EnertyMeter from "./energy-meter";
import ems from "./ems";
import pcs from "./pcs";

export default {
  components: {
    master, // 主控(0)
    power, // 功率(9)
    FireProtection, // 消防(10)
    WaterLogging, // 水浸(11)
    aircondition, // 空调(12)
    EnertyMeter, // 电表(8)
    ems, // EMS(6)
    pcs // PCS(7)
  },
  props: {
    deviceInfo: undefined
  },
  data () {
    return {
      upgrading: false,
      height: 0
    }
  },
  created () {
    this.height = document.body.scrollHeight - 130;
  }
}
</script>