import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`module-Temperature/list?${rand}`, { params });
}

// export const temperatureList = (code, params) => {
//     const rand = Date.now();
//     return request.get(`module-Temperature/Temperature/${code}/?${rand}`, { params });
// }

export const detail = (params) => {
    const rand = Date.now();
    return request.get(`module-Temperature/details?${rand}`, { params });
}

export const save = (data) => {
    const rand = Date.now();
    return request.get(`module-Temperature/save?${rand}`, data);
}

export const update = (data) => {
    const rand = Date.now();
    return request.get(`module-Temperature/update?${rand}`, data);
}

export default {
    list,
    save,
    update,
    // temperatureList,
    detail
}